import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {OrganizationHomePublicComponent} from './organization-home-public/organization-home-public.component';
import {OrganizationRoutes} from './organization.routes';
import {OrganizationsHomeComponent} from './organizations-home/organizations-home.component';
import {OrganizationService} from '@genos/organization-service';
import {OrganizationSearchItemComponent} from './organization-search-item/organization-search-item.component';
import {OrganizationPersonRequestComponent} from './organization-person-request/organization-person-request.component';
import {OrganizationSimpleItemComponent} from './organization-simple-item/organization-simple-item.component';
import {PersonsModule} from '../persons/persons.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {OrganizationCreateFormComponent} from './organization-create-form/organization-create-form.component';
import {
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule
} from '@angular/material';
import {SportsModule} from '../sports/sports.module';
import {PlacesModule} from '../places/places.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {GlobalModule} from '../global/global.module';

@NgModule({
    imports: [
        CommonModule,
        PersonsModule,
        SportsModule,
        PlacesModule,
        GlobalModule,
        MatChipsModule,
        MatInputModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatInputModule,
        FlexLayoutModule,
        FormsModule,
        RouterModule.forChild(OrganizationRoutes),
    ],
    declarations: [
        OrganizationHomePublicComponent,
        OrganizationsHomeComponent,
        OrganizationSearchItemComponent,
        OrganizationPersonRequestComponent,
        OrganizationSimpleItemComponent,
        OrganizationCreateFormComponent],
    exports: [OrganizationSimpleItemComponent,
        OrganizationSearchItemComponent, OrganizationCreateFormComponent],
    providers: [OrganizationService]
})
export class OrganizationModule {
}
