import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfigService, ResponseWithData} from '@mdw/usual-service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {EventCreateRequest} from '../models/event-create-request';
import {EventView} from '../models/event-view';
import {EventStatus} from '../enums/event-status.enum';
import {EventVisibility} from '../enums/event-visibility.enum';

@Injectable({
    providedIn: 'root'
})
export class EventService {

    getBaseUrl(): string {
        return this.appConfigService.getBaseUrl() + '/event/api';
    }

    constructor(protected http: HttpClient,
                private appConfigService: AppConfigService) {
    }

    create(event: EventCreateRequest): Observable<EventView> {
        return this.http.post<ResponseWithData<EventView>>(
            this.getBaseUrl() + '/events', event)
            .pipe(map(p => Object.assign(new EventView(), p.data)));
    }

    prepareFormStatutes(): any[] {
        const statutes = [];
        statutes.push({value: EventStatus.CANCELED, name: 'Annulé'});
        statutes.push({value: EventStatus.WITHDRAWAL, name: 'Forfait'});
        statutes.push({value: EventStatus.PLANNED, name: 'Prévu'});
        statutes.push({value: EventStatus.DONE, name: 'Terminé'});
        return statutes;
    }

    prepareFormVisibilities(): any[] {
        const visibilities = [];
        visibilities.push({value: EventVisibility.PUBLIC, label: 'Publique'});
        visibilities.push({value: EventVisibility.PRIVATE, label: 'Privé'});
        return visibilities;
    }

    getByUri(uri: string): Observable<EventView> {
        return this.http.get<ResponseWithData<EventView>>(
            this.getBaseUrl() + '/events/' + uri).pipe(map(p => Object.assign(new EventView(), p.data)));
    }

    getEventForUser(personUris: string[]): Observable<any> {
        return this.http.get<ResponseWithData<any>>(this.getBaseUrl() + '/events');
    }

}
