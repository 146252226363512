import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Place} from '../models/place';
import {PlacesFilter} from '../models/places-filter';
import {DtoSecurisedService, GlobalSessionService} from '@genos/user-service';
import {AppConfigService} from '@mdw/usual-service';

@Injectable()
export class PlacesService extends DtoSecurisedService<Place> {

    getBaseUrl(): string {
        return this.appConfigService.getSecuredBaseUrl() + '/places';
    }

    constructor(protected sessionService: GlobalSessionService,
                protected client: HttpClient,
                private appConfigService: AppConfigService) {
        super(client, sessionService);
    }

    getAll(filter: PlacesFilter): Observable<Place[]> {
        return this.getArray(this.getBaseUrl() + filter.toUrl(), {}, Place);
    }

    update(place: Place): Observable<Place> {
        return this.put<Place>(this.getBaseUrl() + '/' + place.id, place);
    }

    create(place: Place): Observable<Place> {
        return this.post<Place>(this.getBaseUrl(), place);
    }

    getById(idPlace: number): Observable<Place> {
        return this.getObject(this.getBaseUrl() + '/' + idPlace, {}, Place);
    }
}
