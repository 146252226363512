import {Injectable} from '@angular/core';
import {Game} from '../../game/models/game';
import {GameForm} from '../../game/models/game-form';
import {environment} from '../../../../environments/environment';
import {SportMode} from '../../enums/sport-mode.enum';
import {BscGame} from '../../../bsc/game/models/bsc-game';
import {BscGameForm} from '../../../bsc/game/models/bsc-game-form';
import {NaturalPerson, NaturalPersonForm} from '@genos/person-service';
import {BscNaturalPersonForm} from '../../../bsc/person/models/bsc-natural-person-form';
import {BscNaturalPerson} from '../../../bsc/person/models/bsc-natural-person';

@Injectable()
export class SportModeService {

    constructor() {
    }

    handleGameFromForm(game: Game, form: GameForm): Game {
        if (environment.sportMode === SportMode.BSC) {
            const bscForm = Object.assign(new BscGameForm(), form);
            console.log(bscForm);
            return Object.assign(new BscGame(), game).fromForm(bscForm);
        }
    }

    handleGameToForm(game: Game): GameForm {
        if (environment.sportMode === SportMode.BSC) {
            return Object.assign(new BscGame(), game).toForm();
        }
    }

    handleNaturalPersonFromForm(naturalPerson: NaturalPerson, form: NaturalPersonForm): NaturalPerson {
        if (environment.sportMode === SportMode.BSC) {
            const bscForm = Object.assign(new BscNaturalPersonForm(), form);
            console.log(bscForm);
            return Object.assign(new BscNaturalPerson(), naturalPerson).fromForm(bscForm);
        }
    }

    handleNaturalPersonToForm(naturalPerson: NaturalPerson): NaturalPersonForm {
        if (environment.sportMode === SportMode.BSC) {
            return Object.assign(new BscNaturalPerson(), naturalPerson).toForm();
        }
    }

}
