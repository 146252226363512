<div class="loader" fxLayout="row" *ngIf="loadGame" fxLayoutAlign="center center">
    <p-progressSpinner></p-progressSpinner>
</div>
<app-game-edit *ngIf="isEditMode() && !loadGame"
               (onUpdate)="reloadGame($event)"
               fxLayout="row"
               (showMode)="passToShowMode($event)"
               [game]="currentGame"></app-game-edit>
<app-game-show *ngIf="!isEditMode() && !loadGame"
               (editMode)="passToEditMode($event)"
               (onUpdate)="reloadGame($event)"
               [game]="currentGame"></app-game-show>
