import {Injectable} from '@angular/core';
import {ChildActivationEnd, Router} from '@angular/router';

@Injectable()
export class NavigationService {

    public lastValue: any;
    public newValue: any;
    public animationValue: any;

    constructor(
        private router: Router,
    ) {
        this.router
            .events
            .subscribe((event) => {
                if (event instanceof ChildActivationEnd) {
                    const snapshot = event.snapshot;
                    if (snapshot.data.animationPosition) {
                        this.lastValue = this.newValue;
                        this.newValue = snapshot.data.animationPosition;
                        this.animationValue = this.getAnimationNumber(this.lastValue, this.newValue);
                    }
                }
            });
    }

    getAnimationNumber(lastValue: number, newValue: number): number {
        if (lastValue === undefined) {
            return 0;
        }
        console.log(newValue - lastValue);
        return newValue - lastValue;
    }
}
