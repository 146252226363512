<div fxLayout="column" fxFlex="100">
    <div *ngIf="!isMobile" fxFlex="100" class="buttons" fxLayout="row">
        <div>Nouveau groupe</div>
        <div (click)="addNewMessage()">Nouveau message</div>
    </div>
    <app-main-loader [mode]="loaderMode"></app-main-loader>
    <mat-list fxFlex="100" class="talks-list" *ngIf="!load">
        <mat-list-item *ngFor="let talk of talks" class="talk-item"
                       fxLayout="row" fxLayoutAlign="space-between center"
                       [ngClass]="{'unread': talk.notificationCount > 0, 'active': current.id === talk.id}"
                       (click)="goToTalk(talk)">
            <img *ngIf="talk.hasImage()" matListAvatar [src]="talk.image" alt="...">
            <div [ngClass]="{'fake-logo': !reverse , 'fake-logo-reverse': reverse}" *ngIf="!talk.hasImage()"
                 fxLayout="row"
                 fxLayoutAlign="center center">
                <div>{{talk.getInitiales()}}</div>
            </div>
            <div fxFlexAlign="center" class="meta" fxFlex="calc(100% - 80px)" fxLayout="column">
                <div fxLayoutAlign="space-between center" fxFlex="100">
                    <span class="name">{{ talk.name | truncate: 23}}</span>
                    <span class="timestamp">{{ talk.lastMessage.sent | date:'dd/MM hh:mm' }}</span>
                </div>
                <div fxLayoutAlign="space-between center" fxFlex="100">
                    <div class="snippet">
                        {{ talk.lastMessage.content | truncate: 33}}
                    </div>
                    <div [hidden]="talk.notificationCount == 0">
                        <span [matBadge]="talk.notificationCount"></span>
                    </div>
                </div>
            </div>
        </mat-list-item>
    </mat-list>
</div>