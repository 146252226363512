

export class NotificationSearchRequest  {

    status: string;
    statutes: string[];
    type: string;
    userUri: string;
    personUri: string;
    organizationUri: string;
    count: number;
    offset: number;



    withStatus(status: string): NotificationSearchRequest {
        this.status = status;
        return this;
    }

    withType(type: string): NotificationSearchRequest {
        this.type = type;
        return this;
    }


    withUserUri(userUri: string): NotificationSearchRequest {
        this.userUri = userUri;
        return this;
    }

    withOffset(offset: number): NotificationSearchRequest {
        this.offset = offset;
        return this;
    }

    withCount(count: number): NotificationSearchRequest {
        this.count = count;
        return this;
    }
}
