export enum Rights {
    READ_RIGHT = 4,
    READ_ALL_RIGHT = 5,
    WRITE_RIGHT = 6,
    DELETE_RIGHT = 7,
    UPDATE_RIGHT = 8,
    ATTACH_RIGHT_TO_ROLE = 9,
    ATTACH_RIGHT_TO_ACCOUNT = 10,
    DETACH_RIGHT_TO_ACCOUNT = 11,
    DETACH_RIGHT_TO_ROLE = 12,
    SEE_DASHBOARDS = 14,
    CREATE_DASHBOARD = 15,
    UPDATE_DASHBOARD = 16,
    DELETE_DASHBOARD = 17,
    ATTACH_WIDGET_TO_DASHBOARD = 18,
    DETACH_WIDGET_TO_DASHBOARD = 19,
    READ_ROLE = 21,
    READ_ALL_ROLE = 22,
    WRITE_ROLE = 23,
    DELETE_ROLE = 24,
    UPDATE_ROLE = 25,
    ATTACH_ROLE_TO_ACCOUNT = 26,
    DETACH_ROLE_TO_ACCOUNT = 27,
    READ_ALL_CLUBS = 29,
    READ_CLUB = 30,
    CREATE_CLUB = 31,
    UPDATE_CLUB = 32,
    DELETE_CLUB = 33,
    READ_ALL_TEAMS = 35,
    READ_TEAM = 36,
    CREATE_TEAM = 37,
    UPDATE_TEAM = 38,
    DELETE_TEAM = 39,
    ATTACH_TEAM_TO_CLUB = 40,
    DETACH_TEAM_TO_CLUB = 41,
    ATTACH_TEAM_TO_CONTEST = 42,
    DETACH_TEAM_TO_CONTEST = 43,
    READ_ALL_ROSTERS = 45,
    READ_ROSTER = 46,
    CREATE_ROSTER = 47,
    UPDATE_ROSTER = 48,
    DELETE_ROSTER = 49,
    ATTACH_ROSTER_TO_TEAM = 50,
    DETACH_ROSTER_TO_TEAM = 51,
    ATTACH_MEMBER_TO_ROSTER = 52,
    DETACH_MEMBER_TO_ROSTER = 53,
    READ_ALL_CONTESTS = 55,
    READ_CONTEST = 56,
    CREATE_CONTEST = 57,
    UPDATE_CONTEST = 58,
    DELETE_CONTEST = 59,
    READ_ALL_LEADERBOARDS = 61,
    READ_LEADERBOARD = 62,
    CREATE_LEADERBOARD = 63,
    UPDATE_LEADERBOARD = 64,
    DELETE_LEADERBOARD = 65,
    READ_ALL_ACCOUNTS = 67,
    IMPERSONATION_ACCOUNT = 68,
    CREATE_ACCOUNT = 69,
    HANDLE_ACCOUNT_ASK = 70,
    DELETE_ACCOUNT = 71,
    ACTIVATE_ACCOUNT = 72,
    READ_ALL_EVENTS = 74,
    READ_EVENT = 75,
    CREATE_EVENT = 76,
    UPDATE_EVENT = 77,
    DELETE_EVENT = 78,
    READ_ALL_PERSONS = 87,
    READ_PERSON = 88,
    CREATE_PERSON = 89,
    UPDATE_PERSON = 90,
    DELETE_PERSON = 91,
    ATTACH_PERSON_TO_ACCOUNT = 92,
    DETACH_PERSON_TO_ACCOUNT = 93,
    ATTACH_PERSON_TO_GROUP = 94,
    DETACH_PERSON_TO_GROUP = 95,
    CREATE_ME = 96,
    UPDATE_ME = 97,
    ADD_MEMBER_FAMILY = 98,
    READ_ALL_GROUPS = 100,
    READ_GROUP = 101,
    CREATE_GROUP = 102,
    UPDATE_GROUP = 103,
    DELETE_GROUP = 104,
    READ_ALL_PLACES = 106,
    READ_PLACE = 107,
    CREATE_PLACE = 108,
    UPDATE_PLACE = 109,
    DELETE_PLACE = 110,
    READ_ALL_TRAININGS = 112,
    READ_TRAINING = 113,
    CREATE_TRAINING = 114,
    UPDATE_TRAINING = 115,
    DELETE_TRAINING = 116,
    READ_ALL_TEMPLATES_TRAINING = 117,
    READ_TEMPLATE_TRAINING = 118,
    CREATE_TEMPLATE_TRAINING = 119,
    UPDATE_TEMPLATE_TRAINING = 120,
    DELETE_TEMPLATE_TRAINING = 121,
    DESACTIVATE_ACCOUNT = 122,
    RESET_PASSWORD_ACCOUNT = 123,
    UPDATE_ACCOUNT = 124,
    READ_ACCOUNT = 125,
    CALL_EVENT = 126


}
