import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Game} from '../models/game';
import {isNullOrUndefined} from 'util';
import * as moment from 'moment';
import {Talk} from '../../../mdw/talk/models/talk';
import {TalksService} from '../../../mdw/talk/services/talks.service';
import {TalksFilter} from '../../../mdw/talk/models/talks-filter';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-game-show',
    templateUrl: './game-show.component.html',
    styleUrls: ['./game-show.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class GameShowComponent implements OnInit {


    @Input() game: Game;
    @Output() onUpdate: EventEmitter<void> = new EventEmitter<void>();
    @Output() editMode: EventEmitter<void> = new EventEmitter();
    talks: Talk[];
    loadInfo: boolean;

    modeAttendanceListView: string;

    constructor(private talksService: TalksService) {
    }

    ngOnInit() {
        this.talks = [];
        this.loadInfo = true;
        this.talksService.getAll(new TalksFilter().withIdTo(this.game.id)
            .withToType(this.game.typeTo)).subscribe(talks => {
            this.talks = talks;
            this.loadInfo = false;
        });
        this.modeAttendanceListView = 'COACH';
    }

    passToEditMode() {
        this.editMode.emit();
    }

    onUpdateAttendance(game) {
        this.onUpdate.emit(game);
    }


    havePlace(): boolean {
        return !isNullOrUndefined(this.game.place);
    }

    currentPersonIsInEvent(): boolean {
        if (isNullOrUndefined(this.game.currentAttendances)) {
            return false;
        }
        return this.game.currentAttendances.length > 0;
    }

    isInFuture() {
        const now = moment();
        return now.isBefore(this.game.startDate) || now.diff(this.game.startDate, 'd') <= environment.nbDayAttendance;
    }
}
