import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Club} from '../../club/models/club';
import {ClubsFilter} from '../../club/models/clubs-filter';
import {Router} from '@angular/router';
import {ClubsService} from '../../club/services/clubs.service';
import {MenuItem} from 'primeng/primeng';
import {BreadcrumbService} from '../../../global/services/breadcrumb.service';
import {ClubStatus} from '../enums/club-status.enum';
import {ContextMenuAppService} from '../../../global/services/context-menu-app.service';

@Component({
    selector: 'app-clubs',
    templateUrl: './clubs.component.html',
    styleUrls: ['./clubs.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ClubsComponent implements OnInit {

    clubs: Club[];
    selectedClub: Club;
    loadClub: boolean;

    constructor(private breadcrumbService: BreadcrumbService,
                private contextMenuAppService: ContextMenuAppService,
                private router: Router,
                private clubsService: ClubsService) {

    }

    ngOnInit() {
        this.clubs = [];
        this.breadcrumbService.setItems([{label: 'Clubs extérieurs'}]);
        this.loadClub = true;
        this.clubsService.getAll(new ClubsFilter().withStatus(ClubStatus.ACTIVE)).subscribe(clubs => {
            this.clubs = clubs;
            this.loadClub = false;
        });
        this.contextMenuAppService.setContextMenu(this.buildContextMenu());

    }

    onRowSelect($event) {
        this.router.navigate(['/clubs', this.selectedClub.id]);
    }

    private buildContextMenu(): MenuItem[] {
        const items = [];
        items.push({
            label: 'Ajouter un club',
            icon: 'fa-plus',
            command: (event) => {
                this.addClub();
            }
        });
        return items;
    }

    addClub() {
        this.router.navigate(['/clubs/0']);

    }
}
