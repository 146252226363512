import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {FormControl} from '@angular/forms';
import {GlobalMeService} from '../../global/services/global-me.service';
import {OrganizationService} from '@genos/organization-service';
import {UriWithName} from '@mdw/usual-service';
import {debounceTime} from 'rxjs/operators';

@Component({
    selector: 'app-members-and-groups-autocomplete',
    templateUrl: './members-and-groups-autocomplete.component.html',
    styleUrls: ['./members-and-groups-autocomplete.component.scss']
})
export class MembersAndGroupsAutocompleteComponent implements OnInit {

    @Output() onItemSelected: EventEmitter<UriWithName> = new EventEmitter();
    @Input() exceptUris: string[];

    myControl = new FormControl();
    filteredMembersOrGroups: Observable<UriWithName[]>;

    currentOrganizationUri: string;

    constructor(private me: GlobalMeService,
                private organizationService: OrganizationService) {

    }

    ngOnInit() {
        this.me.needOrganizations(false, organizations => {
            this.currentOrganizationUri = organizations[0].uri;
        });
        this.myControl.valueChanges
            .pipe(debounceTime(400))
            .subscribe(value => {
                this.filteredMembersOrGroups = this._filter(value);
            });
    }

    onMemberSelected(event) {
        this.onItemSelected.emit(event.option.value);
        this.myControl.setValue('');
    }

    private _filter(value: string): Observable<UriWithName[]> {
        const s: Subject<UriWithName[]> = new Subject();
        if (value !== undefined) {
            const filterValue = value.toLowerCase();
            this.organizationService.getMembersAndGroupsAutocomplete(this.currentOrganizationUri,
                filterValue, this.exceptUris, 5).subscribe(r => {
                s.next(r);
            });
        }
        return s.asObservable();
    }
}
