import {Moment} from 'moment';
import {Event} from './event';

export class DayEvent {

    day: Moment;
    events: Event[];


    constructor() {
        this.events = [];
    }
}
