<div class="password" fxLayoutAlign="center center" fxLayout="row" fxFlex="33" fxFlex.xs="100">
    <div class="card genos-form" fxFlex="100" fxLayout="column">
        <div class="header" fxLayout="row" fxLayoutAlign="center center">
            <div class="title">Nouveau mot de passe</div>
        </div>
        <div class="line-no" fxLayout="row" fxLayoutAlign="center center">
            <div class="ui-float-label" fxFlex="80" fxLayout="column">
                <input type="password" fxFlex="100" class="form-input" [(ngModel)]="newPassword.password"
                       pInputText id="password">
                <label for="password" i18n="@@placeholder-password-password">Mot de passe</label>
            </div>
        </div>
        <div class="line-no" fxLayout="row" fxLayoutAlign="center center">
            <div class="ui-float-label line" fxFlex="80" fxLayout="column">
                <input type="password" fxFlex="100" class="form-input" [(ngModel)]="newPassword.confirmPassword"
                       pInputText id="confirmPassword">
                <label for="confirmPassword" i18n="@@placeholder-password-confirm">Confirmation de mot de
                    passe</label>
            </div>
        </div>
        <div class="line-no end">
            <p-progressSpinner class="spinner" *ngIf="loadVerify"></p-progressSpinner>
            <button type="button" *ngIf="!loadVerify" (click)="validate()"
                    class="ui-button ui-widget ui-corner-all ">
                <span class="ui-button-text" i18n="@@button-ask-password">Valider</span>
            </button>
        </div>
    </div>
</div>