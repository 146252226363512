<div fxLayout="column">
    <h4 i18n="@@h4-attendances">Les présences</h4>
    <div class="all-attendances">
        <div *ngIf="haveSeveral">
            <p-tabView>
                <p-tabPanel header="Equipe 1">
                    <app-event-attendance-list-by-team
                            [mode]="mode"
                            [event]="event"
                            (onUpdate)="passUpdate()"
                            [attendances]="attendances1">
                    </app-event-attendance-list-by-team>
                </p-tabPanel>
                <p-tabPanel header="Equipe 2">
                    <app-event-attendance-list-by-team
                            [mode]="mode"
                            [event]="event"
                            (onUpdate)="passUpdate()"
                            [attendances]="attendances2">
                    </app-event-attendance-list-by-team>
                </p-tabPanel>
            </p-tabView>
        </div>
        <app-event-attendance-list-by-team
                *ngIf="!haveSeveral"
                [mode]="mode"
                [event]="event"
                (onUpdate)="passUpdate()"
                [attendances]="event.attendances">
        </app-event-attendance-list-by-team>
    </div>
</div>