import {Injectable} from '@angular/core';
import {SportModeHandler} from '../../../genos/mode/interfaces/sport-mode-handler';
import {BscGame} from '../models/bsc-game';
import {BscGameForm} from '../models/bsc-game-form';

@Injectable()
export class GameHandlerService implements SportModeHandler<BscGame, BscGameForm> {


    constructor() {
    }

    handleToForm(o: BscGame): BscGameForm {
        return Object.assign(new BscGame(), o).toForm();
    }

    handleFromForm(o: BscGame, f: BscGameForm): BscGame {
        const bscForm = Object.assign(new BscGameForm(), f);
        return Object.assign(new BscGame(), o).fromForm(bscForm);
    }

    popDataInForm(f: BscGameForm, datas: any[][]): BscGameForm {
        return f;
    }

    specificSave(): void {
    }


}
