import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Contact} from '../models/contact';
import {ContactsService} from '../services/contacts.service';
import {MessageService} from '../../../global/services/message.service';
import {UserWithRightsView} from '@genos/user-service';
import {ContactType} from '../models/contact-type';
import {EContactType} from '../enums/e-contact-type';
import {AnalyticsClick} from '../../../analytics/decorators/analytics-click.decorator';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContactComponent implements OnInit {

    @Input() contact: Contact;
    @Input() types: ContactType[];
    @Input() user: UserWithRightsView;
    @Output() reload: EventEmitter<boolean> = new EventEmitter();
    type: string;
    editMode: boolean;

    constructor(private contactsService: ContactsService,
                private messageService: MessageService) {
    }

    ngOnInit() {
        const type = this.types.filter(t => t.id === this.contact.type)[0];
        switch (type.type) {
            case EContactType.PERSONAL_EMAIL:
            case EContactType.PROFESSIONAL_EMAIL:
                this.type = 'mail';
                break;
            case EContactType.MOBILE_PHONE:
                this.type = 'smartphone';
                break;
            case EContactType.EMERGENCY_PHONE:
                this.type = 'warning';
                break;
            case EContactType.HOME_PHONE :
            case EContactType.HOME_ADDRESS :
                this.type = 'home';
                break;
            case EContactType.WEBSITE :
                this.type = 'web';
                break;
            default:
                this.type = 'contacts';
        }
    }

    onEditMode() {
        this.editMode = true;
    }

    @AnalyticsClick('my_account', 'Supprimer une information de contact', 'my_account-delete_info_contact')
    delete() {
        this.contactsService.remove(this.user.uri, this.contact).subscribe(response => {
            this.messageService.addSuccess('Suppression réussie', 'L\'information de contact a été supprimée.');
            this.reload.emit();
        });
    }

    @AnalyticsClick('my_account', 'Mise à jour d\'une information de contact', 'my_account-update_info_contact_by_enter')
    updateFromEnter() {
        this.update();
    }

    @AnalyticsClick('my_account', 'Mise à jour d\'une information de contact', 'my_account-update_info_contact')
    update() {
        this.editMode = false;
        this.contactsService.update(this.user.uri, this.contact).subscribe(response => {
            this.messageService.addSuccess('Modification réussie', 'L\'information de contact a été sauvegardée.');
            this.reload.emit();
        });
    }
}
