import {NotificationUserRegistrationKeys} from './notification-user-registration-keys';

export class NotificationUserRegistrationRequest {

    userUri: string;
    endpoint: string;
    keys: NotificationUserRegistrationKeys;


}
