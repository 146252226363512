<div class="admin-persons" fxFlex="100" fxLayout="row">
    <div fxLayout="column" fxFlex="100">
        <h3 i18n="@@h3-admin-rights-accounts">Gestion des comptes</h3>
        <div fxFlex="100" fxLayout="row">
            <div fxFlex="100" *ngIf="loadGetAll" fxLayoutAlign="center center">
                <p-progressSpinner></p-progressSpinner>
            </div>
            <div fxFlex="100" fxLayout="column" *ngIf="!loadGetAll">
                <p-table fxFlex="100" [globalFilterFields]="['lastName','firstName']" #dt [value]="naturalPersonsForms"
                         [paginator]="true" [rows]="40"
                         (onEditInit)="initEdit($event)"
                         (onEditCancel)="cancelEdit($event)"
                         (onEditComplete)="completeEdit($event)"
                         [columns]="cols">
                    <ng-template pTemplate="caption">
                        <div class="ui-helper-clearfix">
                            <button type="button" pButton icon="fa-file-o" iconPos="left" label="All Data"
                                    (click)="dt.exportCSV()" style="float:left"></button>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                                {{col.header}}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr>
                            <td>
                                {{rowData.firstName}}
                            </td>
                            <td>
                                {{rowData.lastName}}
                            </td>
                            <td [pEditableColumn]="rowData" [pEditableColumnField]="'gender'">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <p-dropdown [(ngModel)]="rowData.gender" [options]="genders"
                                                    [autoWidth]="false"
                                                    i18n-placeholder="@@placeholder-select-input-contact-gender"
                                                    placeholder="Selectionnez un genre"
                                                    optionLabel="name"
                                                    [style]="{'width':'100%'}" appendTo="body"></p-dropdown>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{rowData.gender.name}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                {{rowData.nationality.name}}
                            </td>
                            <td>
                                <span>{{rowData.birthday | date:'dd/MM/yyyy'}}</span>
                            </td>
                            <td [pEditableColumn]="rowData" [pEditableColumnField]="'currentLicence'">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <p-dropdown [(ngModel)]="rowData.currentLicence" [options]="licences"
                                                    [autoWidth]="false"
                                                    i18n-placeholder="@@placeholder-select-input-contact-type"
                                                    placeholder="Selectionnez un type"
                                                    optionLabel="name"
                                                    [style]="{'width':'100%'}" appendTo="body"></p-dropdown>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <span *ngIf="rowData.currentLicence">
                                            {{rowData.currentLicence.name}}
                                        </span>
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td [pEditableColumn]="rowData" [pEditableColumnField]="'status'">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <p-dropdown [(ngModel)]="rowData.status" [options]="statutes"
                                                    [autoWidth]="false"
                                                    i18n-placeholder="@@placeholder-select-input-person-status"
                                                    placeholder="Selectionnez un statut"
                                                    optionLabel="name"
                                                    [style]="{'width':'100%'}" appendTo="body"></p-dropdown>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{rowData.status.name}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <mat-chip-list>
                                    <mat-chip *ngFor="let group of rowData.groups">{{group.name}}</mat-chip>
                                </mat-chip-list>
                            </td>
                            <td>
                                <button pButton type="button" icon="fa-check"
                                        *ngIf="rowData.onEdit"
                                        (click)="save(rowData)"
                                        i18n-label="@@button-see-profil" label="Sauvegarder"></button>
                                <button pButton type="button" icon="fa-arrow_forward"
                                        (click)="goToProfile(rowData)"
                                        *ngIf="!rowData.onEdit"
                                        i18n-label="@@button-see-profil" label="Voir le profil"></button>
                            </td>
                        </tr>
                    </ng-template>

                </p-table>
            </div>
        </div>
    </div>
</div>