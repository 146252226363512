import {Buildable, Dto} from '@mdw/usual-service';

export class TalkUser extends Dto implements Buildable<TalkUser> {

    displayName: string;
    image: string;

    build(): TalkUser {
        super.build();
        return this;
    }
}
