import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Location} from '@angular/common';
import {HeaderService} from '../services/header.service';
import {Subscription} from 'rxjs';
import {GlobalSessionService} from '@genos/user-service';
import {Router} from '@angular/router';
import {HeaderOption} from '../models/header-option';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnChanges {

    @Input() isMobile: boolean;
    @Input() isLoggedIn: boolean;
    @Input() showSideNotification: boolean;
    @Input() showSideMessage: boolean;
    @Output() toggleMessages: EventEmitter<void> = new EventEmitter<void>();
    @Output() toggleNotifications: EventEmitter<void> = new EventEmitter<void>();
    @Output() showMenuFullScreen: EventEmitter<void> = new EventEmitter<void>();

    // header toolbar
    showBurgerMenu: boolean;
    showNotification: boolean;
    showQuit: boolean;
    showBackButton: boolean;
    showProgress: boolean;
    showLogo: boolean;
    showTitle: boolean;
    showLinkTo: boolean;
    showAvatar: boolean;
    titleLinkTo: string;
    linkTo: string;
    avatarUrl: string;
    backAction: string;
    headerToolbarTitle: string;
    notificationTalkCount = 0;
    notificationCount = 0;
    partProgress: number;

    headerToolbarSubscriber: Subscription;
    sessionSubscriber: Subscription;

    constructor(private location: Location,
                private router: Router,
                private headerService: HeaderService,
                private session: GlobalSessionService) {
        this.headerToolbarSubscriber = this.headerService.getSubscriber().subscribe(option => {
            this._handleOption(option);
        });
        this.sessionSubscriber = this.session.getSubscriber().subscribe(logged => {
            this.isLoggedIn = logged;
            if (!logged) {
                this.showBackButton = false;
            }
        });
    }

    ngOnInit() {
    }

    _handleOption(option: HeaderOption) {
        this.showNotification = option.showNotification;
        this.showQuit = option.showQuit;
        this.showBackButton = option.showBackButton;
        this.showBurgerMenu = option.showBurgerMenu;
        this.showTitle = option.showTitle;
        this.showLinkTo = option.showLinkTo;
        this.showAvatar = option.showAvatar;
        this.avatarUrl = option.avatarUrl;
        this.titleLinkTo = option.titleLinkTo;
        this.linkTo = option.linkTo;
        this.headerToolbarTitle = option.title;
        this.backAction = option.backAction;
        this.showProgress = option.showProgress;
        this.partProgress = option.partProgress;
    }


    setNotificationTalkCount(count) {
        this.notificationTalkCount = count;
    }

    setNotificationCount(count) {
        this.notificationCount = count;
    }

    goToLink() {
        this.router.navigate([this.linkTo]);
    }

    quitApp() {
        console.log('quit app');
    }

    goBack() {
        if (this.backAction !== undefined && this.backAction !== null) {
            this.router.navigate([this.backAction]);
        } else {
            this.location.back();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isLoggedIn !== undefined) {
            this.isLoggedIn = changes.isLoggedIn.currentValue;
        }
        if (changes.isMobile !== undefined) {
            this.isMobile = changes.isMobile.currentValue;
        }
    }

}
