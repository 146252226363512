export class GroupForm {

    name: string;
    description: string;
    type: any;
    status: any;
    period: string;
    periodicity: any;

}
