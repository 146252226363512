import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService, AppConfigService, ResponseWithData} from '@mdw/usual-service';
import {GlobalSessionService} from '@genos/user-service';
import {SwPush} from '@angular/service-worker';
import {Observable} from 'rxjs/internal/Observable';
import {NotificationView} from '../../mdw/monitoring/models/notification-view';
import {Subject} from 'rxjs';
import {NotificationSearchRequest} from '../models/notification-search-request';
import {NotificationUserRegistrationRequest} from '../models/notification-user-registration-request';
import {NotificationUserRegistrationKeys} from '../models/notification-user-registration-keys';


@Injectable({
    providedIn: 'root'
})
export class NotificationsService extends AbstractService {

    readonly VAPID_PUBLIC_KEY = 'BLmj70Hdpncl9NdYT4huIeGQqg7yCsX227tsQCB2L-8KSUpEN1lw0mOfUP5dzICt1ap-niBeDblJOPxbkVIOYOM';

    constructor(protected http: HttpClient,
                private config: AppConfigService,
                private swPush: SwPush,
                private session: GlobalSessionService,
                @Inject(PLATFORM_ID) private platformId: Object) {
        super(http);
    }

    /**
     * request permission for notification from firebase cloud messaging
     *
     * @param userUri string
     */
    requestPermission(userUri) {
        if (this.swPush.isEnabled) {
            this.swPush.requestSubscription({
                serverPublicKey: this.VAPID_PUBLIC_KEY
            }).then(sub => {
                const nurr = new NotificationUserRegistrationRequest();
                nurr.userUri = userUri;
                nurr.endpoint = sub.endpoint;
                nurr.keys = new NotificationUserRegistrationKeys();
                const json: any = sub.toJSON();
                nurr.keys.auth = json.keys.auth;
                nurr.keys.p256dh = json.keys.p256dh;
                return this.addPushSubscriber(nurr).subscribe();
            }).catch(err => {
                    console.error('Could not subscribe userUri notifications ', err);
                }
            );
        }
    }


    getSimpleNotificationsByUser(request: NotificationSearchRequest): Observable<NotificationView[]> {
        const s: Subject<NotificationView[]> = new Subject<NotificationView[]>();
        const options: any = {
            withCredentials: true,
            params: request
        };
        this.http.get<ResponseWithData<NotificationView[]>>(this.getBaseUrl()
            + '/users/' + request.userUri + '/notifications:simple', options).subscribe(
            (r: any) => {
                s.next(r.data.map(n => Object.assign(new NotificationView(), n)));
            }, error => {
                s.error(error);
            }, () => s.complete()
        );
        return s.asObservable();
    }


    addPushSubscriber(subscription: NotificationUserRegistrationRequest) {
        console.log(subscription);
        return this.http.post(this.getBaseUrl() + '/notifications/register',
            subscription
        );
    }

    send() {
        return this.http.post(this.getBaseUrl() + '/notifications', null);
    }

    getBaseUrl(): string {
        return this.config.getBaseUrl() + '/notification/api';
    }
}
