import {TeamStatus} from '../enums/team-status.enum';
import {TeamForm} from './team-form';
import {Buildable, CanAttach, Dto} from '@mdw/usual-service';
import {environment} from '../../../../environments/environment';

export class Team extends Dto implements Buildable<Team>, CanAttach {


    name: string;
    status: TeamStatus;
    club: number;
    sport: string;
    category: string;
    gender: string;
    logo: string;
    description: string;
    shortDescription: string;

    // Can be attach
    typeTo: string;


    constructor() {
        super();
        this.typeTo = 'TEAM';
        this.status = TeamStatus.ACTIVE;
        this.logo = environment.imageDefaultTeam;
    }

    build(): Team {
        super.build();
        return this;
    }

    toForm(): TeamForm {
        const f = new TeamForm();
        f.name = this.name;
        f.club = this.club;
        f.sport = {value: this.sport};
        f.category = {value: this.category};
        f.gender = {value: this.gender};
        f.status = this.status;
        f.description = this.description;
        f.shortDescription = this.shortDescription;
        return f;
    }

    fromForm(f: TeamForm): Team {
        this.name = f.name;
        this.club = f.club;
        this.status = f.status;
        this.description = f.description;
        this.sport = f.sport.value;
        this.category = f.category.value;
        this.gender = f.gender.value;
        this.shortDescription = f.shortDescription;
        return this;
    }

    isMainTeam(): boolean {
        console.log(this.club);
        console.log(environment.idClubMain);
        return this.club === environment.idClubMain;
    }
}
