import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AnalyticsService} from './services/analytics.service';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [],
    providers: [AnalyticsService]
})
export class AnalyticsModule {
}
