import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GameModule} from './game/game.module';
import {BscModeFactoryService} from './mode/bsc-mode-factory.service';
import {NaturalPersonHandlerService} from './person/services/natural-person-handler.service';
import {GameHandlerService} from './game/services/game-handler.service';

@NgModule({
    imports: [
        CommonModule,
        GameModule
    ],
    exports: [GameModule],
    declarations: [],
    providers: [BscModeFactoryService, NaturalPersonHandlerService, GameHandlerService]
})
export class BscModule {
}
