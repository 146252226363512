export const environment = {
    production: true,
    endpoint: 'https://api.genos.fr',
    wsEndpoint: 'wss://ws.genos.fr/wss',
    apiKeyWSTalk: '5b5d40f7-3f30-401c-b2dd-063ffc3a3570',
    uriTalk: 'local.genos.talk.',
    api: '/api',
    secured: 'secured',
    unsecured: 'unsecured',
    origin: 'web',
    recaptcha: '6Lea-zkUAAAAAMNxm3L8OdPz4j2Es-puN3fkGdyo',
    version: '1.0.0',
    urlAdministration: 'administration',
    urlConfiguration: 'configuration',
    currentYear: '2019',
    idClubMain: 1,
    imageDefaultTeam: 'https://app.genos.fr/uploads/images/teams/default.png',
    imageDefaultChampionship: 'https://app.genos.fr/uploads/images/contests/default-championship.png',
    imageDefaultTournament: 'https://app.genos.fr/uploads/images/contests/default-tournament.png',
    imageDefaultClub: 'https://app.genos.fr/uploads/images/clubs/default.png',
    imageDefaultPerson: 'https://app.genos.fr/uploads/images/persons/default.png',
    imageDefaultEvent: 'https://app.genos.fr/uploads/images/events/default.png',
    imageDefaultTrainingTemplate: 'https://app.genos.fr/uploads/images/trainings/templates/default.png',
    imageDefaultTraining: 'https://app.genos.fr/uploads/images/trainings/default.png',
    latPershing: 48.82989815901239,
    lngPershing: 2.4541548911629434,
    nbDayAttendance: 3,
    maxSportAllowByOrganization: 3,
    sportMode: 'BSC',
    mode: 'CLOUD',
    isCordova: false,
    cookieName: 'jwttoken',
    cookieDomaines: ['.genos.fr', 'clubs.genos.fr', 'leagues.genos.fr']
};
