import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Club} from '../models/club';
import {Observable, Subject} from 'rxjs';
import {ClubsFilter} from '../models/clubs-filter';
import {ClubStatus} from '../enums/club-status.enum';
import {DtoSecurisedService, GlobalSessionService, UploadFileService} from '@genos/user-service';
import {AppConfigService} from '@mdw/usual-service';

@Injectable()
export class ClubsService extends DtoSecurisedService<Club> {


    constructor(protected sessionService: GlobalSessionService,
                protected client: HttpClient,
                protected uploadFileService: UploadFileService,
                protected appConfigService: AppConfigService) {
        super(client, sessionService);
    }

    uploadLogo(id: number, file: File): Observable<string> {
        const s = new Subject<string>();
        this.uploadFileService.uploadFiles(this.getBaseUrl() + '/' + id + ':image', [file], false).subscribe(response => {
            if (response instanceof HttpResponse) {
                s.next(response.body.logo);
                s.complete();
            }
        });
        return s.asObservable();
    }

    getBaseUrl(): string {
        return this.appConfigService.getSecuredBaseUrl() + '/clubs';
    }

    update(club: Club): Observable<Club> {
        return this.put<Club>(this.getBaseUrl() + '/' + club.id, club);
    }

    create(club: Club): Observable<Club> {
        return this.post<Club>(this.getBaseUrl(), club);

    }

    getMain(): Observable<Club> {
        const s: Subject<Club> = new Subject<Club>();
        const cf = new ClubsFilter();
        cf.status = ClubStatus.MAIN;
        this.getArray(this.getBaseUrl() + cf.toUrl(), {}, Club).subscribe(clubs => {
            let club;
            if (clubs.length === 0) {
                club = new Club();
            } else {
                club = clubs[0];
            }
            s.next(club);
        });
        return s.asObservable();
    }

    getById(id: number): Observable<Club> {
        return this.getObject(this.getBaseUrl() + '/' + id, {}, Club);
    }

    getAll(filter: ClubsFilter): Observable<Club[]> {
        return this.getArray(this.getBaseUrl() + filter.toUrl(), {}, Club);
    }


}
