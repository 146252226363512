import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {Account, AccountProjection, AccountService} from '@genos/user-service';
import {MessageService} from '../../../global/services/message.service';
import {BreadcrumbService} from '../../../global/services/breadcrumb.service';
import {ContextMenuAppService} from '../../../global/services/context-menu-app.service';

@Component({
    selector: 'app-accounts-list',
    templateUrl: './accounts-list.component.html',
    styleUrls: ['./accounts-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AccountsListComponent implements OnInit {

    sortO: number;
    sortF: number;

    email: string;
    accounts: Account[];
    selectedAccount: Account;
    loadGetAll: boolean;
    loadAdd: boolean;
    showAddDialog: boolean;

    constructor(private messageService: MessageService,
                private router: Router,
                private breadcrumbService: BreadcrumbService,
                private accountService: AccountService,
                private contextMenuAppService: ContextMenuAppService) {
        this.breadcrumbService.setAdminUserChild([{label: 'Comptes'}]);
    }

    ngOnInit() {
        this._load();
        this.contextMenuAppService.setContextMenu([
            {
                label: 'Nouveau compte',
                icon: 'fa-plus',
                command: (event) => {
                    this.showAddDialog = true;
                }
            }
        ]);

    }

    private _load() {
        this.loadGetAll = true;
        this.accountService.getAll(new AccountProjection().withNaturalPersons(true).withGroups(true)
            .withRoles(true)).subscribe(accounts => {
            this.accounts = accounts;
            this.loadGetAll = false;
        });
    }

    onRowSelect($event) {
        this.router.navigate(['/administration/accounts', this.selectedAccount.id]);
    }

    addAccount() {
        if (this.email.trim() !== '') {
            this.loadAdd = true;
            this.accountService.createFromEmail(this.email).subscribe(account => {
                this.messageService.addSuccess('Ajout réussi', 'Le compte à bien été crée');
                this._load();
            }, error => {
                this.messageService.addError('', error.message);
                this.showAddDialog = false;
                this.loadAdd = false;
            }, () => {
                this.showAddDialog = false;
                this.loadAdd = false;
            });
        }

    }
}
