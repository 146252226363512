<div #divRoles class="admin-rights-accounts" fxFlex="100" fxLayout="column">
    <div fxFlex="100" fxLayout="row">
        <div fxFlex="40" class="card" fxLayout="column">
            <h3 i18n="@@h3-admin-rights-accounts">Gestion des droits des comptes</h3>

            <div fxFlex="100" *ngIf="loadGetAll" fxLayoutAlign="center center">
                <p-progressSpinner></p-progressSpinner>
            </div>
            <div *ngIf="!loadGetAll">
                <div class="ui-widget-header" fxLayout="row">
                    <i class="fa fa-search"></i>
                    <input #gbcr type="text" pInputText size="50" i18n-placeholder="@@placeholder-global-filter"
                           placeholder="Filtre">
                </div>
                <p-dataTable [globalFilter]="gbcr" [value]="accounts"
                             [paginator]="true" [rows]="20">
                    <p-column field="email" [style]="{'width':'250px'}" i18n-header="@@header-data-table-account-email"
                              header="Email"
                              [sortable]="true"></p-column>
                    <p-column field="status" [style]="{'width':'100px'}"
                              i18n-header="@@header-data-table-account-status"
                              header="Statut"
                              [sortable]="true"></p-column>
                    <p-column i18n-header="@@header-data-table-account-roles" [sortable]="true" header="Roles">
                        <ng-template let-account="rowData" pTemplate="body">
                            <mat-chip-list>
                                <mat-chip *ngFor="let role of account.roles" (click)="clickOnChip(role, account)">
                                    {{role.name}}
                                </mat-chip>
                            </mat-chip-list>
                        </ng-template>
                    </p-column>
                    <p-column header="Actions">
                        <ng-template let-account="rowData" pTemplate="body">
                            <div fxLayout="row">
                                <button type="button" pButton (click)="clickOnAttachRole(account)"
                                        icon="ui-icon-hourglass-empty"
                                        class="ui-button-warning"></button>
                                <button type="button" pButton (click)="accreditationToAccount(account)"
                                        icon="ui-icon-gavel"
                                        class="ui-button-info"></button>
                            </div>
                        </ng-template>
                    </p-column>
                </p-dataTable>
            </div>
        </div>
        <div fxFlex="60" *ngIf="showAccreditation">
            <app-rights-to-attach [to]="selectedAccount" mode="ACCOUNT"></app-rights-to-attach>
        </div>
    </div>
</div>
<p-dialog [(visible)]="showAttachDialog" modal="modal" [dismissableMask]="true" showEffect="fade" width="400">
    <p-header i18n="@@header-dialog-account-attach-role">
        Attacher un role au compte
    </p-header>

    <div fxFlex="100">
        <p-dropdown [options]="roles" [autoWidth]="false" appendTo="body" [style]="{'width':'100%'}" optionLabel="name"
                    [(ngModel)]="selectedRole"></p-dropdown>
    </div>
    <p-footer>
        <p-progressSpinner class="spinner" *ngIf="loadAdd"></p-progressSpinner>
        <div *ngIf="!loadAdd" class="ui-dialog-buttonpane ui-helper-clearfix">
            <button type="button" pButton icon="fa-close" class="ui-button-warning" (click)="showAttachDialog=false"
                    i18n-label="@@button-cancel"
                    label="Annuler"></button>
            <button type="button" pButton icon="fa-check" class="ui-button-primary"
                    (click)="attachRole(selectedRole,selectedAccount)"
                    i18n-label="@@button-confirm"
                    label="Valider"></button>
        </div>
    </p-footer>
</p-dialog>
<p-confirmDialog i18n-header="@@header-confirm-dialog-remove-account-role" header="Suppression du role au compte"
                 icon="fa fa-question-circle" width="600"></p-confirmDialog>
