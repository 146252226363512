import {Injectable} from '@angular/core';

declare let gtag: Function;


@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {

    constructor() {
    }

    public sendEvent(category: string, label: string, action: string): void {
        console.log('GA : send event ' + category + ' ' + label + ' ' + action);
        gtag('event', action, {
            'event_category': category,
            'event_label': label
        });
    }

    public setPage(url: string): void {
        console.log('GA : setPage ' + url);
        gtag('set', 'page', url);
        gtag('send', 'pageview');
    }
}
