<div class="groups" fxFlex="100" fxLayout="row">
    <div fxLayout="column" fxFlex="100">
        <div fxLayout="row">
            <h3 i18n="@@h3-groups" fxFlex="50">Gestion des groupes</h3>
            <div fxFlex="50" fxLayoutAlign="end center">
                <div appNeedRight="CREATE_GROUP" fxLayoutAlign="center center" class="button-i"
                     (click)="addGroup()">
                    <i class="material-icons">add</i>
                </div>
            </div>
        </div>
        <div fxFlex="100" fxLayout="row">
            <div fxFlex="100" fxLayout="column">
                <div fxFlex="100" class="loader" fxLayout="row" *ngIf="loadGroup" fxLayoutAlign="center center">
                    <p-progressSpinner class="spinner"></p-progressSpinner>
                </div>
                <div class="ui-widget-header" fxFlex="100" fxLayout="row">
                    <i class="fa fa-search"></i>
                    <input #gb type="text" pInputText size="50" i18n-placeholder="@@placeholder-global-filter"
                           placeholder="Filtre">
                </div>
                <p-dataTable fxFlex="100" *ngIf="!loadGroup " [globalFilter]="gb" [value]="groups"
                             [paginator]="true" [rows]="10"
                             selectionMode="single"
                             [(selection)]="selectedGroup" (onRowSelect)="onRowSelect($event)"
                             [sortField]="sortF" [sortOrder]="sortO">
                    <p-column field="name" i18n-header="@@header-data-table-group-name" header="Nom"
                              [sortable]="true"></p-column>
                    <p-column field="type" i18n-header="@@header-data-table-group-period" header="Type"
                              [sortable]="true"></p-column>
                    <p-column field="periodicity" i18n-header="@@header-data-table-group-sport" header="Périodicity"
                              [sortable]="true">
                        <ng-template let-group="rowData" pTemplate="body">
                            <span>{{group.periodicity}}</span>
                        </ng-template>
                    </p-column>
                    <p-column field="period" i18n-header="@@header-data-table-group-sport" header="Période"
                              [sortable]="true">
                        <ng-template let-group="rowData" pTemplate="body">
                            <span>{{group.period}}</span>
                        </ng-template>
                    </p-column>
                    <p-column field="status" i18n-header="@@header-data-table-account-status" header="Statut"
                              [sortable]="true"></p-column>
                </p-dataTable>
            </div>
        </div>
    </div>
</div>