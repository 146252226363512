import {ClubForm} from './club-form';
import {Team} from '../../team/models/team';
import {Buildable, CanAttach, Dto, FormCompliant} from '@mdw/usual-service';
import {environment} from '../../../../environments/environment';
import {isNullOrUndefined} from 'util';

export class Club extends Dto implements Buildable<Club>, FormCompliant<Club, ClubForm>, CanAttach {

    // can attach
    typeTo: string;

    name: string;
    city: string;
    logo: string;
    zipcode: string;
    status: string;
    description: string;
    teams: Team[];


    constructor() {
        super();
        this.typeTo = 'CLUB';
        this.teams = [];
        this.logo = environment.imageDefaultClub;
    }

    build(): Club {
        super.build();
        if (isNullOrUndefined(this.logo)) {
            this.logo = environment.imageDefaultClub;
        }
        this.teams.map(t => Object.assign(new Team(), t).build());
        return this;
    }

    toForm(): ClubForm {
        const cf = new ClubForm();
        cf.name = this.name;
        cf.city = this.city;
        cf.logo = this.logo;
        cf.zipcode = this.zipcode;
        cf.description = this.description;
        return cf;
    }

    fromForm(form: ClubForm): Club {
        this.name = form.name;
        this.city = form.city;
        this.logo = form.logo;
        this.zipcode = form.zipcode;
        this.description = form.description;
        return this;
    }


}
