import {ContactType} from './contact-type';
import {ContactTechnicalType} from '../enums/contact-technical-type.enum';

export class ContactForm {

    name: string;
    type: ContactType;
    value: string;


}
