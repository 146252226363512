import {GameForm} from '../../../genos/game/models/game-form';

export class BscGameForm extends GameForm {

    needScorer: boolean;
    needUmpires: boolean;

    isValid(): boolean {
        return super.isValid();
    }
}
