import {isNullOrUndefined} from 'util';
import {ToUrl} from '@mdw/usual-service';

export class TalksFilter extends ToUrl {

    idTo: number;
    toType: string;
    status: string;


    toUrl(): string {
        let url = '';
        if (!isNullOrUndefined((this.status))) {
            url += this.getSeparator() + 'status=' + this.status;
        }
        if (!isNullOrUndefined((this.idTo))) {
            url += this.getSeparator() + 'idTo=' + this.idTo;
        }
        if (!isNullOrUndefined((this.toType))) {
            url += this.getSeparator() + 'toType=' + this.toType;
        }
        return url;
    }

    withStatus(status: string): TalksFilter {
        this.status = status;
        return this;
    }

    withIdTo(idTo: number): TalksFilter {
        this.idTo = idTo;
        return this;
    }

    withToType(toType: string): TalksFilter {
        this.toType = toType;
        return this;
    }

}
