<div class="admin-accounts" fxFlex="100" fxLayout="row">
    <div fxLayout="column" fxFlex="100">
        <h3 i18n="@@h3-admin-rights-accounts">Gestion des comptes</h3>
        <div fxFlex="100" fxLayout="row">
            <div fxFlex="100" fxLayout="column">
                <div fxFlex="100" *ngIf="loadGetAll" fxLayoutAlign="center center">
                    <p-progressSpinner></p-progressSpinner>
                </div>
                <div class="ui-widget-header" *ngIf="!loadGetAll"  fxLayout="row">
                    <i class="fa fa-search"></i>
                    <input #gb type="text" pInputText size="50" i18n-placeholder="@@placeholder-global-filter"
                           placeholder="Filtre">
                </div>
                <p-dataTable *ngIf="!loadGetAll" [globalFilter]="gb" [value]="accounts"
                             [paginator]="true" [rows]="40"
                             selectionMode="single"
                             [(selection)]="selectedAccount" (onRowSelect)="onRowSelect($event)"
                             [sortField]="sortF" [sortOrder]="sortO">
                    <p-column field="email" [style]="{'width':'250px'}" i18n-header="@@header-data-table-account-email"
                              header="Email"
                              [sortable]="true"></p-column>
                    <p-column field="created" i18n-header="@@header-data-table-account-created"
                              header="Date de création"
                              [style]="{'width':'175px'}"
                              [sortable]="true">
                        <ng-template let-account="rowData" pTemplate="body">
                            <span>{{account.created | date:'dd/MM/yyyy HH:mm'}}</span>
                        </ng-template>
                    </p-column>
                    <p-column field="status"
                              [style]="{'width':'100px'}"
                              i18n-header="@@header-data-table-account-status" header="Statut"
                              [sortable]="true"></p-column>
                    <p-column field="lastAttemptSucceed" i18n-header="@@header-data-table-account-created"
                              header="Dernière connexion"
                              [style]="{'width':'175px'}"
                              [sortable]="true">
                        <ng-template let-account="rowData" pTemplate="body">
                            <span>{{account.lastAttemptSucceed | date:'dd/MM/yyyy HH:mm'}}</span>
                        </ng-template>
                    </p-column>
                    <p-column i18n-header="@@header-data-table-account-persons" header="Personnes">
                        <ng-template let-account="rowData" pTemplate="body">
                            <span>{{account.naturalPersons.length}}</span>
                        </ng-template>
                    </p-column>
                    <p-column i18n-header="@@header-data-table-account-groups" header="Groupes">
                        <ng-template let-account="rowData" pTemplate="body">
                            <!--<span>{{account.groups.length}}</span>-->
                        </ng-template>
                    </p-column>

                    <p-column i18n-header="@@header-data-table-account-roles" header="Roles">
                        <ng-template let-account="rowData" pTemplate="body">
                            <mat-chip-list>
                                <mat-chip *ngFor="let role of account.roles">{{role.name}}</mat-chip>
                            </mat-chip-list>
                        </ng-template>
                    </p-column>
                </p-dataTable>
            </div>
        </div>
    </div>
</div>
<p-dialog [(visible)]="showAddDialog" modal="modal" [dismissableMask]="true" showEffect="fade" width="400">
    <p-header i18n="@@header-dialog-add-account">
        Ajouter un account
    </p-header>
    <div class="ui-g-12 ui-md-4">
        <input type="text" [(ngModel)]="email" (keyup.enter)="addAccount()" pInputText
               i18n-placeholder="@@placeholder-role-name"
               placeholder="Email *">
    </div>
    <p-footer>
        <p-progressSpinner class="spinner" *ngIf="loadAdd"></p-progressSpinner>
        <div *ngIf="!loadAdd" class="ui-dialog-buttonpane ui-helper-clearfix">
            <button type="button" pButton icon="fa-close" class="ui-button-warning" (click)="showAddDialog=false"
                    i18n-label="@@button-cancel"
                    label="Annuler"></button>
            <button type="button" pButton icon="fa-check" class="ui-button-primary" (click)="addAccount()"
                    i18n-label="@@button-confirm"
                    label="Valider"></button>
        </div>
    </p-footer>
</p-dialog>