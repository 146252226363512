import {Injectable} from '@angular/core';
import {Licence} from '../models/licence';
import {LicencesFilter} from '../models/licences-filter';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {DtoSecurisedService, GlobalSessionService} from '@genos/user-service';
import {AppConfigService} from '@mdw/usual-service';

@Injectable()
export class LicencesService extends DtoSecurisedService<Licence> {

    constructor(protected sessionService: GlobalSessionService,
                protected client: HttpClient,
                private  appConfigService: AppConfigService) {
        super(client, sessionService);
    }

    getBaseUrl(): string {
        return this.appConfigService.getSecuredBaseUrl() + '/licences';
    }

    update(licence: Licence): Observable<Licence> {
        return this.put<Licence>(this.getBaseUrl() + '/' + licence.id, licence);
    }

    create(licence: Licence): Observable<Licence> {
        return this.post<Licence>(this.getBaseUrl(), licence);
    }

    getAll(filter: LicencesFilter): Observable<Licence[]> {
        return this.getArray(this.getBaseUrl() + filter.toUrl(), {}, Licence);
    }


}
