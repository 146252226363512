<div class="attach-groups" fxFlex="100" fxLayout="column">
    <div class="header" fxLayout="row" fxFlex="100">
        <div fxFlex="90">
            <div class="title" fxLayout="row" fxFlex="100">
                <h4 i18n="@@h4-groups" fxFlex="20">Les groupes</h4>
                <p-dropdown fxFlex="80" *ngIf="addMode" [(ngModel)]="newGroup"
                            (onChange)="changeGroup($event)"
                            [options]="availableGroups"
                            [style]="{'width':'300px'}"
                            placeholder="Ajouter un groupe"
                            class="form-input"
                            optionLabel="name"
                ></p-dropdown>
            </div>
        </div>
        <div class="actions" fxFlex="20" fxLayout="row" fxLayoutAlign="end center">
            <div fxLayoutAlign="center center" class="button-i" *ngIf="normalMode && !hasNoGroups()"
                 (click)="passRemoveMode()">
                <i class="material-icons">delete</i>
            </div>
            <div fxLayoutAlign="center center" class="button-i" *ngIf="showCancel()"
                 (click)="cancel()">
                <i class="material-icons">cancel</i>
            </div>
            <div fxLayoutAlign="center center" class="button-i" *ngIf="showCancel()"
                 (click)="saveGroups()">
                <i class="material-icons">save</i>
            </div>
            <div fxLayoutAlign="center center" class="button-i" *ngIf="normalMode"
                 (click)="passAddMode()">
                <i class="material-icons">add</i>
            </div>
        </div>
    </div>
    <div fxLayoutWrap fxLayout="row" fxFlex="100">
        <div fxFlex="100" *ngIf="hasNoGroups()" fxLayoutAlign="center center">
            <div>Aucun groupe</div>
        </div>
        <app-attach-group-item class="card" fxFlex="23"
                               [removeMode]="removeMode"
                               (removeModeHandler)="removeGroup($event)"
                               *ngFor="let group of groups" [group]="group"></app-attach-group-item>
    </div>
</div>