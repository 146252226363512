import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfigService, ResponseWithData} from '@mdw/usual-service';
import {Observable, Subject} from 'rxjs';
import {Talk} from '../models/talk';
import {TalkCreateRequest} from '../models/talk-create-request';
import {TalkWithLastMessageAndCountView} from '../models/talk-with-last-message-and-count-view';
import {TalkMessagesPositionned} from '../models/talk-messages-positionned';
import {TalkMessageCreateRequest} from '../models/talk-message-create-request';
import {TalkMessage} from '../models/talk-message';

@Injectable({
    providedIn: 'root'
})
export class TalkService {


    getBaseUrl(): string {
        return this.appConfigService.getBaseUrl() + '/talk/api';
    }

    constructor(protected http: HttpClient,
                private appConfigService: AppConfigService) {
    }

    create(talk: TalkCreateRequest): Observable<Talk> {
        const s = new Subject<Talk>();
        this.http.post<ResponseWithData<Talk>>(this.getBaseUrl() + '/talks', talk).subscribe(r => {
            s.next(r.data);
        }, error => {
            s.error(error);
        }, () => s.complete());
        return s.asObservable();
    }

    getTalkByUser(userUri: string): Observable<TalkWithLastMessageAndCountView[]> {
        const s = new Subject<TalkWithLastMessageAndCountView[]>();
        this.http.get<ResponseWithData<TalkWithLastMessageAndCountView[]>>(this.getBaseUrl() + '/talks/users/'
            + userUri + ':lastMessage').subscribe(r => {
            s.next(r.data.map(t => Object.assign(new TalkWithLastMessageAndCountView(), t).toNormal()));
        }, error => {
            s.error(error);
        }, () => s.complete());
        return s.asObservable();
    }

    getLoadingMessages(talkId: number): Observable<TalkMessagesPositionned> {
        const s = new Subject<TalkMessagesPositionned>();
        this.http.get <ResponseWithData<TalkMessagesPositionned>>(this.getBaseUrl() + '/talks/'
            + talkId + '/messages:init').subscribe(response => {
            s.next(Object.assign(new TalkMessagesPositionned(), response.data).build());
            s.complete();
        });
        return s.asObservable();
    }

    sendMessageToTalk(talkId: number, content: string, ownerUri: string, userUri: string, previousMessage: TalkMessage): Observable<TalkMessage> {
        const s: Subject<TalkMessage> = new Subject();
        const message = new TalkMessageCreateRequest();
        message.content = content;
        message.ownerUri = ownerUri;
        message.userUri = userUri;
        this.http.post<ResponseWithData<TalkMessage>>(this.getBaseUrl() +
            '/talks/' + talkId + '/messages', message).subscribe(r => {
            s.next(Object.assign(new TalkMessage(), r.data).build().prepareToView([ownerUri], previousMessage.sent));
        }, error => s.error(error), () => s.complete());

        return s.asObservable();
    }
}
