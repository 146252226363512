import {PlaceStatus} from '../enums/place-status';

export class PlaceCreateRequest {

    name: string;
    latitude: number;
    longitude: number;
    status: PlaceStatus;
    description: string;
    address: string;
    zipCode: string;
    country: string;
    googlePlaceId: string;
    city: string;
    howTo: string;
}
