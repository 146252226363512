import {Injectable} from '@angular/core';
import {EventAttendance} from '../models/event-attendance';
import {isNullOrUndefined} from 'util';

@Injectable()
export class EventAttendancesService {

    constructor() {
    }

    haveSeveralGroups(attendances: EventAttendance[]): boolean {
        if (isNullOrUndefined(attendances)) {
            return false;
        }
        const uniqFrom = new Set(attendances.map(a => a.fromId));
        return uniqFrom.size > 1;
    }

    getIdsGroupsByAttendances(attendances: EventAttendance[]): number[] {
        if (isNullOrUndefined(attendances)) {
            return [];
        }
        return Array.from(new Set(attendances.map(a => a.fromId)).values());
    }

    splitAttendancesByGroup(attendances: EventAttendance[]): EventAttendance[][] {
        const ids = Array.from(new Set(attendances.map(a => a.fromId)).values());
        const attendances1 = attendances.filter(a => a.fromId === ids[0]);
        const attendances2 = attendances.filter(a => a.fromId === ids[1]);
        return [attendances1, attendances2];
    }

    sortByFirstName(a: EventAttendance, b: EventAttendance): number {
        if (a.person.getCompleteName() > b.person.getCompleteName()) {
            return 1;
        }
        if (a.person.getCompleteName() < b.person.getCompleteName()) {
            return -1;
        }
        return 0;
    }

}
