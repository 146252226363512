import {Component, OnInit} from '@angular/core';
import {NotificationsService} from '../services/notifications.service';
import {NotificationSearchRequest} from '../models/notification-search-request';
import {GlobalMeService} from '../../global/services/global-me.service';
import {NotificationView} from '../../mdw/monitoring/models/notification-view';

@Component({
    selector: 'app-notification-list',
    templateUrl: './notification-list.component.html',
    styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit {

    notifications: NotificationView[];

    constructor(private me: GlobalMeService,
                private notificationsService: NotificationsService) {
    }

    ngOnInit() {
        const request = new NotificationSearchRequest();
        request.userUri = this.me.getUserUri();
        request.offset = 0;
        request.count = 20;
        this.notificationsService.getSimpleNotificationsByUser(request).subscribe(r => {
            this.notifications = r;
            console.log(r);
        });
    }

}
