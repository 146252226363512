import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {BreadcrumbService} from '../services/breadcrumb.service';
import {MenuItem} from 'primeng/api';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
    subscription: Subscription;
    show: boolean;
    items: MenuItem[];
    @Input() isMobile: boolean;

    constructor(private breadcrumbService: BreadcrumbService) {
        this.subscription = this.breadcrumbService.getSubscriber().subscribe(items => {
            this.items = items;
            this.show = this.breadcrumbService.needToShow();
            console.log(this.show);
        });
        console.log(this.subscription);
    }

    ngOnInit(): void {

    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

}
