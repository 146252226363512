<div class="admin-accounts" fxFlex="100" fxLayout="row">
    <div fxLayout="column" fxFlex="100">
        <h3 i18n="@@h3-admin-rights-accounts">Gestion des démandes de compte</h3>
        <div fxFlex="100" fxLayout="row">
            <div fxFlex="100" *ngIf="loadInfo" fxLayoutAlign="center center">
                <p-progressSpinner class="spinner"></p-progressSpinner>
            </div>
            <div fxFlex="100" fxLayout="row" *ngIf="!loadInfo">
                <div fxFlex="50" fxLayout="column">
                    <div class="ui-widget-header" fxLayout="row">
                        <i class="fa fa-search"></i>
                        <input #gb type="text" pInputText size="50" i18n-placeholder="@@placeholder-global-filter"
                               placeholder="Filtre">
                    </div>
                    <p-dataTable fxFlex="100" [globalFilter]="gb" [value]="asks"
                                 [paginator]="true" [rows]="40"
                                 selectionMode="single"
                                 (onRowSelect)="onRowSelect($event)"
                                 [sortField]="sortF" [sortOrder]="sortO">
                        <p-column field="created" i18n-header="@@header-data-table-account-created"
                                  header="Date de création"
                                  [sortable]="true">
                            <ng-template let-ask="rowData" pTemplate="body">
                                <span>{{ask.created | date:'dd/MM/yyyy HH:mm'}}</span>
                            </ng-template>
                        </p-column>
                        <p-column field="lastName" i18n-header="@@header-data-table-account-email" header="Nom"
                                  [sortable]="true"></p-column>
                        <p-column field="firstName" i18n-header="@@header-data-table-account-email" header="Prénom"
                                  [sortable]="true"></p-column>
                        <p-column field="email" i18n-header="@@header-data-table-account-email" header="Email"
                                  [sortable]="true"></p-column>
                        <p-column field="licenceNumber" i18n-header="@@header-data-table-account-email"
                                  header="N° de licence"
                                  [sortable]="true"></p-column>
                        <p-column field="status" i18n-header="@@header-data-table-account-status" header="Statut"
                                  [sortable]="true"></p-column>
                    </p-dataTable>
                </div>
                <div fxFlex="49" class="card" *ngIf="askIsSelected" fxLayout="column">
                    <h4 i18n="@@h4-admin-ask-account-detail">Détail de la demande</h4>
                    <div fxFlex="100" fxLayout="column" class="genos-form">
                        <div class="ui-float-label line" fxLayout="column">
                            <input type="text" class="form-input" [(ngModel)]="selectedAsk.lastName"
                                   pInputText id="lastName">
                            <label for="lastName" i18n="@@placeholder-natural-person-name">Nom</label>
                        </div>
                        <div class="ui-float-label line" fxLayout="column">
                            <input type="text" class="form-input" [(ngModel)]="selectedAsk.firstName"
                                   pInputText id="firstName">
                            <label for="firstName" i18n="@@placeholder-natural-person-name">Prénom</label>
                        </div>
                        <div class="ui-float-label line" fxLayout="column">
                            <input type="text" class="form-input" [(ngModel)]="selectedAsk.email"
                                   pInputText id="email">
                            <label for="email" i18n="@@placeholder-natural-person-name">Email</label>
                        </div>
                        <div class="ui-float-label line" fxLayout="column">
                            <input type="text" class="form-input" [(ngModel)]="selectedAsk.licenceNumber"
                                   pInputText id="licenceNumber">
                            <label for="licenceNumber" i18n="@@placeholder-natural-person-name">N° de licence</label>
                        </div>

                        <div class="ui-float-label line" fxLayout="column">
                            <textarea [rows]="5" [cols]="30" pInputTextarea id="message"
                                      [(ngModel)]="selectedAsk.message"></textarea>
                            <label for="message" i18n="@@placeholder-natural-team-short-description">Message</label>
                        </div>

                    </div>
                    <p-footer fxLayout="row">
                        <button pButton type="button" class="red-btn" icon="fa-delete"
                                (click)="rejectAsk()"
                                *ngIf="canBeRefuse()"
                                i18n-label="@@button-reject" label="Rejeter"></button>
                        <button pButton type="button" icon="fa-check"
                                (click)="acceptAsk()"
                                *ngIf="canBeValidate()"
                                i18n-label="@@button-accept" label="Accepter"></button>
                    </p-footer>
                </div>

            </div>
        </div>
    </div>
</div>