import {Buildable, Dto} from '@mdw/usual-service';

export class EventOption extends Dto implements Buildable<Dto> {

    event: number;
    type: string;
    group: string;
    value: string;

    build(): EventOption {
        super.build();
        return this;
    }

}
