import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {TeamsService} from '../../team/services/teams.service';
import {forkJoin, Observable, Subject} from 'rxjs';
import {Team} from '../../team/models/team';
import {ContestsService} from '../../contest/services/contests.service';
import {Game} from '../models/game';
import {isNullOrUndefined} from 'util';
import {GameForm} from '../models/game-form';
import {GameStatus} from '../enums/game-status.enum';
import {PlacesFilter} from '../../place/models/places-filter';
import {PlacesService} from '../../place/services/places.service';
import {Place} from '../../place/models/place';
import {EventsService} from '../../../events/services/events.service';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import {TeamsFilter} from '../../team/models/teams-filter';
import {Contest} from '../../contest/models/contest';
import {ContestProjection} from '../../contest/models/contest-projection';
import {EventAttendancesService} from '../../../events/services/event-attendances.service';
import {BreadcrumbService} from '../../../global/services/breadcrumb.service';
import {MessageService} from '../../../global/services/message.service';
import {environment} from '../../../../environments/environment';
import {Group} from '../../group/models/group';
import {GroupsFilter} from '../../group/models/groups-filter';
import {GroupsService} from '../../group/services/groups.service';
import {SportModeService} from '../../mode/services/sport-mode.service';
import {PrimengLocaleService} from '@mdw/usual-service';
import {GenosConfigurationService} from '../../configuration/services/genos-configuration.service';
import {ModeFactoryService} from '../../mode/services/mode-factory.service';
import {FormType} from '../../mode/enums/form-type.enum';
import {SportModeHandler} from '../../mode/interfaces/sport-mode-handler';

@Component({
    selector: 'app-game-edit',
    templateUrl: './game-edit.component.html',
    styleUrls: ['./game-edit.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [SportModeService]
})
export class GameEditComponent implements OnInit {

    @Input() game: Game;
    @Output() onUpdate: EventEmitter<void> = new EventEmitter<void>();
    @Output() showMode: EventEmitter<void> = new EventEmitter<void>();

    idGame: number;
    groupsAttendances: Group[];
    sports: any[];
    genders: any[];
    categories: any[];
    statutes: any[];
    targetTeams: Team[];
    places: Place[];
    targetVictoryTeams: Team[];
    form: GameForm;
    modeAttendanceListView: string;

    contest: Contest;

    loadOption: boolean;
    locale: any;
    handler: SportModeHandler<any, any>;

    constructor(private teamsService: TeamsService,
                private route: ActivatedRoute,
                private placesService: PlacesService,
                private router: Router,
                private modeFactoryService: ModeFactoryService,
                private contestsService: ContestsService,
                private groupsService: GroupsService,
                private eventsService: EventsService,
                private breadcrumbService: BreadcrumbService,
                private attendancesService: EventAttendancesService,
                private messageService: MessageService,
                private primengLocaleService: PrimengLocaleService,
                private genosConfigurationService: GenosConfigurationService) {
        this.handler = this.modeFactoryService.get().get(FormType.GAME);
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    ngOnInit() {
        this.modeAttendanceListView = 'COACH';
        this.locale = this.primengLocaleService.getCurrentLocale();
        this.idGame = this.game.id;
        this.sports = [];
        this.genders = [];
        this.statutes = [];
        this.categories = [];
        this.targetTeams = [];
        this.targetVictoryTeams = [];
        this.loadOption = true;
        this.loadOptions().subscribe(() => {
            if (this.isNew()) {
                this.game = new Game();
                this.game.startDate = moment();
                this.game.endDate = this.game.startDate.clone().add(3, 'h');
                this.game.idParent = environment.idClubMain;
                this.game.typeParent = 'CLUB';
                this.form = this.handler.handleToForm(this.game);
                this.form.status = {value: GameStatus.PLANNED};
                this.form.status = this.statutes.filter(s => s.value === this.form.status.value)[0];
                this.setBreadcrumb();
                this.loadOption = false;
            } else {
                const gIds = this.attendancesService.getIdsGroupsByAttendances(this.game.attendances);
                this.groupsService.getAll(new GroupsFilter().withIds(gIds)).subscribe(groups => {
                    this.groupsAttendances = groups;
                });
                this.form = this.handler.handleToForm(this.game);
                if (this.game.typeParent === 'CONTEST') {
                    this.contestsService.getById(this.game.idParent, new ContestProjection()).subscribe(contest => {
                        this.contest = contest;
                        this.form.gender = this.genders.filter(g => g.value === this.contest.gender)[0];
                        this.form.category = this.categories.filter(g => g.value === this.contest.category)[0];
                        this.form.sport = this.sports.filter(g => g.value === this.contest.sport)[0];
                        this.contestsService.getTeams(this.contest.id).subscribe(teams => {
                            this.targetTeams = teams;
                            this.form.insideTeam = this.targetTeams.filter(t => t.id === this.form.insideTeam.id)[0];
                            this.form.outsideTeam = this.targetTeams.filter(t => t.id === this.form.outsideTeam.id)[0];
                            if (!isNullOrUndefined(this.form.victoryTeam)) {
                                this.form.victoryTeam = this.targetVictoryTeams.filter(t => t.id === this.form.victoryTeam.id)[0];
                            }
                            if (!isNullOrUndefined(this.form.place)) {
                                this.form.place = this.places.filter(p => p.id === this.form.place.id)[0];
                            }
                            this.loadOption = false;
                        });
                    });
                } else {
                    this.form.gender = this.genders.filter(g => g.value === this.form.gender.value)[0];
                    this.form.category = this.categories.filter(g => g.value === this.form.category.value)[0];
                    this.form.sport = this.sports.filter(g => g.value === this.form.sport.value)[0];
                    this.loadOption = false;
                }
                this.changeTeams();
                this.changeVictoryTeam();
                this.setBreadcrumb();
            }
            this.form.status = this.statutes.filter(s => s.value === this.form.status.value)[0];
        });
    }

    showTypes(): boolean {
        return this.game.typeParent !== 'CONTEST';
    }


    setBreadcrumb() {
        const items = [];
        items.push({label: 'Les évènements', routerLink: 'events'});
        if (this.isNew()) {
            items.push({label: 'Nouveau match amical'});
        } else {
            items.push({label: this.game.title});
        }
        this.breadcrumbService.setItems(items);

    }

    changeTeams() {
        let tf = new TeamsFilter();
        tf.club = 0;
        if (!isNullOrUndefined(this.form.gender)) {
            tf = tf.withGender(this.form.gender.value);
        }
        if (!isNullOrUndefined(this.form.category)) {
            tf = tf.withCategory(this.form.category.value);
        }
        if (!isNullOrUndefined(this.form.sport)) {
            tf = tf.withSport(this.form.sport.value);
        }
        this.teamsService.getAll(tf).subscribe(teams => {
            this.targetTeams = teams;
        });
    }

    changeVictoryTeam() {
        this.targetVictoryTeams = [];
        this.targetVictoryTeams.push(this.form.insideTeam);
        this.targetVictoryTeams.push(this.form.outsideTeam);
    }

    isNew(): boolean {
        return isNullOrUndefined(this.idGame);
    }

    loadOptions(): Observable<void> {
        const s = new Subject<any>();
        this.statutes = this.eventsService.prepareFormStatutes();
        forkJoin(
            this.genosConfigurationService.getSports(),
            this.genosConfigurationService.getGenders(),
            this.genosConfigurationService.getSportCategories(),
            this.placesService.getAll(new PlacesFilter().withStatus('ACTIVE')),
        ).subscribe(options => {
            this.sports = options[0];
            this.genders = options[1];
            this.categories = options[2];
            this.places = options[3];
            s.next({});
            s.complete();
        });
        return s.asObservable();
    }

    onSelectStartDate() {
        this.form.endDate = new Date(this.form.startDate);
    }

    saveGame() {
        if (this.form.isValid()) {
            const self = this;
            const game = this.handler.handleFromForm(this.game, this.form);
            this.eventsService.save(game).subscribe(_game => {
                this.messageService.addSuccess('Les données ont été validées', 'Le match à été sauvegardés');
                if (this.isNew()) {
                    this.router.navigate(['games/' + _game.id]);
                }
            });
        } else {
            this.messageService.addError('Sauvegarde non possible', 'Vérifiez les champs obligatoires du formulaire');
        }

    }

    passToNormalMode() {
        this.router.navigate(['games/' + this.game.id]);
    }

    onUpdateAttendance(game) {
        this.onUpdate.emit(game);
    }

}
