import {isNullOrUndefined} from 'util';
import {ToUrl} from '@mdw/usual-service';

export class ClubsFilter extends ToUrl {

    status: string;
    statutes: string[]

    toUrl(): string {
        let url = '';
        if (!isNullOrUndefined((this.status))) {
            url += this.getSeparator() + 'status=' + this.status;
        }
        if (!isNullOrUndefined((this.statutes))) {
            url += this.getSeparator() + 'statutes=' + this.statutes.join(',');
        }
        return url;
    }

    withStatus(status: string): ClubsFilter {
        this.status = status;
        return this;
    }

    withStatutes(statutes: string[]): ClubsFilter {
        this.statutes = statutes;
        return this;
    }

}
