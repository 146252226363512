import {NaturalPerson} from '@genos/person-service';
import {FormCompliant} from '@mdw/usual-service';
import {BscNaturalPersonForm} from './bsc-natural-person-form';
import {Licence} from '../../../licence/models/licence';
import {Group} from '../../../genos/group/models/group';
import {isNullOrUndefined} from 'util';

export class BscNaturalPerson extends NaturalPerson implements FormCompliant<BscNaturalPerson, BscNaturalPersonForm> {

    currentLicence: Licence;
    groups: Group[];

    toForm(): BscNaturalPersonForm {
        const npf = new BscNaturalPersonForm();
        super.toForm(npf);
        npf.groups = this.groups;
        npf.currentLicence = this.currentLicence;
        return npf;
    }

    fromForm(f: BscNaturalPersonForm): BscNaturalPerson {
        super.fromForm(f);
        this.groups = f.groups;
        this.currentLicence = f.currentLicence;
        return this;
    }


    build(): BscNaturalPerson {
        super.build();
        if (!isNullOrUndefined(this.groups)) {
            this.groups.map(g => Object.assign(new Group(), g).build());
        }
        return this;
    }
}

