<div class="place" fxFlex="100" fxLayout="row">
    <div class="loader" fxLayout="row" *ngIf="loadInfo" fxLayoutAlign="center center">
        <p-progressSpinner></p-progressSpinner>
    </div>
    <div *ngIf="!loadInfo" fxLayout="row" fxFlex="100">
        <div fxFlex="40">
            <div class="form-group card card-w-title"
                 fxFlex="100" fxLayout="column">
                <h4 i18n="@@h4-profile">Lieu</h4>
                <p-panel header="Informations générales" i18n-header="@@h3-general-info">
                    <div fxFlex="100" fxLayout="row" class="genos-form">
                        <div fxFlex="50" fxLayout="column">
                            <div class="ui-float-label line" fxLayout="column">
                                <input type="text" class="form-input" [(ngModel)]="form.name"
                                       pInputText id="name">
                                <label for="name" i18n="@@placeholder-place-name">Nom</label>
                            </div>
                            <div class="ui-float-label line" fxLayout="column">
                                <input type="text" class="form-input" [disabled]="true" [(ngModel)]="form.lat"
                                       pInputText id="latitude">
                                <label for="latitude" i18n="@@placeholder-place-latitude">Latitude</label>
                            </div>
                            <div class="ui-float-label line" fxLayout="column">
                                <input type="text" class="form-input" [disabled]="true" [(ngModel)]="form.lont"
                                       pInputText id="lontitude">
                                <label for="lontitude" i18n="@@placeholder-place-lontitude">Longitude</label>
                            </div>
                            <div class="ui-float-label line" fxLayout="column">
                                <input type="text" class="form-input" [(ngModel)]="form.city"
                                       pInputText id="city">
                                <label for="city" i18n="@@placeholder-place-city">Ville</label>
                            </div>
                            <div class="ui-float-label line" fxLayout="column">
                                <input type="text" class="form-input" maxlength="5" [(ngModel)]="form.zipCode"
                                       pInputText id="zipCode">
                                <label for="zipCode" i18n="@@placeholder-place-zip-code">Code postal</label>
                            </div>

                        </div>
                        <div fxFlex="50" fxLayout="column">
                            <div class="ui-float-label" fxLayout="column">
                                <textarea [rows]="5" [cols]="30" pInputTextarea [(ngModel)]="form.address"></textarea>
                                <label for="name" i18n="@@placeholder-place-address">Adresse</label>
                            </div>
                            <div class="ui-float-label" fxLayout="column">
                                <textarea [rows]="5" [cols]="30" pInputTextarea
                                          [(ngModel)]="form.description"></textarea>
                                <label for="name" i18n="@@placeholder-place-description">Description</label>
                            </div>
                            <div class="ui-float-label" fxLayout="column">
                        <textarea [rows]="5" [cols]="30" pInputTextarea
                                  [(ngModel)]="form.howTo"></textarea>
                                <label for="name" i18n="@@placeholder-place-how-to">Comment s'y rendre</label>
                            </div>
                        </div>
                    </div>
                    <p-footer>
                        <button pButton type="button" icon="fa-check"
                                (click)="savePlace()"
                                [disabled]="loadSave"
                                i18n-label="@@button-save" label="Sauvegarder"></button>
                    </p-footer>
                </p-panel>

            </div>
        </div>
        <div fxFlex="60" fxLayout="column" class="genos-map">
            <div fxLayoutAlign="center center">
                <i>Veuillez déplacer rouge le curseur sur l'endroit que vous souhaitez indiquez comme lieu.</i>
            </div>
            <p-gmap [options]="options" [overlays]="overlays" [style]="{'width':'100%','height':'320px'}"></p-gmap>
        </div>
    </div>
</div>