import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import * as moment from 'moment';
import {Training} from '../../training/models/training';
import {EventsService} from '../../../events/services/events.service';
import {EventProjection} from '../../../events/models/event-projection';
import {BreadcrumbService} from '../../../global/services/breadcrumb.service';
import {NaturalPerson, PersonWithUserInfoView} from '@genos/person-service';
import {GlobalMeService} from '../../../global/services/global-me.service';

@Component({
    selector: 'app-training',
    templateUrl: './training.component.html',
    styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit {

    currentTraining: Training;
    idTraining: number;
    loadTraining: boolean;
    editMode: boolean;
    persons: PersonWithUserInfoView[];

    constructor(private route: ActivatedRoute,
                private breadcrumbService: BreadcrumbService,
                private eventsService: EventsService,
                private me: GlobalMeService) {
    }

    ngOnInit() {
        this.me.needAllPersons(false, persons => {
            this.persons = persons;
        });
        this.editMode = false;
        this.loadTraining = true;
        this.idTraining = parseInt(this.route.snapshot.params.id, 10);
        if (this.isNew()) {
            this.editMode = true;
            this.currentTraining = new Training();
            this.currentTraining.startDate = moment();
            this.currentTraining.idParent = 1;
            this.currentTraining.typeParent = 'CLUB';
            this.loadTraining = false;
            this.setBreadcrumb();
        } else {
            this.me.needAllPersons(false, persons => {
                this.persons = persons;
                this.reloadTraining();
            });
        }
    }

    reloadTraining() {
        this.loadTraining = true;
        this.idTraining = parseInt(this.route.snapshot.params.id, 10);
        /*this.eventsService.getById(this.idTraining, new EventProjection()
            .withAttendances(true).withCurrentAttendances(this.persons.map(p => p.id))).subscribe(training => {
            this.currentTraining = Object.assign(new Training(), training).build();
            this.loadTraining = false;
            this.setBreadcrumb();
        });*/
    }

    passToEditMode() {
        this.editMode = true;
    }

    passToShowMode() {
        this.editMode = false;
    }

    isEditMode() {
        return this.editMode;
    }

    isNew(): boolean {
        return this.idTraining === 0;
    }

    setBreadcrumb() {
        const items = [];
        items.push({label: 'Evènements', routerLink: 'events'});
        if (this.isNew()) {
            items.push({label: 'Nouveau entrainement'});
        } else {
            items.push({label: this.currentTraining.title});
        }
        this.breadcrumbService.setItems(items);
    }
}
