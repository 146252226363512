import {TalkMode} from '../enums/talk-mode.enum';
import {UriWithName} from '@mdw/usual-service';

export class TalkOld {
    name: string;
    id: string;
    avatar: string;
    timestamp: number;
    snippet: string;
    members: UriWithName[];
    unreadCount: number;
    mode: TalkMode;


    constructor() {
        this.members = [];
    }

    static make(id: string, name: string, members: UriWithName[]): TalkOld {
        const t = new TalkOld();
        t.id = id;
        t.name = name;
        t.members = members;
        t.avatar = 'https://randomuser.me/api/portraits/men/41.jpg';
        t.snippet = 'some snippet';
        t.timestamp = Date.now() - 50000000;
        t.unreadCount = 4;
        t.mode = TalkMode.NORMAL;
        return t;
    }

    isNew(): boolean {
        return this.mode === TalkMode.NEW;
    }

    isInCreation(): boolean {
        return this.mode === TalkMode.ON_CREATION;
    }

    isNormal(): boolean {
        return this.mode === TalkMode.NORMAL;
    }
}
