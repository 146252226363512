<div fxLayout="column" class="official-options" fxFlex="100">
    <h4 i18n="@@bsc-h4-game-options">Options BSC</h4>
    <div fxLayout="row">
        <p-checkbox name="needUmpires" binary="true" label="Besoin d'arbitre(s)"
                    [(ngModel)]="form.needUmpires"
                    inputId="needUmpires"></p-checkbox>
        <p-checkbox name="needScorer" binary="true" label="Besoin d'un scoreur"
                    [(ngModel)]="form.needScorer"
                    inputId="needScorer"></p-checkbox>
    </div>
</div>
