import {TalkMember} from './talk-member';
import {TalkModeMembers} from '../enums/talk-mode-members.enum';

export class TalkCreateRequest {

    userUri: string;
    organizationUri: string;
    personUri: string;
    modeMembers: TalkModeMembers;
    members: TalkMember[];
    message: string;
    name: string;
}
