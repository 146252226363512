import {Game} from '../../../genos/game/models/game';
import {BscGameForm} from './bsc-game-form';
import {BscEventOption} from '../../event/enums/bsc-event-option.enum';
import {EventOption} from '../../../events/models/event-option';
import {BscEventOptionGroup} from '../../event/enums/bsc-event-option-group.enum';
import {isNullOrUndefined} from 'util';
import {FormCompliant} from '@mdw/usual-service';

export class BscGame extends Game implements FormCompliant<BscGame, BscGameForm> {

    toForm(): BscGameForm {
        const f = new BscGameForm();
        super.toForm(f);
        let fScorer = [];
        let fUmpires = [];
        if (!isNullOrUndefined(this.options)) {
            fScorer = this.options.filter(o => o.type === BscEventOption.NEED_SCORER);
            fUmpires = this.options.filter(o => o.type === BscEventOption.NEED_UMPIRES);
        }
        if (fScorer.length > 0) {
            f.needScorer = fScorer[0].value === 'true';
        } else {
            f.needScorer = false;
        }
        if (fUmpires.length > 0) {
            f.needUmpires = fUmpires[0].value === 'true';
        } else {
            f.needUmpires = false;
        }
        return f;
    }

    fromForm(f: BscGameForm): BscGame {
        super.fromForm(f);
        const fScorer = this.options.filter(o => o.type === BscEventOption.NEED_SCORER);
        const fUmpires = this.options.filter(o => o.type === BscEventOption.NEED_UMPIRES);
        if (fScorer.length > 0) {
            fScorer[0].value = f.needScorer.toString();
        } else {
            const eo = new EventOption();
            eo.type = BscEventOption.NEED_SCORER;
            eo.group = BscEventOptionGroup.OFFICIAL;
            eo.value = f.needScorer ? 'true' : 'false';
            eo.event = this.id;
            this.options.push(eo);
        }
        if (fUmpires.length > 0) {
            fUmpires[0].value = f.needUmpires.toString();
        } else {
            const eo = new EventOption();
            eo.type = BscEventOption.NEED_UMPIRES;
            eo.group = BscEventOptionGroup.OFFICIAL;
            eo.value = f.needUmpires ? 'true' : 'false';
            eo.event = this.id;
            this.options.push(eo);
        }
        return this;
    }
}
