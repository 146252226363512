import {Buildable, Dto} from '@mdw/usual-service';
import {User} from '@genos/user-service';

export class Action extends Dto implements Buildable<Action> {

    idUser: number;
    attachObject: number;
    user: User;
    attachType: string;
    label: string;
    newValue: any;
    oldValue: any;

    constructor() {
        super();
    }

    build(): Action {
        super.build();
        return this;
    }

    isUpdate(): boolean {
        return 'UPDATE' === this.label;
    }

}
