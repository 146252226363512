import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
    selector: 'app-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

    itemName: string;
    uri: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
        this.itemName = data.itemName;
        this.uri = data.uri;
    }

    ngOnInit() {
    }

}
