import {Component, OnInit} from '@angular/core';
import {Place} from '../../place/models/place';
import {Router} from '@angular/router';
import {PlacesService} from '../../place/services/places.service';
import {PlacesFilter} from '../../place/models/places-filter';
import {MenuItem} from 'primeng/primeng';
import {BreadcrumbService} from '../../../global/services/breadcrumb.service';
import {ContextMenuAppService} from '../../../global/services/context-menu-app.service';
 
@Component({
    selector: 'app-places',
    templateUrl: './places.component.html',
    styleUrls: ['./places.component.scss']
})
export class PlacesComponent implements OnInit {

    places: Place[];
    selectedPlace: Place;
    loadInfo: boolean;

    constructor(private breadcrumbService: BreadcrumbService,
                private contextMenuAppService: ContextMenuAppService,
                private router: Router,
                private placesService: PlacesService) {

    }

    ngOnInit() {
        this.places = [];
        this.breadcrumbService.setItems([{label: 'Lieux'}]);
        this.loadInfo = true;
        this.placesService.getAll(new PlacesFilter()).subscribe(places => {
            this.places = places;
            this.loadInfo = false;
        });
        this.contextMenuAppService.setContextMenu(this.buildContextMenu());

    }

    onRowSelect($event) {
        this.router.navigate(['/places', this.selectedPlace.id]);
    }

    private buildContextMenu(): MenuItem[] {
        const items = [];
        items.push({
            label: 'Ajouter un lieu',
            icon: 'fa-plus',
            command: (event) => {
                this.addPlace();
            }
        });
        return items;
    }

    addPlace() {
        this.router.navigate(['/places/0']);
    }
}
