import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Training} from '../models/training';
import {Place} from '../../place/models/place';
import {PlacesService} from '../../place/services/places.service';
import {Router} from '@angular/router';
import {EventsService} from '../../../events/services/events.service';
import {isNullOrUndefined} from 'util';
import {forkJoin, Observable, Subject} from 'rxjs';
import {PlacesFilter} from '../../place/models/places-filter';
import {TrainingForm} from '../models/training-form';
import {MessageService} from '../../../global/services/message.service';
import {PrimengLocaleService} from '@mdw/usual-service';
import {GenosConfigurationService} from '../../configuration/services/genos-configuration.service';

@Component({
    selector: 'app-training-edit',
    templateUrl: './training-edit.component.html',
    styleUrls: ['./training-edit.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TrainingEditComponent implements OnInit {

    @Input() training: Training;
    @Output() onUpdate: EventEmitter<void> = new EventEmitter<void>();
    @Output() showMode: EventEmitter<void> = new EventEmitter<void>();
    locale: any;
    form: TrainingForm;
    visibilities: any[];
    statutes: any[];
    sports: any[];
    genders: any[];
    categories: any[];
    places: Place[];
    loadData: boolean;
    loadSave: boolean;
    modeAttendanceListView: string;

    constructor(private primengLocaleService: PrimengLocaleService,
                private placesService: PlacesService,
                private genosConfigurationService: GenosConfigurationService,
                private router: Router,
                private messageService: MessageService,
                private eventsService: EventsService) {
    }

    ngOnInit() {
        this.modeAttendanceListView = 'MANAGER';
        this.loadData = true;
        this.places = [];
        this.locale = this.primengLocaleService.getCurrentLocale();
        this.loadFormInfos().subscribe(() => {
            this.form = this.training.toForm();
            if (!isNullOrUndefined(this.form.place)) {
                this.form.place = this.places.filter(c => c.id === this.form.place.id)[0];
            }
            this.form.status = this.statutes.filter(c => c.value === this.form.status.value)[0];
            this.form.visibility = this.visibilities.filter(c => c.value === this.form.visibility.value)[0];
            this.form.gender = this.genders.filter(c => c.value === this.form.gender.value)[0];
            this.form.category = this.categories.filter(c => c.value === this.form.category.value)[0];
            this.form.sport = this.sports.filter(c => c.value === this.form.sport.value)[0];
            this.loadData = false;
        });
    }

    isNew(): boolean {
        return isNullOrUndefined(this.training.id);
    }

    myUploader(event, uploader) {
        this.eventsService.uploadLogo(this.training.id, event.files[0]).subscribe(url => {
            this.training.image = url;
            uploader.clear();
        });
    }

    loadFormInfos(): Observable<any> {
        const s = new Subject<any>();
        this.statutes = this.eventsService.prepareFormStatutes();
        this.visibilities = this.eventsService.prepareFormVisibilities();

        forkJoin(
            this.genosConfigurationService.getSports(),
            this.genosConfigurationService.getGenders(),
            this.genosConfigurationService.getSportCategories(),
            this.placesService.getAll(new PlacesFilter().withStatus('ACTIVE')),
        ).subscribe(options => {
            this.sports = options[0];
            this.genders = options[1];
            this.categories = options[2];
            this.places = options[3];
            s.next({});
            s.complete();
        });
        return s.asObservable();
    }

    setCompleteAddress() {
        this.form.address = this.form.place.getCompleteAddress();
    }

    onUpdateAttendance(event) {
        this.onUpdate.emit(event);
    }

    saveTraining() {
        const self = this;
        const training = this.training.fromForm(this.form);
        training.groups = this.training.groups;
        this.eventsService.save(training).subscribe(_training => {
            this.messageService.addSuccess('Entrainement sauvegardé',
                'Les informations concernant l\'entrainement ont été sauvegardées');
            if (this.isNew()) {
                this.router.navigate(['trainings/' + _training.id]);
            }
            self.showMode.emit();
            self.onUpdate.emit();
        });
    }

    passToNormalMode() {
        this.showMode.emit();
    }

}
