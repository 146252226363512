import * as moment from 'moment';
import {Moment} from 'moment';
import {EventStatus} from '../enums/event-status.enum';
import {isNullOrUndefined} from 'util';
import {EventAttendance} from './event-attendance';
import {Place} from '../../genos/place/models/place';
import {EventType} from '../enums/event-type.enum';
import {EventForm} from './event-form';
import {EventVisibility} from '../enums/event-visibility.enum';
import {Buildable, CanAttach, Dto, FormCompliant, Periodicity} from '@mdw/usual-service';
import {environment} from '../../../environments/environment';
import {Group} from '../../genos/group/models/group';
import {EventOption} from './event-option';

export class Event extends Dto implements Buildable<Event>, FormCompliant<Event, EventForm>, CanAttach {

    title: string;
    address: string;
    startDate: Moment;
    startTime: string;
    endDate: Moment;
    endTime: string;
    status: EventStatus;
    description: string;
    shortDescription: string;
    idParent: number;
    typeParent: string;
    image: string;
    visibility: string;
    periodicity: Periodicity;
    period: string;
    type: EventType;
    place: Place;

    typeTo: string;

    currentAttendances: EventAttendance[];
    attendances: EventAttendance[];
    groups: Group[];
    options: EventOption[];


    constructor() {
        super();
        this.currentAttendances = [];
        this.attendances = [];
        this.groups = [];
        this.options = [];
        this.typeTo = EventType.EVENT;
        this.status = EventStatus.PLANNED;
        this.image = environment.imageDefaultEvent;
        this.type = EventType.EVENT;
        this.periodicity = Periodicity.NONE;
        this.visibility = EventVisibility.PUBLIC;
    }

    getSport(): string {
        return '';
    }

    toForm(f?: EventForm): EventForm {
        if (isNullOrUndefined(f)) {
            f = new EventForm();
        }
        f.type = this.type;
        f.title = this.title;
        if (!isNullOrUndefined(this.startDate)) {
            f.startDate = this.startDate.toDate();
            f.startTime = this.startTime;
        }
        if (!isNullOrUndefined(this.endDate)) {
            f.endDate = this.endDate.toDate();
            f.endTime = this.endTime;
        }
        f.title = this.title;
        f.address = this.address;
        f.image = this.image;
        f.idParent = this.idParent;
        f.typeParent = this.typeParent;
        f.status = {value: this.status};
        f.description = this.description;
        f.shortDescription = this.shortDescription;
        f.period = this.period;
        f.periodicity = {value: this.periodicity};
        f.visibility = {value: this.visibility};
        if (!isNullOrUndefined(this.place)) {
            f.place = this.place;
        }
        return f;
    }

    fromForm(f: EventForm): Event {
        this.type = f.type;
        this.title = f.title;
        this.address = f.address;
        this.image = f.image;
        this.idParent = f.idParent;
        this.typeParent = f.typeParent;
        this.status = f.status.value;
        this.description = f.description;
        this.shortDescription = f.shortDescription;
        this.period = f.period;
        this.periodicity = f.periodicity.value;
        this.visibility = f.visibility.value;
        let hours;
        let minutes;
        if (!isNullOrUndefined(f.startTime)) {
            hours = parseInt(f.startTime.split(':')[0], 10);
        }
        if (!isNullOrUndefined(f.startTime)) {
            minutes = parseInt(f.startTime.split(':')[1], 10);
        }
        this.endTime = f.endTime;
        this.startTime = f.startTime;
        this.startDate = moment(f.startDate).hours(0).minutes(0).add(hours, 'h').add(minutes, 'm');
        if (!isNullOrUndefined(f.endTime)) {
            hours = parseInt(f.endTime.split(':')[0], 10);
            minutes = parseInt(f.endTime.split(':')[1], 10);
            this.endDate = moment(f.endDate).hours(0).minutes(0).add(hours, 'h').add(minutes, 'm');
        }
        if (!isNullOrUndefined(f.place)) {
            this.place = f.place;
        }
        return this;
    }

    getStartAndEndDate(): string {
        let d = '';
        if (!isNullOrUndefined(this.startDate) && !isNullOrUndefined(this.endDate)) {
            if (this.endDate.isSame(this.startDate, 'd')) {
                d = 'Le ';
            } else {
                d = 'Du ';
            }
            d += this.startDate.format('DD/MM/YYYY');
            d += ' ' + this.startTime;
        }
        if (!isNullOrUndefined(this.endDate)) {
            if (this.endDate.isSame(this.startDate, 'd') && !isNullOrUndefined(this.endTime)) {
                d += ' à ' + this.endTime;
            } else {
                d += ' au ' + this.endDate.format('DD/MM/YYYY') + ' ' + this.endTime;
            }
        }
        return d;
    }

    build(): Event {
        super.build();
        if (!isNullOrUndefined(this.attendances)) {
            this.attendances = this.attendances.map(a => Object.assign(new EventAttendance(), a).build());
        }
        if (!isNullOrUndefined(this.options)) {
            this.options = this.options.map(o => Object.assign(new EventOption(), o).build());
        }
        if (!isNullOrUndefined(this.currentAttendances)) {
            this.currentAttendances = this.currentAttendances.map(a => Object.assign(new EventAttendance(), a).build());
        }
        if (!isNullOrUndefined(this.startDate)) {
            this.startDate = moment(this.startDate);
        }
        if (!isNullOrUndefined(this.place)) {
            this.place = Object.assign(new Place(), this.place).build();
        }
        if (!isNullOrUndefined(this.endDate)) {
            this.endDate = moment(this.endDate);
        }
        return this;
    }
}
