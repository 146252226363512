<div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center"
     class="contact">
    <div fxFlex="10">
        <span class="material-icons">{{type}}</span>
    </div>
    <div fxFlex="90">
        <p-inplace styleClass="genos-inplace" (onDeactivate)="update()" (onActivate)="onEditMode()" [active]="editMode"
                   fxFlex="100" [closable]="true">
            <span pInplaceDisplay>
                {{contact.value}}
            </span>
            <span pInplaceContent>
                <input type="text" [(ngModel)]="contact.value" (keyup.enter)="updateFromEnter()"
                       pInputText>
            </span>
        </p-inplace>
    </div>
    <div fxFlex="5" class="icons" fxLayout="row">
        <span class="material-icons material-icons-clickable edit-mode" (click)="delete()">delete</span>
    </div>
</div>
