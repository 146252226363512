import {Team} from '../../team/models/team';
import {Buildable, Dto} from '@mdw/usual-service';

export class LeaderboardLine extends Dto implements Buildable<LeaderboardLine> {
    team: Team;
    average: number;
    victory: number;
    defeate: number;
    exaequo: number;
    points: number;
    gamePlayed: number;
    gameLeftToPlay: number;
    penality: number;
    order: number;


    constructor() {
        super();
        this.average = 0;
        this.victory = 0;
        this.defeate = 0;
        this.exaequo = 0;
        this.gamePlayed = 0;
        this.gameLeftToPlay = 0;
        this.penality = 0;
        this.order = 0;
        this.points = 0;
    }

    build(): LeaderboardLine {
        super.build();
        this.team = Object.assign(new Team(), this.team).build();
        return this;
    }


}
