import {Injectable} from '@angular/core';
import {AppConfigService} from '@mdw/usual-service';
import {environment} from '../../../environments/environment';

@Injectable()
export class GenosAppConfigService implements AppConfigService {


    constructor() {
    }

    getBaseUrl(): string {
        return environment.endpoint;
    }

    getSecuredBaseUrl(): string {
        return this.getBaseUrl();
    }

    getUnsecuredBaseUrl(): string {
        return this.getBaseUrl();
    }

    getOrigin(): string {
        return environment.origin;
    }

}
