<form class="example-form">
    <mat-form-field class="example-full-width">
        <input type="text" placeholder="Selectionner un membre ou un groupe" aria-label="membre ou groupe"
               matInput [formControl]="myControl"
               [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onMemberSelected($event)">
            <mat-option *ngFor="let mOrG of filteredMembersOrGroups | async" [value]="mOrG">
                {{mOrG.name}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>