import {Component, OnInit, ViewChild} from '@angular/core';
import {MenuItem} from 'primeng/primeng';
import {RightsToAttachComponent} from '../../rights/rights-to-attach/rights-to-attach.component';
import {CleanStringPipe} from '@mdw/usual-service';
import {Role, RolesService} from '@genos/user-service';
import {ActionListComponent} from '../../../mdw/monitoring/action-list/action-list.component';
import {MessageService} from '../../../global/services/message.service';
import {BreadcrumbService} from '../../../global/services/breadcrumb.service';

@Component({
    selector: 'app-roles',
    templateUrl: './roles-list.component.html',
    styleUrls: ['./roles-list.component.scss'],
    providers: [CleanStringPipe]
})
export class RolesListComponent implements OnInit {

    @ViewChild(ActionListComponent) actionList: ActionListComponent;
    @ViewChild(RightsToAttachComponent) accreditations: RightsToAttachComponent;

    items: MenuItem[];
    showAddDialog: boolean;
    roleName: string;

    roles: Role[];
    role: Role;
    showEditRole: boolean;
    showAccreditationRole: boolean;
    loadGetAll: boolean;
    loadAdd: boolean;

    typeAction: string;

    constructor(private messageService: MessageService,
                private breadcrumbService: BreadcrumbService,
                private cleanString: CleanStringPipe,
                private rolesService: RolesService) {
        this.role = new Role();
        this.typeAction = 'ROLE';
        this.breadcrumbService.setAccreditationChild({label: 'Roles'});
    }

    private _load() {
        this.loadGetAll = true;
        this.rolesService.getAll().subscribe(roles => {
            this.roles = roles;
            this.loadGetAll = false;
        });
    }

    ngOnInit() {
        this.showEditRole = false;
        this.showAccreditationRole = false;
        this._load();
        this.showAddDialog = false;
        this.items = [
            {
                label: 'Nouveau role',
                icon: 'fa-plus',
                command: (event) => {
                    this.passAddRole();
                }
            }
        ];
    }

    passAddRole() {
        this.roleName = '';
        this.showAddDialog = true;
    }

    editRole(role: Role): void {
        this.showAccreditationRole = false;
        if (role.id === this.role.id) {
            this.showEditRole = false;
            this.role = new Role();
        } else {
            this.role = role;
            this.showEditRole = true;
            if (this.actionList) {
                this.actionList.reload('ROLE', this.role.id);
            }
        }

    }

    accreditationToRole(role: Role): void {
        this.showAccreditationRole = false;
        if (role.id === this.role.id) {
            this.showAccreditationRole = false;
            this.role = new Role();
        } else {
            this.showAccreditationRole = false;
            this.role = role;
            if (this.accreditations) {
                this.accreditations.reload(this.role);
            }
            this.showAccreditationRole = true;
            this.showEditRole = false;
        }
    }

    cancelEditRole(reload: boolean): void {
        this.showEditRole = false;
        this.role = new Role();
        if (reload) {
            this._load();
        }
    }

    addRole() {
        if (this.roleName.trim() !== '') {
            this.loadAdd = true;
            this.roleName = this.cleanString.transform(this.roleName, true);
            this.rolesService.createWithName(this.roleName).subscribe(response => {
                this.messageService.addSuccess('Ajout réussi', 'Le role à bien été ajouté');
                this._load();
            }, error => {
                this.messageService.addError('', error.message);
                this.showAddDialog = false;
                this.loadAdd = false;
            }, () => {
                this.showAddDialog = false;
                this.loadAdd = false;
            });
        }
    }

}
