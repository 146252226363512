import {Event} from '../../../events/models/event';
import {isNullOrUndefined} from 'util';
import {Team} from '../../team/models/team';
import {GameForm} from './game-form';
import {EventType} from '../../../events/enums/event-type.enum';
import {EventVisibility} from '../../../events/enums/event-visibility.enum';
import * as moment from 'moment';
import {FormCompliant, Periodicity} from '@mdw/usual-service';

export class Game extends Event implements FormCompliant<Game, GameForm> {


    insideScore: number;
    insideTeam: Team;
    outsideTeam: Team;
    outsideScore: number;
    victoryTeam: Team;
    reference: string;
    sport: string;
    category: string;
    gender: string;


    constructor() {
        super();
        this.type = EventType.GAME;
        this.periodicity = Periodicity.NONE;
        this.visibility = EventVisibility.PUBLIC;
    }

    getSport(): string {
        return this.sport;
    }

    build(): Game {
        super.build();
        this.typeTo = EventType.GAME;

        if (!isNullOrUndefined(this.insideTeam)) {
            this.insideTeam = Object.assign(new Team(), this.insideTeam).build();
        }
        if (!isNullOrUndefined(this.outsideTeam)) {
            this.outsideTeam = Object.assign(new Team(), this.outsideTeam).build();
        }
        if (!isNullOrUndefined(this.victoryTeam)) {
            this.victoryTeam = Object.assign(new Team(), this.victoryTeam).build();
        }
        return this;
    }

    toForm(f?: GameForm): GameForm {
        if (isNullOrUndefined(f)) {
            f = new GameForm();
        }
        super.toForm(f);
        f.reference = this.reference;
        f.outsideScore = this.outsideScore;
        f.insideScore = this.insideScore;
        f.insideTeam = this.insideTeam;
        f.outsideTeam = this.outsideTeam;
        f.victoryTeam = this.victoryTeam;
        f.gender = {value: this.gender};
        f.sport = {value: this.sport};
        f.category = {value: this.category};
        return f;
    }

    fromForm(f: GameForm): Game {
        super.fromForm(f);
        this.reference = f.reference;
        this.outsideScore = f.outsideScore;
        this.insideScore = f.insideScore;
        this.insideTeam = f.insideTeam;
        this.outsideTeam = f.outsideTeam;
        this.victoryTeam = f.victoryTeam;
        this.gender = f.gender.value;
        this.sport = f.sport.value;
        this.category = f.category.value;
        if (!isNullOrUndefined(f.endTime)) {
            const hours = parseInt(f.endTime.split(':')[0], 10);
            const minutes = parseInt(f.endTime.split(':')[1], 10);
            this.endDate = moment(f.startDate).add(hours, 'h').add(minutes, 'm');
        }
        this.title = this.startDate.format('DD/MM/YYYY HH:mm') + ' ' + this.getTeamsName();
        return this;
    }

    getTeamsName(): string {
        const insideName = isNullOrUndefined(this.insideTeam) ? 'Equipe à définir' : this.insideTeam.name;
        const outsideName = isNullOrUndefined(this.outsideTeam) ? 'Equipe à définir' : this.outsideTeam.name;
        const outsideScore = isNullOrUndefined(this.outsideScore) ? '' : this.outsideScore;
        const insideScore = isNullOrUndefined(this.insideScore) ? '' : this.insideScore;
        return insideName + ' ' + insideScore + ' , ' + outsideScore + ' ' + outsideName;
    }

    getDay(): string {
        const day = this.startDate.format('dddd, D MMMM  YYYY');
        return day + ' ' + this.startTime;
    }


    getPlaceName(): string {
        if (isNullOrUndefined(this.place)) {
            return 'Lieu à définir';
        } else {
            return this.place.name;
        }
    }

    getReference(): string {
        if (isNullOrUndefined(this.reference)) {
            return 'Référence à définir';
        } else {
            return this.reference;
        }
    }

}
