import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TrainingsComponent} from './trainings/trainings.component';
import {RouterModule} from '@angular/router';
import {TrainingRoutes} from './training.routes';
import {TrainingsTemplatesComponent} from './trainings-templates/trainings-templates.component';
import {TrainingTemplateComponent} from './training-template/training-template.component';
import {
    CalendarModule, CheckboxModule, DataTableModule, DropdownModule, FileUploadModule, InputTextareaModule,
    InputTextModule, KeyFilterModule, PanelModule, ProgressSpinnerModule, TabViewModule
} from 'primeng/primeng';
import {TrainingTemplatesService} from './services/training-templates.service';
import {FormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ExcludePeriodComponent} from './exclude-period/exclude-period.component';
import {ExcludePeriodsComponent} from './exclude-periods/exclude-periods.component';
import {TrainingComponent} from './training/training.component';
import {PlaceModule} from '../place/place.module';
import {EventModule} from '../../events/event.module';
import {TrainingShowComponent} from './training-show/training-show.component';
import {TrainingEditComponent} from './training-edit/training-edit.component';
import {TalkModule} from '../../mdw/talk/talk.module';
import {GroupModule} from '../group/group.module';

@NgModule({
    imports: [
        GroupModule,
        EventModule,
        TalkModule,
        PlaceModule,
        CommonModule,
        FormsModule,
        InputTextModule,
        FlexLayoutModule,
        CalendarModule,
        KeyFilterModule,
        PanelModule,
        CheckboxModule,
        TabViewModule,
        FileUploadModule,
        InputTextareaModule,
        DropdownModule,
        ProgressSpinnerModule,
        DataTableModule,
        RouterModule.forChild(TrainingRoutes)

    ],
    declarations: [TrainingsComponent, TrainingsTemplatesComponent, TrainingTemplateComponent,
        ExcludePeriodComponent, ExcludePeriodsComponent, TrainingComponent, TrainingShowComponent, TrainingEditComponent],
    providers: [TrainingTemplatesService]
})
export class TrainingModule {
}
