import {Pipe, PipeTransform} from '@angular/core';
import {GenosNotificationTypes} from '../../genos/enums/genos-notification-types.enum';
 
@Pipe({
    name: 'notificationIcon'
})
export class NotificationIconPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        let icon = '';
        switch (value) {
            case GenosNotificationTypes.ACCOUNT_ASK_CREATED:
                icon = 'person_add';
                break;
            default:
                icon = 'priority_high';
        }
        return icon;
    }

}
