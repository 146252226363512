import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-configuration-mail',
  templateUrl: './configuration-mail.component.html',
  styleUrls: ['./configuration-mail.component.scss']
})
export class ConfigurationMailComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
