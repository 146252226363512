import {TalkMessage} from './talk-message';

export class TalkMessagesPositionned {

    messages: TalkMessage[];
    top: string;
    name: string;
    image: string;

    build(): this {
        this.messages = this.messages.map(m => Object.assign(new TalkMessage(), m).build());
        return this;
    }
}
