import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GlobalAuthenticationService} from '@genos/user-service';
import {GlobalMeService} from '../../../global/services/global-me.service';

@Component({
    selector: 'app-ask-activation',
    templateUrl: './ask-activation.component.html',
    styleUrls: ['./ask-activation.component.scss']
})
export class AskActivationComponent implements OnInit {

    token: string;
    loadActivate: boolean;
    loadContinue: boolean;
    errorMessage: boolean;
    errorMessageAlreadyUse: boolean;
    showContinue: boolean;
    continueToken: string;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private me: GlobalMeService,
                private authenticationService: GlobalAuthenticationService) {
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.token = params['token']; // (+) converts string 'idTo' userUri a number
            // In a real app: dispatch action userUri load the details here.
            this.loadActivate = true;
            this.authenticationService.activateEmail(this.token).subscribe(response => {
                this.continueToken = response.generationPasswordKey;
                this.loadActivate = false;
                this.showContinue = true;
            }, response => {
                if (response.status === 409) {
                    this.errorMessageAlreadyUse = true;
                    this.loadActivate = false;
                } else {
                    this.errorMessage = true;
                    this.loadActivate = false;
                }
            });
        });
    }

    continue() {
        this.router.navigate(['password/', this.continueToken]);
    }

}
