import {Component, OnInit} from '@angular/core';
import {TrainingTemplate} from '../models/training-template';
import {TrainingTemplateForm} from '../models/training-template-form';
import {TrainingTemplatesService} from '../services/training-templates.service';
import {forkJoin, Observable, Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {TrainingTemplateStatus} from '../enums/training-template-status.enum';
import {isNullOrUndefined} from 'util';
import {EventVisibility} from '../../../events/enums/event-visibility.enum';
import {PlacesService} from '../../place/services/places.service';
import {PlacesFilter} from '../../place/models/places-filter';
import {Place} from '../../place/models/place';
import {BreadcrumbService} from '../../../global/services/breadcrumb.service';
import {MessageService} from '../../../global/services/message.service';
import {GroupType} from '../../group/enums/group-type.enum';
import {Periodicity} from '@mdw/usual-service';
import {GenosConfigurationService} from '../../configuration/services/genos-configuration.service';

@Component({
    selector: 'app-training-template',
    templateUrl: './training-template.component.html',
    styleUrls: ['./training-template.component.scss']
})
export class TrainingTemplateComponent implements OnInit {

    template: TrainingTemplate;
    templateForm: TrainingTemplateForm;
    idTemplate: number;
    loadInfo: boolean;
    loadSaveTemplate: boolean;

    // options
    places: Place[];
    statutes: any[];
    periodicities: any[];
    visibilities: any[];
    days: any[];
    loadOptions: boolean;
    sports: any[];
    genders: any[];
    categories: any[];
    timeRegexp: RegExp = /[0-9]{2}:[0-9]{2}/;


    groupType: GroupType;

    constructor(private trainingTemplatesService: TrainingTemplatesService,
                private route: ActivatedRoute,
                private router: Router,
                private breadcrumbService: BreadcrumbService,
                private messageService: MessageService,
                private placesService: PlacesService,
                private genosConfigurationService: GenosConfigurationService) {
    }

    ngOnInit() {
        this.loadInfo = true;
        this.idTemplate = parseInt(this.route.snapshot.params.id, 10);
        this.template = new TrainingTemplate();
        this.statutes = [];
        this.periodicities = [];
        this.visibilities = [];
        this.days = [];
        this.groupType = GroupType.TRAINING;
        this.templateForm = this.template.toForm();
        this.loadOptions = true;
        this.loadFormInfos().subscribe(() => {
            this.loadOptions = false;
            this.setInfo();
        });
    }

    setInfo() {
        this.loadInfo = true;
        if (this.idTemplate !== 0) {
            this.trainingTemplatesService.getById(this.idTemplate).subscribe(template => {
                this.template = template;
                this.templateForm = template.toForm();
                if (!isNullOrUndefined(this.templateForm.place)) {
                    this.templateForm.place = this.places.filter(c => c.id === this.templateForm.place.id)[0];
                }
                this.templateForm.status = this.statutes.filter(c => c.value === this.templateForm.status.value)[0];
                this.templateForm.visibility = this.visibilities.filter(c => c.value === this.templateForm.visibility.value)[0];
                this.templateForm.periodicity = this.periodicities.filter(c => c.value === this.templateForm.periodicity.value)[0];
                this.templateForm.day = this.days.filter(c => c.value === this.templateForm.day.value)[0];
                this.templateForm.category = this.categories.filter(c => c.value === this.templateForm.category.value)[0];
                this.templateForm.gender = this.genders.filter(c => c.value === this.templateForm.gender.value)[0];
                this.templateForm.sport = this.sports.filter(c => c.value === this.templateForm.sport.value)[0];
                this.setBreadcrumb();
                this.loadInfo = false;
            });
        } else {

            this.templateForm.status = this.statutes[0];
            this.templateForm.periodicity = this.periodicities[0];
            this.templateForm.visibility = this.visibilities[0];
            this.setBreadcrumb();
            this.loadInfo = false;
        }
    }

    setBreadcrumb() {
        if (this.isNewMode()) {
            this.breadcrumbService.setItems([
                {label: 'Templates d\'entrainements', routerLink: 'trainings-templates'},
                {label: 'Nouveau template'}]);
        } else {
            this.breadcrumbService.setItems([
                {label: 'Templates d\'entrainements', routerLink: 'trainings-templates'},
                {label: this.template.name}]);
        }
    }


    loadFormInfos(): Observable<any> {
        const s = new Subject<any>();
        this.statutes.push({value: TrainingTemplateStatus.ACTIVE, label: 'Actif'});
        this.statutes.push({value: TrainingTemplateStatus.INACTIVE, label: 'Inactif'});
        // this.periodicities.push({value: Periodicity.DAILY, label: 'Journalier'});
        this.periodicities.push({value: Periodicity.WEEKLY, label: 'Hebdomadaire'});
        // this.periodicities.push({value: Periodicity.MONTHLY, label: 'Mensuel'});
        // this.periodicities.push({value: Periodicity.QUARTER, label: 'Trimestriel'});
        // this.periodicities.push({value: Periodicity.BIANUEL, label: 'Semestriel'});
        // this.periodicities.push({value: Periodicity.YEARLY, label: 'Annuel'});
        this.visibilities.push({value: EventVisibility.PUBLIC, label: 'Publique'});
        this.visibilities.push({value: EventVisibility.PRIVATE, label: 'Privé'});

        this.days.push({value: 0, label: 'Lundi'});
        this.days.push({value: 1, label: 'Mardi'});
        this.days.push({value: 2, label: 'Mercredi'});
        this.days.push({value: 3, label: 'Jeudi'});
        this.days.push({value: 4, label: 'Vendredi'});
        this.days.push({value: 5, label: 'Samedi'});
        this.days.push({value: 6, label: 'Dimanche'});

        forkJoin(
            this.placesService.getAll(new PlacesFilter().withStatus('ACTIVE')),
            this.genosConfigurationService.getSports(),
            this.genosConfigurationService.getGenders(),
            this.genosConfigurationService.getSportCategories(),
        ).subscribe(options => {
            this.places = options[0];
            this.sports = options[1];
            this.genders = options[2];
            this.categories = options[3];
            s.next({});
            s.complete();
        });
        return s.asObservable();
    }

    saveTemplate() {
        this.loadSaveTemplate = true;
        this.template = this.template.fromForm(this.templateForm);
        this.trainingTemplatesService.save(this.template).subscribe(response => {

            this.loadSaveTemplate = false;
            this.messageService.addSuccess('Template sauvegardée',
                'Les informations concernant le template de l\'entrainement ont été sauvegardées');
            this.setInfo();
            if (this.isNewMode()) {
                this.router.navigate(['trainings-templates/' + response.id]);
                this.idTemplate = response.id;
                this.setInfo();
            }
        });
    }


    isNewMode(): boolean {
        return this.idTemplate === 0;
    }
}
