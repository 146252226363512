import {Component, OnInit} from '@angular/core';
import {MatBottomSheetRef} from '@angular/material';

@Component({
    selector: 'app-talk-bottom-sheet',
    templateUrl: './talk-bottom-sheet.component.html',
    styleUrls: ['./talk-bottom-sheet.component.scss']
})
export class TalkBottomSheetComponent implements OnInit {

    constructor(private bottomSheetRef: MatBottomSheetRef<TalkBottomSheetComponent>) {
    }

    ngOnInit() {
    }

    openLink(event: MouseEvent): void {
        this.bottomSheetRef.dismiss();
        event.preventDefault();
    }

}
