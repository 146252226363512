import {Licence} from './licence';
import {Buildable, Dto} from '@mdw/usual-service';
import {NaturalPerson} from '@genos/person-service';

export class LicenceMember extends Dto implements Buildable<LicenceMember> {

    member: NaturalPerson;
    licence: Licence;
    period: number;
    periodicity: string;

    build(): LicenceMember {
        super.build();
        this.member = Object.assign(new NaturalPerson(), this.member).build();
        this.licence = Object.assign(new Licence(), this.licence).build();
        return this;
    }
}
