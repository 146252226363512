export enum EContactType {
    MOBILE_PHONE = 'MOBILE_PHONE',
    HOME_PHONE = 'HOME_PHONE',
    OFFICE_PHONE = 'OFFICE_PHONE',
    HOME_ADDRESS = 'HOME_ADDRESS',
    PERSONAL_EMAIL = 'PERSONAL_EMAIL',
    PROFESSIONAL_EMAIL = 'PROFESSIONAL_EMAIL',
    EMERGENCY_PHONE = 'EMERGENCY_PHONE',
    WEBSITE = 'WEBSITE'
}
