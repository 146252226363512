import {Buildable, Dto} from '@mdw/usual-service';
import {ContactTechnicalType} from '../enums/contact-technical-type.enum';
import {EContactType} from '../enums/e-contact-type';

export class ContactType extends Dto implements Buildable<ContactType> {

    name: string;
    description: string;
    technicalType: ContactTechnicalType;
    type: EContactType;

    build(): ContactType {
        return this;
    }

}
