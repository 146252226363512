import {Component, Input, OnInit} from '@angular/core';
import {EventAttendance} from '../models/event-attendance';
import {EventAttendanceStatus} from '../enums/event-attendance-status.enum';
import {isNullOrUndefined} from 'util';

@Component({
    selector: 'app-event-attendance-list',
    templateUrl: './event-attendance-list.component.html',
    styleUrls: ['./event-attendance-list.component.scss']
})
export class EventAttendanceListComponent implements OnInit {

    @Input() attendances: EventAttendance[];
    nbAvailable: number;
    nbNotAvailable: number;
    nbNotAnswer: number;
    nbToBeConfirm: number;

    constructor() {
    }

    ngOnInit() {
        if (!isNullOrUndefined(this.attendances)) {
            this.nbAvailable = this.attendances.filter(a => a.status === EventAttendanceStatus.AVAILABLE).length;
            this.nbNotAvailable = this.attendances.filter(a => a.status === EventAttendanceStatus.NOT_AVAILABLE).length;
            this.nbNotAnswer = this.attendances.filter(a => a.status === EventAttendanceStatus.NOT_ANSWERED).length;
            this.nbToBeConfirm = this.attendances.filter(a => a.status === EventAttendanceStatus.TO_BE_CONFIRM).length;
        } else {
            this.nbAvailable = 0;
            this.nbNotAvailable = 0;
            this.nbNotAnswer = 0;
            this.nbToBeConfirm = 0;
        }
    }


}
