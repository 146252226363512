import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {DataService} from '../services/data.service';
import {Talk} from '../models/talk';
import {TruncatePipe} from '@mdw/usual-service';
import {Router} from '@angular/router';
import {CommonComponent} from '../../common-component';
import {AnalyticsClick} from '../../analytics/decorators/analytics-click.decorator';
import {MatBottomSheet} from '@angular/material';
import {TalkMode} from '../enums/talk-mode.enum';
import {GlobalMeService} from '../../global/services/global-me.service';
import {TalkService} from '../services/talk.service';
import {TalkWithLastMessageAndCountView} from '../models/talk-with-last-message-and-count-view';
import {MainLoaderMode} from '../../global/main-loader/main-loader-mode.enum';


export interface Section {
    name: string;
    updated: Date;
}

@Component({
    selector: 'app-talk-list',
    templateUrl: './talk-list.component.html',
    styleUrls: ['./talk-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [TruncatePipe]
})
export class TalkListComponent extends CommonComponent implements OnInit {

    isMobile: boolean;
    talks: TalkWithLastMessageAndCountView[];
    @Input() current: Talk;
    @Output() threadChange = new EventEmitter<Talk>();

    @Output() openTalk = new EventEmitter<Talk>();
    @Output() setNotificationCount = new EventEmitter<number>();
    load = true;
    loaderMode: MainLoaderMode;

    avatarUrl = 'https://randomuser.me/api/portraits/men/43.jpg';
    name = 'John Doe';
    notification = {type: 'EnableNotifications'};

    constructor(private dataService: DataService,
                private bottomSheet: MatBottomSheet,
                private me: GlobalMeService,
                private talkService: TalkService,
                private router: Router) {
        super();
        this.loaderMode = MainLoaderMode.ON;
        // this.talks = dataService.getTalks();
        // this.current = this.threads[0]; // uncomment to use first thread as default
        this.current = new Talk();
    }

    changeThread(t: Talk) {
        this.current = t;
        this.threadChange.emit(t);
    }


    ngOnInit() {
        super.ngOnInit();
        this.talkService.getTalkByUser(this.me.getUserUri()).subscribe(talks => {
            this.talks = talks;
            let countNotif = 0;
            talks.forEach(t => countNotif += t.notificationCount);
            this.setNotificationCount.emit(countNotif);
            this.load = false;
            this.loaderMode = MainLoaderMode.OFF;
        });
        // this.threadChange.emit(this.current);
    }

    @AnalyticsClick('talk', 'Open talk messages', 'talk-open_talk_messages')
    goToTalk(talk: Talk) {
        if (this.isMobile) {
            this.router.navigate(['/talks/' + talk.id]);
        } else {
            this.openTalk.emit(talk);
        }
    }

    handleResize(): void {
        super.handleResize();
        this.isMobile = this.defineIsMobile();
    }

    @AnalyticsClick('talk', 'Add new talk message', 'talk-add_new_talk_message')
    addNewMessage() {
        if (this.isMobile) {
            // TODO do
            console.log('create page new talk in mobile');
        } else {
            const nTalk = new Talk();
            nTalk.mode = TalkMode.NEW;
            this.openTalk.emit(nTalk);
        }
    }


}
