import {Injectable} from '@angular/core';
import {NotificationView} from '../models/notification-view';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppConfigService} from '@mdw/usual-service';
import {DtoSecurisedService, GlobalSessionService} from '@genos/user-service';
import {NotificationSearchRequest} from '../../../notifications/models/notification-search-request';

@Injectable()
export class NotificationsService extends DtoSecurisedService<NotificationView> {

    getBaseUrl(): string {
        return this.appConfigService.getSecuredBaseUrl() + '/monitoring/notifications';
    }

    constructor(protected session: GlobalSessionService,
                protected client: HttpClient,
                private appConfigService: AppConfigService) {
        super(client, session);
    }

    update(t: NotificationView): Observable<NotificationView> {
        return this.put<NotificationView>(this.getBaseUrl() + '/' + t.id, t);
    }

    create(t: NotificationView): Observable<NotificationView> {
        return this.post<NotificationView>(this.getBaseUrl(), t);
    }

    getNotifications(filter: NotificationSearchRequest): Observable<NotificationView[]> {
        return null; // this.getArray<NotificationView>(this.getBaseUrl() + filter.toUrl(), {}, NotificationView);
    }
}
