import {isNullOrUndefined} from 'util';
import {ToUrl} from '@mdw/usual-service';

export class TeamsFilter extends ToUrl {

    club: number;
    category: string;
    gender: string;
    sport: string;
    status: string;
    contest: number;

    toUrl(): string {
        let url = '';
        if (!isNullOrUndefined((this.club))) {
            url += this.getSeparator() + 'club=' + this.club;
        }
        if (!isNullOrUndefined((this.contest))) {
            url += this.getSeparator() + 'contest=' + this.contest;
        }
        if (!isNullOrUndefined((this.category))) {
            url += this.getSeparator() + 'category=' + this.category;
        }
        if (!isNullOrUndefined((this.gender))) {
            url += this.getSeparator() + 'gender=' + this.gender;
        }
        if (!isNullOrUndefined((this.sport))) {
            url += this.getSeparator() + 'sport=' + this.sport;
        }
        if (!isNullOrUndefined((this.status))) {
            url += this.getSeparator() + 'status=' + this.status;
        }
        return url;
    }

    withContest(contest: number): TeamsFilter {
        this.contest = contest;
        return this;
    }

    withClub(club: number): TeamsFilter {
        this.club = club;
        return this;
    }

    withStatus(status: string): TeamsFilter {
        this.status = status;
        return this;
    }

    withSport(sport: string): TeamsFilter {
        this.sport = sport;
        return this;
    }

    withGender(gender: string): TeamsFilter {
        this.gender = gender;
        return this;
    }

    withCategory(category: string): TeamsFilter {
        this.category = category;
        return this;
    }


}
