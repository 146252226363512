import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {LeaderboardType} from '../enums/leaderboard-type.enum';
import {Leaderboard} from '../models/leaderboard';
import {LeaderboardLine} from '../models/leaderboard-line';
import {Team} from '../../team/models/team';

@Component({
    selector: 'app-leaderboard-edit',
    templateUrl: './leaderboard-edit.component.html',
    styleUrls: ['./leaderboard-edit.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})
export class LeaderboardEditComponent implements OnInit {


    @Input() leaderboard: Leaderboard;
    @Input() availableTeams: Team[];

    type: LeaderboardType;

    constructor() {
    }

    ngOnInit() {
        this.type = this.leaderboard.type.value;
        this.leaderboard.lines.forEach(l => {
            l.team = this.availableTeams.filter(t => t.id === l.team.id)[0];
        });

    }

    reload() {
        this.type = this.leaderboard.type.value;
    }


    up(line: LeaderboardLine) {
        this.leaderboard.lines.forEach(l => {
            if (l.team.id !== line.team.id) {
                l.order++;
            } else {
                l.order--;
            }
        });
        this.sortLines();
    }

    down(line: LeaderboardLine) {
        this.leaderboard.lines.forEach(l => {
            if (l.team.id !== line.team.id) {
                l.order--;
            } else {
                l.order++;
            }
        });
        this.sortLines();
    }

    sortLines() {
        this.leaderboard.lines = this.leaderboard.lines.sort((a, b) => {
            if (a.order > b.order) {
                return 1;
            }
            if (a.order < b.order) {
                return -1;
            }
            if (a.order === b.order) {
                return 0;
            }
        });
    }

    hasNoTeams(): boolean {
        return this.availableTeams.length === 0;
    }


    isPoints(): boolean {
        return this.type === LeaderboardType.LEADERBOARD_TYPE_POINTS;
    }

    isAverage(): boolean {
        return this.type === LeaderboardType.LEADERBOARD_TYPE_AVERAGE;
    }

    addLine() {
        const l = new LeaderboardLine();
        l.order = this.leaderboard.lines.length;
        this.leaderboard.lines.push(l);
    }

}
