import {Component, Host, HostBinding, OnInit} from '@angular/core';
import {DataService} from '../services/data.service';
import {TalkOld} from '../models/talk-old';

@Component({
    selector: 'app-talk-zone',
    templateUrl: './talk-zone.component.html',
    styleUrls: ['./talk-zone.component.scss']
})
export class TalkZoneComponent implements OnInit {
    @HostBinding('class.talk-zone') someClass: Host = true;

    talks: TalkOld[];

    constructor(private dataService: DataService) {
    }

    ngOnInit() {
        this.talks = [];
    }

    openTalk(talk) {
        if (!this.talks.some(t => t.id === talk.id)) {
            this.talks.push(talk);
        }
    }

    closeTalk(id) {
        this.talks.splice(-1, 1);
    }

}
