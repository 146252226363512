<div class="content-card rights-to-role min-height-card genos-form">
    <div class="form-group card card-w-title" fxFlex="100" fxLayout="column">
        <h4 i18n="@@h4-attach-rights-to-role" *ngIf="!isAccountMode()">Droits attaché au role {{to.name}}</h4>
        <h4 i18n="@@h4-attach-rights-to-role" *ngIf="isAccountMode()">Droits attaché au compte {{to.email}}</h4>
        <div fxFlex="100">
            <div fxFlex="100" fxLayout="column">
                <h4 i18n="@@h4-rights">Les droits</h4>
                <div class="ui-float-label line" fxLayout="column">
                    <input type="text" class="form-input" [(ngModel)]="filterDescription"
                           pInputText id="filter">
                    <label for="filter" i18n="@@placeholder-filter">Filtre</label>
                </div>
                <div fxFlex="100" *ngIf="loadRights" fxLayoutAlign="center center">
                    <p-progressSpinner class="spinner"></p-progressSpinner>
                </div>
                <div *ngIf="!loadRights" fxFlex="100" fxLayout="row" fxLayoutWrap>
                    <div class="group" fxFlex="25" *ngFor="let rc of rcs | filterDescriptionRight:filterDescription">
                        <h5>{{rc.group.description}}</h5>
                        <div class="checkboxes" *ngFor="let right of rc.rights">
                            <p-checkbox name="groupname"
                                        binary="true"
                                        [ngModel]="getCheckedByRight(right)"
                                        (onChange)="handleChange($event,right)"
                                        label="{{right.description}}"></p-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>