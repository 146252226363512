<div *ngIf="isGame()">
    <div fxFlex="100" class="event-attendance" fxLayout="row">
        <div fxFlex="10">
            <img class="roundedImage very-small" [src]="person.image">
        </div>
        <div fxFlex="15" class="first-in-game" fxLayoutAlign="start center">
            <i class="material-icons available" (click)="passAvailable()"
               [ngClass]="{'selected-available': available}">check_circle</i>
        </div>
        <div fxFlex="25" fxLayoutAlign="center center">
            <i class="material-icons to-be-confirm" (click)="passToBeConfirmed()"
               [ngClass]="{'selected-to-be-confirm': toBeConfirm}">help</i>
        </div>
        <div fxFlex="25" fxLayoutAlign="center center">
            <i class="material-icons not-answered"
               [ngClass]="{'selected-not-answered': notAnswered}">history</i>
        </div>
        <div fxFlex="25" fxLayoutAlign="center center">
            <i class="material-icons not-available" (click)="passNotAvailable()"
               [ngClass]="{'selected-not-available': notAvailable}">highlight_off</i>
        </div>
    </div>
</div>
<div *ngIf="isEven()">
    <div fxFlex="90" class="event-attendance" fxLayout="row">
        <div fxFlex="20" >
            <img class="roundedImage very-small" [src]="person.image">
        </div>
        <div fxFlex="80" fxLayout="row">
            <div fxFlex="25" fxLayoutAlign="center center">
                <i class="material-icons available" (click)="passAvailable()"
                   [ngClass]="{'selected-available': available}">check_circle</i>
            </div>
            <div fxFlex="25" fxLayoutAlign="center center">
                <i class="material-icons to-be-confirm" (click)="passToBeConfirmed()"
                   [ngClass]="{'selected-to-be-confirm': toBeConfirm}">help</i>
            </div>
            <div fxFlex="25" fxLayoutAlign="center center">
                <i class="material-icons not-answered"
                   [ngClass]="{'selected-not-answered': notAnswered}">history</i>
            </div>
            <div fxFlex="25" fxLayoutAlign="center center">
                <i class="material-icons not-available" (click)="passNotAvailable()"
                   [ngClass]="{'selected-not-available': notAvailable}">highlight_off</i>
            </div>
        </div>
    </div>
    <div fxFlex="10"></div>
</div>
<div *ngIf="isOdd()">
    <div fxFlex="10"></div>
    <div fxFlex="90" class="event-attendance" fxLayout="row">
        <div fxFlex="80" fxLayout="row">
            <div fxFlex="25" fxLayoutAlign="center center">
                <i class="material-icons available" (click)="passAvailable()"
                   [ngClass]="{'selected-available': available}">check_circle</i>
            </div>
            <div fxFlex="25" fxLayoutAlign="center center">
                <i class="material-icons to-be-confirm" (click)="passToBeConfirmed()"
                   [ngClass]="{'selected-to-be-confirm': toBeConfirm}">help</i>
            </div>
            <div fxFlex="25" fxLayoutAlign="center center">
                <i class="material-icons not-answered" (click)="passNotAnswered()"
                   [ngClass]="{'selected-not-answered': notAnswered}">history</i>
            </div>
            <div fxFlex="25" fxLayoutAlign="center center">
                <i class="material-icons not-available" (click)="passNotAvailable()"
                   [ngClass]="{'selected-not-available': notAvailable}">highlight_off</i>
            </div>
        </div>
        <div fxFlex="20">
            <img class="roundedImage very-small" [src]="person.image">
        </div>
    </div>
</div>