export enum ClubOptionGroups {
    FIELD_POSITIONS = 'FIELD_POSITIONS',
    DIPLOMAS = 'DIPLOMAS',
    SPORT_ABILITIES_BASEBALL_INFIELD = 'SPORT_ABILITIES_BASEBALL_INFIELD',
    SPORTS = 'SPORTS',
    GENDERS = 'GENDERS',
    SPORT_CATEGORIES = 'SPORT_CATEGORIES',
    SPORT_LEVEL = 'SPORT_LEVEL',
    LEADERBOARD_TYPE = 'LEADERBOARD_TYPE',
    CONTEST_TYPE = 'CONTEST_TYPE',
}
