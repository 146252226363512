import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {GlobalModule} from './global/global.module';
import {RouterModule} from '@angular/router';
import {AppRoutes} from './app.routes';
import {FlexLayoutModule} from '@angular/flex-layout';
import {DashboardsModule} from './dashboards/dashboards.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {
    ConfirmDialogModule,
    ContextMenuModule,
    DataTableModule,
    DialogModule,
    GrowlModule,
    LightboxModule,
    OverlayPanelModule,
} from 'primeng/primeng';
import {CarService} from './demo/service/carservice';
import {CalendarModule} from './calendar/calendar.module';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {AdminModule} from './admin/admin.module';
import {ClubModule} from './genos/club/club.module';
import {GameModule} from './genos/game/game.module';
import {PlaceModule} from './genos/place/place.module';
import {ContestModule} from './genos/contest/contest.module';
import {TeamModule} from './genos/team/team.module';
import {GroupModule} from './genos/group/group.module';
import {EventModule} from './events/event.module';
import {TrainingModule} from './genos/training/training.module';
import {MonitoringModule} from './mdw/monitoring/monitoring.module';
import {MdwUsualServiceModule} from '@mdw/usual-service';
import {GenosModule} from './genos/genos.module';
import {CookieService, OptionsService} from '@genos/user-service';
import {OnboardingModule} from './onboarding/onboarding.module';
import {OrganizationModule} from './organization/organization.module';
import {GlobalMeService} from './global/services/global-me.service';
import {AnalyticsModule} from './analytics/analytics.module';
import {ErrorInterceptorService} from './genos/services/error-interceptor.service';
import {OrganizationContextService} from '@genos/organization-service';
import {GlobalNoAuthGuard} from './genos/guards/global-no-auth.guard';
import {GlobalAuthGuard} from './genos/guards/global-auth.guard';
import {GenosRightGuard} from './global/services/genos-right.guard';
import {RightGuard} from './genos/guards/right.guard';
import {PersonsModule} from './persons/persons.module';
import {OnboardingContextService} from './onboarding/onboarding-context.service';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {ConfirmComponent} from './genos/confirm/confirm.component';
import {
    MAT_SNACK_BAR_DEFAULT_OPTIONS,
    MatBadgeModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule
} from '@angular/material';
import {NotificationsService} from './notifications/services/notifications.service';
import {FormsModule} from '@angular/forms';
import {DataService} from './talks/services/data.service';
import {HttpHandlerService} from './talks/services/http_handler.service';
import {CacheService} from './talks/services/cache.service';
import {TalksModule} from './talks/talks.module';
import {NotificationsModule} from './notifications/notifications.module';
import {FooterService} from './global/services/footer.service';
import {HeaderService} from './global/services/header.service';
import {CordovaService} from './global/services/cordova.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NavigationService} from './global/services/navigation.service';
import {BreadcrumbService} from './global/services/breadcrumb.service';

registerLocaleData(localeFr);

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        NotificationsModule,
        MatSidenavModule,
        MatCheckboxModule,
        MatButtonModule,
        MatBadgeModule,
        MatToolbarModule,
        FlexLayoutModule,
        MatIconModule,
        FormsModule,
        OnboardingModule,
        OrganizationModule,
        ClubModule,
        GameModule,
        AnalyticsModule,
        PersonsModule,
        PlaceModule,
        ContestModule,
        TeamModule,
        GroupModule,
        DashboardsModule,
        GlobalModule,
        CalendarModule,
        EventModule,
        TrainingModule,
        AdminModule,
        GenosModule,
        TalksModule,
        // MDW
        MdwUsualServiceModule,
        MonitoringModule,
        // VENDOR
        OverlayPanelModule,
        DataTableModule,
        DialogModule,
        ConfirmDialogModule,
        LightboxModule,
        BrowserModule,
        FlexLayoutModule,
        GrowlModule,
        HttpClientModule,
        ContextMenuModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(
            AppRoutes
        ),
        ServiceWorkerModule.register('/ngsw-worker-polyfill.js', {enabled: environment.production})
    ],
    entryComponents: [
        ConfirmComponent
    ],
    providers: [
        CarService,
        NotificationsService,
        OnboardingContextService,
        OptionsService,
        {provide: LOCALE_ID, useValue: 'fr'},
        GlobalMeService,
        GlobalNoAuthGuard,
        GlobalAuthGuard,
        FooterService,
        HeaderService,
        OrganizationContextService,
        {provide: RightGuard, useClass: GenosRightGuard},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true},
        {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 3500}},
        DataService,
        HttpHandlerService,
        CacheService,
        CordovaService,
        NavigationService,
        BreadcrumbService,
        CookieService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
