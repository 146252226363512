import {Injectable} from '@angular/core';
import {SportModeHandler} from '../../genos/mode/interfaces/sport-mode-handler';
import {FormType} from '../../genos/mode/enums/form-type.enum';
import {GameHandlerService} from '../game/services/game-handler.service';
import {NaturalPersonHandlerService} from '../person/services/natural-person-handler.service';
import {SportModeFactory} from '../../genos/mode/interfaces/sport-mode-factory';

@Injectable()
export class BscModeFactoryService implements SportModeFactory {

    constructor(private gameModeHandler: GameHandlerService,
                private naturalPersonHandlerService: NaturalPersonHandlerService) {
    }

    get(type: FormType): SportModeHandler<any, any> {
        let s;
        switch (type) {
            case FormType.GAME:
                s = this.gameModeHandler;
                break;
            case FormType.NATURAL_PERSON:
                s = this.naturalPersonHandlerService;
                break;
        }
        return s;
    }
}
