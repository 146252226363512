<div class="content-card profile common min-height-card"
     fxLayout="row"
     fxLayout.xs="column">
    <div class="loader" fxLayout="row" *ngIf="loadInfo" fxLayoutAlign="center center">
        <p-progressSpinner></p-progressSpinner>
    </div>
    <div *ngIf="!loadInfo" class="form-group card card-w-title contest-info"
         fxFlex="33" fxLayout="column"
         flexXs="100">
        <h4 i18n="@@h4-profile">Compétition</h4>
        <div fxFlex="100" fxLayout="row">
            <div fxFlex="50">
                <span class="profile-image-wrapper">
                    <img class="roundedImage medium" src="{{contest.logo}}">
                </span>
            </div>
            <div fxFlex="50" fxLayoutAlign="bottom left">
                <p-fileUpload #uploader name="logo" mode="basic" customUpload="true"
                              (uploadHandler)="myUploader($event,uploader)"></p-fileUpload>
            </div>
        </div>
        <p-panel header="Informations générales" i18n-header="@@h3-general-info">
            <div fxFlex="100" fxLayout="column" class="genos-form">
                <div class="ui-float-label line" fxLayout="column">
                    <input type="text" class="form-input" [(ngModel)]="contestForm.name"
                           pInputText id="name">
                    <label for="name" i18n="@@placeholder-contest-name">Nom</label>
                </div>
                <div class="ui-float-label line" fxLayout="column">
                    <input type="text" class="form-input" [(ngModel)]="contestForm.period"
                           pInputText id="period">
                    <label for="name" i18n="@@placeholder-contest-period">Période</label>
                </div>
                <p-dropdown [options]="statutes"
                            class="form-input"
                            i18n-placeholder="@@placeholder-select-input-contact-sport"
                            placeholder="Selectionnez un status"
                            [style]="{'width':'250px'}"
                            [(ngModel)]="contestForm.status"
                            optionLabel="name"></p-dropdown>
                <p-dropdown [options]="contestsTypes"
                            class="form-input"
                            (onChange)="setType()"
                            i18n-placeholder="@@placeholder-select-input-contact-sport"
                            placeholder="Selectionnez un type"
                            [style]="{'width':'250px'}"
                            [(ngModel)]="contestForm.type"
                            optionLabel="label"></p-dropdown>
                <p-dropdown [options]="sports"
                            class="form-input"
                            i18n-placeholder="@@placeholder-select-input-contact-sport"
                            placeholder="Selectionnez un sport"
                            [style]="{'width':'250px'}"
                            [(ngModel)]="contestForm.sport"
                            optionLabel="label"></p-dropdown>
                <p-dropdown [options]="genders"
                            class="form-input"
                            i18n-placeholder="@@placeholder-select-input-team-gender"
                            placeholder="Selectionnez un genre"
                            [style]="{'width':'250px'}"
                            [(ngModel)]="contestForm.gender"
                            optionLabel="label"></p-dropdown>
                <p-dropdown [options]="categories"
                            class="form-input"
                            i18n-placeholder="@@placeholder-select-input-team-category"
                            placeholder="Selectionnez une categorie"
                            [style]="{'width':'250px'}"
                            [(ngModel)]="contestForm.category"
                            optionLabel="label"></p-dropdown>
                <p-dropdown [options]="levels"
                            class="form-input"
                            i18n-placeholder="@@placeholder-select-input-team-level"
                            placeholder="Selectionnez une division"
                            [style]="{'width':'250px'}"
                            [(ngModel)]="contestForm.level"
                            optionLabel="label"></p-dropdown>
                <div class="ui-float-label line" fxLayout="column">
                    <input type="text" class="form-input" [(ngModel)]="contestForm.linkOfficial"
                           pInputText id="linkOfficial">
                    <label for="linkOfficial" i18n="@@placeholder-contest-name">Lien officiel de la compétition</label>
                </div>
                <div class="ui-float-label" fxLayout="column">
                    <textarea [rows]="5" [cols]="30" pInputTextarea [(ngModel)]="contestForm.description"></textarea>
                    <label for="name" i18n="@@placeholder-natural-team-description">Description</label>
                </div>
                <div class="ui-float-label" fxLayout="column">
                    <textarea [rows]="5" [cols]="30" pInputTextarea
                              [(ngModel)]="contestForm.shortDescription"></textarea>
                    <label for="name" i18n="@@placeholder-natural-team-short-description">Description courte</label>
                </div>

            </div>
            <p-footer>
                <button pButton type="button"
                        *ngIf="!isNew()"
                        (click)="deleteContest()"
                        icon="fa-delete" class="ui-button-danger"
                        i18n-label="@@button-save" label="Supprimer"></button>
                <button pButton type="button" icon="fa-check"
                        (click)="saveContest()"
                        i18n-label="@@button-save" label="Sauvegarder"></button>
            </p-footer>
        </p-panel>

    </div>
    <div *ngIf="!loadInfo && !isNew()" fxFlex="66" fxLayout="column" class="form-group card card-w-title roster"
         flexXs="100">
        <p-tabView>
            <p-tabPanel header="Matchs">
                <a [href]="contestForm.linkOfficial" target="_blank">Voir les informations officiels</a>
                <app-contest-games [contest]="contest"
                                   (onSaveGame)="handleInit()"
                ></app-contest-games>
            </p-tabPanel>
            <p-tabPanel header="Classements">
                <a [href]="contestForm.linkOfficial" target="_blank">Voir les informations officiels</a>
                <app-leaderboards [contest]="contest" [leaderboards]="contest.leaderboards"></app-leaderboards>
            </p-tabPanel>
            <p-tabPanel header="Equipes">
                <app-contest-teams [contest]="contest"></app-contest-teams>
            </p-tabPanel>
            <!--<p-tabPanel header="Evénements">-->
                <!--Evénements-->
            <!--</p-tabPanel>-->

        </p-tabView>
    </div>

</div>
<p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="425"></p-confirmDialog>

