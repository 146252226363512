import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatAutocompleteModule, MatFormFieldModule, MatInputModule} from '@angular/material';
import {MembersAndGroupsAutocompleteComponent} from './members-and-groups-autocomplete/members-and-groups-autocomplete.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MembersAutocompleteComponent} from './members-autocomplete/members-autocomplete.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatInputModule,
        FormsModule,
        MatFormFieldModule
    ],
    declarations: [MembersAndGroupsAutocompleteComponent, MembersAutocompleteComponent],
    exports: [MembersAndGroupsAutocompleteComponent, MembersAutocompleteComponent]
})
export class MembersModule {
}
