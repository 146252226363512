<div fxLayout="row" fxLayout.xs="column" (mouseenter)="hover = true" (mouseleave)="hover = false">
    <div fxFlex="50" fxFlex.xs="100">
        <p-gmap [options]="options" [overlays]="overlays" [style]="{'width':'100%','height':'200px'}"></p-gmap>
    </div>
    <div fxFlex="50" fxFlex.xs="100" class="infos" fxLayout="column">
        <div fxLayout="row">
            <h4 fxFlex="70">{{place.name}}</h4>
            <div fxFlex="30" fxLayoutAlign="end center" *ngIf="hover">
                <div appNeedRight="UPDATE_PLACE" fxLayoutAlign="center center" class="button-i"
                     (click)="goToPlaceForEdit()">
                    <i class="material-icons">edit_mode</i>
                </div>
            </div>

        </div>
        <div>
            <h4>Adresse :</h4>
            <div>
                {{place.address}} - {{place.zipCode}} - {{place.city}}
            </div>
        </div>
        <div>
            <h4>Comment y aller :</h4>
            <div>
                {{place.howTo}}
            </div>
        </div>
    </div>
</div>