<div class="contests" fxFlex="100" fxLayout="row">
    <div fxFlex="100" class="loader" fxLayout="row" *ngIf="loadContest" fxLayoutAlign="center center">
        <p-progressSpinner></p-progressSpinner>
    </div>
    <div fxLayout="column" class="card" fxFlex="100" *ngIf="!loadContest">
        <div fxLayout="row">
            <h3 i18n="@@h3-contests" fxFlex="50">Gestion des compétitions</h3>
            <div fxFlex="50" fxLayoutAlign="end center">
                <div appNeedRight="CREATE_CONTEST" fxLayoutAlign="center center" class="button-i"
                     (click)="addContest()">
                    <i class="material-icons">add</i>
                </div>
            </div>
        </div>
        <div fxFlex="100" fxLayout="row">
            <div fxFlex="100" fxLayout="column">

                <div class="ui-widget-header" fxFlex="100" fxLayout="row">
                    <i class="fa fa-search"></i>
                    <input #gb type="text" pInputText size="50" i18n-placeholder="@@placeholder-global-filter"
                           placeholder="Filtre">
                </div>
                <p-dataTable fxFlex="100" [globalFilter]="gb" [value]="contests"
                             [paginator]="true" [rows]="10"
                             selectionMode="single"
                             [(selection)]="selectedContest" (onRowSelect)="onRowSelect($event)"
                             [sortField]="sortF" [sortOrder]="sortO">
                    <p-column field="logo" i18n-header="@@header-data-table-contest-sport" header="Logo">
                        <ng-template let-contest="rowData" pTemplate="body">
                            <span class="profile-image-wrapper">
                                <img src="{{contest.logo}}">
                            </span>
                        </ng-template>
                    </p-column>
                    <p-column field="name" i18n-header="@@header-data-table-contest-name" header="Nom"
                              [sortable]="true"></p-column>
                    <p-column field="period" i18n-header="@@header-data-table-contest-period" header="Période"
                              [sortable]="true"></p-column>
                    <p-column field="sport" i18n-header="@@header-data-table-contest-sport" header="Sport"
                              [sortable]="true">
                        <ng-template let-contest="rowData" pTemplate="body">
                            <span>{{contest.sport}}</span>
                        </ng-template>
                    </p-column>
                    <p-column field="level" i18n-header="@@header-data-table-contest-level" header="Division"
                              [sortable]="true">
                        <ng-template let-contest="rowData" pTemplate="body">
                            <span>{{contest.level}}</span>
                        </ng-template>
                    </p-column>
                    <p-column field="category" i18n-header="@@header-data-table-contest-level" header="Categorie"
                              [sortable]="true">
                        <ng-template let-contest="rowData" pTemplate="body">
                            <span>{{contest.category}}</span>
                        </ng-template>
                    </p-column>
                    <p-column field="gender" i18n-header="@@header-data-table-contest-gender" header="Genre"
                              [sortable]="true">
                        <ng-template let-contest="rowData" pTemplate="body">
                            <span>{{contest.gender}}</span>
                        </ng-template>
                    </p-column>
                    <p-column field="status" i18n-header="@@header-data-table-account-status" header="Statut"
                              [sortable]="true"></p-column>
                </p-dataTable>
            </div>
        </div>
    </div>
</div>