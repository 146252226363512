import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OnDemandJsLoadService} from './services/on-demand-js-load.service';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [],
    exports: [],
    providers: [OnDemandJsLoadService]
})
export class MdwModule {
}
