import {Buildable, Dto} from '@mdw/usual-service';
import {NotificationStatus} from '../enums/notification-status.enum';

export class NotificationView extends Dto implements Buildable<NotificationView> {

    type: string;
    title: string;
    message: string;
    image: string;
    status: NotificationStatus;
    actionType: string;
    actionValue: string;
    saw: boolean;
    handled: boolean;

    build(): NotificationView {
        super.build();
        return this;
    }

    isActive(): boolean {
        let active = true;
        switch (this.status) {
            case NotificationStatus.HANDLED:
            case NotificationStatus.READ:
                active = false;
                break;
            case NotificationStatus.CREATED:
                active = !this.saw;
                break;
            case NotificationStatus.TO_HANDLE:
                active = !this.handled;
                break;
        }
        return active;
    }

    read() {
        switch (this.status) {
            case NotificationStatus.CREATED:
                this.saw = true;
                this.status = NotificationStatus.READ;
                break;
            case NotificationStatus.TO_HANDLE:
                this.saw = true;
                break;
        }
    }

    handle() {
        switch (this.status) {
            case NotificationStatus.READ:
                this.handled = true;
                break;
            case NotificationStatus.CREATED:
                this.saw = true;
                this.handled = true;
                break;
            case NotificationStatus.TO_HANDLE:
                this.handled = true;
                this.type = NotificationStatus.HANDLED;
                break;
        }
    }

}
