import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {EventAttendance} from '../models/event-attendance';
import {EventAttendanceStatus} from '../enums/event-attendance-status.enum';
import {EventsService} from '../services/events.service';
import {isNullOrUndefined} from 'util';
import {Event} from '../models/event';
import {MessageService} from '../../global/services/message.service';
import {NaturalPerson} from '@genos/person-service';
import {GlobalMeService} from "../../global/services/global-me.service";

@Component({
    selector: 'app-event-attendance',
    templateUrl: './event-attendance.component.html',
    styleUrls: ['./event-attendance.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EventAttendanceComponent implements OnInit {

    @Input() event: Event;
    @Input() mode: string;
    @Input() attendance: EventAttendance;
    @Output() onUpdate: EventEmitter<EventAttendance> = new EventEmitter<EventAttendance>();
    person: NaturalPerson;

    comment: string;

    available: boolean;
    notAvailable: boolean;
    toBeConfirm: boolean;
    notAnswered: boolean;


    constructor(private me: GlobalMeService,
                private eventsService: EventsService,
                private messageService: MessageService) {
    }

    ngOnInit() {
        this.person = this.attendance.person;
        if (!isNullOrUndefined(this.attendance)) {
            this.setStatus();
        }

    }

    isEven() {
        return this.mode === 'EVEN';
    }

    isGame() {
        return this.mode === 'GAME';
    }

    isOdd() {
        return this.mode === 'ODD';
    }


    setStatus() {
        this.available = this.attendance.status === EventAttendanceStatus.AVAILABLE;
        this.notAvailable = this.attendance.status === EventAttendanceStatus.NOT_AVAILABLE;
        this.toBeConfirm = this.attendance.status === EventAttendanceStatus.TO_BE_CONFIRM;
        this.notAnswered = this.attendance.status === EventAttendanceStatus.NOT_ANSWERED;
    }

    passAvailable() {
        this.changeStatus(EventAttendanceStatus.AVAILABLE);
    }

    passNotAvailable() {
        this.changeStatus(EventAttendanceStatus.NOT_AVAILABLE);

    }

    passNotAnswered() {
        this.changeStatus(EventAttendanceStatus.NOT_ANSWERED);
    }

    passToBeConfirmed() {
        this.changeStatus(EventAttendanceStatus.TO_BE_CONFIRM);
    }

    private changeStatus(status: EventAttendanceStatus) {
        this.attendance.status = status;
        this.eventsService.changeAttendanceStatus(this.attendance, this.event.id).subscribe(attendance => {
            this.messageService.addSuccess('Données sauvegardées', 'Votre présence pour cet évènement à été modifié');
            this.attendance = attendance;
            this.setStatus();
            this.onUpdate.emit(this.attendance);
        });
    }

}
