import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Place} from '../models/place';
import {Router} from '@angular/router';


declare var google: any;

@Component({
    selector: 'app-place-simple-view',
    templateUrl: './place-simple-view.component.html',
    styleUrls: ['./place-simple-view.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PlaceSimpleViewComponent implements OnInit {

    @Input() place: Place;

    options: any;
    hover: boolean;
    overlays: any[];

    constructor(private router: Router) {
    }

    ngOnInit() {
        this.setMap();
    }

    setMap() {
        this.options = {
            center: {
                lat: this.place.lat, lng: this.place.lont
            },
            zoom: 16,
            mapTypeId: 'satellite'
        };
        const marker = new google.maps.Marker(
            {
                position: {lat: this.place.lat, lng: this.place.lont},
                title: this.place.name,
            });
        this.overlays = [marker];
    }

    goToPlaceForEdit() {
        this.router.navigate(['places/' + this.place.id]);
    }

}
