import {Team} from '../../team/models/team';
import {EventForm} from '../../../events/models/event-form';
import {isNullOrUndefined} from 'util';

export class GameForm extends EventForm {

    insideScore: number;
    insideTeam: Team;
    outsideTeam: Team;
    outsideScore: number;
    victoryTeam: Team;
    reference: string;
    sport: any;
    gender: any;
    category: any;

    isValid(): boolean {
        let b = true;
        b = (b && !isNullOrUndefined(this.startDate));
        b = (b && !isNullOrUndefined(this.startTime));
        b = (b && !isNullOrUndefined(this.endDate));
        b = (b && !isNullOrUndefined(this.endTime));
        b = (b && !isNullOrUndefined(this.endTime));
        b = (b && !isNullOrUndefined(this.sport));
        b = (b && !isNullOrUndefined(this.gender));
        b = (b && !isNullOrUndefined(this.category));
        return b;
    }
}
