import {isNullOrUndefined} from 'util';
import {GroupStatus} from '../enums/group-status.enum';
import {GroupType} from '../enums/group-type.enum';
import {ToUrl} from '@mdw/usual-service';


export class GroupsFilter extends ToUrl {

    status: GroupStatus;
    toType: string;
    idTo: number;
    type: GroupType;
    exceptTypes: string[];
    statutes: string[];
    persons: number[];
    ids: number[];

    toUrl(): string {
        let url = '';
        if (!isNullOrUndefined((this.exceptTypes))) {
            url += this.getSeparator() + 'exceptTypes=' + this.exceptTypes.join(',');
        }
        if (!isNullOrUndefined((this.statutes))) {
            url += this.getSeparator() + 'statutes=' + this.statutes.join(',');
        }
        if (!isNullOrUndefined((this.persons))) {
            url += this.getSeparator() + 'persons=' + this.persons.join(',');
        }
        if (!isNullOrUndefined((this.ids))) {
            url += this.getSeparator() + 'ids=' + this.ids.join(',');
        }
        if (!isNullOrUndefined((this.status))) {
            url += this.getSeparator() + 'status=' + this.status;
        }
        if (!isNullOrUndefined((this.toType))) {
            url += this.getSeparator() + 'toType=' + this.toType;
        }
        if (!isNullOrUndefined((this.idTo))) {
            url += this.getSeparator() + 'idTo=' + this.idTo;
        }
        if (!isNullOrUndefined((this.type))) {
            url += this.getSeparator() + 'type=' + this.type;
        }
        return url;
    }

    withPersons(persons: number[]): GroupsFilter {
        this.persons = persons;
        return this;
    }

    withIds(ids: number[]): GroupsFilter {
        this.ids = ids;
        return this;
    }

    withStatutes(statutes: string[]): GroupsFilter {
        this.statutes = statutes;
        return this;
    }

    withExcepTypes(types: string[]): GroupsFilter {
        this.exceptTypes = types;
        return this;
    }

    withType(type: GroupType): GroupsFilter {
        this.type = type;
        return this;
    }
}
