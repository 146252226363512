import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {Talk} from '../models/talk';
import {DataService} from '../services/data.service';
import {CommonComponent} from '../../common-component';
import {TalkMode} from '../enums/talk-mode.enum';
import {TalkCreateRequest} from '../models/talk-create-request';
import {TalkService} from '../services/talk.service';
import {GlobalMeService} from '../../global/services/global-me.service';
import {TalkMember} from '../models/talk-member';
import {TalkMemberRelationship} from '../enums/talk-member-relationship';
import {TalkModeMembers} from '../enums/talk-mode-members.enum';
import {MainLoaderMode} from '../../global/main-loader/main-loader-mode.enum';
import {TalkMessage} from '../models/talk-message';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import {TalkMessageCreateRequest} from '../models/talk-message-create-request';
import {SessionService} from "@genos/user-service";


@Component({
    selector: 'app-talk',
    templateUrl: './talk.component.html',
    styleUrls: ['./talk.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TalkComponent extends CommonComponent implements OnInit, OnDestroy {

    @ViewChild('messageList') messageList: ElementRef;
    @ViewChild('messageListUl') messageListUl: ElementRef;

    @Input() talk: Talk;
    loaderMode: MainLoaderMode;
    messagesLoad: boolean;
    sending: boolean;
    messages: TalkMessage[];
    newMessage = '';


    // WS
    private stompClient;


    constructor(private dataService: DataService,
                private talkService: TalkService,
                private changeDetector: ChangeDetectorRef,
                private session: SessionService,
                private me: GlobalMeService) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        if (this.talk.mode === TalkMode.NORMAL) {
            this.messagesLoad = true;
            this.loaderMode = MainLoaderMode.ON;
            this.talkService.getLoadingMessages(this.talk.id).subscribe(response => {
                this.initializeWebSocketConnection();

                let lastDateMessage: number;
                this.messages = [];
                const personUris: string[] = this.me.persons.map(p => p.uri);
                response.messages.forEach(m => {
                    m.prepareToView(personUris, lastDateMessage);
                    lastDateMessage = m.sent;
                    this.messages.push(m);
                });

                this.messagesLoad = false;
                this.changeDetector.detectChanges();
                const anchorId = response.top;
                const idS: any[] = Array.from(this.messageListUl.nativeElement.children)
                    .filter((e: any) => {
                        return e.id === anchorId;
                    });
                if (idS.length === 1) {
                    this.setScroll(idS[0].offsetTop);
                }
                this.loaderMode = MainLoaderMode.OFF;
            });
        }
    }


    private initializeWebSocketConnection() {
        const url = 'https://app.genos.fr/socket';
        const ws = new SockJS(url);
        // jwttoken
        this.stompClient = Stomp.over(ws);
        const that = this;
        this.stompClient.connect({}, function (frame) {
            that.stompClient.subscribe('/talks/' + that.talk.id, (message) => {
                if (message.body) {
                    const personUris = [that.me.selectedPerson.uri];
                    const previousLast = that.messages.slice(-1)[0];
                    const tm = Object.assign(new TalkMessage(), JSON.parse(message.body));
                    that.messages.push(tm.prepareToView(personUris, previousLast.sent));
                    that.scrollToBottom();
                }
            });
        });
    }


    onScroll($event) {
        // console.log($event.srcElement);
        // console.log($event.srcElement.scrollTop);
        // console.log('scrolling');
    }

    scrollToBottom() {
        this.changeDetector.detectChanges();
        this.setScroll(this.messageList.nativeElement.scrollHeight);
    }

    setScroll(to: number) {
        this.messageList.nativeElement.scrollTop = to;
    }


    addMessage() {
        if (!this.sending) {
            if (this.newMessage.length > 0) {
                this.sending = true;
                if (this.talk.isInCreation()) {
                    const talkToCreate = new TalkCreateRequest();
                    let name = '';
                    talkToCreate.members = this.talk.members.map(m => {
                        const tm = new TalkMember();
                        tm.personUri = m.uri;
                        name += m.name + ' ';
                        tm.relationship = TalkMemberRelationship.Member;
                        return tm;
                    });
                    talkToCreate.name = name;
                    talkToCreate.modeMembers = TalkModeMembers.Pair;
                    talkToCreate.message = this.newMessage;
                    talkToCreate.organizationUri = this.me.organizations[0].uri;
                    talkToCreate.personUri = this.me.selectedPerson.uri;
                    talkToCreate.userUri = this.me.getUserUri();
                    this.talkService.create(talkToCreate).subscribe(r => {
                        console.log(r);
                    }, error => {
                    }, () => {
                        this.newMessage = '';
                        this.sending = false;
                    });
                } else if (this.talk.isNormal()) {
                    this.sendMessage(this.newMessage);
                    this.newMessage = '';
                    this.sending = false;

                }
            }
        }
    }

    sendMessage(content) {
        const message = new TalkMessageCreateRequest();
        message.content = content;
        message.ownerUri = this.me.selectedPerson.uri;
        message.userUri = this.me.getUserUri();
        message.talkId = this.talk.id;
        this.stompClient.send('/app/send/message', {}, JSON.stringify(message));
    }

    ngOnDestroy(): void {
        this.stompClient.disconnect();
        this.stompClient = null;
    }
}
