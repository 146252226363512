import {Buildable, Dto} from '@mdw/usual-service';

export class Dashboard extends Dto implements Buildable<Dashboard> {


    name: string;
    id: number;
    idAccount: number;

    constructor() {
        super();
    }

    build(): Dashboard {
        super.build();
        return this;
    }
}
