import {Projection} from '@mdw/usual-service';

export class ContestProjection extends Projection {

    games: boolean;
    events: boolean;

    toUrl(): string {
        let url = '';
        url = this.toCommonUrl(url);
        if (this.games) {
            url += this.getSeparator() + 'withGames=true';
        }
        if (this.events) {
            url += this.getSeparator() + 'withEvents=true';
        }
        return url;
    }

    withGames(games: boolean): ContestProjection {
        this.games = games;
        return this;
    }

    withEvents(events: boolean): ContestProjection {
        this.events = events;
        return this;
    }
}
