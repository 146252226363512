import {Injectable} from '@angular/core';
import {Contest} from '../models/contest';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {ContestsFilter} from '../models/contests-filter';
import {Team} from '../../team/models/team';
import {ContestProjection} from '../models/contest-projection';
import {DtoSecurisedService, GlobalSessionService, UploadFileService} from '@genos/user-service';
import {AppConfigService} from '@mdw/usual-service';

@Injectable()
export class ContestsService extends DtoSecurisedService<Contest> {

    constructor(protected sessionService: GlobalSessionService,
                protected client: HttpClient,
                private uploadFileService: UploadFileService,
                private appConfigService: AppConfigService) {
        super(client, sessionService);
    }

    getBaseUrl(): string {
        return this.appConfigService.getSecuredBaseUrl() + '/contests';
    }

    public update(t: Contest): Observable<Contest> {
        return this.put<Contest>(this.getBaseUrl() + '/' + t.id, t);
    }

    public create(t: Contest): Observable<Contest> {
        return this.post<Contest>(this.getBaseUrl(), t);
    }

    public getAll(filter: ContestsFilter): Observable<Contest[]> {
        return this.getArray(this.getBaseUrl() + filter.toUrl(), {}, Contest);
    }

    public getById(id: number, projection: ContestProjection): Observable<Contest> {
        return this.getObject(this.getBaseUrl() + '/' + id + projection.toUrl(), {}, Contest);
    }

    uploadLogo(id: number, file: File): Observable<string> {
        const s = new Subject<string>();
        this.uploadFileService.uploadFiles(this.getBaseUrl() + '/' + id + ':image', [file], false).subscribe(response => {
            if (response instanceof HttpResponse) {
                s.next(response.body.logo);
                s.complete();
            }
        });
        return s.asObservable();
    }

    remove(contest: Contest): Observable<Contest> {
        return this.delete<Contest>(this.getBaseUrl() + '/' + contest.id);
    }

    saveTeams(id: number, teams: Team[]): Observable<Contest> {
        return this.post<Contest>(this.getBaseUrl() + '/' + id + '/teams', teams);
    }

    getTeams(idContest: number): Observable<Team[]> {
        return this.getArray(this.getBaseUrl() + '/' + idContest + '/teams', {}, Team);
    }


}
