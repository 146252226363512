<div #divAccounts class="admin dashboard" fxFlex="100" fxLayout="column">
    <h3 i18n="@@h3-admin">Administration</h3>
    <div fxFlex="100" fxLayout="row">
        <div fxFlex="25" class="overview-box overview-box-4">
            <div class="overview-box-header"></div>
            <div class="overview-box-content">
                <div class="overview-box-icon"><i class="material-icons">supervisor_account</i></div>
                <div class="overview-box-title">Users Online</div>
                <div class="overview-box-count">256</div>
            </div>
        </div>
    </div>


</div>