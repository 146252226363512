import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {Leaderboard} from '../models/leaderboard';
import {LeaderboardLine} from '../models/leaderboard-line';
import {LeaderboardType} from '../enums/leaderboard-type.enum';
import {isNullOrUndefined} from 'util';

@Component({
    selector: 'app-leaderboard-display',
    templateUrl: './leaderboard-display.component.html',
    styleUrls: ['./leaderboard-display.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LeaderboardDisplayComponent implements OnInit, OnChanges {


    @Input() leaderboard: Leaderboard;

    lines: LeaderboardLine[];

    constructor() {
    }

    ngOnInit() {
        this.sortLines(this.leaderboard);
    }

    sortLines(leaderboard: Leaderboard) {
        this.lines = leaderboard.lines.sort((a, b) => {
            if (a.order > b.order) {
                return 1;
            }
            if (a.order < b.order) {
                return -1;
            }
            if (a.order === b.order) {
                return 0;
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!isNullOrUndefined(changes.leaderboard)) {
            this.leaderboard = changes.leaderboard.currentValue;
            this.sortLines(this.leaderboard);
        }
    }

    getOrder(line: LeaderboardLine): number {
        return line.order + 1;
    }

    isPoints(): boolean {
        return this.leaderboard.type === LeaderboardType.LEADERBOARD_TYPE_POINTS;
    }

    isAverage(): boolean {
        return this.leaderboard.type === LeaderboardType.LEADERBOARD_TYPE_AVERAGE;
    }


}
