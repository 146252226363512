import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Event} from '../models/event';
import {EventAttendance} from '../models/event-attendance';
import {EventType} from '../enums/event-type.enum';
import {EventAttendancesService} from '../services/event-attendances.service';

@Component({
    selector: 'app-event-attendance-list-view',
    templateUrl: './event-attendance-list-view.component.html',
    styleUrls: ['./event-attendance-list-view.component.scss']
})
export class EventAttendanceListViewComponent implements OnInit {

    @Input() event: Event;
    @Input() mode: string;
    @Output() onUpdate: EventEmitter<void> = new EventEmitter();

    attendances1: EventAttendance[];
    attendances2: EventAttendance[];

    haveSeveral: boolean;

    constructor(private attendancesService: EventAttendancesService) {
    }

    ngOnInit() {
        if (this.event.type === EventType.GAME) {
            this.haveSeveral = this.attendancesService.haveSeveralGroups(this.event.attendances);
            if (this.haveSeveral) {
                const groups = this.attendancesService.splitAttendancesByGroup(this.event.attendances);
                this.attendances1 = groups[0];
                this.attendances2 = groups[1];
            }
        } else {
            this.haveSeveral = false;
        }
    }

    passUpdate() {
        this.onUpdate.emit();
    }


}
