<div fxLayout="column">
    <h4 i18n="@@h4-add-new-attendance">Ajouter une personne</h4>
    <div fxFlex="100" fxLayout="row">
        <div fxFlex="50">
            <p-autoComplete [(ngModel)]="selectedPerson" [suggestions]="filteredPersons"
                            (completeMethod)="filterPersons($event)"
                            field="displayName"
                            [size]="30" [minLength]="1" placeholder="Ajouter une personne" [dropdown]="true">
                <ng-template let-person pTemplate="item">
                    {{person.getCompleteName()}}
                </ng-template>
            </p-autoComplete>
            <p-dropdown  *ngIf="isMultiGroup()" [(ngModel)]="selectedGroup"
                        [options]="groups"
                        [style]="{'width':'300px'}"
                        placeholder="Ajouter au groupe"
                        class="form-input"
                        optionLabel="name"
            ></p-dropdown>
        </div>
        <div fxFlex="50" fxLayoutAlign="end center">
            <button pButton type="button" icon="fa-plus"
                    (click)="addPerson()"
                    i18n-label="@@button-save" label="Ajouter"></button>

        </div>
    </div>

</div>