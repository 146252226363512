import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MenuItem} from 'primeng/primeng';
 import {
    Account, AccountProjection, AccountService, AuthenticationAttempt, AuthenticationAttemptProjection,
    AuthenticationAttemptService
} from '@genos/user-service';
import {BreadcrumbService} from '../../../global/services/breadcrumb.service';
import {MessageService} from '../../../global/services/message.service';
import {ContextMenuAppService} from '../../../global/services/context-menu-app.service';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

    account: Account;
    attempts: AuthenticationAttempt[] = [];
    load: boolean;

    constructor(private breadcrumbService: BreadcrumbService,
                private route: ActivatedRoute,
                private accountService: AccountService,
                private messageService: MessageService,
                private authenticationAttemptsService: AuthenticationAttemptService,
                private contextMenuAppService: ContextMenuAppService) {

        this.breadcrumbService.setAdminUserChild([{label: 'Comptes', routerLink: '/administration/accounts'}]);
    }

    ngOnInit() {

        this.load = true;
        this.route.paramMap.subscribe(params => {
            const ap = new AccountProjection().withRoles(true).withGroups(true).withNaturalPersons(true);
            const id = params.get('id');
            this.accountService.getById(parseInt(id, 0), ap).subscribe(account => {
                this.account = account;
                this.contextMenuAppService.setContextMenu(this.buildContextMenu());
                this.authenticationAttemptsService.getAll(this.account.email, new AuthenticationAttemptProjection()).subscribe(attemps => {
                    this.attempts = attemps;
                });
                this.load = false;
            });
        });
    }

    private buildContextMenu(): MenuItem[] {
        const items = [];
        if (this.account.needActivation()) {
            items.push({
                label: 'Activation',
                icon: 'fa-share',
                command: (event) => {
                    this.accountService.sendActivationEmail(this.account.email).subscribe(response => {
                        this.messageService.addSuccess('Email envoyé',
                            'Un email contenant le lien d\'activation a été envoyé à ' + this.account.email);
                    });
                }
            });
        }
        if (this.account.needPassword() || this.account.isActivated()) {
            items.push({
                label: 'Generation mot de passe',
                icon: 'fa-share',
                command: (event) => {
                    this.accountService.sendFirstPasswordEmail(this.account.email).subscribe(response => {
                        this.messageService.addSuccess('Email envoyé',
                            'Un email contenant le lien de modification de mot de passe a été envoyé à ' + this.account.email);
                    });
                }
            });
        }
        return items;
    }

}
