import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EventAttendance} from '../models/event-attendance';
import {isNullOrUndefined} from 'util';
import {EventAttendancesService} from '../services/event-attendances.service';

@Component({
    selector: 'app-event-attendance-list-by-team',
    templateUrl: './event-attendance-list-by-team.component.html',
    styleUrls: ['./event-attendance-list-by-team.component.scss']
})
export class EventAttendanceListByTeamComponent implements OnInit {

    @Input() event: Event;
    @Input() attendances: EventAttendance[];
    @Input() mode: string;
    @Output() onUpdate = new EventEmitter();
    showXsList: boolean;

    attendanceAvailable: EventAttendance[];
    attendanceToBeConfirm: EventAttendance[];
    attendanceNotAnswered: EventAttendance[];
    attendanceNotAvailable: EventAttendance[];

    constructor(private eventAttendancesService: EventAttendancesService) {
    }

    ngOnInit() {
        if (isNullOrUndefined(this.mode)) {
            this.mode = 'PLAYER';
        }
        this.showXsList = false;
        if (!isNullOrUndefined(this.attendances)) {
            this.attendanceAvailable = this.attendances.filter(a => a.isAvailable())
                .sort(this.eventAttendancesService.sortByFirstName);
            this.attendanceToBeConfirm = this.attendances.filter(a => a.isToBeConfirm())
                .sort(this.eventAttendancesService.sortByFirstName);
            this.attendanceNotAnswered = this.attendances.filter(a => a.isNotAnswered())
                .sort(this.eventAttendancesService.sortByFirstName);
            this.attendanceNotAvailable = this.attendances.filter(a => a.isNotAvailable())
                .sort(this.eventAttendancesService.sortByFirstName);
        }
    }


    isPlayerMode(): boolean {
        return this.mode === 'PLAYER';
    }

    isManagerMode(): boolean {
        return this.mode === 'MANAGER';
    }

    isCoachMode(): boolean {
        return this.mode === 'COACH';
    }

    updateParent(event) {
        this.onUpdate.emit();
    }

}
