import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GamesComponent} from './games/games.component';
import {GameComponent} from './game/game.component';
import {RouterModule} from '@angular/router';
import {GameRoutes} from './game.routes';
import {GamesSimpleListComponent} from './games-simple-list/games-simple-list.component';
import {GamesService} from './services/games.service';
import {FlexLayoutModule} from '@angular/flex-layout';
import {
    ButtonModule,
    CalendarModule,
    DropdownModule,
    InputTextareaModule,
    InputTextModule,
    ProgressSpinnerModule,
    TabViewModule
} from 'primeng/primeng';
import {PlaceModule} from '../place/place.module';
import {EventModule} from '../../events/event.module';
import {GameEditComponent} from './game-edit/game-edit.component';
import {FormsModule} from '@angular/forms';
import {GameShowComponent} from './game-show/game-show.component';
import {TalkModule} from '../../mdw/talk/talk.module';
import {BscModule} from '../../bsc/bsc.module';
import {ModeModule} from '../mode/mode.module';
import {MdwUsualServiceModule} from "@mdw/usual-service";

@NgModule({
    imports: [
        PlaceModule,
        EventModule,
        BscModule,
        TalkModule,
        CommonModule,
        ModeModule,
        MdwUsualServiceModule,
        FlexLayoutModule,
        ProgressSpinnerModule,
        CalendarModule,
        TabViewModule,
        DropdownModule,
        FormsModule,
        ButtonModule,
        InputTextareaModule,
        InputTextModule,
        RouterModule.forChild(GameRoutes)
    ],
    declarations: [GamesComponent, GameComponent, GamesSimpleListComponent, GameEditComponent, GameShowComponent],
    exports: [GamesSimpleListComponent, GameEditComponent],
    providers: [GamesService]
})
export class GameModule {
}
