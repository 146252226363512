import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import {Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';
import {SessionService} from '@genos/user-service';
import {Router} from '@angular/router';
import {FooterService} from '../services/footer.service';
import {CommonComponent} from '../../common-component';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FooterComponent extends CommonComponent implements OnInit, OnChanges, OnDestroy {

    @Input() isLoggedIn: boolean;
    @Output() onChangePage: EventEmitter<void> = new EventEmitter();
    showTabs: boolean;
    showCredits: boolean;

    private sessionSubscription: Subscription;
    private footerSubscription: Subscription;
    version: string;

    constructor(private session: SessionService,
                private footerService: FooterService,
                private router: Router) {
        super();
        this.version = environment.version;

    }


    handleResize(): void {
        super.handleResize();
    }

    ngOnDestroy(): void {
        if (this.sessionSubscription) {
            this.sessionSubscription.unsubscribe();
        }
        if (this.footerSubscription) {
            this.footerSubscription.unsubscribe();
        }
    }

    ngOnInit() {
        super.ngOnInit();
        this.footerSubscription = this.footerService.getSubscriber().subscribe(option => {
            this.showTabs = option.showTabs;
            this.showCredits = option.showCredits;
        });
    }

    goToEventsSwipe() {
        this.onChangePage.emit();
        this.router.navigate(['/events-swiper']);
    }

    goToEventsViewDay() {
        this.onChangePage.emit();
        this.router.navigate(['/events']);
    }

    goToTalks() {
        this.onChangePage.emit();
        this.router.navigate(['/talks']);
    }

    goToResults() {
        this.onChangePage.emit();
        this.router.navigate(['/results']);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isLoggedIn !== undefined) {
            this.isLoggedIn = changes.isLoggedIn.currentValue;
        }
        if (changes.isMobile !== undefined) {
            this.isMobile = changes.isMobile.currentValue;
        }
    }


}
