import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class OnboardingContextService {

    isOnboarding: boolean;
    onboardingType: string;
    source = new Subject<boolean>();


    constructor() {
    }

    public setOnboarding(is: boolean) {
        this.isOnboarding = is;
        this.source.next(is);
    }

    public getSubscriber(): Observable<boolean> {
        return this.source.asObservable();
    }
}
