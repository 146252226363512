import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SportAutocompleteComponent} from './sport-autocomplete/sport-autocomplete.component';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule, MatSelectModule
} from '@angular/material';
import {ReactiveFormsModule} from '@angular/forms';
import {TeamItemComponent} from './team-item/team-item.component';
import {TeamFormComponent} from './team-form/team-form.component';
import {GlobalModule} from '../global/global.module';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
    imports: [
        CommonModule,
        GlobalModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatInputModule,
        MatButtonModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        MatIconModule
    ],
    declarations: [SportAutocompleteComponent, TeamItemComponent, TeamFormComponent],
    exports: [SportAutocompleteComponent, TeamFormComponent, TeamItemComponent]
})
export class SportsModule {
}
