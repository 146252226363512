import {Injectable} from '@angular/core';
import {Event} from '../models/event';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {EventsFilter} from '../models/events-filter';
import {DayEvent} from '../models/day-event';
import {isNullOrUndefined} from 'util';
import {EventProjection} from '../models/event-projection';
import {EventAttendance} from '../models/event-attendance';
import {DaysEvents} from '../models/days-events';
import {EventPaginated} from '../models/event-paginated';
import {EventStatus} from '../enums/event-status.enum';
import {EventVisibility} from '../enums/event-visibility.enum';
import {AppConfigService, PaginatedData} from '@mdw/usual-service';
import {DtoSecurisedService, GlobalSessionService, UploadFileService} from '@genos/user-service';

@Injectable()
export class EventsService extends DtoSecurisedService<Event> {


    constructor(protected sessionService: GlobalSessionService,
                protected client: HttpClient,
                protected uploadFileService: UploadFileService,
                protected appConfigService: AppConfigService) {
        super(client, sessionService);
    }

    getBaseUrl(): string {
        return this.appConfigService.getSecuredBaseUrl() + '/events';
    }

    update(t: Event): Observable<Event> {
        return this.put<Event>(this.getBaseUrl() + '/' + t.id, t);
    }

    create(t: Event): Observable<Event> {
        return this.post<Event>(this.getBaseUrl(), t);
    }


    getAll(filter: EventsFilter, projection: EventProjection): Observable<Event[]> {
        return this.getArray(this.getBaseUrl() + filter.toUrl() + projection.toUrl(false), {}, Event);
    }

    getAllPaginated(filter: EventsFilter, projection: EventProjection): Observable<EventPaginated> {
        return this.getObject(this.getBaseUrl() + filter.toUrl() + projection.toUrl(false), {}, EventPaginated);
    }

    getById(id: number, projection: EventProjection): Observable<Event> {
        return this.getObject(this.getBaseUrl() + '/' + id + projection.toUrl(true), {}, Event);
    }

    getAllLikeDayEvent(filter: EventsFilter, projection: EventProjection): Observable<DaysEvents> {
        const s = new Subject<DaysEvents>();
        this.getAllPaginated(filter, projection).subscribe(paginated => {
            s.next(this.groupByDay(paginated));
            s.complete();
        });
        return s.asObservable();
    }


    groupByDay(paginated: PaginatedData<Event>): DaysEvents {
        const daysEvents = new DaysEvents();
        const map: Map<string, DayEvent> = new Map();
        paginated.data.sort(this.sortByDateAsc);
        paginated.data.forEach(event => {
            const sDay = event.startDate.format('D/M/Y');
            let de = map.get(sDay);
            if (isNullOrUndefined(de)) {
                de = new DayEvent();
                de.day = event.startDate;
                de.events.push(event);
                map.set(sDay, de);
            } else {
                de.events.push(event);
            }
        });
        daysEvents.days = Array.from(map.values());
        daysEvents.count = paginated.data.length;
        daysEvents.total = paginated.total;
        return daysEvents;
    }

    sortByDateAsc(lhs: Event, rhs: Event) {
        return lhs.startDate > rhs.startDate ? 1 : lhs.startDate < rhs.startDate ? -1 : 0;
    }

    changeAttendanceStatus(attendance: EventAttendance, eventId: number): Observable<EventAttendance> {
        return this.put<EventAttendance>(this.getBaseUrl() + '/' + eventId
            + '/attendances/' + attendance.id, attendance);
    }

    createAttendance(attendance: EventAttendance): Observable<EventAttendance> {
        return this.post<EventAttendance>(this.getBaseUrl() + '/' + attendance.event.id + '/attendances', attendance);
    }

    uploadLogo(id: number, file: File): Observable<string> {
        const s = new Subject<string>();
        this.uploadFileService.uploadFiles(this.getBaseUrl() + '/' + id + ':image', [file], false).subscribe(response => {
            if (response instanceof HttpResponse) {
                s.next(response.body.logo);
                s.complete();
            }
        });
        return s.asObservable();
    }

    prepareFormStatutes(): any[] {
        const statutes = [];
        statutes.push({value: EventStatus.CANCELED, name: 'Annulé'});
        statutes.push({value: EventStatus.WITHDRAWAL, name: 'Forfait'});
        statutes.push({value: EventStatus.PLANNED, name: 'Prévu'});
        statutes.push({value: EventStatus.DONE, name: 'Terminé'});
        return statutes;
    }

    prepareFormVisibilities(): any[] {
        const visibilities = [];
        visibilities.push({value: EventVisibility.PUBLIC, label: 'Publique'});
        visibilities.push({value: EventVisibility.PRIVATE, label: 'Privé'});
        return visibilities;
    }

    calling(event: Event): Observable<void> {
        return this.put<void>(this.getBaseUrl() + '/' + event.id + ':calling', {});
    }


}
