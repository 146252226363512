<div class="loader" fxFlex="100" fxLayout="column" *ngIf="loadOption" fxLayoutAlign="center center">
    <p-progressSpinner></p-progressSpinner>
</div>
<div fxLayout="column" class="genos-form" *ngIf="!loadOption">
    <div fxLayout="row">
        <p-calendar [locale]="locale" styleClass="form-input" [(ngModel)]="form.startDate"
                    dateFormat="dd/mm/yy"
                    (onSelect)="onSelectStartDate()"
                    [showIcon]="true"></p-calendar>
        <div class="ui-float-label line" fxLayout="column">
            <input type="text" class="form-input"
                   [(ngModel)]="form.startTime"
                   maxlength="5"
                   mdwTimeStringInput
                   pInputText id="startTime">
            <label for="startTime" i18n="@@placeholder-game-name">Heure de début</label>
        </div>
        <div class="ui-float-label line" fxLayout="column">
            <input type="text" class="form-input"
                   [(ngModel)]="form.endTime"
                   maxlength="5"
                   mdwTimeStringInput
                   pInputText id="endTime">
            <label for="endTime" i18n="@@placeholder-game-name">Heure de fin</label>
        </div>
        <div class="ui-float-label line" fxLayout="column">
            <input type="text" class="form-input"
                   [(ngModel)]="form.reference"
                   pInputText id="reference">
            <label for="reference" i18n="@@placeholder-game-reference">Référence</label>
        </div>
    </div>
    <div fxLayout="row" class="teams">
        <p-dropdown [(ngModel)]="form.insideTeam"
                    [options]="targetTeams"
                    (onChange)="changeTeam()"
                    [style]="{'width':'150px'}"
                    placeholder="Ajouter l’équipe domicile"
                    class="form-input"
                    optionLabel="name"
        ></p-dropdown>
        <div class="between" fxLayoutAlign="center center">versus</div>
        <p-dropdown [(ngModel)]="form.outsideTeam"
                    [options]="targetTeams"
                    (onChange)="changeTeam()"
                    [style]="{'width':'150px'}"
                    placeholder="Ajouter l’équipe extérieur"
                    class="form-input"
                    optionLabel="name"
        ></p-dropdown>
        <div class="between" fxLayoutAlign="center center">Vainqueur :</div>
        <p-dropdown [(ngModel)]="form.victoryTeam"
                    [options]="targetVictoryTeams"
                    [style]="{'width':'150px'}"
                    placeholder="Vainqueur"
                    class="form-input"
                    optionLabel="name"
        ></p-dropdown>
    </div>
    <div fxLayout="row">
        <div class="ui-float-label line" fxLayout="column">
            <input type="number" class="form-input"
                   [(ngModel)]="form.insideScore"
                   pInputText id="insideScore">
            <label for="insideScore" i18n="@@placeholder-game-insideScore">Score domicile</label>
        </div>
        <div class="ui-float-label line" fxLayout="column">
            <input type="number" class="form-input"
                   [(ngModel)]="form.outsideScore"
                   pInputText id="outsideScore">
            <label for="outsideScore" i18n="@@placeholder-game-outsideScore">Score extérieur</label>
        </div>

    </div>
    <div fxLayout="row">
        <p-dropdown [(ngModel)]="form.place" [options]="places"
                    [autoWidth]="false"
                    [showClear]="true"
                    i18n-placeholder="@@placeholder-select-input-game-place"
                    placeholder="Selectionnez un terrain"
                    optionLabel="name"
                    class="form-input"
                    [style]="{'width':'150px'}" appendTo="body"></p-dropdown>
        <p-dropdown [(ngModel)]="form.status" [options]="statutes"
                    [autoWidth]="false"
                    i18n-placeholder="@@placeholder-select-input-game-status"
                    placeholder="Selectionnez un statut"
                    optionLabel="name"
                    class="form-input"
                    [style]="{'width':'150px'}" appendTo="body"></p-dropdown>
    </div>

    <div fxLayoutAlign="end center">
        <button pButton type="button" icon="fa-check"
                (click)="saveGame()"
                i18n-label="@@button-save" label="Sauvegarder"></button>
    </div>
</div>