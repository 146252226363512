import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {GroupsService} from '../services/groups.service';
import {GroupsFilter} from '../models/groups-filter';
import {GroupStatus} from '../enums/group-status.enum';
import {Group} from '../models/group';
import {isNullOrUndefined} from 'util';
import {EventsService} from '../../../events/services/events.service';
import {EventsFilter} from '../../../events/models/events-filter';
import {EventProjection} from '../../../events/models/event-projection';
import {BreadcrumbService} from '../../../global/services/breadcrumb.service';
import {TalksService} from '../../../mdw/talk/services/talks.service';
import {Talk} from '../../../mdw/talk/models/talk';
import {TalksFilter} from '../../../mdw/talk/models/talks-filter';
import {NaturalPerson, PersonWithUserInfoView} from '@genos/person-service';
import {GlobalMeService} from '../../../global/services/global-me.service';

@Component({
    selector: 'app-my-groups',
    templateUrl: './my-groups.component.html',
    styleUrls: ['./my-groups.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MyGroupsComponent implements OnInit {

    loadData: boolean;
    loadDetailData: boolean;
    loadTalks: boolean;
    groups: Group[];
    talks: Talk[];

    currentIndex: number;
    currentGroup: Group;
    groupEvents: Event[];

    constructor(private groupService: GroupsService,
                private eventsService: EventsService,
                private talksService: TalksService,
                private breadcrumbService: BreadcrumbService,
                private me: GlobalMeService) {
    }

    ngOnInit() {
        this.loadData = true;
        this.me.needAllPersons(true, persons => {
            this.loadGroups(persons);
        });
        this.breadcrumbService.setItems([{label: 'Mes groupes'}]);
    }

    loadGroups(persons: PersonWithUserInfoView[]) {
        const filter = new GroupsFilter();
        const personsId = [];// persons.map(p => p.id);
        if (personsId.length > 0) {
            filter.withPersons(personsId);
            filter.withStatutes([GroupStatus.ACTIVE, GroupStatus.MAIN]);
            this.groupService.getAll(filter).subscribe(groups => {
                this.groups = groups;
                this.loadData = false;
            });
        } else {
            this.groups = [];
        }

    }

    haveCurrent(): boolean {
        return !isNullOrUndefined(this.currentGroup);
    }

    selectGroup(group: Group) {
        this.loadDetailData = true;
        this.currentGroup = group;
        this.loadDetail();
    }

    loadDetail() {
        /*this.groupService.getMembers(this.currentGroup).subscribe(members => {
            this.currentGroup.members = members;
            this.loadTalks = true;
            this.talksService.getAll(new TalksFilter().withIdTo(this.currentGroup.id)
                .withToType(this.currentGroup.typeTo)).subscribe(talks => {
                this.talks = talks;
                this.currentIndex = 0;
                this.loadTalks = false;
            });
            this.loadDetailData = false;
            const eFilter = new EventsFilter();
            this.eventsService.getAll(eFilter, new EventProjection()).subscribe(events => {
            });
        });*/
    }
}
