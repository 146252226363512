export class AddressWithLatLng {

    address: string;
    city: string;
    zipCode: string;
    country: string;
    latitude: number;
    longitude: number;
    placeId: string;

    getComplete(): string {
        return this.address;
    }
}
