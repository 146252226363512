import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ContestForm} from '../models/contest-form';
import {Contest} from '../models/contest';
import {isNullOrUndefined} from 'util';
import {ContestsService} from '../services/contests.service';
import {forkJoin, Observable, Subject} from 'rxjs';
import {ContestStatus} from '../enums/contest-status';
import {ContestType} from '../enums/contest-type';
import {ContestProjection} from '../models/contest-projection';
import {ConfirmationService} from 'primeng/primeng';
import {MessageService} from '../../../global/services/message.service';
import {BreadcrumbService} from '../../../global/services/breadcrumb.service';
import {environment} from '../../../../environments/environment';
import {GenosConfigurationService} from '../../configuration/services/genos-configuration.service';

@Component({
    selector: 'app-contest',
    templateUrl: './contest.component.html',
    styleUrls: ['./contest.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [ConfirmationService]
})
export class ContestComponent implements OnInit {

    loadInfo: boolean;
    statutes: any[];
    loadSaveConstest: boolean;
    contestForm: ContestForm;
    contest: Contest;
    sports: any[];
    genders: any[];
    categories: any[];
    contestsTypes: any[];
    levels: any[];
    idContest: number;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private confirmationService: ConfirmationService,
                private contestsService: ContestsService,
                private messageService: MessageService,
                private breadcrumbService: BreadcrumbService,
                private genosConfigurationService: GenosConfigurationService) {
    }

    ngOnInit() {
        this.statutes = [];
        this.contestForm = new ContestForm();
        this.contest = new Contest();
        this.contest.logo = environment.imageDefaultChampionship;
        this.contest.period = environment.currentYear;
        this.loadInfo = true;
        this.loadFormInfos().subscribe((response) => {
            this.handleInit();
        });
    }

    myUploader(event, uploader) {
        this.contestsService.uploadLogo(this.contest.id, event.files[0]).subscribe(url => {
            this.contest.logo = url;
            uploader.clear();
        });
    }


    setType() {
        if (this.contest.logo.indexOf('default')) {
            if (this.contestForm.type.value === ContestType.CHAMPIONSHIP) {
                this.contest.logo = environment.imageDefaultChampionship;
            } else {
                this.contest.logo = environment.imageDefaultTournament;
            }
        }
    }

    handleInit() {
        this.loadInfo = true;
        if (isNullOrUndefined(this.idContest)) {
            this.idContest = parseInt(this.route.snapshot.params.id, 10);
        }
        if (this.idContest !== 0) {
            this.contestsService.getById(this.idContest, new ContestProjection().withEvents(true).withGames(true)).subscribe(contest => {
                this.contest = contest;
                this.contestForm = this.contest.toForm();
                this.contestForm.status = this.statutes.filter(c => c.value === this.contestForm.status.value)[0];
                this.contestForm.category = this.categories.filter(c => c.value === this.contestForm.category.value)[0];
                this.contestForm.gender = this.genders.filter(c => c.value === this.contestForm.gender.value)[0];
                this.contestForm.sport = this.sports.filter(c => c.value === this.contestForm.sport.value)[0];
                this.contestForm.level = this.levels.filter(c => c.value === this.contestForm.level.value)[0];
                this.contestForm.type = this.contestsTypes.filter(c => c.value === this.contestForm.type.value)[0];
                this.breadcrumbService.setItems([{
                    label: 'Compétitions',
                    routerLink: 'contests'
                }, {label: this.contest.name + ' - ' + this.contest.period}]);
                this.loadInfo = false;
            }, error => {
                this.router.navigate(['contests']);
            }, () => {
            });
        } else {
            this.contestForm = this.contest.toForm();
            this.contestForm.type = this.contestsTypes[0];
            this.contestForm.status = this.statutes[0];
            this.breadcrumbService.setItems([{
                label: 'Compétitions',
                routerLink: 'contests'
            }, {label: 'Nouvelle compétition'}]);
            this.loadInfo = false;
        }
    }

    loadFormInfos(): Observable<any> {
        const s = new Subject<any>();
        this.statutes.push({value: ContestStatus.ACTIVE, name: 'En cours'});
        this.statutes.push({value: ContestStatus.COMPLETE, name: 'Terminé'});
        forkJoin(
            this.genosConfigurationService.getSports(),
            this.genosConfigurationService.getGenders(),
            this.genosConfigurationService.getSportCategories(),
            this.genosConfigurationService.getContestsTypes(),
            this.genosConfigurationService.getLevels()
        ).subscribe(options => {
            this.sports = options[0];
            this.genders = options[1];
            this.categories = options[2];
            this.contestsTypes = options[3];
            this.levels = options[4];
            s.next({});
            s.complete();
        });
        return s.asObservable();
    }

    deleteContest() {
        this.confirmationService.confirm({
            message: 'Êtes-vous sur de vouloir supprimer cette compétition ?',
            header: 'Suppression de la compétition',
            icon: 'fa fa-trash',
            accept: () => {
                this.contestsService.remove(this.contest).subscribe(response => {
                    this.messageService.addSuccess('Suppression réussie', 'La compétition à bien été supprimé');
                    this.router.navigate(['/contests']);
                });
            }
        });
    }

    saveContest() {
        this.loadSaveConstest = true;
        this.contest = this.contest.fromForm(this.contestForm);
        this.contestsService.save(this.contest).subscribe(response => {
            this.messageService.addSuccess('Compétition sauvegardée', 'Les informations concernant la compétition ont été sauvegardées');
            if (this.isNew()) {
                this.loadInfo = true;
                this.contest = Object.assign(new Contest(), response).build();
                this.idContest = this.contest.id;
                this.handleInit();
                this.loadSaveConstest = false;
                this.router.navigate(['contests/' + response.id]);
            } else {
                this.handleInit();
                this.loadSaveConstest = false;
            }
        });
    }

    isNew(): boolean {
        return isNullOrUndefined(this.contest.id);
    }

}
