import {isNullOrUndefined} from 'util';
import {ToUrl} from '@mdw/usual-service';

export class ContestsFilter extends ToUrl {

    status: string;
    period: string;
    sport: string;
    type: string;
    gender: string;
    level: string;
    statutes: string[];

    toUrl(): string {
        let url = '';
        if (!isNullOrUndefined((this.status))) {
            url += this.getSeparator() + 'status=' + this.status;
        }
        if (!isNullOrUndefined((this.statutes))) {
            url += this.getSeparator() + 'statutes=' + this.statutes.join(',');
        }
        if (!isNullOrUndefined((this.period))) {
            url += this.getSeparator() + 'period=' + this.period;
        }
        if (!isNullOrUndefined((this.sport))) {
            url += this.getSeparator() + 'sport=' + this.sport;
        }
        if (!isNullOrUndefined((this.type))) {
            url += this.getSeparator() + 'type=' + this.type;
        }
        if (!isNullOrUndefined((this.gender))) {
            url += this.getSeparator() + 'gender=' + this.gender;
        }
        if (!isNullOrUndefined((this.level))) {
            url += this.getSeparator() + 'level=' + this.level;
        }
        return url;
    }


    withStatus(status: string): ContestsFilter {
        this.status = status;
        return this;
    }

    withStatutes(statutes: string[]): ContestsFilter {
        this.statutes = statutes;
        return this;
    }

    withPeriod(period: string): ContestsFilter {
        this.period = period;
        return this;
    }

    withSport(sport: string): ContestsFilter {
        this.sport = sport;
        return this;
    }

    withType(type: string): ContestsFilter {
        this.type = type;
        return this;
    }

    withGender(gender: string): ContestsFilter {
        this.gender = gender;
        return this;
    }

    withLevel(level: string): ContestsFilter {
        this.level = level;
        return this;
    }

}
