import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {GameForm} from '../../game/models/game-form';
import {GameStatus} from '../../game/enums/game-status.enum';
import {PlacesService} from '../../place/services/places.service';
import {Place} from '../../place/models/place';
import {TeamsService} from '../../team/services/teams.service';
import {forkJoin, Observable, Subject} from 'rxjs';
import {PlacesFilter} from '../../place/models/places-filter';
import {isNullOrUndefined} from 'util';
import {ContestsService} from '../services/contests.service';
import {EventsService} from '../../../events/services/events.service';
import {Game} from '../../game/models/game';
import {Team} from '../../team/models/team';
import {Contest} from '../models/contest';
import {MessageService} from '../../../global/services/message.service';
import {CanAttach, PrimengLocaleService} from '@mdw/usual-service';
import {GenosConfigurationService} from '../../configuration/services/genos-configuration.service';
import {PersonGender} from '@genos/person-service';

@Component({
    selector: 'app-contest-game-item-edit',
    templateUrl: './contest-game-item-edit.component.html',
    styleUrls: ['./contest-game-item-edit.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContestGameItemEditComponent implements OnInit {

    @Input() parent: CanAttach;
    @Input() sport: string;
    @Input() gender: PersonGender;
    @Input() game: Game;
    @Output() onSave: EventEmitter<void> = new EventEmitter<void>();

    sports: any[];
    genders: any[];
    categories: any[];
    statutes: any[];
    targetTeams: Team[];
    places: Place[];
    targetVictoryTeams: Team[];
    form: GameForm;

    loadOption: boolean;
    locale: any;

    constructor(private teamsService: TeamsService,
                private placesService: PlacesService,
                private contestsService: ContestsService,
                private eventsService: EventsService,
                private messageService: MessageService,
                private primengLocaleService: PrimengLocaleService,
                private genosConfigurationService: GenosConfigurationService) {
    }

    ngOnInit() {
        this.locale = this.primengLocaleService.getCurrentLocale();
        this.sports = [];
        this.genders = [];
        this.statutes = [];
        this.categories = [];
        this.targetTeams = [];
        this.targetVictoryTeams = [];
        this.loadOption = true;
        this.loadOptions().subscribe(() => {
            this.form = this.game.toForm();
            if (this.isNewGame()) {
                this.form.status = {value: GameStatus.PLANNED};
                const contest = Object.assign(new Contest(), this.parent);
                this.form.gender = this.genders.filter(g => g.value === contest.gender)[0];
                this.form.category = this.categories.filter(g => g.value === contest.category)[0];
                this.form.sport = this.sports.filter(g => g.value === contest.sport)[0];
            } else {
                const contest = Object.assign(new Contest(), this.parent);
                this.form.gender = this.genders.filter(g => g.value === contest.gender)[0];
                this.form.category = this.categories.filter(g => g.value === contest.category)[0];
                this.form.sport = this.sports.filter(g => g.value === contest.sport)[0];
            }
            if (this.parent.typeTo === 'CONTEST') {
                this.loadFromContest().subscribe(() => {
                    this.loadOption = false;
                });
            }
            this.form.status = this.statutes.filter(s => s.value === this.form.status.value)[0];

        });
    }

    changeTeam() {
        this.targetVictoryTeams = [];
        this.targetVictoryTeams.push(this.form.insideTeam);
        this.targetVictoryTeams.push(this.form.outsideTeam);
    }

    isNewGame(): boolean {
        return isNullOrUndefined(this.game.id);
    }

    loadFromContest(): Observable<void> {
        const s = new Subject<void>();

        this.contestsService.getTeams(this.parent.id).subscribe(teams => {
            this.targetTeams = teams;
            if (!isNullOrUndefined(this.form.insideTeam)) {
                this.form.insideTeam = this.targetTeams.filter(t => t.id === this.form.insideTeam.id)[0];
            }
            if (!isNullOrUndefined(this.form.outsideTeam)) {
                this.form.outsideTeam = this.targetTeams.filter(t => t.id === this.form.outsideTeam.id)[0];
            }
            this.changeTeam();
            if (!isNullOrUndefined(this.form.victoryTeam)) {
                this.form.victoryTeam = this.targetVictoryTeams.filter(t => t.id === this.form.victoryTeam.id)[0];
            }
            if (!isNullOrUndefined(this.form.place)) {
                this.form.place = this.places.filter(p => p.id === this.form.place.id)[0];
            }
            s.next();
            s.complete();
        });
        return s.asObservable();
    }

    loadOptions(): Observable<void> {
        const s = new Subject<any>();
        this.statutes.push({value: GameStatus.CANCELED, name: 'Annulé'});
        this.statutes.push({value: GameStatus.WITHDRAWAL, name: 'Forfait'});
        this.statutes.push({value: GameStatus.PLANNED, name: 'Prévu'});
        this.statutes.push({value: GameStatus.DONE, name: 'Terminé'});
        forkJoin(
            this.genosConfigurationService.getSports(),
            this.genosConfigurationService.getGenders(),
            this.genosConfigurationService.getSportCategories(),
            this.placesService.getAll(new PlacesFilter().withStatus('ACTIVE')),
        ).subscribe(options => {
            this.sports = options[0];
            this.genders = options[1];
            this.categories = options[2];
            this.places = options[3];
            s.next({});
            s.complete();
        });
        return s.asObservable();
    }

    onSelectStartDate() {
        this.form.endDate = new Date(this.form.startDate);
    }

    saveGame() {
        const game = this.game.fromForm(this.form);
        this.eventsService.save(game).subscribe(_game => {
            this.messageService.addSuccess('Les données ont été validées', 'Le match à été sauvegardés');
            this.onSave.emit();
        });
    }
}
