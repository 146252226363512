export class TalkMessage {

    content: string;
    id: number;
    owner: any;
    sent: number;
    me: boolean;
    isNewDay: boolean;

    build(): this {
        return this;
    }

    prepareToView(personUris: string[], previousDateMessage: number): this {
        this.me = personUris.some(uri => uri === this.owner.uri);
        const previousDate = new Date(previousDateMessage).getDate();
        const senTDate = new Date(this.sent).getDate();
        if (previousDate !== senTDate) {
            this.isNewDay = true;
        }
        return this;
    }
}
