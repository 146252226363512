<div>
    <mat-list role="list">
        <mat-list-item role="listitem" *ngFor="let notification of notifications">
            <mat-icon matListIcon>{{notification.image}}</mat-icon>
            <h3 matLine> {{notification.title}} </h3>
            <p matLine>
                <span>{{notification.message}}</span>
            </p>
        </mat-list-item>
    </mat-list>
</div>