import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {SportModeService} from '../../../genos/mode/services/sport-mode.service';
import {BscGameForm} from '../models/bsc-game-form';

@Component({
    selector: 'bsc-game-options',
    templateUrl: './game-options.component.html',
    styleUrls: ['./game-options.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [SportModeService]
})
export class GameOptionsComponent implements OnInit {

    @Input() form: BscGameForm;

    constructor() {
    }

    ngOnInit() {
    }


}
