import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DashboardsComponent} from './dashboards/dashboards.component';
import {DashboardService} from './services/dashboard.service';
import {MatProgressSpinnerModule, MatTabsModule} from '@angular/material';
import {ButtonModule, ConfirmDialogModule, GrowlModule, ProgressSpinnerModule, TabViewModule} from 'primeng/primeng';
import {HttpClientModule} from '@angular/common/http';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

@NgModule({
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        TabViewModule,
        HttpClientModule,
        ButtonModule,
        FlexLayoutModule,
        GrowlModule,
        ConfirmDialogModule,
        ProgressSpinnerModule,
        BrowserAnimationsModule,
        FormsModule, MatTabsModule
    ],
    declarations: [DashboardsComponent],
    providers: [DashboardService]
})
export class DashboardsModule {
}
