<div class="activation" fxLayoutAlign="center center" fxLayout="row" fxFlex="33" fxFlex.xs="100">
    <div class="card genos-form" fxFlex="100" fxLayout="column">
        <div class="header" fxLayout="row" fxLayoutAlign="center center">
            <div class="title">Activation de votre compte</div>
        </div>
        <div fxFlex="100" *ngIf="loadActivate" fxLayoutAlign="center center">
            <p-progressSpinner></p-progressSpinner>
        </div>
        <div *ngIf="!loadActivate" fxLayout="column" fxFlex="100" fxLayoutAlign="center center">
            <div fxFlex="100" *ngIf="loadContinue && showContinue" fxLayoutAlign="center center">
                <p-progressSpinner class="spinner"></p-progressSpinner>
            </div>
            <div fxFlex="100" *ngIf="!loadContinue && showContinue" fxLayout="row" fxLayoutAlign="center center">
                <div class="info" i18n="@@message-account-activated-need-continue">Votre email est validé.</div>
            </div>
            <div fxFlex="100" *ngIf="!loadContinue && errorMessageAlreadyUse" fxLayout="row"
                 fxLayoutAlign="center center">
                <div class="info" i18n="@@message-account-already-activated">Votre email est déjà validé.</div>
            </div>
            <div *ngIf="!loadContinue && errorMessage" fxLayout="row" fxLayoutAlign="center center">
                <div class="error" fxFlex="80" i18n="@@message-account-activated-need-continue">
                    Une erreur est survenue.
                    Impossible d'activer votre email pour le moment.
                </div>
            </div>
        </div>
    </div>
</div>

