import {Injectable} from '@angular/core';
import {SportModeHandler} from '../../../genos/mode/interfaces/sport-mode-handler';
import {isNullOrUndefined} from 'util';
import {BscNaturalPersonForm} from '../models/bsc-natural-person-form';
import {BscNaturalPerson} from '../models/bsc-natural-person';

@Injectable()
export class NaturalPersonHandlerService implements SportModeHandler<BscNaturalPerson, BscNaturalPersonForm> {

    constructor() {
    }

    handleToForm(o: BscNaturalPerson): BscNaturalPersonForm {
        return Object.assign(new BscNaturalPerson(), o).toForm();
    }

    handleFromForm(o: BscNaturalPerson, f: BscNaturalPersonForm): BscNaturalPerson {
        const bscForm = Object.assign(new BscNaturalPersonForm(), f);
        return Object.assign(new BscNaturalPerson(), o).fromForm(bscForm);
    }

    /**
     *
     * @param {F} f
     * [0] licences
     * @param {any[][]} datas
     * @returns {F}
     */
    popDataInForm(f: BscNaturalPersonForm, datas: any[][]): BscNaturalPersonForm {
        const licences = datas[0];
        f = Object.assign(new BscNaturalPersonForm(), f);
        if (!isNullOrUndefined(f.currentLicence)) {
            f.currentLicence = licences.filter(l => l.id === f.currentLicence.id)[0];
        }
        return f;
    }

    specificSave(): void {
    }


}
