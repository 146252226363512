import {Component, OnInit} from '@angular/core';
 import {TreeNode} from 'primeng/primeng';
import {Right, RightsService} from '@genos/user-service';
 
@Component({
    selector: 'app-rights-tree',
    templateUrl: './rights-tree.component.html',
    styleUrls: ['./rights-tree.component.scss']
})
export class RightsTreeComponent implements OnInit {

    rights: TreeNode[];
    baseRights: Right[];

    constructor(private rightsService: RightsService) {
    }

    ngOnInit() {
        this.rights = [];
        this.rightsService.getAll().subscribe(rights => {
            this.baseRights = rights;
            const bRight = this.baseRights[0];
            this.rights.push(this.rightsService.toTreeNode(bRight, this.baseRights));
        });
    }


}
