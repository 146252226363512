import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {Country, CountryUtilsService} from '@mdw/usual-service';
import {NaturalPerson, NaturalPersonForm, PersonGender, PersonsService, PersonStatus} from '@genos/person-service';
import {Licence} from '../../licence/models/licence';
import {LicencesService} from '../../licence/services/licences.service';
import {MessageService} from '../../global/services/message.service';
import {BreadcrumbService} from '../../global/services/breadcrumb.service';
import {LicencesFilter} from '../../licence/models/licences-filter';
import {GenosPersonsService} from '../../genos/services/genos-persons.service';
import {SportModeHandler} from '../../genos/mode/interfaces/sport-mode-handler';
import {ModeFactoryService} from '../../genos/mode/services/mode-factory.service';
import {FormType} from '../../genos/mode/enums/form-type.enum';

@Component({
    selector: 'app-persons-list',
    templateUrl: './persons-list.component.html',
    styleUrls: ['./persons-list.component.scss'],
    encapsulation: ViewEncapsulation.None,

})
export class PersonsListComponent implements OnInit {

    naturalPersons: NaturalPerson[];
    naturalPersonsForms: NaturalPersonForm[];
    loadGetAll: boolean;
    licences: Licence[];
    statutes: any[];
    genders: any[];
    countries: Country[];

    handler: SportModeHandler<any, any>;
    cols: any[];


    constructor(private personsService: PersonsService,
                private genosPersonsService: GenosPersonsService,
                private router: Router,
                private countryUtilsService: CountryUtilsService,
                private licencesService: LicencesService,
                private modeFactoryService: ModeFactoryService,
                private messageService: MessageService,
                private breadcrumbService: BreadcrumbService) {
        this.handler = this.modeFactoryService.get().get(FormType.NATURAL_PERSON);
        this.cols = [
            {field: 'firstName', header: 'Prénom'},
            {field: 'lastName', header: 'Nom'},
            {field: 'gender.name', header: 'Sexe'},
            {field: 'nationality.value', header: 'Nationalité'},
            {field: 'birthday', header: 'Date de naissance'},
            {field: 'currentLicence.id', header: 'N° de licence'},
            {field: 'status.value', header: 'Status'},
            {field: 'groups', header: 'Groupes'},
            {header: 'Action'},

        ];
    }


    loadInfos(): Observable<void> {
        const s: Subject<void> = new Subject<void>();
        this.genders.push({value: PersonGender.FEMALE, name: 'Femme'});
        this.genders.push({value: PersonGender.MALE, name: 'Homme'});
        this.statutes.push({value: PersonStatus.ACTIVE, name: 'Active'});
        this.statutes.push({value: PersonStatus.INJURED, name: 'Blessée'});
        this.statutes.push({value: PersonStatus.ASK_DELETE, name: 'En suppression'});
        this.statutes.push({value: PersonStatus.INACTIVE, name: 'Inactive'});
        this.statutes.push({value: PersonStatus.MUTATED, name: 'Mutée'});
        this.countryUtilsService.getCountries().then(countries => {
            this.countries = countries;
        });
        this.licencesService.getAll(new LicencesFilter().withStatus('ACTIVE')).subscribe(licences => {
            this.licences = licences;
            s.next();
            s.complete();
        });
        return s.asObservable();
    }

    ngOnInit() {
        this.statutes = [];
        this.genders = [];
        this.countries = [];
        this.breadcrumbService.setAdminUserChild([{label: 'Personnes'}]);
        this.loadGetAll = true;

        this.loadInfos().subscribe(() => {
            console.log(this.genders);

            /*this.personsService.getAllNatural(new PersonsFilter(),
                new NaturalPersonProjection().withLicence(true)
                    .withInfos(true)).subscribe(persons => {
                this.naturalPersons = persons;
                this.naturalPersonsForms = this.naturalPersons.map(np => this.handler.handleToForm(np));
                this.naturalPersonsForms = this.naturalPersonsForms.map(npf => {
                    npf = this.handler.popDataInForm(npf, [this.licences]);
                    if (!isNullOrUndefined(npf.nationality)) {
                        npf.nationality = this.countries.filter(c => c.code === npf.nationality.code)[0];
                    }
                    if (!isNullOrUndefined(npf.gender)) {
                        npf.gender = this.genders.filter(g => g.value === npf.gender.value)[0];
                    }
                    if (!isNullOrUndefined(npf.status)) {
                        npf.status = this.statutes.filter(s => s.value === npf.status.value)[0];
                    }
                    return npf;
                });
                this.loadGetAll = false;
            });*/
        });

    }

    initEdit(event) {
        event.data.onEdit = true;
    }

    cancelEdit(event) {
        event.data.onEdit = false;
    }

    completeEdit(event) {
        event.data.onEdit = false;
    }

    save(form: NaturalPersonForm) {
        form.onEdit = false;
        const p = this.handler.handleFromForm({}, form);
        /*this.personsService.create(p).subscribe(r => {
            if (!isNullOrUndefined(p.currentLicence)) {
                this.genosPersonsService.attachLicence(p.id, p.currentLicence, environment.currentYear).subscribe(l => {
                    this.messageService.addSuccess('Données personnes sauvegardées',
                        'Les données sur la personnes ont été sauvegardées.');
                });
            } else {
                this.messageService.addSuccess('Données personnes sauvegardées',
                    'Les données sur la personnes ont été sauvegardées.');
            }
        });*/
    }

    goToProfile(person) {
        this.router.navigate(['/administration/natural-persons', person.id]);
    }


}
