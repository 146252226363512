import {isNullOrUndefined} from 'util';
import {ToUrl} from '@mdw/usual-service';

export class TrainingTemplatesFilter extends ToUrl {

    periodicity: number;
    category: string;
    gender: string;
    sport: string;
    status: string;

    toUrl(): string {
        let url = '';
        if (!isNullOrUndefined((this.periodicity))) {
            url += this.getSeparator() + 'periodicity=' + this.periodicity;
        }
        if (!isNullOrUndefined((this.category))) {
            url += this.getSeparator() + 'category=' + this.category;
        }
        if (!isNullOrUndefined((this.gender))) {
            url += this.getSeparator() + 'gender=' + this.gender;
        }
        if (!isNullOrUndefined((this.sport))) {
            url += this.getSeparator() + 'sport=' + this.sport;
        }
        if (!isNullOrUndefined((this.status))) {
            url += this.getSeparator() + 'status=' + this.status;
        }
        return url;
    }


    withPeriodicity(periodicity: number): TrainingTemplatesFilter {
        this.periodicity = periodicity;
        return this;
    }

    withStatus(status: string): TrainingTemplatesFilter {
        this.status = status;
        return this;
    }

    withSport(sport: string): TrainingTemplatesFilter {
        this.sport = sport;
        return this;
    }

    withGender(gender: string): TrainingTemplatesFilter {
        this.gender = gender;
        return this;
    }

    withCategory(category: string): TrainingTemplatesFilter {
        this.category = category;
        return this;
    }


}
