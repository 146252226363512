import {EventType} from '../enums/event-type.enum';
import {EventStatus} from '../enums/event-status.enum';
import {EventVisibility} from '../enums/event-visibility.enum';

export class EventView {

    type: EventType;
    status: EventStatus;
    organizationUri: string;
    uri: string;
    title: string;
    description: string;
    visibility: EventVisibility;
    startDate: Date;
    endDate: Date;
    placeId: number;
    address: string;
}
