import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule} from '@angular/forms';
import {ButtonModule, InputTextModule, ProgressSpinnerModule} from 'primeng/primeng';
import {RouterModule} from '@angular/router';
import {NoAuthGuard} from './guards/no-auth.guard';
import {SimpleGlobalMessageComponent} from './simple-global-message/simple-global-message.component';
import {ActionGlobalMessageComponent} from './action-global-message/action-global-message.component';
import {MatButtonModule, MatCardModule, MatDialogModule} from '@angular/material';
import {ConfirmComponent} from './confirm/confirm.component';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        MatCardModule,
        MatButtonModule,
        MatDialogModule,
        RouterModule,
        ButtonModule,
        InputTextModule,
        FormsModule,
        ProgressSpinnerModule
    ],
    declarations: [SimpleGlobalMessageComponent,
        ActionGlobalMessageComponent,
        ConfirmComponent],
    exports: [SimpleGlobalMessageComponent,
        ActionGlobalMessageComponent],
    providers: [NoAuthGuard]
})
export class GenosModule {
}
