import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {GroupsService} from '../services/groups.service';
import {Group} from '../models/group';
import {GroupsFilter} from '../models/groups-filter';
import {GroupType} from '../enums/group-type.enum';
import {isNullOrUndefined} from 'util';
import {GroupStatus} from '../enums/group-status.enum';
import {CanAttach} from '@mdw/usual-service';

@Component({
    selector: 'app-attach-groups',
    templateUrl: './attach-groups.component.html',
    styleUrls: ['./attach-groups.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AttachGroupsComponent implements OnInit {

    @Input() groupType: GroupType;
    @Input() groups: Group[];
    @Input() canAttach: CanAttach;
    @Output() onSave: EventEmitter<void> = new EventEmitter();


    newGroup: Group;
    availableGroups: Group[];
    normalMode: boolean;
    addMode: boolean;

    beforeDeleteGroups: Group[];
    beforeAddGroups: Group[];
    removeMode: boolean;

    constructor(private groupsService: GroupsService) {
    }

    ngOnInit() {
        this.groups = this.groups;
        this.setNormalMode();
        const filter = new GroupsFilter();
        if (!isNullOrUndefined(this.groupType)) {
            filter.withType(this.groupType);
        }
        filter.withStatutes([GroupStatus.MAIN, GroupStatus.ACTIVE]);
        this.groupsService.getAll(filter).subscribe(groups => {
            this.availableGroups = groups;
        });
    }

    hasNoGroups() {
        return this.groups.length === 0;
    }

    setNormalMode() {
        this.newGroup = new Group();
        this.beforeDeleteGroups = [];
        this.beforeAddGroups = [];
        this.normalMode = true;
        this.addMode = false;
        this.removeMode = false;
    }

    showCancel() {
        return !this.normalMode;
    }

    removeGroup(group) {
        this.groups.splice(this.groups.indexOf(group), 1);
    }

    changeGroup() {
        this.groups.push(this.newGroup);
    }


    cancel() {
        if (this.removeMode) {
            this.groups = this.beforeDeleteGroups.slice(0);
        }
        if (this.addMode) {
            this.groups = this.beforeAddGroups.slice(0);
        }
        this.setNormalMode();
    }

    saveGroups() {
        this.onSave.emit();
        this.setNormalMode();
    }

    passAddMode() {
        if (this.beforeAddGroups.length === 0) {
            this.beforeAddGroups = this.groups.slice(0);
        }
        this.addMode = true;
        this.removeMode = false;
        this.normalMode = false;
    }

    passRemoveMode() {
        if (this.beforeDeleteGroups.length === 0) {
            this.beforeDeleteGroups = this.groups.slice(0);
        }
        this.addMode = false;
        this.removeMode = true;
        this.normalMode = false;
    }
}
