import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Event} from '../models/event';
import {EventsService} from '../services/events.service';
import {EventAttendance} from '../models/event-attendance';
import {isNullOrUndefined} from 'util';
import {MessageService} from '../../global/services/message.service';
import {Group} from '../../genos/group/models/group';
import {NaturalPerson, NaturalPersonProjection, PersonsFilter, PersonsService} from '@genos/person-service';

@Component({
    selector: 'app-event-add-attendance',
    templateUrl: './event-add-attendance.component.html',
    styleUrls: ['./event-add-attendance.component.scss']
})
export class EventAddAttendanceComponent implements OnInit {

    @Input() event: Event;
    @Input() type: string;
    @Input() groups: Group[];
    @Output() onUpdate: EventEmitter<void> = new EventEmitter();
    filteredPersons: NaturalPerson[];
    selectedPerson: NaturalPerson;
    selectedGroup: Group;

    defaultGroup: number;

    constructor(private personsService: PersonsService,
                private messageService: MessageService,
                private eventsService: EventsService) {
    }

    ngOnInit() {
        this.selectedGroup = this.groups[0];
        console.log(this.selectedGroup);
    }

    isMultiGroup(): boolean {
        if (isNullOrUndefined(this.groups)) {
            this.groups = [];
        }
        return this.groups.length > 1;
    }

    filterPersons(event) {
        // event.query
        const f = new PersonsFilter();
        f.withAutocomplete(event.query);
        f.withExceptPersons(this.event.attendances.map(a => a.person.id));
        // this.personsService.getAllNatural(f, new NaturalPersonProjection()).subscribe(persons => {
        //     this.filteredPersons = persons;
        // });
    }

    addPerson() {
        if (!isNullOrUndefined(this.selectedPerson)) {
            const attendance = new EventAttendance();
            attendance.event = this.event;
            attendance.person = this.selectedPerson;
            attendance.fromType = 'Group';
            attendance.fromId = this.selectedGroup.id;
            console.log(attendance);
            this.eventsService.createAttendance(attendance).subscribe(_attendance => {
                delete this.selectedPerson;
                this.messageService.addSuccess('Participant ajouté à l\'évènement',
                    attendance.person.getCompleteName() + ' à été ajouté aux participants');
                delete this.selectedPerson;
            });
            this.onUpdate.emit();
        }
    }


}
