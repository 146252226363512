import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Club} from '../models/club';
import {ClubForm} from '../models/club-form';
import {ClubsService} from '../services/clubs.service';
import {ClubStatus} from '../enums/club-status.enum';
import {isNullOrUndefined} from 'util';
import {TeamsService} from '../../team/services/teams.service';
import {Team} from '../../team/models/team';
import {MenuItem} from 'primeng/primeng';
import {ActivatedRoute, Router} from '@angular/router';
import {ClubMode} from '../enums/club-mode.enum';
import {TeamItemMode} from '../../team/enums/team-item-mode.enum';
import {BreadcrumbService} from '../../../global/services/breadcrumb.service';
import {MessageService} from '../../../global/services/message.service';
import {Contact} from '../../contacts/models/contact';
import {ContactsService} from '../../contacts/services/contacts.service';
import {ContextMenuAppService} from '../../../global/services/context-menu-app.service';
import {ContactsFilter} from '../../contacts/models/contacts-filter';
import {ContactToType} from '../../contacts/enums/contact-to-type';

@Component({
    selector: 'app-club',
    templateUrl: './club.component.html',
    styleUrls: ['./club.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ClubComponent implements OnInit {

    club: Club;
    form: ClubForm;
    contacts: Contact[];
    addContactFlag: boolean;
    loadInfo: boolean;
    loadContacts: boolean;
    modeTeamItem: TeamItemMode;

    // Teams
    loadTeams: boolean;
    teams: Team[];

    imageUrl: string;

    // External Club
    mode: string;
    idClub: number;

    constructor(private breadcrumbService: BreadcrumbService,
                private clubsService: ClubsService,
                private router: Router,
                private route: ActivatedRoute,
                private contactsService: ContactsService,
                private teamsService: TeamsService,
                private contextMenuAppService: ContextMenuAppService,
                private messageService: MessageService) {
    }

    ngOnInit() {
        this.modeTeamItem = TeamItemMode.CLUB;
        this.form = new ClubForm();
        this.club = new Club();
        this.mode = this.route.snapshot.data.mode ? this.route.snapshot.data.mode : ClubMode.DEFAULT;
        this.idClub = this.route.snapshot.data.mode ? parseInt(this.route.snapshot.params.id, 10) : 1;
        this.reload();
    }


    addContact() {
        this.addContactFlag = true;
    }

    cancelContact() {
        this.addContactFlag = false;
    }

    reload() {
        this.loadInfo = true;
        if (this.mode === ClubMode.DEFAULT) {
            this.breadcrumbService.setItems([{label: 'Le Club'}]);
            this.clubsService.getMain().subscribe(club => {
                if (isNullOrUndefined(club.id)) {
                    this.club = new Club();
                    this.club.status = ClubStatus.MAIN;
                    this.form = new ClubForm();
                } else {
                    this.club = club;
                    this.form = this.club.toForm();
                    this.teams = club.teams;
                    /*this.contactsService.getAll(new ContactsFilter().withIdTo(this.club.id).withToType(ContactToType.CLUB))
                        .subscribe(contacts => {
                            this.contacts = contacts;
                        });*/
                }
                this.loadInfo = false;
                this.contextMenuAppService.setContextMenu(this.buildContextMenu());
            });
        } else {
            if (this.isNew()) {
                this.club = new Club();
                this.club.status = ClubStatus.ACTIVE;
                this.form = new ClubForm();
                this.breadcrumbService.setItems([
                    {
                        label: 'Clubs extérieurs',
                        routerLink: 'clubs'
                    },
                    {label: 'Nouveau club'}
                ]);
                this.loadInfo = false;
            } else {
                this.clubsService.getById(this.idClub).subscribe(club => {
                    this.club = club;
                    this.form = this.club.toForm();
                    this.teams = this.club.teams;
                    this.breadcrumbService.setItems([
                        {
                            label: 'Clubs extérieurs',
                            routerLink: 'clubs'
                        },
                        {label: this.club.name}
                    ]);
                    /*this.contactsService.getAll(new ContactsFilter().withIdTo(this.club.id).withToType(ContactToType.CLUB))
                        .subscribe(contacts => {
                            this.loadInfo = false;
                            this.contacts = contacts;
                        });*/
                });
            }
        }


    }

    isNew() {
        return this.idClub === 0;
    }

    private buildContextMenu(): MenuItem[] {
        const items = [];
        items.push({
            label: 'Ajouter une équipe',
            icon: 'fa-plus',
            command: (event) => {
                this.addTeam();
            }
        });
        return items;
    }

    addTeam() {
        if (this.mode === ClubMode.DEFAULT) {
            this.router.navigate(['/club/teams/0']);
        } else {
            this.router.navigate(['/clubs/' + this.club.id + '/teams/0']);
        }
    }

    save() {
        this.clubsService.save(this.club.fromForm(this.form)).subscribe(club => {
            this.idClub = club.id;
            this.reload();
            this.messageService.addSuccess('Sauvegarde réussie', 'Les données ont été sauvegardées');
        });
    }

    myUploader(event, uploader) {
        this.clubsService.uploadLogo(this.club.id, event.files[0]).subscribe(url => {
            this.club.logo = url;
            uploader.clear();
        });
    }

}
