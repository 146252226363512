import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {isNullOrUndefined} from 'util';
import {Event} from '../../../events/models/event';
import * as moment from 'moment';
import {environment} from '../../../../environments/environment';


@Component({
    selector: 'app-training-show',
    templateUrl: './training-show.component.html',
    styleUrls: ['./training-show.component.scss']
})
export class TrainingShowComponent implements OnInit {

    @Input() training: Event;
    @Output() onUpdate: EventEmitter<void> = new EventEmitter<void>();
    @Output() editMode: EventEmitter<void> = new EventEmitter();
    loadInfo: boolean;

    constructor() {
    }

    ngOnInit() {

    }


    havePlace(): boolean {
        return !isNullOrUndefined(this.training.place);
    }

    passToEditMode() {
        this.editMode.emit();
    }

    onUpdateAttendance(training) {
        this.onUpdate.emit(training);
    }

    currentPersonIsInEvent(): boolean {
        if (isNullOrUndefined(this.training.currentAttendances)) {
            return false;
        }
        return this.training.currentAttendances.length > 0;
    }

    isInFuture() {
        const now = moment();
        return now.isBefore(this.training.startDate) || now.diff(this.training.startDate, 'd') <= environment.nbDayAttendance;
    }

}
