import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PlacesComponent} from './places/places.component';
import {PlaceComponent} from './place/place.component';
import {RouterModule} from '@angular/router';
import {PlaceRoutes} from './place.routes';
import {
    ButtonModule, DataTableModule, DropdownModule, GMapModule, InputTextareaModule, InputTextModule, PanelModule,
    ProgressSpinnerModule
} from 'primeng/primeng';
import {FormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {PlacesService} from './services/places.service';
import {PlaceSimpleViewComponent} from './place-simple-view/place-simple-view.component';

@NgModule({
    imports: [
        GMapModule,
        ProgressSpinnerModule,
        PanelModule,
        InputTextModule,
        DataTableModule,
        InputTextareaModule,
        FlexLayoutModule,
        FormsModule,
        CommonModule,
        DropdownModule,
        ButtonModule,
        RouterModule.forChild(PlaceRoutes)
    ],
    declarations: [PlacesComponent, PlaceComponent, PlaceSimpleViewComponent],
    providers: [PlacesService],
    exports: [PlaceSimpleViewComponent]
})
export class PlaceModule {
}
