<div class="attendances-list" fxFlex="100" fxLayout="row">
    <div fxFlex="25" class="number available" fxLayout="column"
         fxLayoutAlign="center center">
        <i class="material-icons">check_circle</i>
        <div>{{nbAvailable}}</div>
    </div>
    <div fxFlex="25" class="number to-be-confirm" fxLayout="column"
         fxLayoutAlign="center center">
        <i class="material-icons">help</i>
        <div>{{nbToBeConfirm}}</div>
    </div>
    <div fxFlex="25" class="number not-answered" fxLayout="column"
         fxLayoutAlign="center center">
        <i class="material-icons">history</i>
        <div>{{nbNotAnswer}}</div>
    </div>
    <div fxFlex="25" class="number not-available" fxLayout="column"
         fxLayoutAlign="center center">
        <i class="material-icons">highlight_off</i>
        <div>{{nbNotAvailable}}</div>
    </div>
</div>