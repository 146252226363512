<div fxFlex="100" fxLayout="column">
    <h4 *ngIf="showTitle" i18n="@@h4-members">Les membres</h4>
    <div fxFlex="100" class="card" *ngFor="let member of members" fxLayout="row">
        <div fxFlex="35">
            <img class="roundedImage medium" src="{{member.image}}">
        </div>
        <div fxFlex="65" fxLayout="row" fxLayoutAlign="start center">
            <div class="name">{{member.getCompleteName()}}</div>
        </div>
    </div>
</div>