import {Injectable} from '@angular/core';
import {GlobalSessionService, OptionsService} from '@genos/user-service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ClubOptionGroups} from '../../enums/club-option-groups.enum';
import {AppConfigService} from '@mdw/usual-service';

@Injectable()
export class GenosConfigurationService extends OptionsService {

    getBaseUrl(): string {
        return this.appConfigService.getSecuredBaseUrl() + '/options';
    }

    constructor(protected sessionService: GlobalSessionService,
                protected client: HttpClient,
                protected appConfigService: AppConfigService) {
        super(sessionService, client, appConfigService);
    }

    getSports(): Observable<any[]> {
        return this.getSimpleOption(ClubOptionGroups.SPORTS);
    }

    getSportCategories(): Observable<any[]> {
        return this.getSimpleOption(ClubOptionGroups.SPORT_CATEGORIES);
    }

    getContestsTypes(): Observable<any[]> {
        return this.getSimpleOption(ClubOptionGroups.CONTEST_TYPE);
    }

    getLevels(): Observable<any[]> {
        return this.getSimpleOption(ClubOptionGroups.SPORT_LEVEL);
    }

    getGenders(): Observable<any[]> {
        return this.getSimpleOption(ClubOptionGroups.GENDERS);
    }

}
