import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SportPersonsFilter} from '../models/sport-persons-filter';
import {GlobalSessionService, UploadFileService} from '@genos/user-service';
import {NaturalPerson, PersonsService} from '@genos/person-service';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from '@mdw/usual-service';

@Injectable()
export class SportPersonsService extends PersonsService {

    constructor(protected sessionService: GlobalSessionService,
                protected client: HttpClient,
                protected uploadFileService: UploadFileService,
                protected appConfigService: AppConfigService) {
        super(sessionService, client, uploadFileService, appConfigService);
    }

    getSportPersons(filter: SportPersonsFilter): Observable<NaturalPerson[]> {
        return null; // this.getArray(this.getBaseUrl() + ':sport' + filter.toUrl(), {}, NaturalPerson);
    }
}
