import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LeaderboardsComponent} from './leaderboards/leaderboards.component';
import {LeaderboardsService} from './services/leaderboards.service';
import {LeaderboardDisplayComponent} from './leaderboard-display/leaderboard-display.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule} from '@angular/forms';
import {DropdownModule, InputTextModule} from 'primeng/primeng';
import {LeaderboardEditComponent} from './leaderboard-edit/leaderboard-edit.component';

@NgModule({
    imports: [
        FlexLayoutModule,
        FormsModule,
        InputTextModule,
        DropdownModule,
        CommonModule
    ],
    declarations: [LeaderboardsComponent, LeaderboardDisplayComponent, LeaderboardEditComponent],
    exports: [LeaderboardsComponent],
    providers: [LeaderboardsService]
})
export class LeaderboardModule {
}
