<div fxFlex="100" fxLayout="column">
    <div class="message-list" #messageList fxFlex="100" (scroll)="onScroll($event)">
        <app-main-loader [mode]="loaderMode"></app-main-loader>
        <ul class="list-unstyled px-2" #messageListUl *ngIf="!messagesLoad">
            <li fxLayout="column" *ngFor="let message of messages" class="message"
                id="anchor-talk-{{talk.id}}-message-{{message.id}}"
                [ngClass]="{'out': message.me, 'in': !message.me,
                'local-last': message.isLocalLast, 'new-day': message.isNewDay}">
                <div fxFlexAlign="center" class="day-sep" *ngIf="message.isNewDay">{{ message.sent | timesince }}
                </div>
                <div fxLayoutAlign="{{ !message.me ? 'start' : 'end'}} top">
                    <span class="arrow" fxFlexOrder="{{ !message.me ? '0' : '1' }}"></span>
                    <div class="content">
                        <div class="sender-name" *ngIf="talk.members.length > 1 && message.userID != 'me'">{{
                            dataService.getTalkInfo(message.userID).name }}
                        </div>
                        {{ message.content }}
                        <span class="timestamp">{{ message.sent | date:'shortTime' }}</span>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div class="talk-footer" fxFlex="60px" fxLayoutAlign="stretch center">
        <input class="form-control" placeholder="Votre message"
               [(ngModel)]="newMessage"
               fxFlex (keyup.enter)="addMessage()"/>
        <i *ngIf="!sending" class="material-icons" (click)="addMessage()">send</i>
        <mat-spinner [diameter]="20" *ngIf="sending"></mat-spinner>
    </div>
</div>
