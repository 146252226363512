import {Component, OnInit} from '@angular/core';
import {BreadcrumbService} from '../../global/services/breadcrumb.service';

@Component({
    selector: 'app-admin-dashboard',
    templateUrl: './admin-dashboard.component.html',
    styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {

    constructor(private breadcrumbService: BreadcrumbService) {
    }

    ngOnInit() {
        this.breadcrumbService.setAdministrationChild();
    }

}
