import * as moment from 'moment';
import {Moment} from 'moment';
import {isNullOrUndefined} from 'util';
import {Buildable, Dto} from '@mdw/usual-service';

export class ExcludePeriod extends Dto implements Buildable<ExcludePeriod> {

    startDate: Moment;
    endDate: Moment;
    comment: string;

    // optional
    addMode: boolean;

    build(): ExcludePeriod {
        super.build();
        if (!isNullOrUndefined(this.startDate)) {
            this.startDate = moment(this.startDate);
        }
        if (!isNullOrUndefined(this.endDate)) {
            this.endDate = moment(this.endDate);
        }
        return this;
    }
}
