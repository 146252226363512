<div class="templates" fxFlex="100" fxLayout="row">
    <div fxLayout="column" fxFlex="100">
        <h3 i18n="@@h3-templates">Gestion des templates d'entrainements</h3>
        <div fxFlex="100" fxLayout="row">
            <div fxFlex="100" fxLayout="column">
                <div fxFlex="100" class="loader" fxLayout="row" *ngIf="loadTrainingTemplate"
                     fxLayoutAlign="center center">
                    <p-progressSpinner class="spinner"></p-progressSpinner>
                </div>
                <div class="ui-widget-header" fxFlex="100" fxLayout="row">
                    <i class="fa fa-search"></i>
                    <input #gb type="text" pInputText size="50" i18n-placeholder="@@placeholder-global-filter"
                           placeholder="Filtre">
                </div>
                <p-dataTable fxFlex="100" *ngIf="!loadTrainingTemplate " [globalFilter]="gb" [value]="templates"
                             [paginator]="true" [rows]="10"
                             selectionMode="single"
                             [(selection)]="selectedTrainingTemplate" (onRowSelect)="onRowSelect($event)"
                             [sortField]="sortF" [sortOrder]="sortO">
                    <p-column field="logo" i18n-header="@@header-data-table-template-sport" header="Logo">
                        <ng-template let-template="rowData" pTemplate="body">
                            <span class="profile-image-wrapper">
                                <img class="roundedImage small" src="{{template.image}}">
                            </span>
                        </ng-template>
                    </p-column>
                    <p-column field="name" i18n-header="@@header-data-table-template-name" header="Nom"
                              [sortable]="true"></p-column>
                    <p-column field="periodicity" i18n-header="@@header-data-table-template-period" header="Périodicité"
                              [sortable]="true"></p-column>
                    <p-column field="day" i18n-header="@@header-data-table-template-sport" header="Jour"
                              [sortable]="true">
                        <ng-template let-template="rowData" pTemplate="body">
                            <span>{{template.day}}</span>
                        </ng-template>
                    </p-column>
                    <p-column field="startTime" i18n-header="@@header-data-table-template-period" header="Début"
                              [sortable]="true"></p-column>
                    <p-column field="endTime" i18n-header="@@header-data-table-template-period" header="Fin"
                              [sortable]="true"></p-column>
                    <p-column field="sport" i18n-header="@@header-data-table-template-sport" header="Sport"
                              [sortable]="true">
                        <ng-template let-template="rowData" pTemplate="body">
                            <span>{{template.sport}}</span>
                        </ng-template>
                    </p-column>
                    <p-column field="category" i18n-header="@@header-data-table-template-level" header="Categorie"
                              [sortable]="true">
                        <ng-template let-template="rowData" pTemplate="body">
                            <span>{{template.category}}</span>
                        </ng-template>
                    </p-column>
                    <p-column field="gender" i18n-header="@@header-data-table-template-gender" header="Genre"
                              [sortable]="true">
                        <ng-template let-template="rowData" pTemplate="body">
                            <span>{{template.gender}}</span>
                        </ng-template>
                    </p-column>
                    <p-column field="visibility" i18n-header="@@header-data-table-account-status" header="Visibilité"
                              [sortable]="true"></p-column>
                    <p-column field="status" i18n-header="@@header-data-table-account-status" header="Statut"
                              [sortable]="true"></p-column>
                </p-dataTable>
            </div>
        </div>
    </div>
</div>