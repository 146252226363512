<div class="game-show" fxLayout="row" fxLayout.xs="column">
    <div class="loader" fxLayout="row" *ngIf="loadInfo" fxLayoutAlign="center center">
        <p-progressSpinner></p-progressSpinner>
    </div>
    <div class="content card" ngClass.xs="content-xs" fxFlex="49" fxFlex.xs="50" fxLayout="column">
        <div class="title" fxLayout="row">
            <div class="insideTeam" fxFlex="20" fxLayoutAlign="center center">
                <div class="logo" *ngIf="game.insideTeam">
                    <img src="{{game.insideTeam.logo}}">
                </div>
            </div>
            <div class="info" fxFlex="60" fxLayout="column">
                <div class="teams">
                    {{game.getTeamsName()}}
                </div>
                <div class="date">
                    {{game.getDay()}}
                </div>
            </div>
            <div class="outsideTeam" fxFlex="15" fxLayoutAlign="center center">
                <div class="logo" *ngIf="game.outsideTeam">
                    <img src="{{game.outsideTeam.logo}}">
                </div>
            </div>
            <div fxFlex="5" fxLayoutAlign="end start" fxHide.xs>
                <div appNeedRight="UPDATE_EVENT" fxLayoutAlign="center center" class="button-i"
                     (click)="passToEditMode()">
                    <i class="material-icons">edit_mode</i>
                </div>
            </div>
        </div>
        <div fxLayout="column" class="place">
            {{game.shortDescription}}
        </div>
        <div fxLayout="column" class="place">
            <h4 i18n="@@h4-game-place">Lieu du match</h4>
            <div *ngIf="!havePlace()">
                Pas de lieu défini pour le moment.
            </div>
            <app-place-simple-view *ngIf="havePlace()" [place]="game.place"></app-place-simple-view>
        </div>
        <div>
            <!--<app-talk *ngIf="false"></app-talk>-->
        </div>
    </div>
    <div class="attendances " fxFlex="49" fxFlex.xs="50" fxLayout="column">
        <div class="card" appNeedRight="CALL_EVENT">
            <h4>Actions</h4>
            <app-event-calling [event]="game"></app-event-calling>
        </div>
        <div *ngIf="currentPersonIsInEvent() && isInFuture()" class="card">
            <h4 i18n="@@h4-attendances">Ma présence</h4>
            <app-event-attendance *ngFor="let attendance of game.currentAttendances"
                                  [event]="game"
                                  mode="GAME"
                                  (onUpdate)="onUpdateAttendance()"
                                  [attendance]="attendance"></app-event-attendance>
        </div>
        <div fxLayout="column" class="card" *ngIf="game.currentAttendances.length > 0">
            <h4 i18n="@@h4-game-coach">Mot du coach</h4>
            {{game.description}}
        </div>
        <p-tabView *ngIf="talks.length > 0" class="card">
            <p-tabPanel class="chat" *ngFor="let talk of talks" header="{{talk.title}}">
                <!--<app-talk [talk]="talk"></app-talk>-->
            </p-tabPanel>
        </p-tabView>
        <div class="card">
            <app-event-attendance-list-view [event]="game"
                                            *ngIf="game.attendances"
                                            (onUpdate)="onUpdateAttendance()"
                                            [mode]="modeAttendanceListView"></app-event-attendance-list-view>
        </div>
    </div>
</div>
