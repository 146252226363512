import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CalendarComponent} from './calendar/calendar.component';
import {ScheduleModule} from 'primeng/primeng';
import {CalendarRoutes} from './calendar.routes';
import {RouterModule} from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        ScheduleModule,
        RouterModule.forChild(CalendarRoutes),

    ],
    declarations: [CalendarComponent]
})
export class CalendarModule {
}
