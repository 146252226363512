import {Component, OnInit} from '@angular/core';
import {DashboardService} from '../services/dashboard.service';
import {Dashboard} from '../models/dashboard';
import {ConfirmationService, Message} from 'primeng/primeng';
import {BreadcrumbService} from '../../global/services/breadcrumb.service';
import {MessageService} from '../../global/services/message.service';
import {SessionService} from '@genos/user-service';

@Component({
    selector: 'app-dashboards',
    templateUrl: './dashboards.component.html',
    styleUrls: ['./dashboards.component.scss'],
    providers: [ConfirmationService]
})
export class DashboardsComponent implements OnInit {

    dashboards: Dashboard[] = [];
    load = false;
    showTabs = false;
    name: string;
    msgs: Message[];

    constructor(private breadcrumbService: BreadcrumbService,
                private dashboardService: DashboardService,
                private messageService: MessageService,
                private session: SessionService,
                private confirmationService: ConfirmationService) {
        this.breadcrumbService.setItems([{label: 'Tableaux de bords'}]);
    }

    ngOnInit() {
        this.load = false;
        this.dashboards = [];
        this._load();
    }

    private _load(): void {
        this.load = true;
        this.dashboardService.getToCurrentUser().subscribe((response: Dashboard[]) => {
            this.dashboards = response;
            this.name = '';
            this.load = false;
            this.showTabs = this.dashboards.length > 0;
        }, error => {
        });
    }

    needToCreateFirst(): boolean {
        return this.dashboards.length === 0;
    }


    add(): void {
        const dashboard = new Dashboard();
        dashboard.idAccount = this.session.getUser().id;
        dashboard.name = this.name;
        this.dashboardService.create(dashboard).subscribe(response => {
            this._load();
        }, error => {
            this.messageService.addError('Error des services', error.message);
        });
    }

    remove(dashboard: Dashboard): void {
        this.confirmationService.confirm({
            message: 'Êtes-vous sur de vouloir supprimer ce tableau de bord ?',
            header: 'Suppression d\'un tableau de bord',
            icon: 'fa fa-trash',
            accept: () => {
                this.dashboardService.remove(dashboard).subscribe(response => {
                    this._load();
                    this.msgs = [{severity: 'info', summary: 'Confirmed', detail: 'Record deleted'}];
                });
            }
        });
    }

}
