import {Injectable} from '@angular/core';
import {GlobalSessionService, UploadFileService} from '@genos/user-service';
import {PersonsService} from '@genos/person-service';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from '@mdw/usual-service';

@Injectable()
export class GenosPersonsService extends PersonsService {

    getBaseUrl(): string {
        return this.appConfigService.getSecuredBaseUrl() + '/persons';
    }

    constructor(protected sessionService: GlobalSessionService,
                protected client: HttpClient,
                protected uploadFileService: UploadFileService,
                protected appConfigService: AppConfigService) {
        super(sessionService, client, uploadFileService, appConfigService);
    }

    /* public attachLicence(idPerson: number, licence: Licence, period: string): Observable<any> {
         return this.post(this.getBaseUrl() + '/' + idPerson + ':attachLicence', {
             licenceId: licence.id,
             period: period
         });
     }*/
}