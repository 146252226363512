export class FooterOption {

    showCredits: boolean;
    showTabs: boolean;


    public static mobileMainScreen(): FooterOption {
        const fo = new FooterOption();
        fo.showTabs = true;
        fo.showCredits = false;
        return fo;
    }

    public static webDefault(): FooterOption {
        const fo = new FooterOption();
        fo.showTabs = false;
        fo.showCredits = false;
        return fo;
    }

    public static mobileEmpty(): FooterOption {
        const fo = new FooterOption();
        fo.showTabs = false;
        fo.showCredits = false;
        return fo;
    }
}
