import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContactsService} from './services/contacts.service';
import {ContactComponent} from './contact/contact.component';
import {DropdownModule, InplaceModule, InputTextModule, TooltipModule} from 'primeng/primeng';
import {ContactAddComponent} from './contact-add/contact-add.component';
import {FormsModule} from '@angular/forms';
import {I18NphonePipe} from './pipes/i18-nphone.pipe';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
    imports: [
        CommonModule,
        DropdownModule,
        TooltipModule,
        InplaceModule,
        InputTextModule,
        FormsModule,
        FlexLayoutModule,
    ],
    declarations: [ContactComponent, ContactAddComponent, I18NphonePipe],
    exports: [ContactAddComponent, ContactComponent],
    providers: [ContactsService]
})
export class ContactsModule {
}
