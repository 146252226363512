import {PlaceForm} from './place-form';
import {Buildable, Dto, FormCompliant} from '@mdw/usual-service';
import {PlaceStatus} from '../enums/place-status';

export class Place extends Dto implements Buildable<Place>, FormCompliant<Place, PlaceForm> {


    name: string;
    lat: number;
    lont: number;
    status: PlaceStatus;
    description: string;
    address: string;
    zipCode: string;
    city: string;
    howTo: string;


    constructor() {
        super();
        this.status = PlaceStatus.ACTIVE;
    }

    build(): Place {
        super.build();
        return this;
    }

    getCompleteAddress(): string {
        return this.address + ' ' + this.zipCode + ' ' + this.city;
    }

    toForm(): PlaceForm {
        const f = new PlaceForm();
        f.name = this.name;
        f.lat = this.lat;
        f.lont = this.lont;
        f.status = this.status;
        f.description = this.description;
        f.address = this.address;
        f.zipCode = this.zipCode;
        f.city = this.city;
        f.howTo = this.howTo;
        return f;
    }

    fromForm(form: PlaceForm): Place {
        this.name = form.name;
        this.lat = form.lat;
        this.lont = form.lont;
        this.description = form.description;
        this.address = form.address;
        this.zipCode = form.zipCode;
        this.city = form.city;
        this.howTo = form.howTo;
        this.status = form.status;
        return this;
    }
}
