<div class="content-card profile common min-height-card"
     fxLayout="row"
     fxLayout.xs="column">
    <div class="loader" fxLayout="row" *ngIf="loadInfo" fxLayoutAlign="center center">
        <p-progressSpinner></p-progressSpinner>
    </div>
    <div *ngIf="!loadInfo" class="form-group card card-w-title"
         fxFlex="33" fxLayout="column"
         flexXs="100">
        <h4 i18n="@@h4-profile">Template d'entrainement</h4>
        <div fxFlex="100" fxLayout="row">
            <div fxFlex="50">
                <span class="profile-image-wrapper">
                    <img class="roundedImage" src="{{template.image}}">
                </span>
            </div>
            <div fxFlex="50" fxLayoutAlign="bottom left">
                <p-fileUpload #uploader name="logo" mode="basic" customUpload="true"
                              (uploadHandler)="myUploader($event,uploader)"></p-fileUpload>
            </div>
        </div>
        <p-panel header="Informations générales" i18n-header="@@h3-general-info">
            <div fxFlex="100" fxLayout="column" class="genos-form">
                <div class="ui-float-label line" fxLayout="column">
                    <input type="text" class="form-input" [(ngModel)]="templateForm.name"
                           pInputText id="name">
                    <label for="name" i18n="@@placeholder-natural-person-name">Nom</label>
                </div>
                <p-dropdown [options]="statutes"
                            class="form-input"
                            i18n-placeholder="@@placeholder-select-input-template-sport"
                            placeholder="Selectionnez un statut"
                            [style]="{'width':'250px'}"
                            [(ngModel)]="templateForm.status"
                            optionLabel="label"></p-dropdown>
                <p-dropdown [options]="visibilities"
                            class="form-input"
                            i18n-placeholder="@@placeholder-select-input-template-sport"
                            placeholder="Définir une visibilité"
                            [style]="{'width':'250px'}"
                            [(ngModel)]="templateForm.visibility"
                            optionLabel="label"></p-dropdown>
                <p-dropdown [options]="periodicities"
                            class="form-input"
                            i18n-placeholder="@@placeholder-select-input-template-sport"
                            placeholder="Selectionnez une périodicité"
                            [style]="{'width':'250px'}"
                            [(ngModel)]="templateForm.periodicity"
                            optionLabel="label"></p-dropdown>
                <p-dropdown [options]="days"
                            class="form-input"
                            i18n-placeholder="@@placeholder-select-input-template-sport"
                            placeholder="Selectionnez un jour"
                            [style]="{'width':'250px'}"
                            [(ngModel)]="templateForm.day"
                            optionLabel="label"></p-dropdown>
                <div fxLayout="row">
                    <div class="ui-float-label line" fxLayout="column">
                        <input type="text" class="form-input"
                               [(ngModel)]="templateForm.startTime"
                               maxlength="5"
                               mdwTimeStringInput
                               pInputText id="startTime">
                        <label for="startTime" i18n="@@placeholder-natural-person-name">Heure de début</label>
                    </div>
                    <div class="ui-float-label line" fxLayout="column">
                        <input type="text" class="form-input"
                               [(ngModel)]="templateForm.endTime"
                               mdwTimeStringInput
                               maxlength="5"
                               pInputText id="endTime">
                        <label for="endTime" i18n="@@placeholder-natural-person-name">Heure de fin</label>
                    </div>
                </div>
                <p-dropdown [options]="places"
                            class="form-input"
                            i18n-placeholder="@@placeholder-select-input-template-sport"
                            placeholder="Selectionnez un lieu"
                            [style]="{'width':'250px'}"
                            [(ngModel)]="templateForm.place"
                            optionLabel="name"></p-dropdown>
                <p-dropdown [options]="sports"
                            class="form-input"
                            i18n-placeholder="@@placeholder-select-input-template-sport"
                            placeholder="Selectionnez un sport"
                            [style]="{'width':'250px'}"
                            [(ngModel)]="templateForm.sport"
                            optionLabel="label"></p-dropdown>
                <p-dropdown [options]="genders"
                            class="form-input"
                            i18n-placeholder="@@placeholder-select-input-template-gender"
                            placeholder="Selectionnez un genre"
                            [style]="{'width':'250px'}"
                            [(ngModel)]="templateForm.gender"
                            optionLabel="label"></p-dropdown>
                <p-dropdown [options]="categories"
                            class="form-input"
                            i18n-placeholder="@@placeholder-select-input-template-category"
                            placeholder="Selectionnez une categorie"
                            [style]="{'width':'250px'}"
                            [(ngModel)]="templateForm.category"
                            optionLabel="label"></p-dropdown>
                <p-checkbox name="openNewPersons" binary="true" label="Ouvert au non licencié débutant pour test"
                            [(ngModel)]="templateForm.openNewPersons"
                            inputId="openNewPersons"></p-checkbox>
                <div class="ui-float-label" fxLayout="column">
                    <textarea [rows]="5" [cols]="30" pInputTextarea [(ngModel)]="templateForm.description"></textarea>
                    <label for="name" i18n="@@placeholder-natural-teamplte-description">Description</label>
                </div>

            </div>
            <p-footer>
                <button pButton type="button" icon="fa-check"
                        (click)="saveTemplate()"
                        i18n-label="@@button-save" label="Sauvegarder"></button>
            </p-footer>
        </p-panel>

    </div>
    <div *ngIf="!loadInfo && !isNewMode()" fxFlex="66" fxLayout="column" class="form-group card card-w-title roster"
         flexXs="100">
        <p-tabView>
            <p-tabPanel header="Groupes">
                <app-attach-groups [canAttach]="template" [(groups)]="template.groups"
                                   (onSave)="saveTemplate()"
                                   [groupType]="groupType"></app-attach-groups>
            </p-tabPanel>
            <!--<p-tabPanel header="Périodes d'exclusions">-->
            <!--<app-exclude-periods [(template)]="template"></app-exclude-periods>-->
            <!--</p-tabPanel>-->
        </p-tabView>
    </div>

</div>

