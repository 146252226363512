import {Event} from './event';
import {PaginatedData} from '@mdw/usual-service';

export class EventPaginated implements PaginatedData<Event> {
    data: Event[];
    total: number;

    build(): PaginatedData<Event> {
        this.data = this.data.map(e => Object.assign(new Event(), e).build());
        return this;
    }

}
