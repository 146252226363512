import {Component, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/primeng';
import {GroupsFilter} from '../models/groups-filter';
import {Router} from '@angular/router';
import {GroupsService} from '../services/groups.service';
import {Group} from '../models/group';
import {GroupType} from '../enums/group-type.enum';
import {BreadcrumbService} from '../../../global/services/breadcrumb.service';
import {ContextMenuAppService} from '../../../global/services/context-menu-app.service';
 
@Component({
    selector: 'app-groups',
    templateUrl: './groups.component.html',
    styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {

    groups: Group[];
    selectedGroup: Group;
    loadGroup: boolean;

    constructor(private breadcrumbService: BreadcrumbService,
                private contextMenuAppService: ContextMenuAppService,
                private router: Router,
                private groupsService: GroupsService) {

    }

    ngOnInit() {
        this.groups = [];
        this.breadcrumbService.setItems([{label: 'Groupes'}]);
        this.loadGroup = true;
        this.groupsService.getAll(new GroupsFilter().withExcepTypes([GroupType.ROSTER])).subscribe(groups => {
            this.groups = groups;
            this.loadGroup = false;
        });
        this.contextMenuAppService.setContextMenu(this.buildContextMenu());

    }

    onRowSelect($event) {
        this.router.navigate(['/groups', this.selectedGroup.id]);
    }

    private buildContextMenu(): MenuItem[] {
        const items = [];
        items.push({
            label: 'Ajouter un groupe',
            icon: 'fa-plus',
            command: (event) => {
                this.addGroup();
            }
        });
        return items;
    }

    addGroup() {
        this.router.navigate(['/groups/0']);

    }
}
