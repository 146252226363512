import {Pipe, PipeTransform} from '@angular/core';

/*
 * Returns relative time from a timestamp.
 * Replace full dates with "Today", "Yesterday" where applicable
 * This is very flawed, but very sufficient for our purposes.
*/
@Pipe({name: 'timesince'})
export class TimeSince implements PipeTransform {
    today = new Date(Date.now()).getDate();

    transform(value: number, args: string): string {
        const d = new Date(value);
        if (this.today - d.getDate() === 0) {
            return 'Ajourd\'hui';
        }
        if (this.today - d.getDate() === 1) {
            return 'Hier';
        }
        let month: string = (d.getMonth() + 1) + '';
        if (month.length === 1) {
            month = '0' + month;
        }
        return d.getDate() + '/' + month + '/' + d.getFullYear();
    }
}
