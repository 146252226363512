import {TalkMode} from '../enums/talk-mode.enum';

export class Talk {
    id: number;
    name: string;
    image: string;
    mode: TalkMode;
    members: any[];


    constructor() {
        this.members = [];
        this.mode = TalkMode.NORMAL;
    }

    hasImage(): boolean {
        return this.image !== null && this.image !== undefined;
    }

    getInitiales(): string {
        return this.name.charAt(0);
    }

    toNormal(): this {
        this.mode = TalkMode.NORMAL;
        return this;
    }

    isNew(): boolean {
        return this.mode === TalkMode.NEW;
    }

    isInCreation(): boolean {
        return this.mode === TalkMode.ON_CREATION;
    }

    isNormal(): boolean {
        return this.mode === TalkMode.NORMAL;
    }
}
