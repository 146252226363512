import {Injectable} from '@angular/core';
import {GlobalSessionService} from '@genos/user-service';
import {Router} from '@angular/router';
import {MessageService} from './message.service';
import {RightGuard} from '../../genos/guards/right.guard';

@Injectable()
export class GenosRightGuard extends RightGuard {


    constructor(session: GlobalSessionService,
                router: Router, private messageService: MessageService) {
        super(session, router);
    }

    handleError(requiredRight: any): any {
        this.messageService.addError('Vous n`avez pas les droits', 'Il vous manque le droit ' + requiredRight,
            true);
        return requiredRight;
    }

}
