<div class="content-card club common min-height-card"
     fxLayout="row"
     fxLayout.xs="column">
    <div class="loader" fxLayout="row" *ngIf="loadInfo" fxLayoutAlign="center center">
        <p-progressSpinner></p-progressSpinner>
    </div>
    <div *ngIf="!loadInfo" class="form-group card card-w-title club-info"
         fxFlex="33" fxLayout="column"
         flexXs="100">
        <h4 i18n="@@h4-generale-information">Le Club</h4>
        <div fxFlex="100" fxLayout="row" *ngIf="!isNew()">
            <div fxFlex="50">
                <span class="profile-image-wrapper">
                    <img src="{{club.logo}}">
                </span>
            </div>
            <div fxFlex="50" fxLayoutAlign="bottom left">
                <p-fileUpload #uploader name="logo" mode="basic" customUpload="true"
                              (uploadHandler)="myUploader($event,uploader)"></p-fileUpload>
            </div>
        </div>
        <p-panel header="Informations générales" i18n-header="@@h3-club-general-infos" [toggleable]="true">
            <div fxFlex="100" fxLayout="column" class="genos-form">
                <div class="ui-float-label line" fxLayout="column" fxFlex="100">
                    <input type="text" class="form-input" [(ngModel)]="form.name"
                           pInputText id="name">
                    <label for="name" i18n="@@placeholder-club-name">Nom</label>
                </div>
                <div class="ui-float-label line" fxLayout="column" fxFlex="100">
                    <input type="text" class="form-input" [(ngModel)]="form.city"
                           pInputText id="city">
                    <label for="city" i18n="@@placeholder-club-city">Ville</label>
                </div>
                <div class="ui-float-label line" fxLayout="column" fxFlex="100">
                    <input type="text" class="form-input" [(ngModel)]="form.zipcode"
                           pInputText id="zipcode">
                    <label for="zipcode" i18n="@@placeholder-club-zipcode">Code Postal</label>
                </div>
                <div class="ui-float-label line" fxLayout="column" fxFlex="100">
                    <input type="text" class="form-input" [(ngModel)]="form.description"
                           pInputText id="description">
                    <label for="description" i18n="@@placeholder-club-description">Description</label>
                </div>
            </div>
            <p-footer>
                <button pButton type="button" icon="fa-check"
                        (click)="save()"
                        i18n-label="@@button-save" label="Sauvegarder"></button>
            </p-footer>
        </p-panel>
        <p-panel header="Informations de contact" i18n-header="@@h3-contact-info"
                 *ngIf="club.id"
                 [toggleable]="true">
            <div fxLayout="column" fxFlex="100">
                <div fxFlexAlign="center right" class="addButton" fxFlex="100">
                    <span class="material-icons" (click)="addContact()"
                          *ngIf="!addContactFlag">add_circle_outline</span>
                    <span class="material-icons" (click)="cancelContact()"
                          *ngIf="addContactFlag">remove_circle_outline</span>
                </div>
                <div fxFlex="100" *ngIf="addContactFlag">
                    <!--<app-contact-add (reload)="reload()" [userUri]="club"></app-contact-add>-->
                </div>
                <div>
                    <app-contact (reload)="reload()" *ngFor="let contact of contacts" [contact]="contact"></app-contact>
                </div>
            </div>


        </p-panel>
    </div>
    <div class="loader" fxLayout="row" *ngIf="loadTeams" fxLayoutAlign="center center">
        <p-progressSpinner></p-progressSpinner>
    </div>
    <div *ngIf="!loadTeams && !loadInfo && !isNew()" fxFlex="66" fxLayout="column" class="form-group card card-w-title"
         flexXs="100">
        <p-tabView>
            <p-tabPanel header="Les équipes">
                <div fxFlex="100" fxLayout="column">
                    <div fxFlex="100">
                        <h3 i18n="@@h3-clubs" fxFlex="50">Les équipes</h3>
                        <div fxFlex="50" fxLayoutAlign="end center">
                            <div appNeedRight="CREATE_TEAM" fxLayoutAlign="center center" class="button-i"
                                 (click)="addTeam()">
                                <i class="material-icons">add</i>
                            </div>
                        </div>
                    </div>
                    <div fxFlex="100" fxLayoutWrap fxLayout="row">
                        <!--<app-team-item class="card" fxFlex="23" [team]="team" [club]="club"-->
                                       <!--[mode]="modeTeamItem"-->
                                       <!--*ngFor="let team of teams"></app-team-item>-->
                    </div>

                </div>
            </p-tabPanel>
            <p-tabPanel header="Les terrains">
                <div fxFlex="100" fxLayoutWrap fxLayout="row">
                </div>
            </p-tabPanel>
        </p-tabView>

    </div>
</div>