import {PlaceStatus} from "../enums/place-status";

export class PlaceForm {

    name: string;
    lat: number;
    lont: number;
    status: PlaceStatus;
    description: string;
    address: string;
    zipCode: string;
    city: string;
    howTo: string;
}
