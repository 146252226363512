import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PanelModule, ProgressSpinnerModule, TabViewModule} from 'primeng/primeng';
import {TalksService} from './services/talks.service';
import {FormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {TalkWsService} from './services/talk-ws.service';
import {TalkComponent} from './talk/talk.component';

@NgModule({
    imports: [
        CommonModule,
        TabViewModule,
        FlexLayoutModule,
        FormsModule,
        ProgressSpinnerModule,
        PanelModule,
    ],
    declarations: [TalkComponent],
    exports: [],
    providers: [TalksService, TalkWsService]
})
export class TalkModule {
}
