<div class="leaderboards" fxFlex="100" fxLayout="column">
    <div class="header" fxLayout="row">
        <div fxFlex="80">
            <div class="title">
                <h4 i18n="@@h4-roster" *ngIf="!hasSeveral()">{{leaderboard.name}}</h4>
                <p-dropdown *ngIf="hasSeveral() && normalMode" [(ngModel)]="leaderboard"
                            (onChange)="change($event)"
                            [options]="leaderboards"
                            [style]="{'width':'300px'}"
                            class="form-input"
                            optionLabel="name"
                ></p-dropdown>
            </div>
            <div class="form" fxLayout="row" *ngIf="editMode || addMode">
                <div class="ui-float-label line" fxLayout="column" fxFlex="50">
                    <input type="text" class="form-input" [(ngModel)]="leaderboard.name"
                           pInputText id="name">
                    <label for="name" i18n="@@placeholder-leaderboard-name">Nom</label>
                </div>
                <p-dropdown [ngModel]="leaderboard.type"
                            (onChange)="changeType($event)"
                            [options]="types"
                            [style]="{'width':'100px'}"
                            class="form-input"
                            optionLabel="name"
                ></p-dropdown>
            </div>
        </div>
        <div class="actions" fxFlex="20" fxLayoutAlign="end center">
            <div fxLayoutAlign="center center" class="button-i" *ngIf="normalMode && haveLeaderboard()"
                 (click)="passToEditMode()">
                <i class="material-icons">mode_edit</i>
            </div>
            <div fxLayoutAlign="center center" class="button-i cancel" *ngIf="showCancel()"
                 (click)="cancel()">
                <i class="material-icons">cancel</i>
            </div>
            <div fxLayoutAlign="center center" class="button-i delete" *ngIf="showCancel()"
                 (click)="deleteLeaderboard()">
                <i class="material-icons">delete</i>
            </div>

            <div fxLayoutAlign="center center" class="button-i" *ngIf="showCancel()"
                 (click)="saveLeaderboard()">
                <i class="material-icons">save</i>
            </div>
            <div fxLayoutAlign="center center" class="button-i" *ngIf="normalMode"
                 (click)="passAddMode()">
                <i class="material-icons">add</i>
            </div>
        </div>
    </div>
    <div fxLayoutAlign="center center">
        <div *ngIf="!haveLeaderboard()">
            Aucun classement
        </div>
        <app-leaderboard-display fxFlex="100" *ngIf="haveLeaderboard() && !editMode"
                                 [leaderboard]="leaderboard"></app-leaderboard-display>
        <app-leaderboard-edit fxFlex="100" *ngIf="haveLeaderboard() && editMode"
                              [availableTeams]="contest.teams"
                              [leaderboard]="leaderboard"></app-leaderboard-edit>
    </div>
</div>