import {Component, Input, OnInit} from '@angular/core';
import {Contest} from '../models/contest';
import {TeamsService} from '../../team/services/teams.service';
import {TeamsFilter} from '../../team/models/teams-filter';
import {TeamStatus} from '../../team/enums/team-status.enum';
import {TeamItemMode} from '../../team/enums/team-item-mode.enum';
import {Team} from '../../team/models/team';
import {ContestsService} from '../services/contests.service';
import {MessageService} from '../../../global/services/message.service';

@Component({
    selector: 'app-contest-teams',
    templateUrl: './contest-teams.component.html',
    styleUrls: ['./contest-teams.component.scss']
})
export class ContestTeamsComponent implements OnInit {

    @Input() contest: Contest;

    teams: Team[];
    newTeam: Team;
    availableTeams: Team[];
    normalMode: boolean;
    addMode: boolean;

    beforeDeleteTeams: Team[];
    beforeAddTeams: Team[];
    removeMode: boolean;
    modeTeamItem: TeamItemMode;

    loadSaveTeam: boolean;

    constructor(private teamsService: TeamsService,
                private messageService: MessageService,
                private contestsService: ContestsService) {
    }

    ngOnInit() {
        this.modeTeamItem = TeamItemMode.CONTEST;
        this.teams = this.contest.teams;
        this.setNormalMode();
        const filter = new TeamsFilter().withCategory(this.contest.category)
            .withStatus(TeamStatus.ACTIVE)
            .withGender(this.contest.gender).withSport(this.contest.sport);
        filter.club = 0;
        this.teamsService.getAll(filter).subscribe(teams => {
            this.availableTeams = teams;
        });
    }

    hasNoTeams() {
        return this.teams.length === 0;
    }

    setNormalMode() {
        this.newTeam = new Team();
        this.beforeDeleteTeams = [];
        this.beforeAddTeams = [];
        this.normalMode = true;
        this.addMode = false;
        this.removeMode = false;
    }

    showCancel() {
        return !this.normalMode;
    }

    removeTeam(team) {
        this.teams.splice(this.teams.indexOf(team), 1);
    }

    changeTeam() {
        this.teams.push(this.newTeam);
    }


    cancel() {
        if (this.removeMode) {
            this.teams = this.beforeDeleteTeams.slice(0);
        }
        if (this.addMode) {
            this.teams = this.beforeAddTeams.slice(0);
        }
        this.setNormalMode();
    }

    saveTeams() {
        this.loadSaveTeam = true;
        this.contestsService.saveTeams(this.contest.id, this.teams).subscribe(contest => {
            this.loadSaveTeam = false;
            this.messageService.addSuccess('Données sauvegardées', 'Les équipes ont été sauvegardées');
            this.setNormalMode();
        });

    }

    passAddMode() {
        if (this.beforeAddTeams.length === 0) {
            this.beforeAddTeams = this.teams.slice(0);
        }
        this.addMode = true;
        this.removeMode = false;
        this.normalMode = false;
    }

    passRemoveMode() {
        if (this.beforeDeleteTeams.length === 0) {
            this.beforeDeleteTeams = this.teams.slice(0);
        }
        this.addMode = false;
        this.removeMode = true;
        this.normalMode = false;
    }
}
