import {Injectable} from '@angular/core';
import {Action} from '../models/action';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {DtoSecurisedService, GlobalSessionService} from '@genos/user-service';
import {AppConfigService} from '@mdw/usual-service';

@Injectable()
export class ActionService extends DtoSecurisedService<Action> {

    getBaseUrl(): string {
        return this.appConfigService.getSecuredBaseUrl() + '/monitoring/actions';
    }

    constructor(protected session: GlobalSessionService,
                protected client: HttpClient,
                private appConfigService: AppConfigService) {
        super(client, session);
    }

    update(t: Action): Observable<Action> {
        return undefined;
    }

    create(t: Action): Observable<Action> {
        return undefined;
    }


    getActionsToAttachObject(type: string, id: number): Observable<Action[]> {
        return this.getArray<Action>(this.getBaseUrl() + '?attachType=' + type + '&attachObject=' + id, {}, Action);
    }

}
