import {Component, Input, OnInit} from '@angular/core';
import {Event} from '../models/event';
import {EventsService} from '../services/events.service';
import {MessageService} from '../../global/services/message.service';

@Component({
    selector: 'app-event-calling',
    templateUrl: './event-calling.component.html',
    styleUrls: ['./event-calling.component.scss']
})
export class EventCallingComponent implements OnInit {

    @Input() event: Event;

    call: boolean;

    constructor(private eventsService: EventsService,
                private messageService: MessageService) {
    }

    ngOnInit() {
    }

    calling() {
        this.call = true;
        this.eventsService.calling(this.event).subscribe(r => {
            this.call = false;
            this.messageService.addSuccess('Convocation envoyées', 'Les convocations ont été envoyées pour ' + this.event.title);
        }, error => {
            this.call = false;
        });
    }

}
