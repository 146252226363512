import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {OnboardingRoutes} from './onboarding.routes';
import {
    AutoCompleteModule,
    ButtonModule,
    InputTextModule,
    ProgressBarModule,
    ProgressSpinnerModule
} from 'primeng/primeng';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
    MatButtonModule,
    MatChipsModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule
} from '@angular/material';
import {PlacesModule} from '../places/places.module';
import {SportsModule} from '../sports/sports.module';
import {OrganizationModule} from '../organization/organization.module';
import {CloudOnboardingEmailConfigurationComponent} from './cloud-onboarding-email-configuration/cloud-onboarding-email-configuration.component';

@NgModule({
    imports: [
        PlacesModule,
        SportsModule,
        OrganizationModule,
        CommonModule,
        ProgressBarModule,
        ProgressSpinnerModule,
        FlexLayoutModule,
        MatInputModule,
        MatFormFieldModule,
        MatChipsModule,
        MatDividerModule,
        MatButtonModule,
        MatIconModule,
        ReactiveFormsModule,
        FormsModule,
        AutoCompleteModule,
        ButtonModule,
        InputTextModule,
        RouterModule.forChild(OnboardingRoutes)
    ],
    declarations: [
        CloudOnboardingEmailConfigurationComponent],
    exports: []
})
export class OnboardingModule {
}
