import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PlaceForm} from '../models/place-form';
import {Place} from '../models/place';
import {PlacesService} from '../services/places.service';
import {BreadcrumbService} from '../../../global/services/breadcrumb.service';
import {MessageService} from '../../../global/services/message.service';
import {environment} from '../../../../environments/environment';

declare var google: any;

@Component({
    selector: 'app-place',
    templateUrl: './place.component.html',
    styleUrls: ['./place.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PlaceComponent implements OnInit {

    options: any;
    overlays = [];

    idPlace: number;
    form: PlaceForm;
    place: Place;
    loadInfo: boolean;
    loadSave: boolean;

    lat: number;
    lng: number;

    constructor(private breadcrumbService: BreadcrumbService,
                private route: ActivatedRoute,
                private router: Router,
                private messageService: MessageService,
                private placesService: PlacesService) {

    }

    ngOnInit() {
        this.loadInfo = true;
        this.loadSave = false;
        this.lat = environment.latPershing;
        this.lng = environment.lngPershing;
        this.idPlace = parseInt(this.route.snapshot.params.id, 10);
        if (this.isNew()) {
            this.initNew();
        } else {
            this.initForUpdate();
        }
    }

    isNew() {
        return this.idPlace === 0;
    }

    initForUpdate() {
        this.placesService.getById(this.idPlace).subscribe(place => {
            this.place = place;
            this.form = this.place.toForm();
            this.setMap();
            this.setBreadcrumb();
            this.loadInfo = false;
        });
    }

    setMap() {
        this.options = {
            center: {lat: this.form.lat, lng: this.form.lont},
            zoom: 18,
            mapTypeId: 'satellite'
        };
        const marker = new google.maps.Marker(
            {
                position: {lat: this.form.lat, lng: this.form.lont},
                title: this.form.name,
                animation: google.maps.Animation.DROP,
                draggable: true,
            });
        const self = this;
        marker.addListener('dragend', function (event) {
            self.form.lat = event.latLng.lat();
            self.form.lont = event.latLng.lng();
        });
        this.overlays = [marker];
    }

    savePlace() {
        this.loadSave = true;
        this.placesService.save(this.place.fromForm(this.form)).subscribe(place => {
            this.place = place;
            this.messageService.addSuccess('Les données ont été sauvegardées', 'Le lieu à été mis à jour');
            if (this.isNew()) {
                this.router.navigate(['places/' + this.place.id]);
            }
            this.loadSave = false;
        }, error => {
            this.loadSave = true;
        });
    }

    initNew() {
        this.place = new Place();
        this.place.lat = this.lat;
        this.place.lont = this.lng;
        this.form = this.place.toForm();
        this.setMap();
        this.setBreadcrumb();
        this.loadInfo = false;
    }

    setBreadcrumb() {
        if (this.isNew()) {
            this.breadcrumbService.setItems([
                {
                    label: 'Lieux',
                    routerLink: 'places'
                },
                {label: 'Nouveau lieu'}
            ]);
        } else {
            this.breadcrumbService.setItems([
                {
                    label: 'Lieux',
                    routerLink: 'places'
                },
                {label: this.place.name}
            ]);
        }
    }

}
