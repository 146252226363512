<div class="loader" fxLayout="row" *ngIf="loadTraining" fxLayoutAlign="center center">
    <p-progressSpinner></p-progressSpinner>
</div>
<app-training-edit *ngIf="isEditMode() && !loadTraining"
                   (onUpdate)="reloadTraining($event)"
                   (showMode)="passToShowMode($event)"
                   fxLayout="row"
                   [training]="currentTraining"></app-training-edit>
<app-training-show *ngIf="!isEditMode() && !loadTraining"
                   (editMode)="passToEditMode($event)"
                   (onUpdate)="reloadTraining($event)"
                   [training]="currentTraining"></app-training-show>
