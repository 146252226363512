<div #divAccounts class="dashboard admin-account" fxFlex="100" fxLayout="row" *ngIf="!load">
    <div class="user-card card" fxFlex="25">
        <div class="user-card-header">
            <img src="assets/layout/images/dashboard/usercard.png" library="serenity-layout" width="100"/>
        </div>
        <div class="user-card-content">
            <img src="assets/layout/images/avatar.png" library="serenity-layout"/>
            <!--<button pButton type="button" icon="ui-icon-settings" class="secondary-btn"></button>-->

            <div class="user-card-name">
                <span>{{account.email}}</span>
            </div>

            <div class="user-detail">
                <ul>
                    <li class="clearfix">
                        <i class="material-icons">assignment</i>
                        <span class="project-title">Tasks</span>
                        <span class="project-detail">3 open</span>
                        <div class="project-progressbar">
                            <div class="project-progressbar-value" style="width: 50%"></div>
                        </div>
                    </li>
                    <li class="clearfix">
                        <i class="material-icons">attach_money</i>
                        <span class="project-title">Revenue</span>
                        <span class="project-detail">+20%</span>
                        <div class="project-progressbar">
                            <div class="project-progressbar-value" style="width: 20%"></div>
                        </div>
                    </li>
                    <li class="clearfix">
                        <i class="material-icons">payment</i>
                        <span class="project-title">Payments</span>
                        <span class="project-detail">24 new</span>
                        <div class="project-progressbar">
                            <div class="project-progressbar-value" style="width: 65%"></div>
                        </div>
                    </li>
                    <li class="clearfix">
                        <i class="material-icons">business_center</i>
                        <span class="project-title">Clients</span>
                        <span class="project-detail">+80%</span>
                        <div class="project-progressbar">
                            <div class="project-progressbar-value" style="width: 80%"></div>
                        </div>
                    </li>
                    <li class="clearfix">
                        <i class="material-icons">pie_chart</i>
                        <span class="project-title">Turnover</span>
                        <span class="project-detail">+40%</span>
                        <div class="project-progressbar">
                            <div class="project-progressbar-value" style="width: 40%"></div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div fxFlex="25">
        <app-action-list [type]="'ACCOUNT'" [id]="account.id"></app-action-list>
        <app-account-last-attempt [attempts]="attempts"></app-account-last-attempt>
    </div>


</div>