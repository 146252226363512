import {Licence} from '../../../licence/models/licence';
import {Group} from '../../../genos/group/models/group';
import {NaturalPersonForm} from '@genos/person-service';

export class BscNaturalPersonForm extends NaturalPersonForm {

    currentLicence: Licence;
    groups: Group[];


    constructor() {
        super();
    }
}
