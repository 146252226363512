import {Buildable, Dto, FormCompliant} from '@mdw/usual-service';
import {ContactForm} from './contact-form';

export class Contact extends Dto implements FormCompliant<Contact, ContactForm>, Buildable<Contact> {

    type: number;
    value: string;

    build(): Contact {
        return this;
    }

    fromForm(form: ContactForm): Contact {
        this.value = form.value;
        this.type = form.type.id;
        return this;
    }

    toForm(): ContactForm {
        const form = new ContactForm();
        form.value = this.value;
        return form;
    }


}
