export class TrainingTemplateForm {

    name: string;
    day: any;
    endTime: string;
    startTime: string;
    status: any;
    sport: any;
    gender: any;
    category: any;
    openNewPersons: boolean;
    visibility: any;
    periodicity: any;
    place: any;
    description: string;
}
