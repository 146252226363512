import {Injectable} from '@angular/core';
import {Rights} from '../../genos/enums/rights.enum';
import {OrganizationUserRelationship, OrganizationWithUserRelationshipView} from '@genos/organization-service';
import {Observable, Subject} from 'rxjs';

@Injectable()
export class MenuService {

    private menuSource = new Subject<any>();

    private isMobile: boolean;


    constructor() {
    }

    public getMenuSubscriber(): Observable<any> {
        return this.menuSource.asObservable();
    }

    public setMenu() {
        this.menuSource.next({});
    }


    getMenusByOrganizationRelationship(organizations: OrganizationWithUserRelationshipView[], isMobile: boolean) {
        this.isMobile = isMobile;
        const menus = [];
        this.prepareMemberMenu(menus, organizations, isMobile);
        organizations.forEach(organization => {
            if (this.isMaster(organization)) {
                this.prepareMasterMenuFor(menus, organization);
            }
            if (this.isOwner(organization)) {
                this.prepareOwnerMenuFor(menus, organization);
            }
        });
        this.prepareEndMenu(menus, organizations);
        return menus;
    }

    private prepareOwnerMenuFor(menus: any[], organization: OrganizationWithUserRelationshipView) {
        const adminMenu = {
            label: organization.name,
            icon: 'settings',
            items: [], order: 9
        };
        adminMenu.items.push({
            label: 'Tableau de bord',
            icon: 'settings',
            order: 10,
            routerLink: '/clubs/' + organization.host + '/dashboard',
        });
        adminMenu.items.push({
            label: 'Utilisateurs',
            icon: 'perm_identity',
            order: 12,
            routerLink: '/clubs/' + organization.host + '/users',
        });
        adminMenu.items.push({
            label: 'Adhérents',
            icon: 'face',
            order: 13,
            routerLink: '/clubs/' + organization.host + '/members',
        });
        adminMenu.items.push({
            label: 'Groupes',
            icon: 'groups',
            order: 15,
            routerLink: '/clubs/' + organization.host + '/groups',
        });
        adminMenu.items.push({
            label: 'Compétitions',
            icon: 'insert_chart',
            order: 16,
            routerLink: '/clubs/' + organization.host + '/contests',
        });
        adminMenu.items.push({
            label: 'Equipes',
            icon: 'stars',
            order: 17,
            routerLink: '/clubs/' + organization.host + '/teams',
        });
        menus.push(adminMenu);
    }


    private prepareMasterMenuFor(menus: any[], organization: OrganizationWithUserRelationshipView) {

    }


    private prepareMemberMenu(menus: any[], organizations: OrganizationWithUserRelationshipView[], isMobile: boolean) {
        menus.push(this.getEventsItem());
        if (isMobile) {
            menus.push(this.getTalksItem());
        }
        menus.push(this.getResultsItem());
        menus.push(this.getVolonteersItem());
        return menus;
    }

    private prepareEndMenu(menus: any[], organizations: OrganizationWithUserRelationshipView[]) {
        if (!organizations.some(o => this.isOwner(o))) {
            menus.push(this.getCreateClubItem());
        }
        menus.push(this.getProfilsItem());
        menus.push(this.getLogoutItem());
        return menus;
    }

    isOwner(organization: OrganizationWithUserRelationshipView) {
        return organization.relationship === OrganizationUserRelationship.Owner;
    }

    isMaster(organization: OrganizationWithUserRelationshipView) {
        return organization.relationship === OrganizationUserRelationship.Master;
    }

    isMember(organization: OrganizationWithUserRelationshipView) {
        return organization.relationship === OrganizationUserRelationship.Member;
    }

    getMenusByRights(rights: number[]) {
        const menus = [];
        const adminMenu = {
            label: 'Administration', icon: 'settings',
            items: [], order: 9
        };
        const adminUserMenu = {
            label: 'Utilisateurs', icon: 'perm_identity', items: [], order: 1
        };
        const adminRightsMenu = {
            label: 'Accréditations', icon: 'traffic', items: [], order: 2
        };
        const adminConfigMenu = {
            label: 'Configurations', icon: 'traffic', items: [], order: 3
        };
        rights.forEach(right => {
            switch (right) {
                case Rights.READ_ALL_EVENTS :
                    menus.push(this.getEventsItem());
                    break;
                case Rights.READ_TEAM :
                    menus.push(this.getMyTeamsItem());
                    break;
                case Rights.READ_ALL_TEMPLATES_TRAINING:
                    menus.push(this.getTrainingsItem());
                    break;
                case Rights.READ_ALL_CONTESTS:
                    menus.push(this.getContestsItem());
                    break;
                case Rights.READ_ALL_GROUPS:
                    menus.push(this.getGroupsItem());
                    break;
                case Rights.READ_CLUB:
                    menus.push(this.getClubItem());
                    break;
                case Rights.READ_ALL_PLACES:
                    menus.push(this.getPlacesItem());
                    break;
                case Rights.READ_ALL_CLUBS:
                    menus.push(this.getClubsItem());
                    break;
                case Rights.READ_ALL_ACCOUNTS:
                    adminUserMenu.items.push(this.getAdminUserAccount());
                    break;
                case Rights.HANDLE_ACCOUNT_ASK:
                    adminUserMenu.items.push(this.getAdminUserAccountAsk());
                    break;
                case Rights.READ_ALL_PERSONS:
                    adminUserMenu.items.push(this.getAdminUserPerson());
                    break;
                case Rights.ATTACH_RIGHT_TO_ROLE:
                    adminRightsMenu.items.push(this.getAdminRightRole());
                    break;
                case Rights.ATTACH_RIGHT_TO_ACCOUNT:
                    adminRightsMenu.items.push(this.getAdminRightAccount());
                    break;
                case Rights.DETACH_RIGHT_TO_ACCOUNT:
                    adminConfigMenu.items.push(this.getAdminConfMail());
                    break;
            }

        });
        if (adminUserMenu.items.length > 0) {
            adminUserMenu.items.sort(this.sortByOrder);
            adminMenu.items.push(adminUserMenu);
        }
        if (adminRightsMenu.items.length > 0) {
            adminRightsMenu.items.sort(this.sortByOrder);
            adminMenu.items.push(adminRightsMenu);
        }
        if (adminConfigMenu.items.length > 0) {
            adminConfigMenu.items.sort(this.sortByOrder);
            adminMenu.items.push(adminConfigMenu);
        }
        if (adminMenu.items.length > 0) {
            adminMenu.items.sort(this.sortByOrder);
            menus.push(adminMenu);
        }
        menus.sort(this.sortByOrder);
        return menus;
    }

    sortByOrder(a, b) {
        if (a.order < b.order) {
            return -1;
        }
        if (a.order > b.order) {
            return 1;
        }
        return 0;
    }


    getEventsItem(): any {
        // badge: '2',
        return {
            label: 'Evenements', icon: 'events', routerLink: ['/events'], order: 1
        };
    }

    getTalksItem(): any {
        // badge: '2',
        return {
            label: 'Discussions', icon: 'chat_bubble_outline', routerLink: ['/talks'], order: 2
        };
    }

    getVolonteersItem(): any {
        return {
            label: 'Bénévolats', icon: 'score', routerLink: ['/volonteers'], order: 4
        };
    }

    getResultsItem(): any {
        return {
            label: 'Résultats', icon: 'score', routerLink: ['/results'], order: 3
        };
    }

    getCreateClubItem(): any {
        return {
            label: 'Créer un club', icon: 'club', routerLink: ['/clubs/create'], order: 97
        };
    }

    getProfilsItem(): any {
        return {
            label: 'Profils', icon: 'profiles', routerLink: ['/profiles'], order: 98
        };
    }

    getLogoutItem(): any {
        return {
            label: 'Déconnexion', icon: 'logout', routerLink: ['/logout'], order: 99
        };
    }

    getMyTeamsItem(): any {
        return {
            label: 'Mes groupes', icon: 'account_balance', routerLink: ['/my-groups'], order: 2
        };
    }

    getTrainingsItem(): any {
        return {
            label: 'Entrainements', icon: 'assistant_photo', routerLink: ['/trainings-templates'], order: 3
        };
    }

    getContestsItem(): any {
        return {
            label: 'Compétitions', icon: 'insert_chart', routerLink: ['/contests'], order: 4
        };
    }

    getClubItem(): any {
        return {
            label: 'Club', icon: 'stars', routerLink: ['/club'], order: 5
        };
    }

    getGroupsItem(): any {
        return {
            label: 'Groupes', icon: 'group', routerLink: ['/groups'], order: 6
        };
    }

    getPlacesItem(): any {
        return {
            label: 'Lieux', icon: 'place', routerLink: ['/places'], order: 7
        };
    }

    getClubsItem(): any {
        return {
            label: 'Les clubs exterieurs', icon: 'bubble_chart', routerLink: ['/clubs'], order: 8
        };
    }

    getAdminUserAccount(): any {
        return {label: 'Comptes', icon: 'account_box', routerLink: ['/administration/accounts']};
    }

    getAdminUserAccountAsk(): any {
        return {
            label: 'Demandes de comptes',
            icon: 'account_box',
            routerLink: ['/administration/asks-account']
        };
    }

    getAdminUserPerson(): any {
        return {label: 'Personnes', icon: 'face', routerLink: ['/administration/natural-persons']};
    }


    getAdminRightAccount(): any {
        return {
            label: 'Comptes',
            icon: 'supervisor_account',
            routerLink: ['/administration/rights-accounts']
        };
    }

    getAdminRightRole(): any {
        return {label: 'Roles', icon: 'hourglass_empty', routerLink: ['/administration/roles']};
    }

    getAdminConfMail(): any {
        return {
            label: 'Mail',
            icon: 'supervisor_account',
            routerLink: ['/administration/configuration/mail']
        };
    }


}
