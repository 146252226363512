import {Component, OnInit} from '@angular/core';
import {BreadcrumbService} from '../../global/services/breadcrumb.service';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
    headerConfig: any;

    constructor(private breadcrumbService: BreadcrumbService) {
        this.breadcrumbService.setItems([{label: 'Mon calendrier'}]);
    }

    ngOnInit() {
        this.headerConfig = {
            left: 'prev,next today',
            center: 'title',
            right: 'month,agendaWeek,agendaDay'
        };
    }

}
