import {isNullOrUndefined} from 'util';
import {AnalyticsService} from '../services/analytics.service';

export function AnalyticsClick(_category: string, _label: string, _action: string) {

    const analyticsService = new AnalyticsService();
    return function (target, key, descriptor) {
        if (isNullOrUndefined(descriptor)) {
            descriptor = Object.getOwnPropertyDescriptor(target, key);
        }
        const originalMethod = descriptor.value;

        descriptor.value = function (...args: any[]) {
            analyticsService.sendEvent(_category, _label, _action);
            return originalMethod.apply(this, args);
        };

        return descriptor;
    };
}
