<div class="training" fxLayout="row" fxLayout.xs="column">
    <div class="loader" fxLayout="row" *ngIf="loadInfo" fxLayoutAlign="center center">
        <p-progressSpinner></p-progressSpinner>
    </div>
    <div class="content card" fxFlex="49" fxFlex.xs="100" fxLayout="column" *ngIf="!loadInfo">
        <div class="title" fxLayout="row">
            <h3 fxFlex="50">{{training.title}}</h3>
            <div fxFlex="50" fxLayoutAlign="end center">
                <div appNeedRight="UPDATE_EVENT" fxLayoutAlign="center center" class="button-i"
                     (click)="passToEditMode()">
                    <i class="material-icons">edit_mode</i>
                </div>
            </div>
        </div>
        <div class="startDate" fxLayout="row">{{training.getStartAndEndDate()}}</div>
        <div>
            <h4>Description</h4>
            {{training.description}}
        </div>
        <div fxLayout="column">
            <h4 i18n="@@h4-training-place">Lieu de l'évènement</h4>
            <div *ngIf="!havePlace()">
                Pas de lieu défini pour le moment.
            </div>
            <app-place-simple-view *ngIf="havePlace()" [place]="training.place"></app-place-simple-view>
        </div>

    </div>
    <div fxFlex="49" fxFlex.xs="100" fxLayout="column" *ngIf="!loadInfo">
        <div class="card" appNeedRight="CALL_EVENT">
            <h4>Actions</h4>
            <app-event-calling [event]="training"></app-event-calling>
        </div>
        <div *ngIf="currentPersonIsInEvent() && isInFuture()" class="card">
            <h4 i18n="@@h4-attendances">Ma présence</h4>
            <app-event-attendance *ngFor="let attendance of training.currentAttendances"
                                  [event]="training"
                                  mode="GAME"
                                  (onUpdate)="onUpdateAttendance($event)"
                                  [attendance]="attendance"></app-event-attendance>
        </div>
        <div class="attendances card">
            <app-event-attendance-list-view [event]="training"
                                            *ngIf="training.attendances"
                                            (onUpdate)="onUpdateAttendance($event)"
                                            [mode]="modeAttendanceListView"></app-event-attendance-list-view>
        </div>
    </div>

</div>
