<div class="dashboard" fxLayout="column" fxLayoutAlign="center center">
  <div *ngIf="load">
    <p-progressSpinner></p-progressSpinner>
  </div>
  <div *ngIf="!load">
    <div *ngIf="!showTabs">
      <div class="ui-inputgroup">
        <input type="text" [(ngModel)]="name"
               pInputText placeholder="Mon tableau" i18n-placeholder="@@placeholder-dashboard-name">
        <button pButton type="button" (click)="add()" icon="fa-plus-circle" label="Ajouter un tableau de bord"
                i18n-label="@@button-add-first-dashboard"></button>
      </div>
    </div>
  </div>
</div>
<mat-tab-group *ngIf="showTabs" fxFlex="100">
  <mat-tab *ngFor="let dashboard of dashboards">
    <ng-template mat-tab-label>
      {{dashboard.name}}
      <i class="fa fa-close on-title" (click)="remove(dashboard)" aria-hidden="true"></i>
    </ng-template>
    Content 1
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <i class="fa fa-plus-circle" aria-hidden="true"></i>
    </ng-template>
    <div class="dashboard" fxLayout="column" fxLayoutAlign="center center">
      <div class="ui-inputgroup">
        <input type="text" [(ngModel)]="name"
               pInputText placeholder="Mon tableau" i18n-placeholder="@@placeholder-dashboard-name">
        <button pButton type="button" (click)="add()" icon="fa-plus-circle" label="Ajouter un tableau de bord"
                i18n-label="@@button-add-first-dashboard"></button>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
<p-growl [value]="msgs"></p-growl>
<p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="425"></p-confirmDialog>
