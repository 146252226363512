<div class="clubs" fxFlex="100" fxLayout="row">
    <div fxFlex="100" class="loader" fxLayout="row" *ngIf="loadClub" fxLayoutAlign="center center">
        <p-progressSpinner></p-progressSpinner>
    </div>
    <div fxLayout="column" fxFlex="100" *ngIf="!loadClub">
        <div fxLayout="row">
            <h3 i18n="@@h3-clubs" fxFlex="50" >Gestion des clubs extérieurs</h3>
            <div fxFlex="50" fxLayoutAlign="end center">
                <div appNeedRight="CREATE_CLUB" fxLayoutAlign="center center" class="button-i"
                     (click)="addClub()">
                    <i class="material-icons">add</i>
                </div>
            </div>
        </div>
        <div fxFlex="100" fxLayout="row">
            <div fxFlex="100" fxLayout="column">

                <div class="ui-widget-header" fxFlex="100" fxLayout="row">
                    <i class="fa fa-search"></i>
                    <input #gb type="text" pInputText size="50" i18n-placeholder="@@placeholder-global-filter"
                           placeholder="Filtre">
                </div>
                <p-dataTable fxFlex="100" [globalFilter]="gb" [value]="clubs"
                             [paginator]="true" [rows]="10"
                             selectionMode="single"
                             [(selection)]="selectedClub" (onRowSelect)="onRowSelect($event)"
                             [sortField]="sortF" [sortOrder]="sortO">
                    <p-column field="logo" i18n-header="@@header-data-table-club-sport" header="Logo">
                        <ng-template let-club="rowData" pTemplate="body">
                            <span class="profile-image-wrapper">
                                <img src="{{club.logo}}">
                            </span>
                        </ng-template>
                    </p-column>
                    <p-column field="name" i18n-header="@@header-data-table-club-name" header="Nom"
                              [sortable]="true"></p-column>
                    <p-column field="city" i18n-header="@@header-data-table-club-period" header="Ville"
                              [sortable]="true"></p-column>
                    <p-column field="zipcode" i18n-header="@@header-data-table-club-sport" header="Code postal"
                              [sortable]="true">
                    </p-column>
                    <p-column field="teams" i18n-header="@@header-data-table-club-level" header="Equipes"
                              [sortable]="true">
                        <ng-template let-club="rowData" pTemplate="body">
                            <span>{{club.teams.length}}</span>
                        </ng-template>
                    </p-column>
                    <p-column field="status" i18n-header="@@header-data-table-account-status" header="Statut"
                              [sortable]="true"></p-column>
                </p-dataTable>
            </div>
        </div>
    </div>
</div>