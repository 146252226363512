import {TeamStatus} from '../enums/team-status.enum';

export class TeamForm {

    name: string;
    status: TeamStatus;
    club: number;
    sport: any;
    category: any;
    gender: any;
    logo: string;
    description: string;
    shortDescription: string;

}
