<div #divRoles class="admin-roles " fxFlex="100" fxLayout="column">
    <div fxFlex="100" fxLayout="row">
        <div fxFlex="33" class="card" fxLayout="column">
            <div fxLayout="row">
                <h3 i18n="@@h3-admin-roles" fxFlex="30">Gestion des rôles</h3>
                <div fxFlex="70" fxLayout="column" fxLayoutAlign="center end">
                    <div fxLayoutAlign="center center" class="button-i" (click)="passAddRole()">
                        <i class="material-icons">add</i>
                    </div>
                </div>
            </div>
            <div>
                <p-progressSpinner class="spinner" *ngIf="loadGetAll"></p-progressSpinner>
                <p-dataList *ngIf="!loadGetAll" [value]="roles" [paginator]="true" [rows]="10"
                            styleClass="cars-datalist">
                    <p-header>
                        <span i18n="@@h3-roles-list">Liste des rôles</span>
                    </p-header>
                    <ng-template let-role pTemplate="item">
                        <app-role-line (onAccreditation)="accreditationToRole($event)" (onEdit)="editRole($event)"
                                       [role]="role"></app-role-line>
                    </ng-template>
                </p-dataList>
            </div>
        </div>
        <div fxFlex="33" *ngIf="showEditRole">
            <app-role-edit [role]="role" (onCancel)="cancelEditRole($event)"></app-role-edit>
        </div>
        <div fxFlex="33" *ngIf="showEditRole">
            <app-action-list [type]="'ROLE'" [id]="role.id"></app-action-list>
        </div>
        <div fxFlex="66" *ngIf="showAccreditationRole">
            <app-rights-to-attach [to]="role" mode="ROLE"></app-rights-to-attach>
        </div>
    </div>
</div>
<p-dialog [(visible)]="showAddDialog" modal="modal" [dismissableMask]="true" showEffect="fade" width="400">
    <p-header i18n="@@header-dialog-add-role">
        Ajouter un role
    </p-header>
    <div class="ui-g-12 ui-md-4">
        <input type="text" [(ngModel)]="roleName" (keyup.enter)="addRole()" pInputText
               i18n-placeholder="@@placeholder-role-name"
               placeholder="Nom du role *">
    </div>
    <p-footer>
        <p-progressSpinner class="spinner" *ngIf="loadAdd"></p-progressSpinner>
        <div *ngIf="!loadAdd" class="ui-dialog-buttonpane ui-helper-clearfix">
            <button type="button" pButton icon="fa-close" class="ui-button-warning" (click)="showAddDialog=false"
                    i18n-label="@@button-cancel"
                    label="Annuler"></button>
            <button type="button" pButton icon="fa-check" class="ui-button-primary" (click)="addRole()"
                    i18n-label="@@button-confirm"
                    label="Valider"></button>
        </div>
    </p-footer>
</p-dialog>
<p-contextMenu [target]="divRoles" [model]="items"></p-contextMenu>
