import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {HeaderOption} from '../models/header-option';

@Injectable({
    providedIn: 'root'
})
export class HeaderService {

    subject: ReplaySubject<HeaderOption>;

    constructor() {
        this.subject = new ReplaySubject();
    }

    getSubscriber(): Observable<HeaderOption> {
        return this.subject.asObservable();
    }

    setOption(option: HeaderOption) {
        this.subject.next(option);
    }

    setMobileOnboading(title: string, part: number) {
        this.subject.next(HeaderOption.mobileOnboarding(title, part));
    }

    setMobileDefault() {
        this.subject.next(HeaderOption.mobileDefault());
    }

    setMobileOutsideDefault(titleLinkTo: string, linkTo: string) {
        this.subject.next(HeaderOption.mobileOutsideDefault(titleLinkTo, linkTo));
    }

    setMobileWelcome() {
        this.subject.next(HeaderOption.mobileWelcome());
    }

    setMobileDefaultTitle(title: string) {
        this.subject.next(HeaderOption.mobileDefaultTitle(title));
    }

    setMobileBack(title: string) {
        this.subject.next(HeaderOption.mobileBackButton(title));
    }

    setMobileBackWithActionAndData(title: string, action: string, data: any) {
        this.subject.next(HeaderOption.mobileBackButtonWithActionAndData(title, action, data));
    }
}
