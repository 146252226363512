import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    ButtonModule,
    ConfirmDialogModule,
    ContextMenuModule,
    DataListModule,
    DataTableModule,
    DialogModule,
    DropdownModule,
    InputTextareaModule,
    InputTextModule,
    PanelModule,
    ProgressSpinnerModule
} from 'primeng/primeng';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule} from '@angular/forms';
import {MatChipsModule, MatListModule, MatSelectModule} from '@angular/material';
import {RightsModule} from '../rights/rights.module';
import {AccountsListComponent} from './accounts-list/accounts-list.component';
import {AccountsLineComponent} from './accounts-line/accounts-line.component';
import {AskActivationComponent} from './ask-activation/ask-activation.component';
import {PasswordComponent} from './password/password.component';
import {AccountComponent} from './account/account.component';
import {AccountLastAttemptComponent} from './account-last-attempt/account-last-attempt.component';
import {AsksAccountComponent} from './asks-account/asks-account.component';
import {MonitoringModule} from '../../mdw/monitoring/monitoring.module';
import {AccountService, AskAccountService, GenosUserServiceModule} from '@genos/user-service';
import {MdwUsualServiceModule} from '@mdw/usual-service';

@NgModule({
    imports: [
        CommonModule,
        ProgressSpinnerModule,
        ButtonModule,
        InputTextModule,
        InputTextareaModule,
        MdwUsualServiceModule,
        GenosUserServiceModule,
        FlexLayoutModule,
        FormsModule,
        MatChipsModule,
        MatSelectModule,
        DialogModule,
        ConfirmDialogModule,
        DropdownModule,
        RightsModule,
        DataListModule,
        DataTableModule,
        PanelModule,
        ContextMenuModule,
        MonitoringModule,
        MatListModule
    ],
    declarations: [
        AccountsListComponent,
        AccountsLineComponent,
        AskActivationComponent,
        PasswordComponent,
        AccountComponent,
        AccountLastAttemptComponent,
        AsksAccountComponent
    ],
    exports:
        [AsksAccountComponent],
    providers:
        [AccountService, AskAccountService]
})

export class AccountsModule {
}
