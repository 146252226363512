import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Group} from '../models/group';
import {GroupForm} from '../models/group-form';
import {GroupsService} from '../services/groups.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GroupStatus} from '../enums/group-status.enum';
import {GroupType} from '../enums/group-type.enum';
import {SportPersonsService} from '../../team/services/sport-persons.service';
import {SportPersonsFilter} from '../../team/models/sport-persons-filter';
import {forkJoin, Observable, Subject} from 'rxjs';
import {ConfirmationService} from 'primeng/primeng';
import {BreadcrumbService} from '../../../global/services/breadcrumb.service';
import {MessageService} from '../../../global/services/message.service';
import {Periodicity} from '@mdw/usual-service';
import {NaturalPerson, PersonsFilterForPipe, PersonsFilterPipe, PersonStatus} from '@genos/person-service';
import {GenosConfigurationService} from '../../configuration/services/genos-configuration.service';

@Component({
    selector: 'app-group',
    templateUrl: './group.component.html',
    styleUrls: ['./group.component.scss'],
    providers: [ConfirmationService],
    encapsulation: ViewEncapsulation.None
})
export class GroupComponent implements OnInit {

    group: Group;
    form: GroupForm;

    filter: any;
    membersFilter: PersonsFilterForPipe;
    loadInfo: boolean;
    sports: any[];
    genders: any[];
    categories: any[];

    // Members
    loadMembers: boolean;
    targets: NaturalPerson[];

    statutes: any[];
    types: any[];
    periodicities: any[];

    // External Group
    mode: string;
    idGroup: number;

    constructor(private breadcrumbService: BreadcrumbService,
                private groupsService: GroupsService,
                private route: ActivatedRoute,
                private router: Router,
                private genosConfigurationService: GenosConfigurationService,
                private confirmationService: ConfirmationService,
                private sportPersonsService: SportPersonsService,
                private messageService: MessageService) {
    }

    ngOnInit() {
        this.clearFilter();
        this.loadInfo = true;
        this.filter = new SportPersonsFilter();
        this.form = new GroupForm();
        this.group = new Group();
        this.statutes = [];
        this.types = [];
        this.periodicities = [];
        this.idGroup = parseInt(this.route.snapshot.params.id, 10);
        this.loadFormInfos().subscribe(() => {
            this.reload();
        });
    }

    clearFilter() {
        this.membersFilter = new PersonsFilterForPipe();
        this.filter = new SportPersonsFilter();
    }

    updateTargets() {
        this.loadAllPerson();
    }


    saveCurrentMembers() {
        this.groupsService.saveMembers(this.group, this.group.members).subscribe(response => {
            this.messageService.addSuccess('Sauvegarde réussie', 'Le groupe à été renommé');
        });
    }


    reload() {
        this.loadInfo = true;
        if (this.idGroup === 0) {
            this.group = new Group();
            this.group.status = GroupStatus.ACTIVE;
            this.group.periodicity = Periodicity.NONE;
            this.form = new GroupForm();
            this.setBreadcrumb();
            this.loadInfo = false;

        } else {
            this.groupsService.getById(this.idGroup).subscribe(group => {
                this.group = group;
                this.form = this.group.toForm();
                this.form.status = this.statutes.filter(c => c.value === this.form.status.value)[0];
                this.form.type = this.types.filter(c => c.value === this.form.type.value)[0];
                this.groupsService.getMembers(this.group).subscribe(members => {
                    this.group.members = members;
                    this.setBreadcrumb();
                    this.loadAllPerson();
                });
            });
        }
    }

    loadAllPerson() {
        this.loadInfo = true;
        this.sportPersonsService.getSportPersons(new SportPersonsFilter()
            .withExceptGroups([this.group.id]).withExceptStatutes([PersonStatus.INACTIVE, PersonStatus.ASK_DELETE])
            .withCategory(this.filter.category ? this.filter.category.value : null)
            .withGender(this.filter.gender ? this.filter.gender.value : null)
            .withSport(this.filter.sport ? this.filter.sport.value : null))
            .subscribe(response => {
                this.targets = new PersonsFilterPipe().transform(response, this.membersFilter);
                this.loadInfo = false;
            });
    }

    loadFormInfos(): Observable<any> {
        const s = new Subject<any>();
        this.statutes.push({value: GroupStatus.ACTIVE, name: 'Actif'});
        this.statutes.push({value: GroupStatus.INACTIVE, name: 'Inactif'});
        this.statutes.push({value: GroupStatus.INVISIBLE, name: 'Non visible'});
        this.statutes.push({value: GroupStatus.MAIN, name: 'Principal'});
        this.periodicities.push({value: Periodicity.NONE, label: 'Aucune'});
        this.periodicities.push({value: Periodicity.MONTHLY, label: 'Mensuelle'});
        this.periodicities.push({value: Periodicity.QUARTER, label: 'Trimestrielle'});
        this.periodicities.push({value: Periodicity.BIANUEL, label: 'Semestrielle'});
        this.periodicities.push({value: Periodicity.YEARLY, label: 'Annuelle'});
        this.types.push({value: GroupType.ADMINISTRATIVE, label: 'Gestionnaire'});
        this.types.push({value: GroupType.EVENT, label: 'Evenement'});
        this.types.push({value: GroupType.TRAINING, label: 'Entrainement'});
        forkJoin(
            this.genosConfigurationService.getSports(),
            this.genosConfigurationService.getGenders(),
            this.genosConfigurationService.getSportCategories(),
            this.genosConfigurationService.getContestsTypes(),
            this.genosConfigurationService.getLevels()
        ).subscribe(options => {
            this.sports = options[0];
            this.genders = options[1];
            this.categories = options[2];
            s.next({});
            s.complete();
        });
        return s.asObservable();


    }

    setBreadcrumb() {
        if (this.idGroup === 0) {
            this.breadcrumbService.setItems([
                {
                    label: 'Les groupes',
                    routerLink: 'groups'
                },
                {label: 'Nouveau groupe'}
            ]);
        } else {
            this.breadcrumbService.setItems([
                {
                    label: 'Les groupes',
                    routerLink: 'groups'
                },
                {label: this.group.name}
            ]);
        }
    }

    isNew() {
        return this.idGroup === 0;
    }

    delete() {
        this.confirmationService.confirm({
            message: 'Êtes-vous sur de vouloir supprimer ce groupe ?',
            header: 'Suppression d\'un group',
            icon: 'fa fa-trash',
            accept: () => {
                this.groupsService.remove(this.group.id).subscribe(response => {
                    this.messageService.addSuccess('Suppression réussie', 'Les données ont été supprimée');
                    this.router.navigate(['groups/']);
                });
            }
        });
    }

    save() {
        this.groupsService.save(this.group.fromForm(this.form)).subscribe(group => {
            this.group = group;
            this.idGroup = group.id;
            this.router.navigate(['groups/' + this.idGroup]);
            this.reload();
            this.messageService.addSuccess('Sauvegarde réussie', 'Les données ont été sauvegardées');
        });
    }

}
