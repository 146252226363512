import {Periodicity} from '@mdw/usual-service';

export class ContestForm {

    logo: string;
    name: string;
    periodicity: Periodicity;
    period: string;
    status: any;
    type: any;
    description: string;
    linkOfficial: string;
    shortDescription: string;
    sport: any;
    gender: any;
    category: any;
    level: any;
}
