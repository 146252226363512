import {AfterViewInit, Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {MessageService} from './global/services/message.service';
import {MenuItem} from 'primeng/primeng';
import * as moment from 'moment';
import {GlobalSessionService} from '@genos/user-service';
import {ContextMenuAppService} from './global/services/context-menu-app.service';
import {NavigationEnd, Router} from '@angular/router';
import {AnalyticsService} from './analytics/services/analytics.service';
import {GlobalMeService} from './global/services/global-me.service';
import {OnboardingContextService} from './onboarding/onboarding-context.service';
import {SwPush, SwUpdate} from '@angular/service-worker';
import {CommonComponent} from './common-component';
import {TalkZoneComponent} from './talks/talk-zone/talk-zone.component';
import {HeaderService} from './global/services/header.service';
import {Location} from '@angular/common';
import {FooterService} from './global/services/footer.service';
import {environment} from '../environments/environment';
import {CordovaService} from './global/services/cordova.service';
import {routerTransition} from './animation/router.animations';
import {NavigationService} from './global/services/navigation.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [
        routerTransition(),
    ]
})
export class AppComponent extends CommonComponent implements OnInit, OnDestroy,
    AfterViewInit, OnChanges {

    @ViewChild('talkZone') talkZone: TalkZoneComponent;

    routesToHideBreadcrumb: string [] = ['/events', '/talks', '/results'];
    currentRoute: string;

    isMobile: boolean;
    full = false;
    showBreadcrumb: boolean;

    sideMenuMode: string;

    events: string[] = [];
    showSideMessage: boolean;
    showRightSide: boolean;
    sideRightMode: string;
    showSideNotification: boolean;
    showSideMenu: boolean;
    extendsMenu: boolean;
    fullMenu: boolean;

    messageSubscriber: Subscription;
    headerToolbarSubscriber: Subscription;
    onboardingSubscriber: Subscription;
    sessionSubscriber: Subscription;
    footerSubscription: Subscription;
    contextMenuSubscriber: Subscription;
    msgs: any[];
    breadcrumbItems: MenuItem[];

    loadApp: boolean;
    stickyMode: boolean;
    isOnboarding: boolean;

    contextMenu: MenuItem[];
    isLoggedIn: boolean;

    // FROM SERENITY
    menuClick: boolean;

    topbarMenuClick: boolean;

    topbarMenuActive: boolean;

    activeTopbarItem: Element;


    constructor(private messageService: MessageService,
                private session: GlobalSessionService,
                private analyticsService: AnalyticsService,
                private onboardingContext: OnboardingContextService,
                private me: GlobalMeService,
                private swPush: SwPush,
                private router: Router,
                private contextMenuAppService: ContextMenuAppService,
                private headerService: HeaderService,
                private footerService: FooterService,
                private location: Location,
                private swUpdate: SwUpdate,
                private cordovaService: CordovaService,
                private navigationService: NavigationService) {
        super();
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                const route = event.urlAfterRedirects.split('?')[0];
                this.currentRoute = route;
                this.handleChangeRoute(route);
            }
        });
        this.onboardingSubscriber = this.onboardingContext.getSubscriber().subscribe(is => {
            this.isOnboarding = is;
        });
        this.sessionSubscriber = this.session.getSubscriber().subscribe(logged => {
            this.isLoggedIn = logged;
            if (logged) {
                this.handleLogin();
            } else {
                this.handleLogout();
            }
        });
        this.footerSubscription = this.footerService.getSubscriber().subscribe(option => {
            setTimeout(() => {
                if (this.isMobile) {
                    this.full = !option.showCredits && !option.showTabs;
                } else {
                    this.full = false;
                }
            }, 10);
        });
        this.contextMenuSubscriber = this.contextMenuAppService.getSubscriber().subscribe(response => {
            if (response.length === 0) {
                response.push({label: 'Aucune action', disabled: true});
            }
            this.contextMenu = response;
        });
        if (environment.isCordova) {
            this.cordovaService.getBackButtonSubscriber().subscribe(value => {
                console.log('on back button subscriber');
            });
            this.cordovaService.getResumeSubscriber().subscribe(value => {
                console.log('on resume subscriber');
            });
        }
    }

    ngOnInit(): void {
        super.ngOnInit();

        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(() => {
                if (confirm('Une nouvelle version de l\'application est disponible, se mettre à jour ?')) {
                    window.location.reload();
                }
            });
        }
        this.breadcrumbItems = [];
        this.loadApp = true;
        this.me.reload(true, user => {
            this.isLoggedIn = this.session.isLoggedIn();
            if (this.isLoggedIn) {
                this.handleLogin();
                this.me.needOrganizations(true, () => {
                    this.me.needMainPerson(true, () => {
                        this.loadApp = false;
                    });

                });
                if (this.isMobile) {
                    // this.showBurgerMenu = true;
                }
            } else {
                this.loadApp = false;
            }
        });
        moment.locale('fr');
        moment.fn.toJSON = function () {
            return this.format();
        };
        this.contextMenu = [{label: 'Aucune action', disabled: true}];
        this.msgs = [];
        this.stickyMode = false;

    }

    openTalk(talk) {
        this.talkZone.openTalk(talk);
    }


    handleLogin(): void {
        if (this.isMobile) {
            setTimeout(() => {
                this.headerService.setMobileDefault();
            }, 1);
            setTimeout(() => {
                this.footerService.setMobileMainScreen();
            }, 1);
        }
    }

    handleLogout(): void {
        this.showBreadcrumb = false;
    }

    handleChangeRoute(route: string): void {
        this.handleBreadcrumbByRoute(route);
        this.analyticsService.setPage(route);
    }

    handleBreadcrumbByRoute(route: string) {
        if (route) {
            if (this.routesToHideBreadcrumb.filter(v => route.indexOf(v) !== -1).length > 0) {
                this.showBreadcrumb = !this.isMobile;
            } else {
                this.showBreadcrumb = this.isLoggedIn;
            }
        }
    }


    handleResize(): void {
        super.handleResize();
        this.isMobile = this.defineIsMobile();
        this.showSideMenu = !this.isMobile;
        this.handleBreadcrumbByRoute(this.currentRoute);
        if (this.isMobile) {
            this.sideMenuMode = 'over';
            this.sideRightMode = 'over';
        } else {
            this.sideMenuMode = 'side';
            this.sideRightMode = 'side';
        }
    }

    ngOnDestroy(): void {
        if (this.messageSubscriber) {
            this.messageSubscriber.unsubscribe();
        }
        if (this.onboardingSubscriber) {
            this.onboardingSubscriber.unsubscribe();
        }
        if (this.sessionSubscriber) {
            this.sessionSubscriber.unsubscribe();
        }
        if (this.headerToolbarSubscriber) {
            this.headerToolbarSubscriber.unsubscribe();
        }
        if (this.footerSubscription) {
            this.footerSubscription.unsubscribe();
        }
    }

    toggleMessages() {
        this.showSideMessage = !this.showSideMessage;
        if (this.showSideMessage) {
            this.showSideNotification = false;
            this.showRightSide = true;
            if (this.isMobile) {
                this.showSideMenu = false;
            }
        } else {
            this.showRightSide = !this.showRightSide;
            if (this.showRightSide) {
                this.showSideMenu = false;
            }
        }
    }

    toggleNotifications() {
        this.showSideNotification = !this.showSideNotification;
        if (this.showSideNotification) {
            this.showSideMessage = false;
            this.showRightSide = true;
            if (this.isMobile) {
                this.showSideMenu = false;
            }
        } else {
            this.showRightSide = !this.showRightSide;
            if (this.showRightSide) {
                this.showSideMenu = false;
            }
        }
    }

    handleChangePage($event) {
        if (this.showSideMenu) {
            this.showSideMenu = false;
        }
        if (this.showRightSide) {
            this.showRightSide = false;
        }
    }

    setNotificationCount($event) {

    }


    showMenuFullScreen() {
        this.fullMenu = true;
        this.showSideMenu = !this.showSideMenu;
        if (this.showSideMenu) {
            this.showRightSide = false;
        }
    }


    ngAfterViewInit(): void {
        if (this.isMobile) {
            this.headerService.setMobileDefault();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log(changes);
    }


    getRouteAnimation(outlet) {
        return this.navigationService.animationValue;
    }

}
