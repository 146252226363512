import {ContestForm} from './contest-form';
import {ContestType} from '../enums/contest-type';
import {Team} from '../../team/models/team';
import {ContestStatus} from '../enums/contest-status';
import {Game} from '../../game/models/game';
import {Event} from '../../../events/models/event';
import {Leaderboard} from '../../leaderboard/models/leaderboard';
import {Buildable, CanAttach, Dto, FormCompliant, Periodicity} from '@mdw/usual-service';
import {environment} from '../../../../environments/environment';
import {isNullOrUndefined} from 'util';

export class Contest extends Dto implements CanAttach, Buildable<Contest>, FormCompliant<Contest, ContestForm> {

    logo: string;
    name: string;
    periodicity: Periodicity;
    period: string;
    status: ContestStatus;
    type: ContestType;
    description: string;
    shortDescription: string;
    linkOfficial: string;
    sport: string;
    gender: string;
    category: string;
    level: string;
    teams: Team[];
    leaderboards: Leaderboard[];
    games: Game[];
    events: Event[];

    // Can attach
    typeTo: string;

    constructor() {
        super();
        this.typeTo = 'CONTEST';
        this.status = ContestStatus.ACTIVE;
        this.periodicity = Periodicity.YEARLY;
        this.logo = environment.imageDefaultChampionship;
    }

    toForm(): ContestForm {
        const cf = new ContestForm();
        cf.name = this.name;
        cf.period = this.period;
        cf.description = this.description;
        cf.shortDescription = this.shortDescription;
        cf.linkOfficial = this.linkOfficial;
        cf.logo = this.logo;
        cf.status = {value: this.status};
        cf.category = {value: this.category};
        cf.type = {value: this.type};
        cf.level = {value: this.level};
        cf.gender = {value: this.gender};
        cf.sport = {value: this.sport};
        return cf;
    }

    fromForm(f: ContestForm): Contest {
        this.name = f.name;
        this.logo = f.logo;
        this.period = f.period;
        this.description = f.description;
        this.sport = f.sport.value;
        this.category = f.category.value;
        this.type = f.type.value;
        this.level = f.level.value;
        this.gender = f.gender.value;
        this.status = f.status.value;
        this.shortDescription = f.shortDescription;
        this.linkOfficial = f.linkOfficial;
        return this;
    }

    build(): Contest {
        super.build();
        this.teams = this.teams.map(t => Object.assign(new Team(), t).build());
        this.leaderboards = this.leaderboards.map(l => Object.assign(new Leaderboard(), l).build());
        this.games = this.games.map(g => Object.assign(new Game(), g).build());
        this.events = this.events.map(e => Object.assign(new Event(), e).build());
        if (isNullOrUndefined(this.logo)) {
            if (this.type === ContestType.CHAMPIONSHIP) {
                this.logo = environment.imageDefaultChampionship;
            } else {
                this.logo = environment.imageDefaultTournament;
            }
        }
        return this;
    }

}
