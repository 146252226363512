<div fxFlex="100" class="app" fxLayout="column" [ngClass]="{'is-logged': isLoggedIn, 'full': full }">
    <app-header [isMobile]="isMobile" [isLoggedIn]="isLoggedIn"
                (toggleMessages)="toggleMessages()"
                (toggleNotifications)="toggleNotifications()"
                (showMenuFullScreen)="showMenuFullScreen()"
    ></app-header>
    <mat-sidenav-container class="container">
        <mat-sidenav #sideMessage position="end"
                     class="side-messages"
                     *ngIf="isLoggedIn"
                     [mode]="sideRightMode"
                     [(opened)]="showRightSide">
            <div *ngIf="showRightSide && showSideMessage">
                <app-talk-list
                        (setNotificationCount)="setNotificationCount($event)"
                        (openTalk)="openTalk($event)"></app-talk-list>
            </div>
            <div *ngIf="showRightSide && showSideNotification">
                <app-notification-list></app-notification-list>
            </div>
        </mat-sidenav>
        <mat-sidenav #sideMenu class="side-menu mat-elevation-z8"
                     [ngClass]="{'extended':extendsMenu,'normal':!extendsMenu, 'full':fullMenu}"
                     *ngIf="isLoggedIn"
                     (mouseenter)="extendsMenu=true" (mouseleave)="extendsMenu=false"
                     [mode]="sideMenuMode"
                     position="start" [(opened)]="showSideMenu">
            <app-menu [extendedMenu]="extendsMenu || fullMenu" (onClick)="showSideMenu = false"
                      [isMobile]="fullMenu"></app-menu>
        </mat-sidenav>
        <mat-sidenav-content class="layout-wrapper" fxFlex="100" fxLayout="row"
                             [ngClass]="{'not-mobile-and-logged': !isMobile && isLoggedIn, 'full-width': isMobile || !isLoggedIn}">
            <div fxLayout="column" class="layout-main" fxFlex="100">
                <app-breadcrumb [isMobile]="isMobile" *ngIf="isLoggedIn && !isMobile"></app-breadcrumb>
                <div fxFlex="100" fxLayout="row" fxLayoutAlign="center stretch">
                    <div class="genos-main" [ngClass]="{'is-logged': isLoggedIn}" scrollSpy>
                        <!--<app-notification-request *ngIf="!isMobile && isLoggedIn"></app-notification-request>-->
                        <div [@routerAnimation]="getRouteAnimation(router)" class="content"
                             [ngClass]="{'full-height-minus-footer': isLoggedIn && !isMobile ,'full-height' : isMobile}">
                            <router-outlet #router="outlet"></router-outlet>
                        </div>

                        <!--<p-contextMenu [target]="layoutContent" [model]="contextMenu"></p-contextMenu>-->
                    </div>
                    <app-talk-zone *ngIf="!isMobile" #talkZone
                                   [ngClass]="{'right-side-bar-extended':showRightSide, 'not-displayed': !showRightSide}"></app-talk-zone>

                </div>


            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
    <app-footer [isLoggedIn]="isLoggedIn" (onChangePage)="handleChangePage($event)"></app-footer>
</div>