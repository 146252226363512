import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {GlobalSessionService} from '@genos/user-service';
 
@Injectable()
export abstract class RightGuard implements CanActivate, CanActivateChild {


    constructor(private session: GlobalSessionService,
                private router: Router) {
    }

    abstract handleError(requiredRight: any);

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return this.canActivate(childRoute, state);
    }

    canActivate(next: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): boolean {
        return true;
        /*const rights = this.session.getUser().rights;
        const requiredRight = next.data.right;
        const canAccess = rights.filter(r => r === requiredRight).length > 0;
        if (canAccess) {
            return true;
        } else {
            this.handleError(requiredRight);
            return false;
        }*/

    }
}
