import {Buildable, Dto} from '@mdw/usual-service';
import {TalkMessageStatus} from '../enums/talk-message-status.enum';
import * as moment from 'moment';
import {TalkUser} from './talk-user';

export class TalkMessage extends Dto implements Buildable<TalkMessage> {

    content: string;
    status: TalkMessageStatus;
    user: TalkUser;
    idTalk: number;
    apiKeyWSTalk: string;
    talkRole: string;

    constructor() {
        super();
        this.created = moment();
        this.talkRole = '';
    }

    build(): TalkMessage {
        super.build();
        this.user = Object.assign(new TalkUser(), this.user).build();
        return this;
    }

    isOwn(me: number) {
        return this.user.id === me;
    }
}
