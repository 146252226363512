<div class="content-card">
    <div class="content-card card card-w-title">
        <h4 i18n="@@h4-action-list">Dernières actions</h4>
        <p-accordion>
            <p-accordionTab header="{{getTitle(action)}}" *ngFor="let action of actions" fxLayout="column">
                <div fxFlex="100" fxLayout="column">
                    <div fxFlex="100" class="line" [ngClass]="{'updated' : !data.isSame() && action.isUpdate()}" fxLayout="row" *ngFor="let data of getDatas(action)">
                        <div fxFlex="33" class="label">{{data.label}}</div>
                        <div fxFlex="33">{{data.old}}</div>
                        <div fxFlex="33">{{data.new}}</div>
                    </div>
                </div>
            </p-accordionTab>
        </p-accordion>
        <p *ngIf="actions.length === 0" i18n="@@p-no-actions">Aucune actions effectuées sur cette donnée</p>
    </div>
</div>