import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {MessageService} from '../../global/services/message.service';
import {SessionService} from '@genos/user-service';
import {tap} from 'rxjs/operators';


@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {


    constructor(private router: Router,
                private message: MessageService,
                private session: SessionService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(tap(
            (response) => {
            },
            error => {
                if (error instanceof HttpErrorResponse) {
                    if (error.status === 401) {
                        this._logout();
                    }
                    console.log(error);
                    // else {
                    //     this.message.addError(error.error.code, error.error.message);
                    // }
                }
            }));
    }


    private _logout() {
        this.router.navigate(['']);
        this.session.logout();
    }
}
