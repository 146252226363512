import {Component, Input, OnInit} from '@angular/core';
import {AuthenticationAttempt} from '@genos/user-service';
 
@Component({
    selector: 'app-account-last-attempt',
    templateUrl: './account-last-attempt.component.html',
    styleUrls: ['./account-last-attempt.component.scss']
})
export class AccountLastAttemptComponent implements OnInit {

    @Input() attempts: AuthenticationAttempt[];

    constructor() {
    }

    ngOnInit() {
    }

}
