<div fxFlex="100" fxLayout="column">
    <div class="header" fxLayout="row">
        <div fxFlex="90">
            <div class="title" fxLayout="row" fxFlex="100">
                <h4 i18n="@@h4-teams" fxFlex="20">Les équipes</h4>
                <p-dropdown fxFlex="80" *ngIf="addMode" [(ngModel)]="newTeam"
                            (onChange)="changeTeam($event)"
                            [options]="availableTeams"
                            [style]="{'width':'300px'}"
                            placeholder="Ajouter une équipe"
                            class="form-input"
                            optionLabel="name"
                ></p-dropdown>
            </div>
        </div>
        <div class="actions" fxFlex="20" fxLayoutAlign="left center">
            <div fxLayoutAlign="center center" class="button-i delete" *ngIf="normalMode && !hasNoTeams()"
                 (click)="passRemoveMode()">
                <i class="material-icons">delete</i>
            </div>
            <div fxLayoutAlign="center center" class="button-i cancel" *ngIf="showCancel()" (click)="cancel()">
                <i class="material-icons">cancel</i>
            </div>
            <div fxLayoutAlign="center center" class="button-i" *ngIf="showCancel()" (click)="saveTeams()">
                <i class="material-icons">save</i>
            </div>
            <div fxLayoutAlign="center center" class="button-i" *ngIf="normalMode" (click)="passAddMode()">
                <i class="material-icons">add</i>
            </div>
        </div>
    </div>
    <div fxLayoutWrap fxLayout="row" fxFlex="100">
        <div fxFlex="100" *ngIf="hasNoTeams()" fxLayoutAlign="center center">
            <div>Aucune équipe</div>
        </div>
        <!--<app-team-item class="card" fxFlex="23" [team]="team"-->
                       <!--[mode]="modeTeamItem"-->
                       <!--[removeMode]="removeMode"-->
                       <!--(removeModeHandler)="removeTeam($event)"-->
                       <!--*ngFor="let team of teams"></app-team-item>-->
    </div>
</div>