import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Role} from '@genos/user-service';
 
@Component({
    selector: 'app-role-line',
    templateUrl: './role-line.component.html',
    styleUrls: ['./role-line.component.scss']
})
export class RoleLineComponent implements OnInit {

    @Input() role: Role;
    @Output() onEdit: EventEmitter<Role> = new EventEmitter<Role>();
    @Output() onAccreditation: EventEmitter<Role> = new EventEmitter<Role>();

    constructor() {
    }

    ngOnInit() {
    }

    clickOnEdit(): void {
        this.onEdit.emit(this.role);
    }

    clickOnAccreditation(): void {

        this.onAccreditation.emit(this.role);
    }

}
