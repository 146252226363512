import {TrainingTemplateStatus} from '../enums/training-template-status.enum';

import {TrainingTemplateForm} from './training-template-form';
import {ExcludePeriod} from './exclude-period';
import {EventVisibility} from '../../../events/enums/event-visibility.enum';
import {isNullOrUndefined} from 'util';
import {Buildable, CanAttach, Dto, FormCompliant, Periodicity} from '@mdw/usual-service';
import {environment} from '../../../../environments/environment';
import {Group} from '../../group/models/group';

export class TrainingTemplate extends Dto implements Buildable<TrainingTemplate>,
    FormCompliant<TrainingTemplate, TrainingTemplateForm>, CanAttach {


    name: string;
    status: TrainingTemplateStatus;
    day: number;
    place: number;
    endTime: string;
    startTime: string;
    image: string;
    sport: string;
    gender: string;
    category: string;
    openNewPersons: boolean;
    visibility: EventVisibility;
    periodicity: Periodicity;
    description: string;
    groups: Group[];
    excludePeriods: ExcludePeriod[];

    typeTo: string;

    constructor() {
        super();
        this.typeTo = 'TRAINING_TEMPLATE';
        this.startTime = '00:00';
        this.endTime = '00:00';
        this.openNewPersons = false;
        this.image = environment.imageDefaultTrainingTemplate;
    }

    build(): TrainingTemplate {
        super.build();
        this.excludePeriods = this.excludePeriods.map(ep => Object.assign(new ExcludePeriod(), ep).build());
        return this;
    }

    toForm(): TrainingTemplateForm {
        const f = new TrainingTemplateForm();
        f.name = this.name;
        f.openNewPersons = this.openNewPersons;
        f.day = {value: this.day};
        f.startTime = this.startTime;
        f.endTime = this.endTime;
        f.periodicity = {value: this.periodicity};
        f.visibility = {value: this.visibility};
        f.sport = {value: this.sport};
        f.category = {value: this.category};
        f.place = this.place;
        f.gender = {value: this.gender};
        f.status = {value: this.status};
        f.description = this.description;
        return f;
    }

    fromForm(f: TrainingTemplateForm): TrainingTemplate {
        this.name = f.name;
        this.status = f.status.value;
        this.openNewPersons = f.openNewPersons;
        this.day = f.day.value;
        this.startTime = f.startTime;
        this.endTime = f.endTime;
        this.description = f.description;
        this.visibility = f.visibility.value;
        if (!isNullOrUndefined(f.place)) {
            this.place = f.place.id;
        }
        this.periodicity = f.periodicity.value;
        this.sport = f.sport.value;
        this.category = f.category.value;
        this.gender = f.gender.value;
        return this;
    }
}
