import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContestsComponent} from './contests/contests.component';
import {ContestComponent} from './contest/contest.component';
import {ContestRoutes} from './contest.routes';
import {RouterModule} from '@angular/router';
import {ContestsService} from './services/contests.service';
import {
    CalendarModule, ConfirmDialogModule, DataTableModule, DropdownModule, FileUploadModule, InputTextareaModule,
    InputTextModule, PanelModule, ProgressSpinnerModule, TabViewModule
} from 'primeng/primeng';
import {FormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {LeaderboardModule} from '../leaderboard/leaderboard.module';
import {TeamModule} from '../team/team.module';
import {ContestTeamsComponent} from './contest-teams/contest-teams.component';
import {GameModule} from '../game/game.module';
import {ContestGamesComponent} from './contest-games/contest-games.component';
import {ContestGameItemComponent} from './contest-game-item/contest-game-item.component';
import {ContestGameItemEditComponent} from './contest-game-item-edit/contest-game-item-edit.component';
import {MdwUsualServiceModule} from '@mdw/usual-service';

@NgModule({
    imports: [
        TeamModule,
        GameModule,
        LeaderboardModule,
        CommonModule,
        MdwUsualServiceModule,
        DataTableModule,
        DropdownModule,
        ConfirmDialogModule,
        PanelModule,
        InputTextModule,
        InputTextareaModule,
        TabViewModule,
        CalendarModule,
        FlexLayoutModule,
        FileUploadModule,
        FormsModule,
        ProgressSpinnerModule,
        RouterModule.forChild(ContestRoutes)
    ],
    declarations: [ContestsComponent, ContestComponent, ContestTeamsComponent,
        ContestGamesComponent, ContestGameItemComponent, ContestGameItemEditComponent],
    providers: [ContestsService]
})
export class ContestModule {
}
