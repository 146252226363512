<div class="leaderboard" fxLayout="column">
    <div fxLayout="row" class="leaderboard-header">
        <div class="leaderboard-header-name " fxFlex="30" fxFlex.gt-xs="40">Équipe</div>
        <div class="leaderboard-header-label" fxFlex="20" fxFlex.gt-xs="10" *ngIf="isAverage()">Moyenne</div>
        <div class="leaderboard-header-label" fxFlex="20" fxFlex.gt-xs="10" *ngIf="isPoints()">Points</div>
        <div class="leaderboard-header-label" fxFlex="20" fxFlex.gt-xs="10">Victoires</div>
        <div class="leaderboard-header-label" fxFlex="20" fxFlex.gt-xs="10">Nul</div>
        <div class="leaderboard-header-label" fxFlex="20" fxFlex.gt-xs="10">Défaites</div>
        <div class="leaderboard-header-label" fxFlex="20" fxFlex.gt-xs="10">Joués</div>
        <div class="leaderboard-header-label" hide.xs="true" fxFlex="10">Restant</div>
        <div class="leaderboard-header-label" hide.xs="true" fxFlex="10">Pénalités</div>
    </div>
    <div fxLayout="row" class="leaderboard-row"
         *ngFor="let line of leaderboard.lines; first as isFirst; last as isLast;">
        <div class="leaderboard-team" fxFlex="30" fxFlex-gt-xs="30"
             fxLayout="row">
            <div *ngIf="hasNoTeams()">
                Aucune équipes
            </div>
            <div *ngIf="!hasNoTeams()" fxFlex="30">
                <i class="material-icons" (click)="up(line)" *ngIf="!isFirst">arrow_upward</i>
                <i class="material-icons" (click)="down(line)" *ngIf="!isLast">arrow_downward</i>
            </div>
            <p-dropdown fxFlex="70"
                        *ngIf="!hasNoTeams()"
                        [options]="availableTeams"
                        [style]="{'width':'150px'}"
                        [(ngModel)]="line.team"
                        appendTo="body"
                        placeholder="Sélectionner une équipe"
                        class="form-input"
                        optionLabel="name"
            ></p-dropdown>
        </div>
        <div class="leaderboard-header-label" fxFlex="20" fxFlex.gt-xs="10" *ngIf="isPoints()">
            <input type="number" [(ngModel)]="line.points" name="points"/>
        </div>
        <div class="leaderboard-header-label" fxFlex="20" fxFlex.gt-xs="10" *ngIf="isAverage()">
            <input type="number" [(ngModel)]="line.average" name="average"/>
        </div>
        <div class="leaderboard-header-label" fxFlex="20" fxFlex.gt-xs="10">
            <input type="number" [(ngModel)]="line.victory" name="victory"/>
        </div>
        <div class="leaderboard-header-label" fxFlex="20" fxFlex.gt-xs="10">
            <input type="number" [(ngModel)]="line.exaequo" name="exaequo"/>
        </div>
        <div class="leaderboard-header-label" fxFlex="20" fxFlex.gt-xs="10">
            <input type="number" [(ngModel)]="line.defeate" name="defeate"/>
        </div>
        <div class="leaderboard-header-label" fxFlex="20" fxFlex.gt-xs="10">
            <input type="number" [(ngModel)]="line.gamePlayed" name="gamePlayed"/>
        </div>
        <div class="leaderboard-header-label" hide.xs="true" fxFlex="10">
            <input type="number" [(ngModel)]="line.gameLeftToPlay" name="gameLeftToPlay"/>
        </div>
        <div class="leaderboard-header-label" hide.xs="true" fxFlex="10">
            <input type="number" [(ngModel)]="line.penality" name="penality"/>
        </div>
    </div>
    <div class="add-action">
        <i class="material-icons" (click)="addLine()">add</i>

    </div>
</div>