import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RolesListComponent} from './roles-list/roles-list.component';
import {
    ButtonModule, ContextMenuModule, DataListModule, DialogModule, InputTextareaModule, InputTextModule,
    ProgressSpinnerModule, TreeModule
} from 'primeng/primeng';
import {FormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {RoleLineComponent} from './role-line/role-line.component';
import {RoleEditComponent} from './role-edit/role-edit.component';
import {RightsModule} from '../rights/rights.module';
import {MonitoringModule} from '../../mdw/monitoring/monitoring.module';
import {RolesService} from '@genos/user-service';

@NgModule({
    imports: [
        CommonModule,
        ContextMenuModule,
        DialogModule,
        ButtonModule,
        InputTextareaModule,
        InputTextModule,
        FormsModule,
        FlexLayoutModule,
        ProgressSpinnerModule,
        DataListModule,
        MonitoringModule,
        TreeModule,
        RightsModule
    ],
    declarations: [RolesListComponent, RoleLineComponent, RoleEditComponent],
    providers: [RolesService]
})
export class RolesModule {
}
