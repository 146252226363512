import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GroupsService} from './services/groups.service';
import {
    ButtonModule,
    ConfirmDialogModule,
    DataTableModule,
    DropdownModule,
    InputTextareaModule,
    InputTextModule,
    PanelModule,
    PickListModule,
    ProgressSpinnerModule,
    TabViewModule
} from 'primeng/primeng';
import {FlexLayoutModule} from '@angular/flex-layout';
import {GroupsComponent} from './groups/groups.component';
import {RouterModule} from '@angular/router';
import {GroupRoutes} from './group.routes';
import {GroupComponent} from './group/group.component';
import {FormsModule} from '@angular/forms';
import {AttachGroupsComponent} from './attach-groups/attach-groups.component';
import {AttachGroupItemComponent} from './attach-group-item/attach-group-item.component';
import {MyGroupsComponent} from './my-groups/my-groups.component';
import {GroupDisplayMembersComponent} from './group-display-members/group-display-members.component';
import {TalkModule} from '../../mdw/talk/talk.module';
import {PersonsModule} from '../../persons/persons.module';


@NgModule({
    imports: [
        TalkModule,
        CommonModule,
        PersonsModule,
        FlexLayoutModule,
        PanelModule,
        InputTextModule,
        TabViewModule,
        ConfirmDialogModule,
        ButtonModule,
        InputTextareaModule,
        DropdownModule,
        ProgressSpinnerModule,
        FormsModule,
        DataTableModule,
        PickListModule,
        RouterModule.forChild(GroupRoutes)
    ],
    declarations: [GroupsComponent, GroupComponent, AttachGroupsComponent,
        AttachGroupItemComponent, MyGroupsComponent, GroupDisplayMembersComponent],
    exports: [AttachGroupsComponent],
    providers: [GroupsService]
})
export class GroupModule {
}
