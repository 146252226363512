import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MainLoaderMode} from './main-loader-mode.enum';

@Component({
    selector: 'app-main-loader',
    templateUrl: './main-loader.component.html',
    styleUrls: ['./main-loader.component.scss']
})
export class MainLoaderComponent implements OnInit, OnChanges {

    @Input() mode: MainLoaderMode;
    @Input() errorMessage: string;

    showLoading = true
    showError = false

    constructor() {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.mode === MainLoaderMode.OFF) {
            this.showError = false;
            this.showLoading = false;
        }
        if (this.mode === MainLoaderMode.ON) {
            this.showError = false;
            this.showLoading = true;
        }
        if (this.mode === MainLoaderMode.ERROR) {
            this.showError = true;
            this.showLoading = false;
        }
    }


}
