import {EventAttendanceStatus} from '../enums/event-attendance-status.enum';
import {Buildable, Dto} from '@mdw/usual-service';
import {NaturalPerson} from '@genos/person-service';

export class EventAttendance extends Dto implements Buildable<EventAttendance> {

    event: any;
    person: NaturalPerson;
    status: EventAttendanceStatus;
    comment: string;
    fromType: string;
    fromId: number;
    type: string;


    constructor() {
        super();
        this.status = EventAttendanceStatus.NOT_ANSWERED;
    }

    build(): EventAttendance {
        super.build();
        this.person = Object.assign(new NaturalPerson(), this.person).build();
        return this;
    }

    isAvailable(): boolean {
        return this.status === EventAttendanceStatus.AVAILABLE;
    }

    isToBeConfirm(): boolean {
        return this.status === EventAttendanceStatus.TO_BE_CONFIRM;
    }

    isNotAnswered(): boolean {
        return this.status === EventAttendanceStatus.NOT_ANSWERED;
    }

    isNotAvailable(): boolean {
        return this.status === EventAttendanceStatus.NOT_AVAILABLE;
    }

}
