import {Injectable} from '@angular/core';
import {MenuItem} from 'primeng/primeng';
import {Subject} from 'rxjs';
import {Observable} from 'rxjs';

@Injectable()
export class ContextMenuAppService {

    private itemsSource = new Subject<MenuItem[]>();

    public getSubscriber(): Observable<MenuItem[]> {
        return this.itemsSource.asObservable();
    }

    constructor() {
    }

    public setContextMenu(contextMenu: MenuItem[]): void {
        this.itemsSource.next(contextMenu);
    }

}
