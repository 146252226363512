<div>
    <mat-toolbar color="primary" class="top-bar">
        <mat-toolbar-row class="toolbar-row-mobile" fxLayoutAlign="space-between center" *ngIf="isMobile">
            <div fxFlex="20" *ngIf="showQuit" (click)="quitApp()">
                <mat-icon>close</mat-icon>
            </div>
            <div fxFlex="20" *ngIf="showBurgerMenu" (click)="showMenuFullScreen.emit()">
                <mat-icon>menu</mat-icon>
            </div>
            <div fxFlex="20" *ngIf="showBackButton" (click)="goBack()">
                <mat-icon>arrow_back</mat-icon>
            </div>
            <div fxFlex="100" *ngIf="showLogo" fxLayoutAlign="center center">
                <img class="logo" src="assets/layout/images/logo-white.png">
            </div>
            <div fxFlex="20" *ngIf="showAvatar" fxLayoutAlign="center center">
                <img class="avatar" [src]="avatarUrl">
            </div>
            <div fxFlex="80" class="title" *ngIf="showTitle" fxLayoutAlign="start center">
                {{headerToolbarTitle}}
            </div>
            <div fxFlex="45" class="link-to" *ngIf="showLinkTo" fxLayout="row" fxLayoutAlign="end center"
                 (click)="goToLink()">
                <div>{{titleLinkTo}}</div>
            </div>
            <div class="actions-icon" fxFlex="20" fxLayoutAlign="end center" fxLayout="row"
                 *ngIf="isLoggedIn && showNotification">
                <mat-icon [matBadge]="notificationCount" *ngIf="!showSideNotification && notificationCount !== 0"
                          matBadgeColor="accent"
                          (click)="toggleNotifications.emit()" matBadgeSize="medium">
                    notifications
                </mat-icon>
                <mat-icon *ngIf="!showSideNotification  && notificationCount === 0"
                          (click)="toggleNotifications.emit()" matBadgeSize="medium">
                    notifications
                </mat-icon>
                <mat-icon *ngIf="showSideNotification" (click)="toggleNotifications.emit()">
                    close
                </mat-icon>
            </div>
        </mat-toolbar-row>
        <mat-toolbar-row *ngIf="!isMobile" fxLayout="row" fxLayoutAlign="space-between center">
            <div fxFlex="20" *ngIf="showBackButton  && !isLoggedIn" (click)="goBack()">
                <mat-icon>arrow_back</mat-icon>
            </div>
            <div fxFlex="10" *ngIf="showLogo" fxLayoutAlign="center center">
                <img class="logo" src="assets/layout/images/logo-white.png">
            </div>
            <div fxFlex="80" class="title" fxLayoutAlign="start center">
                GENOS : L'ADN des clubs sportifs
            </div>
            <div fxFlex="45" class="link-to" *ngIf="showLinkTo && !isLoggedIn" fxLayout="row" fxLayoutAlign="end center"
                 (click)="goToLink()">
                <div>{{titleLinkTo}}</div>
            </div>
            <div class="actions-icon" fxFlex="20" fxLayoutAlign="end center" fxLayout="row" *ngIf="isLoggedIn">
                <mat-icon *ngIf="!showSideMessage && notificationTalkCount === 0" (click)="toggleMessages.emit()">
                    message
                </mat-icon>
                <mat-icon [matBadge]="notificationTalkCount"
                          *ngIf="!showSideMessage && notificationTalkCount > 0" matBadgeColor="accent"
                          (click)="toggleMessages.emit()">message
                </mat-icon>
                <mat-icon *ngIf="showSideMessage" (click)="toggleMessages.emit()">close</mat-icon>
                <mat-icon [matBadge]="notificationCount" *ngIf="!showSideNotification" matBadgeColor="accent"
                          (click)="toggleNotifications.emit()" matBadgeSize="medium">
                    notifications
                </mat-icon>
                <mat-icon *ngIf="showSideNotification" (click)="toggleNotifications.emit()"
                          matBadgeSize="medium">
                    close
                </mat-icon>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
    <mat-progress-bar *ngIf="showProgress" mode="determinate" [value]="partProgress" color="accent"></mat-progress-bar>
</div>