import {CommonModule} from '@angular/common';
import {
    MatBadgeModule,
    MatBottomSheetModule,
    MatIconModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatToolbarModule
} from '@angular/material';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MdwUsualServiceModule} from '@mdw/usual-service';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {GenosModule} from '../genos/genos.module';
import {NotificationListComponent} from './notification-list/notification-list.component';
import {NotificationListPageComponent} from './notification-list-page/notification-list-page.component';
import {NotificationsService} from './services/notifications.service';
import {NotificationRequestComponent} from './notification-request/notification-request.component';

@NgModule({
    imports: [
        CommonModule,
        GenosModule,
        FormsModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatBadgeModule,
        MatIconModule,
        MatToolbarModule,
        FlexLayoutModule,
        MdwUsualServiceModule,
        MatBottomSheetModule
    ],
    declarations: [NotificationListComponent, NotificationListPageComponent, NotificationRequestComponent],
    exports: [NotificationListComponent, NotificationRequestComponent],
    providers: [NotificationsService],
    entryComponents: []
})
export class NotificationsModule {
}
