import {Component, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/primeng';
import {Router} from '@angular/router';
import {TrainingTemplate} from '../models/training-template';
import {TrainingTemplatesFilter} from '../models/training-templates-filter';
import {TrainingTemplatesService} from '../services/training-templates.service';
import {BreadcrumbService} from '../../../global/services/breadcrumb.service';
import {ContextMenuAppService} from '../../../global/services/context-menu-app.service';

@Component({
    selector: 'app-trainings-templates',
    templateUrl: './trainings-templates.component.html',
    styleUrls: ['./trainings-templates.component.scss']
})
export class TrainingsTemplatesComponent implements OnInit {

    templates: TrainingTemplate[];
    selectedTrainingTemplate: TrainingTemplate;
    loadTrainingTemplate: boolean;

    constructor(private breadcrumbService: BreadcrumbService,
                private contextMenuAppService: ContextMenuAppService,
                private router: Router,
                private trainingTemplatesService: TrainingTemplatesService) {

    }

    ngOnInit() {
        this.templates = [];
        this.breadcrumbService.setItems([{label: 'Templates d\'entrainements'}]);
        this.loadTrainingTemplate = true;
        this.trainingTemplatesService.getAll(new TrainingTemplatesFilter()).subscribe(templates => {
            this.templates = templates;
            this.loadTrainingTemplate = false;
        });

        this.contextMenuAppService.setContextMenu(this.buildContextMenu());

    }

    onRowSelect($event) {
        this.router.navigate(['/trainings-templates', this.selectedTrainingTemplate.id]);
    }

    private buildContextMenu(): MenuItem[] {
        const items = [];
        items.push({
            label: 'Ajouter un template',
            icon: 'fa-plus',
            command: (event) => {
                this.router.navigate(['/trainings-templates/0']);
            }
        });
        return items;
    }
}
