import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LicenceMember} from '../models/licence-member';
import {Observable} from 'rxjs';
import {LicencesMemberFilter} from '../models/licences-member-filter';
import {Subject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {DtoSecurisedService, GlobalSessionService} from '@genos/user-service';
import {AppConfigService} from '@mdw/usual-service';

@Injectable()
export class LicencesMembersService extends DtoSecurisedService<LicenceMember> {


    constructor(protected sessionService: GlobalSessionService,
                protected client: HttpClient,
                private appConfigService: AppConfigService) {
        super(client, sessionService);
    }

    getBaseUrl(): string {
        return this.appConfigService.getSecuredBaseUrl() + '/licences/members';
    }

    update(t: LicenceMember): Observable<LicenceMember> {
        return undefined;
    }

    create(t: LicenceMember): Observable<LicenceMember> {
        return undefined;
    }

    getAll(filter: LicencesMemberFilter): Observable<LicenceMember[]> {
        return this.getArray(this.getBaseUrl() + filter.toUrl(), {}, LicenceMember);
    }

    getCurrentByPerson(idPerson: number): Observable<LicenceMember> {
        const s = new Subject<LicenceMember>();
        this.getAll(new LicencesMemberFilter().withMember(idPerson).withPeriod(environment.currentYear)).subscribe(licences => {
            if (licences.length === 0) {
                s.next(null);
            } else if (licences.length === 1) {
                s.next(licences[0]);
            } else {
                s.error({});
            }
        });
        return s.asObservable();
    }
}
