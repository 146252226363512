import {Injectable} from '@angular/core';
import {TrainingTemplate} from '../models/training-template';
import {HttpClient} from '@angular/common/http';
import {TrainingTemplatesFilter} from '../models/training-templates-filter';
import {Observable} from 'rxjs';
import {AppConfigService, CanAttach} from '@mdw/usual-service';
import {DtoSecurisedService, GlobalSessionService} from '@genos/user-service';

@Injectable()
export class TrainingTemplatesService extends DtoSecurisedService<TrainingTemplate> {

    getBaseUrl(): string {
        return this.appConfigService.getSecuredBaseUrl() + '/trainings/templates';
    }

    constructor(protected sessionService: GlobalSessionService,
                protected client: HttpClient,
                protected appConfigService: AppConfigService) {
        super(client, sessionService);
    }

    getById(id: number): Observable<TrainingTemplate> {
        return this.getObject(this.getBaseUrl() + '/' + id, {}, TrainingTemplate);
    }

    getAll(filter: TrainingTemplatesFilter): Observable<TrainingTemplate[]> {
        return this.getArray(this.getBaseUrl() + filter.toUrl(), {}, TrainingTemplate);
    }

    update(t: TrainingTemplate): Observable<TrainingTemplate> {
        return this.put<TrainingTemplate>(this.getBaseUrl() + '/' + t.id, t);
    }

    create(t: TrainingTemplate): Observable<TrainingTemplate> {
        return this.post<TrainingTemplate>(this.getBaseUrl(), t);
    }

    updateForGroups(t: CanAttach): Observable<TrainingTemplate> {
        return this.put<TrainingTemplate>(this.getBaseUrl() + '/' + t.id, t);
    }


}
