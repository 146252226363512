import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FooterComponent} from './footer/footer.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {HeaderComponent} from './header/header.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {
    MatBadgeModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatToolbarModule
} from '@angular/material';
import {
    ButtonModule,
    CaptchaModule,
    GrowlModule,
    InputTextareaModule,
    InputTextModule,
    MenubarModule,
    ProgressSpinnerModule
} from 'primeng/primeng';
import {MenuComponent} from './menu/menu.component';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {SubMenuComponent} from './sub-menu/sub-menu.component';
import {LogoutComponent} from './logout/logout.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MessageService} from './services/message.service';
import {NotificationsTopComponent} from './notifications-top/notifications-top.component';
import {NotificationIconPipe} from './pipes/notification-icon.pipe';
import {AppConfigService, MdwUsualServiceModule} from '@mdw/usual-service';
import {ContextMenuAppService} from './services/context-menu-app.service';
import {GenosAppConfigService} from './services/genos-app-config.service';
import {AuthenticationService, MeService, SessionService, UploadFileService} from '@genos/user-service';
import {MenuService} from './services/menu.service';
import {GenosRightGuard} from './services/genos-right.guard';
import {RightGuard} from '../genos/guards/right.guard';
import {NoAuthGuard} from '../genos/guards/no-auth.guard';
import {GlobalAuthGuard} from '../genos/guards/global-auth.guard';
import {FormSubmitComponent} from './form-submit/form-submit.component';
import {MainLoaderComponent} from './main-loader/main-loader.component';
import {NoResultComponent} from './no-result/no-result.component';
import {AddItemComponent} from './add-item/add-item.component';
import {InputTextComponent} from './input-text/input-text.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        FlexLayoutModule,
        MdwUsualServiceModule,
        MenubarModule,
        InputTextModule,
        MatToolbarModule,
        MatButtonModule,
        MatBadgeModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatFormFieldModule,
        MatIconModule,
        ReactiveFormsModule,
        ButtonModule,
        CaptchaModule,
        ProgressSpinnerModule,
        InputTextareaModule,
        GrowlModule
    ],
    declarations: [
        NotificationIconPipe,
        FooterComponent,
        NotFoundComponent,
        HeaderComponent,
        MenuComponent,
        SubMenuComponent,
        BreadcrumbComponent,
        LogoutComponent,
        NotificationsTopComponent,
        FormSubmitComponent,
        MainLoaderComponent,
        NoResultComponent,
        AddItemComponent,
        InputTextComponent,
    ],
    exports: [HeaderComponent,
        FooterComponent,
        MenuComponent,
        BreadcrumbComponent,
        FormSubmitComponent,
        MainLoaderComponent,
        NoResultComponent,
        AddItemComponent,
        InputTextComponent
    ],
    providers: [MessageService,
        NotificationIconPipe,
        SessionService,
        MeService,
        UploadFileService,
        {provide: AppConfigService, useClass: GenosAppConfigService},
        {provide: RightGuard, useClass: GenosRightGuard},
        GlobalAuthGuard,
        NoAuthGuard,
        AuthenticationService,
        MenuService,
        ContextMenuAppService]
})
export class GlobalModule {
}
