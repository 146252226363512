import {Component, OnInit, ViewChild} from '@angular/core';
import {ConfirmationService} from 'primeng/primeng';
import {RightsToAttachComponent} from '../rights-to-attach/rights-to-attach.component';
import {Context} from '@mdw/usual-service';
import {Account, AccountProjection, AccountService, Role, RolesService} from '@genos/user-service';
import {MessageService} from '../../../global/services/message.service';
import {BreadcrumbService} from '../../../global/services/breadcrumb.service';

@Component({
    selector: 'app-rights-accounts-list',
    templateUrl: './rights-accounts-list.component.html',
    styleUrls: ['./rights-accounts-list.component.scss'],
    providers: [ConfirmationService]
})
export class RightsAccountsListComponent implements OnInit {

    @ViewChild(RightsToAttachComponent) accreditations: RightsToAttachComponent;

    accounts: Account[];
    roles: Role[];
    selectedRole: Role;
    loadGetAll: boolean;
    loadAdd: boolean;
    showAccreditation: boolean;
    showAttachDialog: boolean;
    selectedAccount: Account;
    removeRole: Role;

    constructor(private messageService: MessageService,
                private breadcrumbService: BreadcrumbService,
                private confirmationService: ConfirmationService,
                private roleService: RolesService,
                private accountService: AccountService) {
        this.breadcrumbService.setAccreditationChild({label: 'Comptes'});
    }

    ngOnInit() {
        this.accounts = [];
        this.load();
    }

    load() {
        this.selectedAccount = new Account();
        this.roleService.getAll().subscribe(roles => {
            this.roles = roles;
        });
        this.loadGetAll = true;
        const projection = new AccountProjection().withRoles(true).withRolesIdTo(true);
        this.accountService.getAll(projection).subscribe(accounts => {
            this.accounts = accounts;
            this.loadGetAll = false;
        }, error => {
            this.loadGetAll = false;
        });
    }


    clickOnChip(role, account) {
        this.removeRole = role;
        this.selectedAccount = account;
        this.confirmationService.confirm({
            message: this.getSentenceConfirm(),
            accept: () => {
                this.roleService.detachTo(this.removeRole.idRoleTo).subscribe(response => {
                    this.messageService.addSuccess('Suppresion du role réussi', '');
                    this.showAccreditation = false;
                    this.load();
                });
            }
        });
    }

    getSentenceConfirm(): string {
        let header = 'Voulez vous supprimer le role ';
        if (this.removeRole) {
            header += this.removeRole.name + ' pour le compte ';
            header += this.selectedAccount.email;
        }
        return header;
    }

    clickOnAttachRole(account: Account): void {
        this.selectedAccount = account;
        this.selectedRole = new Role();
        this.showAttachDialog = true;
        this.showAccreditation = false;
    }

    attachRole(role, account): void {
        this.loadAdd = true;
        this.roleService.attachTo(role, account, new Context()).subscribe(response => {
            this.messageService.addSuccess('Ajout du role réussi', '');
            this.load();
            this.loadAdd = false;
            this.showAttachDialog = false;
        }, error => {
            this.loadAdd = false;
            this.showAttachDialog = false;
        });
    }

    accreditationToAccount(account) {
        this.showAccreditation = false;
        if (this.selectedAccount.id === account.id) {
            this.showAccreditation = false;
            this.selectedAccount = new Account();
        } else {
            this.showAccreditation = false;
            this.selectedAccount = account;
            if (this.accreditations) {
                this.accreditations.reload(this.selectedAccount);
            }
            this.showAccreditation = true;
        }
    }
}
