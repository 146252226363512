<div class="content-card">
    <div class="form-group card card-w-title" fxFlex="100" fxLayout="column">
        <h4 i18n="@@h4-update-role">Modification d'un role</h4>
        <div fxFlex="100">
            <span class="md-inputfield" fxLayout="column">
                <input type="text" [(ngModel)]="role.name" pInputText>
                <label i18n="@@label-role-name">Nom</label>
                <div *ngIf="errorName" class="ui-message ui-messages-error ui-corner-all">
                            This field is invalid
                </div>
            </span>
        </div>
        <div fxFlex="100">
            <span class="md-inputfield" fxLayout="column">
                <input type="text" [(ngModel)]="role.label" pInputText>
                <label i18n="@@label-role-label">Libellé</label>
                <div *ngIf="errorLabel" class="ui-message ui-messages-error ui-corner-all">
                            This field is invalid
                </div>
            </span>
        </div>
        <div fxFlex="100">
            <span class="md-inputfield" fxLayout="column">
                <textarea [rows]="5" [cols]="30" [(ngModel)]="role.description" pInputTextarea
                          autoResize="autoResize" i18n-placeholder="@@label-role-description"
                          placeholder="Description"></textarea>
                <div *ngIf="errorDescription" class="ui-message ui-messages-error ui-corner-all">
                            This field is invalid
                </div>
            </span>
        </div>
        <div fxFlex="100" fxLayout="row" class="buttons">
            <button [disabled]="lockButtons()" type="button" pButton icon="fa-delete" class="ui-button-danger"
                    (click)="delete()"
                    i18n-label="@@button-delete"
                    label="Supprimer" fxFlexAlign="left"></button>
            <button [disabled]="lockButtons()" type="button" pButton icon="fa-close" class="ui-button-warning"
                    (click)="cancel()"
                    i18n-label="@@button-cancel"
                    label="Annuler" fxFlexAlign="right"></button>
            <button [disabled]="lockButtons()" type="button" pButton icon="fa-check" class="ui-button-primary"
                    (click)="update()"
                    i18n-label="@@button-update"
                    label="Modifier" fxFlexAlign="right"></button>
        </div>
    </div>

</div>