import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TeamsComponent} from './teams/teams.component';
import {TeamComponent} from './team/team.component';
import {RouterModule} from '@angular/router';
import {TeamRoutes} from './team.routes';
import {TeamsService} from './services/teams.service';
import {
    AutoCompleteModule,
    DropdownModule,
    FileUploadModule,
    InputTextareaModule,
    InputTextModule,
    PanelModule,
    ProgressSpinnerModule,
    RadioButtonModule
} from 'primeng/primeng';
import {FormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {GroupModule} from '../group/group.module';
import {RosterComponent} from './roster/roster.component';
import {RostersComponent} from './rosters/rosters.component';
import {SportPersonsService} from './services/sport-persons.service';
import {RosterMembersComponent} from './roster-members/roster-members.component';
import {RosterMemberItemComponent} from './roster-member-item/roster-member-item.component';
import {ConfigurationModule} from '../configuration/configuration.module';
import {PersonsModule} from '../../persons/persons.module';

@NgModule({
    imports: [
        GroupModule,
        PersonsModule,
        ConfigurationModule,
        CommonModule,
        ProgressSpinnerModule,
        FileUploadModule,
        PanelModule,
        InputTextModule,
        InputTextareaModule,
        FormsModule,
        FlexLayoutModule,
        RadioButtonModule,
        DropdownModule,
        AutoCompleteModule,
        RouterModule.forChild(TeamRoutes)
    ],
    declarations: [TeamsComponent,
        TeamComponent,
        RosterComponent,
        RostersComponent,
        RosterMembersComponent,
        RosterMemberItemComponent],
    exports: [],
    providers: [TeamsService, SportPersonsService]
})
export class TeamModule {
}
