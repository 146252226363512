import {Component, Host, HostBinding, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-organizations-home',
    templateUrl: './organizations-home.component.html',
    styleUrls: ['./organizations-home.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OrganizationsHomeComponent implements OnInit {
    @HostBinding('class.default-page') someClass: Host = true;

    constructor() {
    }

    ngOnInit() {
    }

}
