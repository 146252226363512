import {UriWithName} from '@mdw/usual-service';
import {PlaceStatus} from '../enums/place-status';

export class PlaceView extends UriWithName {

    googlePlaceId: string;
    latitude: number;
    longitude: number;
    status: PlaceStatus;
    description: string;
    address: string;
    zipCode: string;
    country: string;
    city: string;
    howTo: string;

    fullAddress: string;

    constructor() {
        super();
    }

    build(): this {
        this.fullAddress = this.address;
        return this;
    }
}
