<div class="loader" fxLayout="row" *ngIf="loadOption" fxLayoutAlign="center center">
    <p-progressSpinner></p-progressSpinner>
</div>
<div fxLayout="column" class="card genos-form game-edit" fxFlex="40" *ngIf="!loadOption">
    <div fxLayout="colunm">
        <h3 fxFlex="70">{{game.title}}</h3>
        <div fxFlex="30" fxLayoutAlign="end center" *ngIf="!isNew()">
            <div appNeedRight="UPDATE_EVENT" fxLayoutAlign="center center" class="button-i cancel"
                 (click)="passToNormalMode()">
                <i class="material-icons">cancel</i>
            </div>
        </div>
    </div>
    <h4>Date</h4>
    <div fxLayout="row">
        <p-calendar [locale]="locale" styleClass="form-input" [(ngModel)]="form.startDate"
                    dateFormat="dd/mm/yy"
                    (onSelect)="onSelectStartDate()"
                    [showIcon]="true"></p-calendar>
        <div class="ui-float-label line" fxLayout="column">
            <input type="text" class="form-input"
                   [(ngModel)]="form.startTime"
                   mdwTimeStringInput
                   maxlength="5"
                   pInputText id="startTime">
            <label for="startTime" i18n="@@placeholder-game-name">Heure de début *</label>
        </div>
        <div class="ui-float-label line" fxLayout="column">
            <input type="text" class="form-input"
                   [(ngModel)]="form.endTime"
                   mdwTimeStringInput
                   maxlength="5"
                   pInputText id="endTime">
            <label for="endTime" i18n="@@placeholder-game-name">Heure de fin *</label>
        </div>
        <div class="ui-float-label line" fxLayout="column">
            <input type="text" class="form-input"
                   [(ngModel)]="form.reference"
                   pInputText id="reference">
            <label for="reference" i18n="@@placeholder-game-reference">Référence</label>
        </div>
    </div>
    <div *ngIf="showTypes()" fxLayout="column">
        <h4>Type</h4>
        <div fxLayout="row">
            <div fxLayout="row" class="teams">
                <p-dropdown [(ngModel)]="form.sport"
                            [options]="sports"
                            (onChange)="changeTeams()"
                            [style]="{'width':'150px'}"
                            placeholder="Définir un sport *"
                            class="form-input"
                            optionLabel="label"
                ></p-dropdown>
                <p-dropdown [(ngModel)]="form.gender"
                            [options]="genders"
                            (onChange)="changeTeams()"
                            [style]="{'width':'150px'}"
                            placeholder="Définir un genre *"
                            class="form-input"
                            optionLabel="label"
                ></p-dropdown>
                <p-dropdown [(ngModel)]="form.category"
                            [options]="categories"
                            (onChange)="changeTeams()"
                            [style]="{'width':'150px'}"
                            placeholder="Définir une categorie *"
                            class="form-input"
                            optionLabel="label"
                ></p-dropdown>
            </div>
        </div>
    </div>
    <h4>Equipes</h4>
    <div fxLayout="row" class="teams">
        <p-dropdown [(ngModel)]="form.insideTeam"
                    [options]="targetTeams"
                    (onChange)="changeVictoryTeam()"
                    [style]="{'width':'150px'}"
                    placeholder="Ajouter l’équipe domicile"
                    class="form-input"
                    optionLabel="name"
        ></p-dropdown>
        <div class="between" fxLayoutAlign="center center">versus</div>
        <p-dropdown [(ngModel)]="form.outsideTeam"
                    [options]="targetTeams"
                    (onChange)="changeVictoryTeam()"
                    [style]="{'width':'150px'}"
                    placeholder="Ajouter l’équipe extérieur"
                    class="form-input"
                    optionLabel="name"
        ></p-dropdown>
        <div class="between" fxLayoutAlign="center center">Vainqueur :</div>
        <p-dropdown [(ngModel)]="form.victoryTeam"
                    [options]="targetVictoryTeams"
                    [style]="{'width':'150px'}"
                    placeholder="Vainqueur"
                    class="form-input"
                    optionLabel="name"
        ></p-dropdown>
    </div>
    <div fxLayout="row">
        <div class="ui-float-label line" fxLayout="column">
            <input type="number" class="form-input"
                   [(ngModel)]="form.insideScore"
                   pInputText id="insideScore">
            <label for="insideScore" i18n="@@placeholder-game-insideScore">Score domicile</label>
        </div>
        <div class="ui-float-label line" fxLayout="column">
            <input type="number" class="form-input"
                   [(ngModel)]="form.outsideScore"
                   pInputText id="outsideScore">
            <label for="outsideScore" i18n="@@placeholder-game-outsideScore">Score extérieur</label>
        </div>

    </div>
    <div fxLayout="row">
        <p-dropdown [(ngModel)]="form.place" [options]="places"
                    [autoWidth]="false"
                    [showClear]="true"
                    i18n-placeholder="@@placeholder-select-input-game-place"
                    placeholder="Selectionnez un terrain"
                    optionLabel="name"
                    class="form-input"
                    [style]="{'width':'150px'}" appendTo="body"></p-dropdown>
        <p-dropdown [(ngModel)]="form.status" [options]="statutes"
                    [autoWidth]="false"
                    i18n-placeholder="@@placeholder-select-input-game-status"
                    placeholder="Selectionnez un statut"
                    optionLabel="name"
                    class="form-input"
                    [style]="{'width':'150px'}" appendTo="body"></p-dropdown>
    </div>
    <bsc-game-options [form]="form"></bsc-game-options>
    <div class="ui-float-label" fxLayout="column">
                    <textarea [rows]="5" [cols]="30" pInputTextarea
                              id="short-description"
                              [(ngModel)]="form.shortDescription"></textarea>
        <label for="short-description" i18n="@@placeholder-natural-team-short-description">Description publique</label>
    </div>
    <div class="ui-float-label" fxLayout="column">
        <textarea [rows]="5" [cols]="30" pInputTextarea
                  id="description"
                  [(ngModel)]="form.description"></textarea>
        <label for="description" i18n="@@placeholder-natural-team-description">Mot pour les joueur.e.s</label>
    </div>


    <div fxLayoutAlign="end center">
        <button pButton type="button" icon="fa-check"
                (click)="saveGame()"
                i18n-label="@@button-save" label="Sauvegarder"></button>
    </div>
</div>
<div fxFlex="60">
    <div *ngIf="!loadOption && !isNew()" fxFlex="100" fxLayout="column" class="form-group card card-w-title roster">
        <p-tabView [activeIndex]="1">
            <p-tabPanel header="Participants ({{game.attendances.length}})">
                <app-event-add-attendance [event]="game"
                                          type="GAME"
                                          [groups]="groupsAttendances"
                                          (onUpdate)="onUpdateAttendance($event)">
                </app-event-add-attendance>
                <app-event-attendance-list-view [event]="game"
                                                *ngIf="game.attendances.length > 0"
                                                (onUpdate)="onUpdateAttendance($event)"
                                                [mode]="modeAttendanceListView"></app-event-attendance-list-view>
                <div *ngIf="game.attendances.length === 0">
                    Aucun participants pour le moment
                </div>
            </p-tabPanel>
            <!--<p-tabPanel header="Périodes d'exclusions">-->
            <!--<app-exclude-periods [(template)]="template"></app-exclude-periods>-->
            <!--</p-tabPanel>-->
        </p-tabView>
    </div>
</div>