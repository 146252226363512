import {Injectable} from '@angular/core';
import {isNullOrUndefined} from 'util';
import {
    GlobalAuthenticationService,
    GlobalSessionService,
    GlobalUserSession,
    UserWithRightsView
} from '@genos/user-service';
import {NaturalPerson, PersonsService, PersonWithUserInfoView, UserPersonRelationship} from '@genos/person-service';
import {
    Organization,
    OrganizationService,
    OrganizationWithUserRelationshipView
} from '@genos/organization-service';
import {Observable, Subject} from 'rxjs';

@Injectable()
export class GlobalMeService {

    private personMainSource = new Subject<PersonWithUserInfoView>();


    session: GlobalUserSession;
    organizations: OrganizationWithUserRelationshipView[];
    persons: PersonWithUserInfoView[];
    selectedPerson: PersonWithUserInfoView;
    selectedOrganization: string;


    constructor(private sessionService: GlobalSessionService,
                private authenticationService: GlobalAuthenticationService,
                private organizationService: OrganizationService,
                private personsService: PersonsService) {
        this.organizations = [];
        this.persons = [];
    }

    public getPersonSubscriber(): Observable<PersonWithUserInfoView> {
        return this.personMainSource.asObservable();
    }

    public setMainPerson(person: PersonWithUserInfoView) {
        this.selectedPerson = person;
        this.personMainSource.next(person);
    }


    private justDoIt(data: any, doIt?: (value: any) => void) {
        if (!isNullOrUndefined(doIt)) {
            doIt(data);
        }
    }

    getUserUri(): string {
        return this.session.uri;
    }


    reload(force: boolean, doIt?: (value: UserWithRightsView) => void): GlobalMeService {
        if (isNullOrUndefined(this.session) || force) {
            this.sessionService.isLoggedIn();
            if (isNullOrUndefined(this.sessionService.getJWT().jwtRefresh.token)) {
                this.session = this.sessionService.getCurrentUser();
                this.justDoIt(this.session, doIt);
            } else {
                this.authenticationService.refresh(this.sessionService.getJWT().jwtRefresh.token).subscribe(r => {
                    this.session = this.sessionService.getCurrentUser();
                    this.justDoIt(this.session, doIt);
                }, error => {
                    console.error(error);
                    this.justDoIt(null, doIt);
                });
            }

        } else {
            this.justDoIt(this.session, doIt);
        }
        return this;
    }

    needOrganizations(force: boolean, doIt?: (value: Organization[]) => void): GlobalMeService {
        if (isNullOrUndefined(this.organizations) || force) {
            this.session = this.sessionService.getCurrentUser();
            this.organizationService.getByUser('me').subscribe(response => {
                this.organizations = response;
                if (response.length > 0) {
                    this.selectedOrganization = response[0].uri;
                }
                this.justDoIt(this.organizations, doIt);
            });
        } else {
            this.justDoIt(this.organizations, doIt);
        }
        return this;
    }

    needMainPerson(force: boolean, doIt?: (value: PersonWithUserInfoView) => void): GlobalMeService {
        if (this.persons.length === 0 || force) {
            if (this.session) {
                this.personsService.getAllByUser('me').subscribe(persons => {
                    if (persons.length > 0) {
                        this.selectedPerson = persons.filter(p => p.relationship === UserPersonRelationship.MAIN)[0];
                    }
                    this.persons = persons;
                    this.justDoIt(this.selectedPerson, doIt);
                });
            }
        } else {
            this.justDoIt(this.persons[0], doIt);
        }
        return this;
    }

    needAllPersons(force: boolean, doIt?: (value: PersonWithUserInfoView[]) => void): GlobalMeService {
        if (isNullOrUndefined(this.persons) || force) {
            // if (this.session) {
            //   this.naturalPersonsService.getAllNatural(new PersonsFilter().withIdAccount(this.session.id),
            //     new NaturalPersonProjection()).subscribe(persons => {
            //     this.persons = persons;
            //     this.justDoIt(persons, doIt);
            //   });
            // }
        } else {
            this.justDoIt(this.persons, doIt);
        }
        return this;
    }

}
