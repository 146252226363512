import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Contact} from '../models/contact';
import {GlobalSessionService} from '@genos/user-service';
import {AppConfigService, ResponseWithData} from '@mdw/usual-service';
import {Observable, Subject} from 'rxjs';
import {ContactType} from '../models/contact-type';

@Injectable()
export class ContactsService {

    constructor(protected client: HttpClient,
                protected sessionService: GlobalSessionService,
                private appConfigService: AppConfigService) {
    }

    getBaseUrl(): string {
        return this.appConfigService.getSecuredBaseUrl() + '/auth/api/users';
    }

    create(userUri: string, contact: Contact): Observable<Contact> {
        const s = new Subject<Contact>();
        this.client.post(this.getBaseUrl() + '/' + userUri + '/contacts', contact).subscribe(res => {
            console.log(res);
            s.next(Object.assign(new Contact(), res).build());
        }, error => s.error(error), () => s.complete());
        return s.asObservable();
    }

    update(userUri: string, contact: Contact): Observable<Contact> {
        const s = new Subject<Contact>();
        this.client.put(this.getBaseUrl() + '/' + userUri + '/contacts/' + contact.id, contact).subscribe(res => {
            console.log(res);
            s.next(Object.assign(new Contact(), res).build());
        }, error => s.error(error), () => s.complete());
        return s.asObservable();
    }

    remove(userUri: string, contact: Contact): Observable<Contact> {
        const s = new Subject<Contact>();
        this.client.delete(this.getBaseUrl() + '/' + userUri + '/contacts/' + contact.id).subscribe(res => {
            console.log(res);
            s.next(Object.assign(new Contact(), res).build());
        }, error => s.error(error), () => s.complete());
        return s.asObservable();
    }

    getAllByUser(userUri: string): Observable<Contact[]> {
        const s = new Subject<Contact[]>();
        this.client.get<ResponseWithData<Contact[]>>(this.getBaseUrl() + '/' + userUri + '/contacts').subscribe(res => {
            console.log(res);
            s.next(res.data.map(c => Object.assign(new Contact(), c).build()));
        }, error => s.error(error), () => s.complete());
        return s.asObservable();
    }

    getTypes(): Observable<ContactType[]> {
        const s = new Subject<ContactType[]>();
        this.client.get<ResponseWithData<Contact[]>>('/auth/api/contacts/types').subscribe(res => {
            console.log(res);
            s.next(res.data.map(c => Object.assign(new ContactType(), c).build()));
        }, error => s.error(error), () => s.complete());
        return s.asObservable();
    }

}
