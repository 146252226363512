import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AskAccountService, GlobalAuthenticationService, NewPassword} from '@genos/user-service';
import {MessageService} from '../../../global/services/message.service';

@Component({
    selector: 'app-password',
    templateUrl: './password.component.html',
    styleUrls: ['./password.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PasswordComponent implements OnInit {

    newPassword: NewPassword;
    token: string;
    load: boolean;
    loadVerify: boolean;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private authenticationService: GlobalAuthenticationService,
                private accountService: AskAccountService,
                private messageService: MessageService) {
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.token = params['token']; // (+) converts string 'idTo' userUri a number
            this.newPassword = new NewPassword();
            this.newPassword.token = this.token;
        });
    }

    validate() {
        if (this.newPassword.isValid()) {
            this.loadVerify = true;
            this.authenticationService.recoverPassword(this.token, this.newPassword.confirmPassword).subscribe(response => {
                this.loadVerify = false;
                this.messageService.addSuccess('Mot de passe changé',
                    'Vous venez de modifier votre mot de passe avec succès');
                this.router.navigate(['/']);
            }, error => {
                this.loadVerify = false;
            });
        } else {
            this.messageService.addError('Mots de passe de passe non correcte',
                'Les mot de passes ne sont pas identiques ou ne possède pas 6 caractères');
        }
    }

}
