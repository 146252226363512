import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ClubsComponent} from './clubs/clubs.component';
import {ClubComponent} from './club/club.component';
import {ClubRoutes} from './club.routes';
import {RouterModule} from '@angular/router';
import {
    AutoCompleteModule, DataTableModule, FileUploadModule, InputTextModule, PanelModule, ProgressSpinnerModule,
    RadioButtonModule, TabViewModule
} from 'primeng/primeng';
import {FormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ClubsService} from './services/clubs.service';
import {TeamModule} from '../team/team.module';
import {ContactsModule} from '../contacts/contacts.module';

@NgModule({
    imports: [
        CommonModule,
        DataTableModule,
        ProgressSpinnerModule,
        FileUploadModule,
        ContactsModule,
        TabViewModule,
        TeamModule,
        PanelModule,
        InputTextModule,
        FlexLayoutModule,
        FormsModule,
        AutoCompleteModule,
        RadioButtonModule,
        RouterModule.forChild(ClubRoutes)
    ],
    providers: [ClubsService],
    declarations: [ClubsComponent, ClubComponent]
})
export class ClubModule {
}
