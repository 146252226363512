<div class="loader" fxLayout="row" *ngIf="loadInfo" fxLayoutAlign="center center">
    <p-progressSpinner></p-progressSpinner>
</div>
<div class="content-card group common min-height-card"
     *ngIf="!loadInfo"
     fxLayout="row"
     fxLayout.xs="column">
    <div class="form-group card card-w-title group-info"
         fxFlex="33" fxLayout="column"
         flexXs="100">
        <h4 i18n="@@h4-generale-information">Le Groupe</h4>
        <p-panel header="Informations générales" i18n-header="@@h3-group-general-infos">
            <div fxLayout="column" class="genos-form">
                <div class="ui-float-label line" fxLayout="column">
                    <input type="text" class="form-input" [(ngModel)]="form.name"
                           pInputText id="name">
                    <label for="name" i18n="@@placeholder-group-name">Nom</label>
                </div>
                <p-dropdown [options]="statutes"
                            class="form-input"
                            i18n-placeholder="@@placeholder-select-input-contact-sport"
                            placeholder="Selectionnez un status"
                            [style]="{'width':'250px'}"
                            [(ngModel)]="form.status"
                            optionLabel="name"></p-dropdown>
                <p-dropdown [options]="types"
                            class="form-input"
                            i18n-placeholder="@@placeholder-select-input-contact-sport"
                            placeholder="Selectionnez un type"
                            [style]="{'width':'250px'}"
                            [(ngModel)]="form.type"
                            optionLabel="label"></p-dropdown>
                <div class="ui-float-label" fxLayout="column">
                    <textarea [rows]="5" [cols]="30" pInputTextarea [(ngModel)]="form.description"></textarea>
                    <label for="name" i18n="@@placeholder-natural-team-description">Description</label>
                </div>
            </div>
            <p-footer>
                <button pButton type="button" icon="fa-delete"
                        (click)="delete()"
                        i18n-label="@@button-delete" label="Supprimer"></button>
                <button pButton type="button" icon="fa-check"
                        (click)="save()"
                        i18n-label="@@button-save" label="Sauvegarder"></button>
            </p-footer>
        </p-panel>
    </div>
    <div *ngIf="!isNew()" fxFlex="66"
         class="form-group card card-w-title"
         flexXs="100">
        <div fxLayout="column">
            <div class="header" fxLayout="row" fxFlex="100">
                <h4 i18n="@@h4-members">Les membres</h4>
                <div fxLayout="90" fxLayout="row" class="filters" fxLayoutAlign="start center">
                    <p-dropdown [options]="genders"
                                class="form-input"
                                i18n-placeholder="@@placeholder-select-input-team-category"
                                placeholder="Selectionnez une genre"
                                [style]="{'width':'150px'}"
                                (onChange)="updateTargets()"
                                [(ngModel)]="membersFilter.gender"
                                optionLabel="label"></p-dropdown>
                    <p-dropdown [options]="categories"
                                class="form-input"
                                i18n-placeholder="@@placeholder-select-input-team-category"
                                placeholder="Selectionnez une categorie"
                                [style]="{'width':'150px'}"
                                (onChange)="updateTargets()"
                                [(ngModel)]="filter.category"
                                optionLabel="label"></p-dropdown>
                    <div fxLayoutAlign="center center" class="button-i" (click)="clearFilter()">
                        <i class="material-icons">clear</i>
                        <div i18n="@@delete-filter" fxHide.xs>Supprimer filtre</div>
                    </div>
                </div>
                <div class="actions" fxFlex="45" fxLayout="row" fxLayoutAlign="end center">
                    <div fxLayoutAlign="center center" class="button-i"
                         (click)="saveCurrentMembers()">
                        <i class="material-icons">save</i>
                    </div>
                </div>
            </div>
            <div fxFlex="100" fxLayoutWrap fxLayout="row">
                <!--<app-group-members [all]="targets"-->
                                   <!--[(members)]="group.members"-->
                                   <!--fxFlex="100"-->
                                   <!--headerAll="Personnes" headerMembers="Membres"-->
                <!--&gt;</app-group-members>-->
            </div>
        </div>
    </div>
</div>
<p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="425"></p-confirmDialog>
