import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot, CanActivate, CanActivateChild, NavigationExtras, Router,
    RouterStateSnapshot
} from '@angular/router';
import {Observable} from 'rxjs';
import {GlobalSessionService} from '@genos/user-service';

@Injectable()
export class GlobalAuthGuard implements CanActivate, CanActivateChild {

    constructor(private session: GlobalSessionService,
                private router: Router) {
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return this.canActivate(childRoute, state);
    }

    canActivate(next: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): boolean {
        if (this.session.isLoggedIn()) {
            return true;
        } else {
            this.session.destroy();
            const nav: NavigationExtras = {};
            if (state.url !== '') {
                nav.queryParams = {redirection: state.url};
            }
            this.router.navigate([''], nav);
            return false;
        }
    }
}
