import {Place} from '../../genos/place/models/place';
import {EventType} from '../enums/event-type.enum';
import {isNullOrUndefined} from 'util';

export class EventForm {

    title: string;
    address: string;
    startDate: Date;
    startTime: string;
    endDate: Date;
    endTime: string;
    status: any;
    description: string;
    shortDescription: string;
    idParent: number;
    typeParent: string;
    image: string;
    visibility: any;
    periodicity: any;
    period: string;
    type: EventType;
    place: Place;

    isValid(): boolean {
        let b = true;
        b = (b && !isNullOrUndefined(this.title));
        b = (b && !isNullOrUndefined(this.startDate));
        b = (b && !isNullOrUndefined(this.startTime));
        b = (b && !isNullOrUndefined(this.endDate));
        b = (b && !isNullOrUndefined(this.endTime));
        b = (b && !isNullOrUndefined(this.endTime));
        return b;
    }
}
