import {isNullOrUndefined} from 'util';
import {ToUrl} from '@mdw/usual-service';


export class SportPersonsFilter extends ToUrl {

    licence: number;
    licenceActive: boolean;
    sport: string;
    status: string;
    category: string;
    gender: string;
    exceptGroups: number[];
    exceptStatutes: string[];

    toUrl(): string {
        let url = '';
        if (!isNullOrUndefined((this.exceptGroups))) {
            url += this.getSeparator() + 'exceptGroups=' + this.exceptGroups.join(',');
        }
        if (!isNullOrUndefined((this.exceptStatutes))) {
            url += this.getSeparator() + 'exceptStatutes=' + this.exceptStatutes.join(',');
        }
        if (!isNullOrUndefined((this.licence))) {
            url += this.getSeparator() + 'licence=' + this.licence;
        }
        if (!isNullOrUndefined((this.licenceActive))) {
            url += this.getSeparator() + 'licenceActive=' + this.licenceActive;
        }
        if (!isNullOrUndefined((this.sport))) {
            url += this.getSeparator() + 'sport=' + this.sport;
        }
        if (!isNullOrUndefined((this.category))) {
            url += this.getSeparator() + 'category=' + this.category;
        }
        if (!isNullOrUndefined((this.gender))) {
            url += this.getSeparator() + 'gender=' + this.gender;
        }
        if (!isNullOrUndefined((this.status))) {
            url += this.getSeparator() + 'status=' + this.status;
        }
        return url;
    }

    withLicenceActive(licenceActive: boolean): SportPersonsFilter {
        this.licenceActive = licenceActive;
        return this;
    }

    withStatus(status: string): SportPersonsFilter {
        this.status = status;
        return this;
    }

    withExceptStatutes(statutes: string[]): SportPersonsFilter {
        this.exceptStatutes = statutes;
        return this;
    }

    withExceptGroups(groups: number[]): SportPersonsFilter {
        this.exceptGroups = groups;
        return this;
    }

    withLicence(licence: number): SportPersonsFilter {
        this.licence = licence;
        return this;
    }

    withSport(sport: string): SportPersonsFilter {
        this.sport = sport;
        return this;
    }

    withCategory(category: string): SportPersonsFilter {
        this.category = category;
        return this;
    }

    withGender(gender: string): SportPersonsFilter {
        this.gender = gender;
        return this;
    }

}
