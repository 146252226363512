import {Injectable} from '@angular/core';
import {Leaderboard} from '../models/leaderboard';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DtoSecurisedService, GlobalSessionService} from '@genos/user-service';
import {AppConfigService} from '@mdw/usual-service';

@Injectable()
export class LeaderboardsService extends DtoSecurisedService<Leaderboard> {

    getBaseUrl(): string {
        return this.appConfidService.getSecuredBaseUrl() + '/contests';
    }

    constructor(protected sessionService: GlobalSessionService,
                protected client: HttpClient,
                protected appConfidService: AppConfigService) {
        super(client, sessionService);
    }


    public update(t: Leaderboard): Observable<Leaderboard> {
        return this.put<Leaderboard>(this.getBaseUrl() + '/' + t.idContest + '/leaderboards/' + t.id, t);
    }

    public create(t: Leaderboard): Observable<Leaderboard> {
        return this.post<Leaderboard>(this.getBaseUrl() + '/' + t.idContest + '/leaderboards', t);
    }

}
