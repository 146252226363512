<div class="layout-footer clearfix" fxLayout="row" [ngClass]="{'hide': !showTabs && !showCredits}">
    <div class="not-mobile" *ngIf="(!getIsMobile && isLoggedIn) || !isLoggedIn" fxLayout="row" fxFlex="100"
         fxLayoutAlign="space-around center">
        <div>{{version}}</div>
        <div>
            2019
            <span class="material-icons">copyright</span>
            GENOS - MY DEV WORLD
            <span fxHide.xs>- All Rights Reserved</span>
        </div>
        <div></div>
    </div>
    <div *ngIf="getIsMobile && isLoggedIn && showTabs" class="mobile" fxLayout="row" fxFlex="100">
        <div class="button" (click)="goToEventsViewDay()" fxLayout="row" fxFlex="33" fxLayoutAlign="center center">
            <i class="material-icons">view_day</i>
        </div>
        <div class="button" (click)="goToEventsSwipe()" fxLayout="row" fxFlex="33" fxLayoutAlign="center center">
            <i class="material-icons">view_carousel</i>
        </div>
        <div class="button" (click)="goToTalks()" fxLayout="row" fxFlex="33" fxLayoutAlign="center center">
            <i class="material-icons">chat_bubble_outline</i>
        </div>
        <div class="button" (click)="goToResults()" fxLayout="row" fxFlex="33" fxLayoutAlign="center center">
            <i class="material-icons">score</i>
        </div>
    </div>
</div>
