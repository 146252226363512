import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RightsTreeComponent} from './rights-tree/rights-tree.component';
import {
    CheckboxModule,
    ConfirmDialogModule,
    DataListModule,
    DataTableModule,
    DialogModule,
    DropdownModule,
    InputTextModule,
    ProgressSpinnerModule,
    TreeModule
} from 'primeng/primeng';
import {RightsAccountsListComponent} from './rights-accounts-list/rights-accounts-list.component';
import {RightsAccreditationsComponent} from './rights-accreditations/rights-accreditations.component';
import {MatChipsModule} from '@angular/material';
import {FormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {RightsToAttachComponent} from './rights-to-attach/rights-to-attach.component';
import {GenosUserServiceModule, RightsService} from '@genos/user-service';

@NgModule({
    imports: [
        CommonModule,
        TreeModule,
        GenosUserServiceModule,
        ProgressSpinnerModule,
        MatChipsModule,
        DataTableModule,
        DialogModule,
        CheckboxModule,
        FormsModule,
        InputTextModule,
        DropdownModule,
        FlexLayoutModule,
        ConfirmDialogModule,
        FormsModule,
        DataListModule],
    declarations: [RightsTreeComponent, RightsAccountsListComponent,
        RightsAccreditationsComponent, RightsToAttachComponent],
    exports: [RightsAccreditationsComponent, RightsToAttachComponent, RightsTreeComponent],
    providers: [RightsService]
})
export class RightsModule {
}
