import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BscModule} from '../../bsc/bsc.module';
import {ModeFactoryService} from './services/mode-factory.service';

@NgModule({
    imports: [
        CommonModule,
        BscModule
    ],
    declarations: [],
    providers: [ModeFactoryService]
})
export class ModeModule {
}
