import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {Message} from 'primeng/primeng';
import {MatSnackBar} from '@angular/material';

@Injectable()
export class MessageService {

    subject: ReplaySubject<Message>;
    stickyMode: boolean;

    constructor(public snackBar: MatSnackBar) {
        this.subject = new ReplaySubject();
    }

    showMessage(message: string) {
        this.snackBar.open(message, null, {panelClass: 'snackbar-message'});
    }

    showMessageAndAction(message: string, action: string, data: any, toDo: Function) {
        const snackBarRef = this.snackBar.open(message, action, {
            panelClass: 'snackbar-message',
            data: data
        });
        snackBarRef.onAction().subscribe(() => {
            toDo(data);
        });
    }

    showWarning(message: string) {
        const snackBarRef = this.snackBar.open(message, null, {
            panelClass: 'snackbar-warning'
        });
    }

    showError(message: string) {
        const snackBarRef = this.snackBar.open(message, null, {
            panelClass: 'snackbar-error'
        });
    }

    add(message: Message) {
        this.subject.next(message);
    }

    addError(title: string, content: string, stickyMode: boolean = false) {
        this.stickyMode = true;
        this.subject.next({
            severity: 'error',
            summary: title,
            detail: content
        });
    }

    addSuccess(title: string, content: string, stickyMode: boolean = false) {
        this.stickyMode = stickyMode;
        this.subject.next({
            severity: 'success',
            summary: title,
            detail: content
        });
    }

    addMessage(title: string, content: string, stickyMode: boolean = false) {
        this.subject.next({
            severity: 'info',
            summary: title,
            detail: content
        });
    }


    addWarning(title: string, content: string, stickyMode: boolean = false) {
        this.subject.next({
            severity: 'warning',
            summary: title,
            detail: content
        });
    }

    getSubscriber(): Observable<Message> {
        return this.subject.asObservable();
    }
}
