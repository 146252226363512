<div fxFlex="100" fxLayout="column">
    <div class="header" fxLayout="row">
        <div fxFlex="95">
            <div class="title" fxLayout="row" fxFlex="100">
                <h4 i18n="@@h4-games" fxFlex="20">Les matchs</h4>
            </div>
        </div>
        <div class="actions" fxFlex="5" fxLayoutAlign="end center">
            <div fxLayoutAlign="center center" class="button-i cancel" *ngIf="showCancel()" (click)="cancel()">
                <i class="material-icons">cancel</i>
            </div>
            <div fxLayoutAlign="center center" class="button-i" *ngIf="normalMode" (click)="passAddMode()">
                <i class="material-icons">add</i>
            </div>
        </div>
    </div>
    <div fxLayoutWrap fxLayout="row" fxFlex="100">
        <app-contest-game-item-edit [parent]="contest" [game]="currentGame"
                                    [gender]="contest.gender"
                                    (onSave)="saveGame()"
                                    *ngIf="!normalMode"></app-contest-game-item-edit>
        <div fxFlex="100" *ngIf="hasNoGames() && normalMode" fxLayoutAlign="center center">
            <div>Aucun match</div>
        </div>
        <div fxLayout="column" fxFlex="100" *ngIf="normalMode">
            <app-contest-game-item fxFlex="100"
                                   (toUpdate)="setCurrentGameToEdit($event)"
                                   *ngFor="let game of games" [game]="game"></app-contest-game-item>

        </div>
    </div>
</div>