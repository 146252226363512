import {Component, Input, OnInit} from '@angular/core';
import {NaturalPerson, PersonsService} from '@genos/person-service';

@Component({
    selector: 'app-group-display-members',
    templateUrl: './group-display-members.component.html',
    styleUrls: ['./group-display-members.component.scss']
})
export class GroupDisplayMembersComponent implements OnInit {

    @Input() members: NaturalPerson[];
    @Input() showTitle = true;

    constructor(private personsService: PersonsService) {
    }

    ngOnInit() {
        this.members = this.members.sort(this.personsService.sortByFirstName);
    }

}
