import {Component, Input, OnInit} from '@angular/core';
 import {CanAttach, Context} from '@mdw/usual-service';
import {Right, RightCheckboxes, RightsService, RightTo} from '@genos/user-service';
import {MessageService} from '../../../global/services/message.service';

@Component({
    selector: 'app-rights-to-attach',
    templateUrl: './rights-to-attach.component.html',
    styleUrls: ['./rights-to-attach.component.scss']
})
export class RightsToAttachComponent implements OnInit {

    @Input() to: CanAttach;
    @Input() mode: string;
    filterDescription: string;

    rights: Right[];
    rcs: RightCheckboxes[];
    rightsTo: RightTo[];

    loadRights: boolean;

    constructor(private rightService: RightsService,
                private messageService: MessageService) {
    }

    ngOnInit() {
        this.reload(this.to);
    }

    reload(to: CanAttach) {
        this.rights = [];
        this.rightsTo = [];
        this.loadRights = true;
        this.rightService.getAll().subscribe(rights => {
            this.rights = rights;
            this.rcs = this.rightService.toCheckboxes(rights);
            this.rightService.getAllAttachTo(to).subscribe(rightsTo => {
                this.rightsTo = rightsTo;
                this.loadRights = false;
            });
        });
    }

    getCheckedByRight(right: Right): boolean {
        return this.rightsTo.filter(rt => rt.idRight === right.id).length > 0;
    }

    isAccountMode(): boolean {
        return this.mode === 'ACCOUNT';
    }

    handleChange(event, right: Right) {
        if (event) {
            this.handleAdd(right);
        } else {
            this.handleRemove(right);
        }
    }

    handleAdd(right: Right) {
        this.rightService.attachTo(right, this.to, new Context()).subscribe(response => {
            this.messageService.addSuccess('Ajout du droit réussi', '');
        });
    }

    getRightTo(right: Right): RightTo {
        return this.rightsTo.filter(rt => rt.idRight === right.id)[0];
    }

    handleRemove(right: Right) {
        this.rightService.detachTo(this.getRightTo(right).id).subscribe(response => { //
            this.messageService.addSuccess('Suppression du droit réussie', '');
        });
    }
}
