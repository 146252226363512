<div class="places" fxFlex="100" fxLayout="row">
    <div class="loader" fxLayout="row" *ngIf="loadInfo" fxLayoutAlign="center center">
        <p-progressSpinner></p-progressSpinner>
    </div>
    <div fxLayout="column" fxFlex="100" *ngIf="!loadInfo">
        <div fxLayout="row">
            <h3 i18n="@@h3-places" fxFlex="50">Gestion des lieux</h3>
            <div fxFlex="50" fxLayoutAlign="end center">
                <div appNeedRight="CREATE_PLACE" fxLayoutAlign="center center" class="button-i"
                     (click)="addPlace()">
                    <i class="material-icons">add</i>
                </div>
            </div>
        </div>
        <div fxFlex="100" fxLayout="row">
            <div fxFlex="100" fxLayout="column">
                <div class="ui-widget-header" fxFlex="100" fxLayout="row">
                    <i class="fa fa-search"></i>
                    <input #gb type="text" pInputText size="50" i18n-placeholder="@@placeholder-global-filter"
                           placeholder="Filtre">
                </div>
                <p-dataTable fxFlex="100"  [globalFilter]="gb" [value]="places"
                             [paginator]="true" [rows]="10"
                             selectionMode="single"
                             [(selection)]="selectedPlace" (onRowSelect)="onRowSelect($event)"
                             [sortField]="sortF" [sortOrder]="sortO">
                    <p-column field="name" i18n-header="@@header-data-table-place-name" header="Nom"
                              [sortable]="true"></p-column>
                    <p-column field="city" i18n-header="@@header-data-table-place-city" header="Ville"
                              [sortable]="true"></p-column>
                    <p-column field="zipCode" i18n-header="@@header-data-table-place-sport" header="Code Postale"
                              [sortable]="true">
                    </p-column>
                    <p-column field="address" i18n-header="@@header-data-table-place-address" header="Adresse"
                              [sortable]="true">
                    </p-column>
                    <p-column field="status" i18n-header="@@header-data-table-account-status" header="Statut"
                              [sortable]="true"></p-column>
                </p-dataTable>
            </div>
        </div>
    </div>
</div>