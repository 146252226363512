import {Injectable} from '@angular/core';
import {Team} from '../models/team';
import {Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {TeamsFilter} from '../models/teams-filter';
import {Subject} from 'rxjs';
import {DtoSecurisedService, GlobalSessionService, UploadFileService} from '@genos/user-service';
import {AppConfigService} from '@mdw/usual-service';

@Injectable()
export class TeamsService extends DtoSecurisedService<Team> {

    getBaseUrl(): string {
        return this.appConfigService.getSecuredBaseUrl() + '/clubs';
    }

    constructor(protected sessionService: GlobalSessionService,
                protected client: HttpClient,
                protected uploadFileService: UploadFileService,
                protected appConfigService: AppConfigService) {
        super(client, sessionService);
    }

    getAll(filter: TeamsFilter): Observable<Team[]> {
        return this.getArray(this.getBaseUrl() + '/' + filter.club + '/teams' + filter.toUrl(), {}, Team);
    }

    update(team: Team): Observable<Team> {
        return this.put<Team>(this.getBaseUrl() + '/' + team.club + '/teams/' + team.id, team);
    }

    create(team: Team): Observable<Team> {
        return this.post<Team>(this.getBaseUrl() + '/' + team.club + '/teams', team);
    }

    getById(idClub: number, idTeam: number): Observable<Team> {
        return this.getObject(this.getBaseUrl() + '/' + idClub + '/teams/' + idTeam, {}, Team);
    }

    uploadLogo(idTeam: number, file: File): Observable<string> {
        const s = new Subject<string>();
        this.uploadFileService.uploadFiles(this.getBaseUrl() + '/0/teams/' + idTeam + ':image', [file], false).subscribe(response => {
            if (response instanceof HttpResponse) {
                s.next(response.body.logo);
                s.complete();
            }
        });
        return s.asObservable();
    }

}
