import {GroupForm} from './group-form';
import {GroupType} from '../enums/group-type.enum';
import {GroupStatus} from '../enums/group-status.enum';
import {isNullOrUndefined} from 'util';
import {Buildable, CanAttach, CanBeAttach, Dto, FormCompliant, Periodicity} from '@mdw/usual-service';
import {environment} from '../../../../environments/environment';
import {NaturalPerson} from '@genos/person-service';

export class Group extends Dto implements Buildable<Group>,
    FormCompliant<Group, GroupForm>, CanBeAttach, CanAttach {

    // can attach
    typeTo: string;
    // Can be attach
    toType: string;
    idTo: number;

    name: string;
    description: string;
    type: GroupType;
    status: GroupStatus;
    period: string;
    periodicity: Periodicity;

    // optional
    members: NaturalPerson[];


    constructor() {
        super();
        this.typeTo = 'Group';
        this.type = GroupType.ADMINISTRATIVE;
        this.toType = 'CLUB';
        this.idTo = environment.idClubMain;
    }

    build(): Group {
        super.build();
        return this;
    }


    toForm(): GroupForm {
        const f = new GroupForm();
        f.name = this.name;
        f.description = this.description;
        f.type = {value: this.type};
        f.status = {value: this.status};
        f.periodicity = {value: this.periodicity};
        f.period = this.period;
        return f;
    }

    fromForm(form: GroupForm): Group {
        if (!isNullOrUndefined(form.periodicity)) {
            this.periodicity = form.periodicity.value;
        }
        this.description = form.description;
        this.name = form.name;
        this.type = form.type.value;
        this.period = form.period;
        this.status = form.status.value;
        return this;
    }

}
