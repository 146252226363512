export class HeaderOption {

    showBurgerMenu: boolean;
    showQuit: boolean;
    showLinkTo: boolean;
    showBackButton: boolean;
    showSearch: boolean;
    showTitle: boolean;
    showProgress: boolean;
    showAvatar: boolean;
    showNotification: boolean;
    showLogo: boolean;
    partProgress: number;
    avatarUrl: string;
    title: string;
    linkTo: string;
    titleLinkTo: string;
    backAction: string;
    backData: any;


    constructor() {
        this.showBackButton = false;
        this.showQuit = false;
        this.showLinkTo = false;
        this.showBurgerMenu = false;
        this.showSearch = false;
        this.showTitle = false;
        this.showProgress = false;
        this.showAvatar = false;
        this.showNotification = false;
        this.showLogo = false;
    }

    public static mobileOnboarding(title: string, partProgress: number): HeaderOption {
        const hto = new HeaderOption();
        hto.title = title;
        hto.showTitle = true;
        hto.showBackButton = false;
        hto.showNotification = false;
        hto.showProgress = true;
        hto.partProgress = partProgress;
        return hto;
    }

    public static mobileBackButton(title: string): HeaderOption {
        const hto = new HeaderOption();
        hto.title = title;
        hto.showBackButton = true;
        hto.showNotification = false;
        hto.showTitle = true;
        return hto;
    }

    public static mobileBackButtonWithActionAndData(title: string, action: string, data: any): HeaderOption {
        const hto = this.mobileBackButton(title);
        hto.backAction = action;
        hto.backData = data;
        return hto;
    }

    public static mobileBackButtonAndSearch(title: string): HeaderOption {
        const hto = HeaderOption.mobileBackButton(title);
        hto.showSearch = true;
        return hto;
    }

    public static mobileDefault(): HeaderOption {
        const hto = new HeaderOption();
        hto.showBurgerMenu = true;
        hto.showNotification = true;
        hto.showLogo = true;
        return hto;
    }

    public static mobileWelcome(): HeaderOption {
        const hto = new HeaderOption();
        hto.showQuit = true;
        hto.showLinkTo = true;
        hto.linkTo = 'login';
        hto.titleLinkTo = 'Connexion';
        return hto;
    }

    public static mobileOutsideDefault(titleLinkTo: string, linkTo: string): HeaderOption {
        const hto = new HeaderOption();
        hto.showBackButton = true;
        hto.showLinkTo = true;
        hto.linkTo = linkTo;
        hto.titleLinkTo = titleLinkTo;
        return hto;
    }

    public static mobileDefaultTitle(title: string): HeaderOption {
        const hto = new HeaderOption();
        hto.showBurgerMenu = true;
        hto.showNotification = true;
        hto.showLogo = false;
        hto.title = title;
        hto.showTitle = true;
        return hto;
    }
}
