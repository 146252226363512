import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {FooterOption} from '../models/footer-option';

@Injectable({
    providedIn: 'root'
})
export class FooterService {

    subject: Subject<FooterOption>;

    constructor() {
        this.subject = new Subject();
    }

    getSubscriber(): Observable<FooterOption> {
        return this.subject.asObservable();
    }

    setOption(option: FooterOption) {
        this.subject.next(option);
    }

    setWebDefault() {
        this.subject.next(FooterOption.webDefault());
    }

    setMobileMainScreen() {
        this.subject.next(FooterOption.mobileMainScreen());
    }

    setMobileEmpty() {
        this.subject.next(FooterOption.mobileEmpty());
    }

}
