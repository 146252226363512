import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ContestsService} from '../services/contests.service';
import {Contest} from '../models/contest';
import {ContestsFilter} from '../models/contests-filter';
import {MenuItem} from 'primeng/primeng';
import {Router} from '@angular/router';
import {BreadcrumbService} from '../../../global/services/breadcrumb.service';
import {ContestStatus} from '../enums/contest-status';
import {ContextMenuAppService} from '../../../global/services/context-menu-app.service';

@Component({
    selector: 'app-contests',
    templateUrl: './contests.component.html',
    styleUrls: ['./contests.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContestsComponent implements OnInit {

    contests: Contest[];
    selectedContest: Contest;
    loadContest: boolean;

    constructor(private breadcrumbService: BreadcrumbService,
                private contextMenuAppService: ContextMenuAppService,
                private router: Router,
                private contestsService: ContestsService) {

    }

    ngOnInit() {
        this.contests = [];
        this.breadcrumbService.setItems([{label: 'Compétitions'}]);
        this.loadContest = true;
        this.contestsService.getAll(new ContestsFilter()
            .withStatutes([ContestStatus.COMPLETE, ContestStatus.ACTIVE])).subscribe(contests => {
            this.contests = contests;
            this.loadContest = false;
        });
        this.contextMenuAppService.setContextMenu(this.buildContextMenu());

    }

    onRowSelect($event) {
        this.router.navigate(['/contests', this.selectedContest.id]);
    }

    private buildContextMenu(): MenuItem[] {
        const items = [];
        items.push({
            label: 'Ajouter une compétition',
            icon: 'fa-plus',
            command: (event) => {
                this.addContest();
            }
        });
        return items;
    }

    addContest() {
        this.router.navigate(['/contests/0']);
    }

}
