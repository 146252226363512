import {Component, Input, OnInit} from '@angular/core';
import {UriWithName} from '@mdw/usual-service';
import {TalkOld} from '../models/talk-old';
import {TalkMode} from '../enums/talk-mode.enum';

@Component({
    selector: 'app-talk-create',
    templateUrl: './talk-create.component.html',
    styleUrls: ['./talk-create.component.scss']
})
export class TalkCreateComponent implements OnInit {

    @Input() talk: TalkOld;

    constructor() {
    }

    ngOnInit() {
    }

    addMemberToTalk(member: UriWithName) {
        this.talk.members.push(member);
        if (this.talk.members.length > 1) {
            let newName = '';
            this.talk.members.forEach(m => newName = newName + m.name + ' ');
            this.talk.name = newName;
        } else {
            this.talk.name = member.name;
        }
        this.talk.mode = TalkMode.ON_CREATION;
    }



}
