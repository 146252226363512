import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfigService, ResponseWithData} from '@mdw/usual-service';
import {Observable} from 'rxjs';
import {PlaceCreateRequest} from '../models/place-create-request';
import {PlaceView} from '../models/place-view';
import {PlaceAutocompleteView} from '../models/place-autocomplete-view';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PlaceService {

    getBaseUrl(): string {
        return this.appConfigService.getBaseUrl() + '/event/api';
    }

    constructor(protected http: HttpClient,
                private appConfigService: AppConfigService) {
    }

    create(place: PlaceCreateRequest, organizationUri: string): Observable<PlaceView> {
        return this.http.post<ResponseWithData<PlaceView>>(
            this.getBaseUrl() + '/places?organizationUri=' + organizationUri, place)
            .pipe(map(p => Object.assign(new PlaceView(), p.data).build()));
    }

    getPlaceAutocomplete(organizationUri: string, prefix: string, count: number): Observable<PlaceAutocompleteView[]> {
        const values: any = {
            prefix: prefix,
            organizationUri: organizationUri,
            count: count
        }
        const options = {
            withCredentials: true,
            params: values
        };
        return this.http.get<ResponseWithData<PlaceAutocompleteView[]>>(this.getBaseUrl() + '/places:autocomplete', options)
            .pipe(
                map(r => {
                    return r.data.map(p => Object.assign(new PlaceAutocompleteView(), p));
                }));
    }
}
