<div class="content-card">
    <div class="content-card card card-w-title">
        <h4 i18n="@@h4-last-attempt">Dernières connexions</h4>

        <mat-list *ngIf="attempts.length > 0">
            <mat-list-item class="attempt" *ngFor="let attempt of attempts">
                <i *ngIf="attempt.isWeb()" class="material-icons">computer</i>
                <i *ngIf="attempt.isMobile()" class="material-icons">smartphone</i>
                {{attempt.created | date: 'dd/MM/yyyy HH:mm'}} : {{attempt.ip}} - {{attempt.status}}
            </mat-list-item>
        </mat-list>
        <span *ngIf="attempts.length === 0 " i18n="@@span-no-attempt">Aucune tentative de connexion</span>
    </div>
</div>