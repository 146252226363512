import {Component, OnInit} from '@angular/core';
import {BreadcrumbService} from '../../../global/services/breadcrumb.service';
 
@Component({
    selector: 'app-configuration-dashboard',
    templateUrl: './configuration-dashboard.component.html',
    styleUrls: ['./configuration-dashboard.component.scss']
})
export class ConfigurationDashboardComponent implements OnInit {

    constructor(private breadcrumbService: BreadcrumbService) {
    }

    ngOnInit() {
        this.breadcrumbService.setConfigurationChild();
    }

}
