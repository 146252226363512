import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {BreadcrumbService} from '../../../global/services/breadcrumb.service';
import {EventsService} from '../../../events/services/events.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Game} from '../models/game';
import {EventProjection} from '../../../events/models/event-projection';
import * as moment from 'moment';
import {NaturalPerson, PersonWithUserInfoView} from '@genos/person-service';
import {GlobalMeService} from "../../../global/services/global-me.service";

@Component({
    selector: 'app-game',
    templateUrl: './game.component.html',
    styleUrls: ['./game.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class GameComponent implements OnInit {

    currentGame: Game;
    idGame: number;
    loadGame: boolean;
    editMode: boolean;
    persons: PersonWithUserInfoView[];

    constructor(private route: ActivatedRoute,
                private router: Router,
                private breadcrumbService: BreadcrumbService,
                private eventsService: EventsService,
                private me: GlobalMeService) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    ngOnInit() {
        this.me.needAllPersons(false, persons => {
            this.persons = persons;
        });
        this.editMode = false;
        this.loadGame = true;
        this.idGame = parseInt(this.route.snapshot.params.id, 10);
        if (this.isNew()) {
            this.editMode = true;
            this.currentGame = new Game();
            this.currentGame.startDate = moment();
            this.currentGame.idParent = 1;
            this.currentGame.typeParent = 'CLUB';
            this.loadGame = false;
            this.setBreadcrumb();
        } else {
            this.me.needAllPersons(false, persons => {
                this.persons = persons;
                this.reloadGame();
            });
        }
    }

    reloadGame() {
        this.loadGame = true;
        this.idGame = parseInt(this.route.snapshot.params.id, 10);
        /*this.eventsService.getById(this.idGame, new EventProjection()
            .withAttendances(true)
            .withOptions(true)
            .withCurrentAttendances(this.persons.map(p => p.id))).subscribe(game => {
            this.currentGame = Object.assign(new Game(), game).build();
            this.loadGame = false;
            this.passToShowMode();
            this.setBreadcrumb();
        }); */
    }

    passToEditMode() {
        this.editMode = true;
    }

    passToShowMode() {
        this.editMode = false;
    }

    isEditMode() {
        return this.editMode;
    }

    isNew(): boolean {
        return this.idGame === 0;
    }

    setBreadcrumb() {
        const items = [];
        items.push({label: 'Evènements', routerLink: 'events'});
        if (this.isNew()) {
            items.push({label: 'Nouveau entrainement'});
        } else {
            items.push({label: this.currentGame.title});
        }
        this.breadcrumbService.setItems(items);
    }
}
