 import {isNullOrUndefined} from 'util';
 import {ToUrl} from '@mdw/usual-service';

export class PlacesFilter extends ToUrl {

    status: string;

    toUrl(): string {
        let url = '';
        if (!isNullOrUndefined((this.status))) {
            url += this.getSeparator() + 'status=' + this.status;
        }
        return url;
    }


    withStatus(status: string): PlacesFilter {
        this.status = status;
        return this;
    }


}
