import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CommonComponent} from '../../common-component';
import {PlaceCreateRequest} from '../models/place-create-request';
import {PlaceStatus} from '../enums/place-status';
import {PlaceService} from '../services/place.service';
import {AddressWithLatLng} from '../models/address-with-lat-lng';
import {isNullOrUndefined} from 'util';

@Component({
    selector: 'app-place-add-dialog',
    templateUrl: './place-add-dialog.component.html',
    styleUrls: ['./place-add-dialog.component.scss']
})
export class PlaceAddDialogComponent extends CommonComponent implements OnInit {

    organizationUri: string;
    address: AddressWithLatLng;
    valid: boolean;
    load: boolean;

    placeForm = new FormGroup({
        name: new FormControl('', [Validators.required])
    });


    constructor(public dialogRef: MatDialogRef<PlaceAddDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public snackBar: MatSnackBar,
                private placeService: PlaceService) {
        super();
        this.organizationUri = data.organizationUri;
    }

    ngOnInit() {
        super.ngOnInit();
        this.placeForm.get('name').valueChanges.subscribe(val => {
            this.valid = !isNullOrUndefined(val) && val !== '' && !isNullOrUndefined(this.address);
        });
    }

    savePlace() {
        if (this.placeForm.valid) {
            this.load = true;
            const request = new PlaceCreateRequest();
            request.name = this.placeForm.get('name').value;
            request.status = PlaceStatus.ACTIVE;
            request.address = this.address.address;
            request.city = this.address.city;
            request.zipCode = this.address.zipCode;
            request.country = this.address.country;
            request.googlePlaceId = this.address.placeId;
            request.latitude = this.address.latitude;
            request.longitude = this.address.longitude;
            this.placeService.create(request, this.organizationUri).subscribe(view => {
                this.snackBar.open('Le lieu a bien été créé');
                this.load = false;
                this.dialogRef.close({do: true, place: view});
            });
        }
    }

    addAddressToPlace(address) {
        this.address = address;
        this.valid = this.placeForm.valid && !isNullOrUndefined(this.address);
    }

}
