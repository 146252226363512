import {TalkType} from '../enums/talk-type.enum';
import {TalkMessage} from './talk-message';
import {Buildable, Dto} from '@mdw/usual-service';
import {NaturalPerson} from '@genos/person-service';

export class Talk extends Dto implements Buildable<Talk> {


    title: string;
    type: TalkType;
    status: string;
    members: NaturalPerson[];
    messages: TalkMessage[];

    build(): Talk {
        super.build();
        this.messages = this.messages.map(m => Object.assign(new TalkMessage(), m).build());
        return this;
    }


}
