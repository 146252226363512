import {Event} from '../../../events/models/event';
import {TrainingForm} from './training-form';
import {EventType} from '../../../events/enums/event-type.enum';
import {EventVisibility} from '../../../events/enums/event-visibility.enum';
import {Buildable, FormCompliant, Periodicity} from '@mdw/usual-service';

export class Training extends Event implements FormCompliant<Training, TrainingForm>, Buildable<Training> {

    openNewPersons: boolean;
    sport: string;
    category: string;
    gender: string;

    constructor() {
        super();
        this.type = EventType.TRAINING;
        this.periodicity = Periodicity.NONE;
        this.visibility = EventVisibility.PUBLIC;
        this.openNewPersons = false;
    }

    build(): Training {
        super.build();
        return this;
    }


    getSport(): string {
        return this.sport;
    }

    toForm(): TrainingForm {
        const f = new TrainingForm();
        super.toForm(f);
        f.openNewPersons = this.openNewPersons;
        f.gender = {value: this.gender};
        f.sport = {value: this.sport};
        f.category = {value: this.category};
        return f;
    }

    fromForm(f: TrainingForm): Training {
        super.fromForm(f);
        this.openNewPersons = f.openNewPersons;
        this.gender = f.gender.value;
        this.sport = f.sport.value;
        this.category = f.category.value;
        return this;
    }

}
