import {LeaderboardForm} from './leaderboard-form';
import {LeaderboardLine} from './leaderboard-line';
import {Buildable, Dto, FormCompliant} from '@mdw/usual-service';
import {LeaderboardType} from '../enums/leaderboard-type.enum';

export class Leaderboard extends Dto implements Buildable<Leaderboard>, FormCompliant<Leaderboard, LeaderboardForm> {

    name: string;
    type: any;
    lines: LeaderboardLine[];

    // userUri map
    idContest: number;

    constructor() {
        super();
        this.lines = [];
        this.type = LeaderboardType.LEADERBOARD_TYPE_POINTS;
    }

    toForm(): LeaderboardForm {
        const lf = new Leaderboard();
        return lf;
    }

    fromForm(form: LeaderboardForm): Leaderboard {
        return this;
    }

    build(): Leaderboard {
        super.build();
        this.lines.map(line => Object.assign(new LeaderboardLine(), line).build());
        return this;
    }
}
