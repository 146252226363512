<div class="no" fxLayout="row">
    <div class="nH aJl" *ngFor="let talk of talks">
        <div class="aJn">
            <mat-toolbar class="talk-header">
                <mat-toolbar-row>
                    <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">
                        <div fxFlex="80" fxLayout="row" *ngIf="!talk.isNew()"
                             fxLayoutAlign="space-between center">
                            <i class="material-icons" *ngIf="talk.members.length === 1">perm_identity</i>
                            <i class="material-icons" *ngIf="talk.members.length > 1">group</i>
                            <div>
                                {{talk.name}}
                            </div>
                        </div>
                        <div fxFlex="80" fxLayout="row" *ngIf="talk.isNew()"
                             fxLayoutAlign="space-between center">
                            Nouvelle discussion
                        </div>
                        <div fxLayout="row" fxLayoutAlign="end center" fxFlex="20">
                            <div><i class="material-icons" (click)="closeTalk()">close</i></div>
                        </div>
                    </div>
                </mat-toolbar-row>
            </mat-toolbar>
            <app-talk [talk]="talk" *ngIf="!talk.isNew()"></app-talk>
            <app-talk-create [talk]="talk" *ngIf="talk.isNew()"></app-talk-create>
        </div>
    </div>
</div>