import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {MenuItem} from 'primeng/primeng';
import {environment} from '../../../environments/environment';
import {Organization} from '@genos/organization-service';

@Injectable()
export class BreadcrumbService {

    private itemsSource = new Subject<MenuItem[]>();
    private _show: boolean;

    public getSubscriber(): Observable<MenuItem[]> {
        return this.itemsSource.asObservable();
    }

    needToShow(): boolean {
        return this._show;
    }

    show() {
        this._show = true;
    }

    hide() {
        this._show = false;
    }

    setOrganizationUserItems(organization: Organization, items: MenuItem[]) {
        const o = this.getOrganizationParent(organization);
        const _items: MenuItem[] = [{
            label: 'Utilisateurs',
            routerLink: '/clubs/' + organization.host + '/users'
        }];
        items.forEach(i => _items.push(i));
        return this.handlerChild(o, _items);
    }

    setOrganizationItems(organization: Organization, items: MenuItem[]) {
        const o = this.getOrganizationParent(organization);
        return this.handlerChild(o, items);
    }

    private getOrganizationParent(organization: Organization): MenuItem {
        return {label: organization.name, routerLink: '/clubs/' + organization.host + '/dashboard'};
    }


    setItems(items: MenuItem[]) {
        this.itemsSource.next(items);
    }

    getAdministrationParent(): MenuItem {
        return {label: 'Administration', routerLink: environment.urlAdministration};
    }

    getConfigurationParent(): MenuItem {
        return {label: 'Configuration', routerLink: environment.urlAdministration + '/' + environment.urlConfiguration};
    }

    handlerChild(parent: MenuItem, items?: MenuItem[]) {
        if (!items) {
            items = [];
            items.push(parent);
            this.setItems(items);
        } else {
            let _items = [];
            _items.push(parent);
            _items = _items.concat(items);
            this.setItems(_items);
            return _items;
        }
        return items;
    }

    setAdministrationChild(items?: MenuItem[]): MenuItem[] {
        const o = this.getAdministrationParent();
        return this.handlerChild(o, items);
    }

    setConfigurationChild(items?: MenuItem[]): MenuItem[] {
        const o = {label: 'Configuration', routerLink: 'configuration'};
        items = [];
        items = this.setAdministrationChild(items);
        if (!items) {
            items = [];
            items.push(o);
            this.setItems(items);
        } else {
            items.push(o);
        }
        return items;
    }

    setAdminUserChild(_items: MenuItem[]) {
        let items: MenuItem[] = [];
        items = this.setAdministrationChild(items);
        items.push({label: 'Utilisateurs', routerLink: environment.urlAdministration + '/users'});
        _items.forEach(i => {
            items.push(i);
        });
        this.setItems(items);
    }

    setAccreditationChild(item: MenuItem) {
        let items: MenuItem[] = [];
        items = this.setAdministrationChild(items);
        items.push({label: 'Accréditations'});
        items.push(item);
        this.setItems(items);
    }
}
