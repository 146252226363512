<div class="leaderboard-display" fxLayout="column">
    <div fxLayout="row" class="leaderboard-header">
        <div class="leaderboard-header-name " fxFlex="30" fxFlex.gt-xs="40">Équipe</div>
        <div class="leaderboard-header-label" fxFlex="20" fxFlex.gt-xs="10" *ngIf="isAverage()">Moyenne</div>
        <div class="leaderboard-header-label" fxFlex="20" fxFlex.gt-xs="10" *ngIf="isPoints()">Points</div>
        <div class="leaderboard-header-label" fxFlex="20" fxFlex.gt-xs="10">Victoires</div>
        <div class="leaderboard-header-label" fxFlex="20" fxFlex.gt-xs="10">Nul</div>
        <div class="leaderboard-header-label" fxFlex="20" fxFlex.gt-xs="10">Défaites</div>
        <div class="leaderboard-header-label" fxFlex="20" fxFlex.gt-xs="10">Joués</div>
        <div class="leaderboard-header-label" hide.xs="true" fxFlex="10">Restant</div>
        <div class="leaderboard-header-label" hide.xs="true" fxFlex="10">Pénalités</div>
    </div>
    <div fxLayout="row" class="leaderboard-row" *ngFor="let line of lines">
        <div class="leaderboard-header-label" hide.xs="true" fxFlex.gt-xs="10">{{getOrder(line)}}</div>
        <div class="leaderboard-team" fxFlex="30" fxFlex-gt-xs="30"
             fxLayout="row">
            <img hide-xs="" class="logo" src="{{line.team.logo}}">
            <div class="name">{{line.team.name}}</div>
        </div>
        <div class="leaderboard-header-label" fxFlex="20" fxFlex.gt-xs="10" *ngIf="isPoints()">{{line.points}}</div>
        <div class="leaderboard-header-label" fxFlex="20" fxFlex.gt-xs="10" *ngIf="isAverage()">{{line.average}}</div>
        <div class="leaderboard-header-label" fxFlex="20" fxFlex.gt-xs="10">{{line.victory}}</div>
        <div class="leaderboard-header-label" fxFlex="20" fxFlex.gt-xs="10">{{line.exaequo}}</div>
        <div class="leaderboard-header-label" fxFlex="20" fxFlex.gt-xs="10">{{line.defeate}}</div>
        <div class="leaderboard-header-label" fxFlex="20" fxFlex.gt-xs="10">{{line.gamePlayed}}</div>
        <div class="leaderboard-header-label" hide.xs="true" fxFlex="10">{{line.gameLeftToPlay}}</div>
        <div class="leaderboard-header-label" hide.xs="true" fxFlex="10">{{line.penality}}</div>
    </div>
</div>