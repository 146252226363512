<h2 mat-dialog-title>Ajouter un lieu</h2>
<form [formGroup]="placeForm"
      (ngSubmit)="savePlace()">
    <mat-dialog-content>
        <div fxLayout="column">
            <mat-form-field>
                <input matInput placeholder="Name" formControlName="name" required>
                <mat-error *ngIf="placeForm.get('name').invalid">
                    {{getErrorMessage(placeForm,'name')}}
                </mat-error>
                <mat-icon *ngIf="placeForm.get('name').value !== ''" matSuffix
                          (click)="placeForm.get('name').setValue('')">close
                </mat-icon>
            </mat-form-field>
            <app-address-autocomplete
                    (onItemSelected)="addAddressToPlace($event)"></app-address-autocomplete>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div fxLayout="row" *ngIf="!load" fxLayoutAlign="space-between center">
            <button color="accent" mat-raised-button mat-dialog-close>Annuler</button>
            <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
            <button color="primary" mat-raised-button type="submit" [disabled]="!valid">Créer</button>
        </div>
        <div fxFlex="100" *ngIf="load" fxLayoutAlign="center center">
            <mat-progress-spinner diameter="20" mode="indeterminate"></mat-progress-spinner>
        </div>
    </mat-dialog-actions>
</form>
