import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {Group} from '../../group/models/group';
import {Club} from '../../club/models/club';

@Component({
    selector: 'app-attach-group-item',
    templateUrl: './attach-group-item.component.html',
    styleUrls: ['./attach-group-item.component.scss']
})
export class AttachGroupItemComponent implements OnInit {

    @Input() group: Group;
    @Input() club: Club;
    @Input() removeMode: boolean;
    @Output() removeModeHandler: EventEmitter<Group> = new EventEmitter<Group>();


    constructor(private router: Router) {
    }

    ngOnInit() {
    }


    onClick() {
        if (this.removeMode) {
            this.removeModeHandler.emit(this.group);
        } else {
            this.router.navigate(['groups/', this.group.id]);
        }

    }
}
