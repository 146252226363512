import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PlaceAutocompleteComponent} from './place-autocomplete/place-autocomplete.component';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule, MatProgressSpinnerModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AddressAutocompleteComponent} from './address-autocomplete/address-autocomplete.component';
import {MdwModule} from '../mdw/mdw.module';
import {PlaceAddDialogComponent} from './place-add-dialog/place-add-dialog.component';
import {PlaceService} from './services/place.service';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
    imports: [
        CommonModule,
        MdwModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatAutocompleteModule,
        MatInputModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatDialogModule,
        FormsModule,
        MatFormFieldModule
    ],
    entryComponents: [
        PlaceAddDialogComponent
    ],
    declarations: [PlaceAutocompleteComponent, AddressAutocompleteComponent, PlaceAddDialogComponent],
    exports: [PlaceAutocompleteComponent, AddressAutocompleteComponent],
    providers: [PlaceService]
})
export class PlacesModule {
}
