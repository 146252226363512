import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {AdminRoutes} from './admin.routes';
import {AdminDashboardComponent} from './admin-dashboard/admin-dashboard.component';
import {ConfigurationModule} from '../genos/configuration/configuration.module';
import {RightsModule} from '../genos/rights/rights.module';
import {RolesModule} from '../genos/roles/roles.module';
import {AccountsModule} from '../genos/accounts/accounts.module';


@NgModule({
    imports: [
        CommonModule,
        AccountsModule,
        RightsModule,
        RolesModule,
        ConfigurationModule,
        RouterModule.forChild(AdminRoutes)
    ],
    declarations: [AdminDashboardComponent]
})
export class AdminModule {
}
