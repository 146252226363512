<div class="loader" fxLayout="row" *ngIf="loadData" fxLayoutAlign="center center">
    <p-progressSpinner></p-progressSpinner>
</div>
<div fxLayout="column" class="card genos-form training" fxFlex="50" *ngIf="!loadData">
    <div fxLayout="colunm">
        <h3 fxFlex="50">{{training.title}}</h3>
        <div fxFlex="50" fxLayoutAlign="end center">
            <div appNeedRight="UPDATE_EVENT" fxLayoutAlign="center center" class="button-i cancel"
                 (click)="passToNormalMode()">
                <i class="material-icons">cancel</i>
            </div>
        </div>
    </div>
    <div fxLayout="row">
        <div fxLayout="column" fxFlex="50">
            <div class="ui-float-label line" fxFlex="50" fxLayout="column">
                <input type="text" class="form-input"
                       [(ngModel)]="form.title"
                       pInputText id="title">
                <label for="title" i18n="@@placeholder-game-reference">Titre</label>
            </div>
            <p-dropdown [options]="visibilities"
                        class="form-input"
                        i18n-placeholder="@@placeholder-select-input-template-sport"
                        placeholder="Définir une visibilité"
                        [style]="{'width':'150px'}"
                        [(ngModel)]="form.visibility"
                        optionLabel="label"></p-dropdown>
            <p-dropdown [(ngModel)]="form.status" [options]="statutes"
                        [autoWidth]="false"
                        i18n-placeholder="@@placeholder-select-input-game-status"
                        placeholder="Selectionnez un statut"
                        optionLabel="name"
                        class="form-input"
                        [style]="{'width':'150px'}" appendTo="body"></p-dropdown>
            <p-checkbox name="openNewPersons" binary="true" label="Ouvert au non licencié débutant pour test"
                        [(ngModel)]="form.openNewPersons"
                        inputId="openNewPersons"></p-checkbox>
        </div>
        <div fxFlex="50" *ngIf="!isNew()">
            <div fxFlex="100" fxLayout="row">
                <div fxFlex="50">
                <span class="profile-image-wrapper">
                    <img class="roundedImage normal" src="{{training.image}}">
                </span>
                </div>
                <div fxFlex="50" fxLayoutAlign="bottom left">
                    <p-fileUpload #uploader name="logo" mode="basic" customUpload="true"
                                  (uploadHandler)="myUploader($event,uploader)"></p-fileUpload>
                </div>
            </div>
        </div>
    </div>
    <h4>Date</h4>
    <div fxLayout="row">
        <p-calendar [locale]="locale" styleClass="form-input" [(ngModel)]="form.startDate"
                    dateFormat="dd/mm/yy"
                    [showIcon]="true"></p-calendar>
        <div class="ui-float-label line" fxLayout="column">
            <input type="text" class="form-input"
                   [(ngModel)]="form.startTime"
                   maxlength="5"
                   mdwTimeStringInput pInputText id="startTime">
            <label for="startTime" i18n="@@placeholder-game-name">Heure de début</label>
        </div>
        <p-calendar [locale]="locale" styleClass="form-input" [(ngModel)]="form.endDate"
                    dateFormat="dd/mm/yy"
                    [showIcon]="true"></p-calendar>
        <div class="ui-float-label line" fxLayout="column">
            <input type="text" class="form-input"
                   [(ngModel)]="form.endTime"
                   maxlength="5"
                   mdwTimeStringInput
                   pInputText id="endTime">
            <label for="endTime" i18n="@@placeholder-game-name">Heure de fin</label>
        </div>

    </div>
    <h4>Type</h4>
    <div fxLayout="row">
        <div fxLayout="row" class="teams">
            <p-dropdown [(ngModel)]="form.sport"
                        [options]="sports"
                        [style]="{'width':'150px'}"
                        placeholder="Définir un sport"
                        class="form-input"
                        optionLabel="label"
            ></p-dropdown>
            <p-dropdown [(ngModel)]="form.gender"
                        [options]="genders"
                        [style]="{'width':'150px'}"
                        placeholder="Définir un genre"
                        class="form-input"
                        optionLabel="label"
            ></p-dropdown>
            <p-dropdown [(ngModel)]="form.category"
                        [options]="categories"
                        [style]="{'width':'150px'}"
                        placeholder="Définir une categorie"
                        class="form-input"
                        optionLabel="label"
            ></p-dropdown>
        </div>
    </div>
    <h4>Endroits</h4>
    <div fxLayout="row">
        <p-dropdown [(ngModel)]="form.place" [options]="places"
                    [autoWidth]="false"
                    [showClear]="true"
                    i18n-placeholder="@@placeholder-select-input-game-place"
                    placeholder="Selectionnez un terrain"
                    optionLabel="name"
                    (onChange)="setCompleteAddress()"
                    class="form-input"
                    [style]="{'width':'200px'}" appendTo="body"></p-dropdown>
        <div class="ui-float-label line handle-dropdown" fxFlex="50" fxLayout="column">
            <input type="text" class="form-input"
                   [(ngModel)]="form.address"
                   pInputText id="address">
            <label for="address" i18n="@@placeholder-game-address">Adresse</label>
        </div>
    </div>
    <div class="ui-float-label" fxLayout="column">
        <textarea [rows]="5" [cols]="30" pInputTextarea
                  id="description"
                  [(ngModel)]="form.description"></textarea>
        <label for="description" i18n="@@placeholder-natural-team-description">Description</label>
    </div>
    <div class="ui-float-label" fxLayout="column">
                    <textarea [rows]="5" [cols]="30" pInputTextarea
                              id="short-description"
                              [(ngModel)]="form.shortDescription"></textarea>
        <label for="short-description" i18n="@@placeholder-natural-team-short-description">Description courte</label>
    </div>
    <div fxLayoutAlign="end center">
        <button pButton type="button" icon="fa-check"
                (click)="saveTraining()"
                i18n-label="@@button-save" label="Sauvegarder"></button>
    </div>
</div>
<div fxFlex="50">
    <div *ngIf="!loadData && !isNew()" fxFlex="100" fxLayout="column" class="form-group card card-w-title roster">
        <p-tabView [activeIndex]="1">
            <p-tabPanel header="Groupes">
                <app-attach-groups [canAttach]="training" [(groups)]="training.groups"
                                   (onSave)="saveTraining()"
                                   [groupType]="groupType"></app-attach-groups>
            </p-tabPanel>
            <p-tabPanel header="Participants ({{training.attendances.length}})">
                <app-event-add-attendance [event]="training"
                                          (onUpdate)="onUpdateAttendance($event)"></app-event-add-attendance>
                <app-event-attendance-list-view [event]="training"
                                                *ngIf="training.attendances.length > 0"
                                                (onUpdate)="onUpdateAttendance($event)"
                                                [mode]="modeAttendanceListView"></app-event-attendance-list-view>
                <div *ngIf="training.attendances.length === 0">
                    Aucun participants pour le moment
                </div>

            </p-tabPanel>
        </p-tabView>
    </div>
</div>