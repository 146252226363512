import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ActionService} from '../services/action.service';
import {Action} from '../models/action';
import {Data} from '../models/data';
import {SessionService} from '@genos/user-service';
 
@Component({
    selector: 'app-action-list',
    templateUrl: './action-list.component.html',
    styleUrls: ['./action-list.component.scss'],
})
export class ActionListComponent implements OnInit {

    @Input() type: string;
    @Input() id: number;

    actions: Action[];
    load: boolean;

    constructor(private actionService: ActionService,
                private session: SessionService) {
    }

    ngOnInit() {
        this.actions = [];
        this._init(this.type, this.id);
    }

    reload(type: string, id: number) {
        this._init(type, id);
    }

    getTitle(action: Action): string {
        let s = action.created.format('DD/MM/YYYY HH:mm');
        s += ' ' + action.label + ' par ';
        const b = this.session.getUser().id === action.idUser;
        if (b) {
            s += 'vous';
        } else {
            s += action.idUser;
        }
        return s;
    }

    getDatas(action: Action): Data[] {
        const datas = [];
        const _new = JSON.parse(action.newValue);
        const _old = JSON.parse(action.oldValue);
        const lN = Object.keys(_new);
        const lO = Object.keys(_old);
        if (lN.length === lO.length) {
            Object.entries(_new).forEach(([key, value]) => {
                datas.push(new Data(key, _old[key], _new[key]));
            });
        } else if (lN.length > lO.length) {
            Object.entries(_new).forEach(([key, value]) => {
                datas.push(new Data(key, _old[key], _new[key]));
            });
        } else {
            Object.entries(_old).forEach(([key, value]) => {
                datas.push(new Data(key, _old[key], _new[key]));
            });
        }
        return datas;
    }

    private _init(type: string, id: number) {
        this.load = true;
        this.actionService.getActionsToAttachObject(type, id).subscribe(actions => {
            this.actions = actions;
            this.load = false;
        });
    }

}
