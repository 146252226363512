import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PersonsListComponent} from './persons-list/persons-list.component';
import {RouterModule} from '@angular/router';
import {PersonsRoutes} from './persons.routes';
import {
    AutoCompleteModule,
    ButtonModule,
    ChartModule,
    DataTableModule,
    DropdownModule,
    FileUploadModule,
    InputSwitchModule,
    InputTextModule,
    MultiSelectModule,
    PanelModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    SliderModule,
    SplitButtonModule,
    TooltipModule
} from 'primeng/primeng';
import {TableModule} from 'primeng/table';

import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ContactsModule} from '../genos/contacts/contacts.module';
import {InfosClubComponent} from './infos-club/infos-club.component';
import {KeyFilterModule} from 'primeng/components/keyfilter/keyfilter';
import {SportAbilitiesComponent} from './sport-abilities/sport-abilities.component';
import {
    MatButtonModule,
    MatCardModule,
    MatChipsModule, MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatListModule, MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule
} from '@angular/material';
import {GroupsService, PersonInformationsService, PersonsService} from '@genos/person-service';
import {LicenceModule} from '../licence/licence.module';
import {GenosPersonsService} from '../genos/services/genos-persons.service';
import {PersonSimpleComponent} from './person-simple/person-simple.component';
import {ProfileCreateFormComponent} from './profile-create-form/profile-create-form.component';
import {GlobalModule} from '../global/global.module';
import {GroupCreateFormComponent} from './group-create-form/group-create-form.component';
import {GroupInfoComponent} from './group-info/group-info.component';
import {GroupMembersComponent} from './group-members/group-members.component';
import {MembersModule} from '../members/members.module';
import {GroupPersonComponent} from './group-person/group-person.component';

@NgModule({
    imports: [
        LicenceModule,
        MembersModule,
        GlobalModule,
        MatChipsModule,
        MatIconModule,
        MatCardModule,
        MatInputModule,
        MatListModule,
        MatExpansionModule,
        MatButtonModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        ReactiveFormsModule,
        ButtonModule,
        CommonModule,
        ContactsModule,
        FlexLayoutModule,
        TooltipModule,
        ProgressSpinnerModule,
        ContactsModule,
        PanelModule,
        RadioButtonModule,
        DropdownModule,
        AutoCompleteModule,
        ButtonModule,
        FormsModule,
        InputTextModule,
        DataTableModule,
        TableModule,
        ChartModule,
        InputSwitchModule,
        SliderModule,
        SplitButtonModule,
        FileUploadModule,
        MultiSelectModule,
        KeyFilterModule,
        RouterModule.forChild(PersonsRoutes)
    ],
    declarations: [PersonsListComponent,
        InfosClubComponent,
        SportAbilitiesComponent,
        PersonSimpleComponent,
        ProfileCreateFormComponent,
        GroupCreateFormComponent,
        GroupInfoComponent,
        GroupMembersComponent,
        GroupPersonComponent],
    exports: [PersonSimpleComponent,
        ProfileCreateFormComponent,
        GroupPersonComponent,
        SportAbilitiesComponent,
        InfosClubComponent,
        GroupMembersComponent,
        GroupInfoComponent, GroupCreateFormComponent],
    providers: [PersonsService, PersonInformationsService, GenosPersonsService, GroupsService]
})
export class PersonsModule {
}
