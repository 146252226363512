import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GameOptionsComponent} from './game-options/game-options.component';
import {FormsModule} from '@angular/forms';
import {CheckboxModule} from 'primeng/primeng';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        CheckboxModule,
        FlexLayoutModule
    ],
    exports: [GameOptionsComponent],
    providers: [],
    declarations: [GameOptionsComponent]
})
export class GameModule {
}
