import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Contest} from '../models/contest';
import {ContestsService} from '../services/contests.service';
 import {GamesService} from '../../game/services/games.service';
import {Game} from '../../game/models/game';
import * as moment from 'moment';
import {MessageService} from '../../../global/services/message.service';

@Component({
    selector: 'app-contest-games',
    templateUrl: './contest-games.component.html',
    styleUrls: ['./contest-games.component.scss']
})
export class ContestGamesComponent implements OnInit {

    @Input() contest: Contest;
    @Output() onSaveGame: EventEmitter<void> = new EventEmitter<void>();


    games: Game[];
    currentGame: Game;
    availableGames: Game[];
    normalMode: boolean;
    addMode: boolean;

    beforeDeleteGames: Game[];
    beforeAddGames: Game[];
    removeMode: boolean;
    // modeGameItem: GameItemMode;

    loadSaveGame: boolean;

    constructor(private gamesService: GamesService,
                private messageService: MessageService,
                private contestsService: ContestsService) {
    }

    ngOnInit() {
        this.games = this.contest.games;
        this.setNormalMode();
    }

    saveGame() {
        this.onSaveGame.emit();
        this.setNormalMode();
        this.games = this.contest.games;

    }

    setCurrentGameToEdit(event) {
        this.currentGame = event;
        this.passEditMode();
    }

    hasNoGames() {
        return this.games.length === 0;
    }

    setNormalMode() {
        this.beforeDeleteGames = [];
        this.beforeAddGames = [];
        this.normalMode = true;
        this.addMode = false;
        this.removeMode = false;
    }

    showCancel() {
        return !this.normalMode;
    }

    removeGame(game) {
        this.games.splice(this.games.indexOf(game), 1);
    }

    changeGame() {
        this.games.push(this.currentGame);
    }


    cancel() {
        if (this.removeMode) {
            this.games = this.beforeDeleteGames.slice(0);
        }
        if (this.addMode) {
            this.games = this.beforeAddGames.slice(0);
        }
        this.setNormalMode();
    }

    passEditMode() {
        this.normalMode = false;
    }

    passAddMode() {
        if (this.beforeAddGames.length === 0) {
            this.beforeAddGames = this.games.slice(0);
        }
        this.currentGame = new Game();
        this.currentGame.startDate = moment();
        this.currentGame.endDate = this.currentGame.startDate.clone().add(3, 'h');
        this.currentGame.idParent = this.contest.id;
        this.currentGame.typeParent = 'CONTEST';
        this.addMode = true;
        this.removeMode = false;
        this.normalMode = false;
    }

    passRemoveMode() {
        if (this.beforeDeleteGames.length === 0) {
            this.beforeDeleteGames = this.games.slice(0);
        }
        this.addMode = false;
        this.removeMode = true;
        this.normalMode = false;
    }
}
