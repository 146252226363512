import {EventAttendanceStatus} from '../enums/event-attendance-status.enum';
import {EventAttendance} from '../models/event-attendance';
import {EventsService} from '../services/events.service';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Event} from '../models/event';
import {MessageService} from '../../global/services/message.service';

@Component({
    selector: 'app-event-attendance-update-view',
    templateUrl: './event-attendance-update-view.component.html',
    styleUrls: ['./event-attendance-update-view.component.scss']
})
export class EventAttendanceUpdateViewComponent implements OnInit {

    @Input() attendance: EventAttendance;
    @Input() event: Event;
    @Output() onChangeStatus = new EventEmitter<void>();

    available: boolean;
    notAvailable: boolean;
    toBeConfirm: boolean;
    notAnswered: boolean;
    classStatus: string;

    constructor(private eventsService: EventsService,
                private messageService: MessageService) {
    }

    ngOnInit() {
        this.setStatus();
        this.classStatus = 'border_' + this.attendance.status.toString().toLowerCase();
    }

    setStatus() {
        this.available = this.attendance.status === EventAttendanceStatus.AVAILABLE;
        this.notAvailable = this.attendance.status === EventAttendanceStatus.NOT_AVAILABLE;
        this.toBeConfirm = this.attendance.status === EventAttendanceStatus.TO_BE_CONFIRM;
        this.notAnswered = this.attendance.status === EventAttendanceStatus.NOT_ANSWERED;
    }

    passAvailable() {
        this.changeStatus(EventAttendanceStatus.AVAILABLE);
    }

    passNotAvailable() {
        this.changeStatus(EventAttendanceStatus.NOT_AVAILABLE);

    }

    passNotAnswered() {
        this.changeStatus(EventAttendanceStatus.NOT_ANSWERED);
    }

    passToBeConfirmed() {
        this.changeStatus(EventAttendanceStatus.TO_BE_CONFIRM);
    }

    private changeStatus(status: EventAttendanceStatus) {
        this.attendance.status = status;
        delete this.event.currentAttendances;
        delete this.event.attendances;
        this.attendance.event = this.event;
        this.eventsService.changeAttendanceStatus(this.attendance, this.event.id).subscribe(attendance => {
            this.messageService.addSuccess('Données sauvegardées', 'La présence pour cet évènement à été modifié');
            this.attendance = attendance;
        });
        this.onChangeStatus.emit();
    }
}
