import {map, mergeMap, pairwise, takeWhile} from 'rxjs/operators';
import {Event} from '../../events/models/event';


import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

import {HttpHandlerService} from './http_handler.service';
import {TalkOld} from '../models/talk-old';
import {Message, MessageContentType} from '../models/message';
import {IdToEvent, IdToMessages, IdToTalk, Repository} from '../models/repository';
import {TalkMode} from '../enums/talk-mode.enum';
import {UriWithName} from '@mdw/usual-service';

@Injectable()
export class DataService implements Repository {
    smsRepo: IdToMessages = {};
    talkRepo: IdToTalk = {};
    eventRepo: IdToEvent = {};

    constructor(private http: HttpHandlerService) {
        this.getRandomUsers().subscribe((t: TalkOld) => {
            this.talkRepo[t.id] = t;
            /* this.genMessages(t.id, t.members.map(m => m.uri)).subscribe(m => {
                this.smsRepo[m.threadID] = this.smsRepo[m.threadID] || [];
                this.smsRepo[m.threadID].push(m);

                // set message to last
                t.timestamp = m.timestamp;
                t.snippet = m.content;
                t.unreadCount = this.chooseAny([0, 0, 0, 0, 1, 2, 3, 5, 8]);
                t.mode = TalkMode.NORMAL;
            });*/
        });
        this.getRandomEvents().subscribe((es: Event[]) => {
            es.forEach(e => {
                this.eventRepo[e.id] = e;
            });
        });
    }

    getMessages(threadID: string): Message[] {
        return this.smsRepo[threadID];
    }

    getEvents(): Event[] {
        return Object.values(this.eventRepo);
    }

    getTalks(): TalkOld[] {
        return Object.values(this.talkRepo);
    }

    getEventInfo(id: string): Event {
        return this.eventRepo[id];
    }

    getTalkInfo(id: string): TalkOld {
        return this.talkRepo[id];
    }

    getQuotes(): Observable<string> {
        return this.http.getAndCache('https://talaikis.com/api/quotes/').pipe(
            mergeMap(x => x),
            map(x => x['quote']));
    }

    genMessages(threadID: string, userIDs: string[]): Observable<Message> {
        const day = 86400000;
        let lastTimeStamp = Date.now() - day * 3;
        return this.getQuotes().pipe(
            map(quote => {
                const m = new Message();
                m.contentType = MessageContentType.PlainText;
                m.content = quote;
                m.userID = this.chooseAny([...userIDs, 'me']);
                lastTimeStamp = this.getTimeAfter(lastTimeStamp);
                m.timestamp = lastTimeStamp;
                m.threadID = threadID;
                return m;
            }),
            takeWhile(m => m.timestamp < Date.now()),
            pairwise(),
            mergeMap((pair: Message[]) => {
                if (pair.length === 1) {
                    pair[0].isLocalLast = true;
                    pair[0].isNewDay = true;
                    return of(pair[0]);
                }

                const curr = pair[0];
                const next = pair[1];
                if (curr.userID !== next.userID) {
                    curr.isLocalLast = true;
                }
                if (new Date(curr.timestamp).getDate() !== new Date(next.timestamp).getDate()) {
                    next.isNewDay = true;
                }
                return of(curr);
            }));
    }

    getRandomEvents(): Observable<Event[]> {
        return this.http
            .getAndCache(
                'https://randomapi.com/api/2c46af92f00a4ba3a959e8769159b170').pipe(
                map(x => {
                    const events = x['results'][0];
                    const _events: Event[] = [];
                    events.forEach((e: any) => {
                        const event = new Event();
                        event.startDate = e.startDate;
                        event.endDate = e.endDate;
                        event.title = e.title;
                        event.id = e.id;
                        _events.push(event.build());
                    });
                    return _events;
                }));
    }

    getRandomUsers(): Observable<TalkOld> {
        const cap = (x: string) => x.charAt(0).toUpperCase() + x.substr(1);
        return this.http
            .getAndCache(
                'https://randomuser.me/api/?inc=name,cell,picture&results=20').pipe(
                map(x => x['results']),
                mergeMap(x => x),
                map(x => {
                    const thread = new TalkOld();
                    thread.name = cap(x['name']['first']) + ' ' + cap(x['name']['last']);
                    thread.avatar = x['picture']['large'];
                    thread.id = x['cell'];
                    const m = new UriWithName();
                    m.uri = thread.id;
                    m.name = '';
                    thread.members = [m];
                    return thread;
                }), pairwise(),
                mergeMap((pair: TalkOld[]) => {
                    const prob = this.randomInt(0, 100);
                    if (pair.length < 2 || prob < 75) {
                        return pair;
                    }
                    const x = pair[0];
                    const y = pair[1];
                    const thread = new TalkOld();
                    thread.name = x.name + ', ' + y.name;
                    thread.avatar = 'https://image.freepik.com/free-icon/group_318-27951.jpg';
                    // for merging avatars, see https://stackoverflow.com/a/15620872
                    thread.id = x.id + y.id;
                    const m = new UriWithName();
                    m.uri = x.id;
                    m.name = '';
                    const n = new UriWithName();
                    n.uri = x.id;
                    n.name = '';
                    thread.members = [m, n];
                    pair.push(thread);
                    return pair;
                }));
    }

// Returns a random integer between min (included) and max (included)
    randomInt(min: number, max: number): number {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    chooseAny<E>(arr: E[]): E {
        if (!arr || arr.length === 0) {
            throw new Error('cannot choose from null or empty array');
        }
        return arr[this.randomInt(0, arr.length - 1)];
    }

// a day is about 86400000 milliseconds
    getTimeAfter(timestamp: number): number {
        return timestamp + this.randomInt(0, 50000000);
    }
}
