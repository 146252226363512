<div class="layout-breadcrumb">
    <ul>
        <li><a routerLink="/events"><i class="material-icons">home</i></a></li>
        <li>/</li>
        <ng-template ngFor let-item let-last="last" [ngForOf]="items">
            <li>
                <a [routerLink]="item.routerLink" *ngIf="item.routerLink">{{item.label}}</a>
                <ng-container *ngIf="!item.routerLink">{{item.label}}</ng-container>
            </li>
            <li *ngIf="!last">/</li>
        </ng-template>
    </ul>

    <div class="layout-breadcrumb-options">
        <!--<a routerLink="/calendar" title="Calendrier" i18n-title="@@title-calendar">
            <i class="material-icons">event</i>
        </a>-->
        <a routerLink="/logout" title="Logout">
            <i class="material-icons">power_settings_new</i>
        </a>
    </div>
</div>