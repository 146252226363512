<mat-form-field class="example-full-width" *ngIf="!needLockFieldAfterSelection || (!isSelected && needLockFieldAfterSelection)">
    <input type="text" placeholder="Selectionner une adresse" aria-label="Adresse"
           matInput [formControl]="myControl" #textInput [required]="isRequired"
           [matAutocomplete]="autoAddress">
    <mat-error *ngIf="myControl.invalid">
        {{getErrorMessageControl(myControl)}}
    </mat-error>
    <mat-autocomplete #autoAddress="matAutocomplete" (optionSelected)="onPlaceSelected($event)">
        <mat-option *ngIf="search">
            <mat-spinner [diameter]="20"></mat-spinner>
        </mat-option>
        <mat-option *ngFor="let p of filteredPlaces" [value]="p">
            {{p.description}}
        </mat-option>
    </mat-autocomplete>
    <mat-icon *ngIf="myControl.value !== null" matSuffix
              (click)="clearValue()">close
    </mat-icon>
</mat-form-field>
<div class="address-autocomplete-selected" fxLayout="row" *ngIf="isSelected && needLockFieldAfterSelection">
    <div fxFlex="90">{{selectedValue.getComplete()}}</div>
    <div fxFlex="10"><i class="material-icons" (click)="removeSelectedAddress()">close</i></div>
</div>