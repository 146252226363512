import {Component, Input, OnInit} from '@angular/core';
import {EventAttendance} from '../models/event-attendance';

@Component({
    selector: 'app-event-attendance-single-view',
    templateUrl: './event-attendance-single-view.component.html',
    styleUrls: ['./event-attendance-single-view.component.scss']
})
export class EventAttendanceSingleViewComponent implements OnInit {

    @Input() attendance: EventAttendance;

    classStatus: string;

    constructor() {
    }

    ngOnInit() {
        this.classStatus = 'border_' + this.attendance.status.toString().toLowerCase();
    }

}
