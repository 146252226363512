import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Dashboard} from '../models/dashboard';
import {DtoSecurisedService, GlobalSessionService} from '@genos/user-service';
import {AppConfigService} from '@mdw/usual-service';

@Injectable()
export class DashboardService extends DtoSecurisedService<Dashboard> {


    getBaseUrl(): string {
        return this.appConfigService.getSecuredBaseUrl() + '/dashboards';
    }

    constructor(protected sessionService: GlobalSessionService,
                protected client: HttpClient,
                protected appConfigService: AppConfigService) {
        super(client, sessionService);
    }

    update(t: Dashboard): Observable<Dashboard> {
        return undefined;
    }

    create(dashboard: Dashboard): Observable<Dashboard> {
        return this.post<Dashboard>(this.getBaseUrl(), dashboard);
    }

    public getToCurrentUser(): Observable<Dashboard[]> {
        return this.getArray<Dashboard>(this.getBaseUrl(), {}, Dashboard);
    }

    public remove(dashboard: Dashboard): Observable<any> {
        return this.delete(this.getBaseUrl() + '/' + dashboard.id);
    }
}
