import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {EventRoutes} from './event.routes';
import {FlexLayoutModule} from '@angular/flex-layout';
import {
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSelectModule,
    MatSnackBarModule
} from '@angular/material';
import {EventsService} from './services/events.service';
import {EventComponent} from './event/event.component';
import {EventAttendanceComponent} from './event-attendance/event-attendance.component';
import {
    AutoCompleteModule,
    CalendarModule,
    CheckboxModule,
    DropdownModule,
    FileUploadModule,
    InputTextareaModule,
    InputTextModule,
    PanelModule,
    ProgressSpinnerModule,
    TabViewModule,
    ToggleButtonModule
} from 'primeng/primeng';
import {EventAttendanceListComponent} from './event-attendance-list/event-attendance-list.component';
import {EventAttendanceListViewComponent} from './event-attendance-list-view/event-attendance-list-view.component';
import {EventAttendanceSingleViewComponent} from './event-attendance-single-view/event-attendance-single-view.component';
import {EventAttendanceUpdateViewComponent} from './event-attendance-update-view/event-attendance-update-view.component';
import {EventsFilterPipe} from './pipes/events-filter.pipe';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EventAttendanceListByTeamComponent} from './event-attendance-list-by-team/event-attendance-list-by-team.component';
import {EventEditComponent} from './event-edit/event-edit.component';
import {EventShowComponent} from './event-show/event-show.component';
import {EventChronoItemComponent} from './event-chrono-item/event-chrono-item.component';
import {EventsChronoComponent} from './events-chrono/events-chrono.component';
import {EventAddAttendanceComponent} from './event-add-attendance/event-add-attendance.component';
import {PlaceModule} from '../genos/place/place.module';
import {EventChronoHeaderGameItemComponent} from './event-chrono-header-game-item/event-chrono-header-game-item.component';
import {EventChronoHeaderItemComponent} from './event-chrono-header-item/event-chrono-header-item.component';
import {EventChronoHeaderTrainingItemComponent} from './event-chrono-header-training-item/event-chrono-header-training-item.component';
import {EventCallingComponent} from './event-calling/event-calling.component';
import {EventAttendancesService} from './services/event-attendances.service';
import {TalkModule} from '../mdw/talk/talk.module';
import {GroupModule} from '../genos/group/group.module';
import {MdwUsualServiceModule} from '@mdw/usual-service';
import {EventsViewDayComponent} from './events-view-day/events-view-day.component';
import {GenosModule} from '../genos/genos.module';
import {GlobalModule} from '../global/global.module';
import {EventFormComponent} from './event-form/event-form.component';
import {PlacesModule} from '../places/places.module';
import {EventService} from './services/event.service';

@NgModule({
    imports: [
        GenosModule,
        CommonModule,
        GroupModule,
        TalkModule,
        PlacesModule,
        PlaceModule,
        MdwUsualServiceModule,
        CommonModule,
        GlobalModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatRadioModule,
        MatIconModule,
        MatButtonModule,
        MatListModule,
        MatSnackBarModule,
        ProgressSpinnerModule,
        ToggleButtonModule,
        AutoCompleteModule,
        CheckboxModule,
        DropdownModule,
        TabViewModule,
        PanelModule,
        InputTextModule,
        InputTextareaModule,
        MatChipsModule,
        FileUploadModule,
        CalendarModule,
        FormsModule,
        RouterModule.forChild(EventRoutes)
    ],
    declarations: [EventComponent,
        EventAttendanceComponent,
        EventAttendanceListComponent,
        EventAttendanceListViewComponent,
        EventAttendanceSingleViewComponent,
        EventAttendanceUpdateViewComponent,
        EventsFilterPipe,
        EventAttendanceListByTeamComponent,
        EventEditComponent,
        EventShowComponent,
        EventChronoItemComponent,
        EventsChronoComponent,
        EventAddAttendanceComponent,
        EventChronoHeaderGameItemComponent,
        EventChronoHeaderItemComponent,
        EventChronoHeaderTrainingItemComponent,
        EventCallingComponent,
        EventsViewDayComponent,
        EventFormComponent,
    ],
    providers: [EventsService, EventService, EventAttendancesService],
    exports: [EventAttendanceListViewComponent,
        EventCallingComponent,
        EventAddAttendanceComponent,
        EventAttendanceComponent,
        EventsViewDayComponent,
        EventsChronoComponent,
        EventFormComponent,
        EventAttendanceListByTeamComponent]
})
export class EventModule {
}
