<div fxLayout="row" class="game" fxFlex="100" fxLayoutAlign="space-between center">
    <div class="text" fxFlex="20">{{game.startDate.format('DD/MM/YYYY')}} {{game.startTime}} - {{game.reference}}</div>
    <div class="team inside" fxFlex="20" fxLayout="row" fxLayoutAlign="end center">
        <div fxLayout="row" *ngIf="game.insideTeam">
            <div class="name">{{game.insideTeam.name}}</div>
            <img hide-xs="" class="logo" src="{{game.insideTeam.logo}}">
        </div>
    </div>
    <div class="score" fxLayout="row" fxFlex="5">
        <div class=" inside" fxFlex="40">{{game.insideScore}}</div>
        <div class="versus" fxFlex="20">-</div>
        <div class=" outside" fxFlex="40">{{game.outsideScore}}</div>
    </div>
    <div class="team outside" fxFlex="20" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="row" *ngIf="game.outsideTeam">
            <img hide-xs="" class="logo" src="{{game.outsideTeam.logo}}">
            <div class="name">{{game.outsideTeam.name}}</div>
        </div>
    </div>
    <div class="text" fxFlex="20">{{game.getPlaceName()}} - {{game.status}}</div>
    <div class="actions" fxLayout="row" fxLayoutAlign="end end">
        <div fxLayoutAlign="center center" class="button-i" *ngIf="!normalMode" (click)="passNormalMode()">
            <i class="material-icons">cancel</i>
        </div>
        <div fxLayoutAlign="center center" class="button-i" *ngIf="!normalMode" (click)="saveGame()">
            <i class="material-icons">save</i>
        </div>
        <div fxLayoutAlign="center center" class="button-i" *ngIf="normalMode" (click)="passEditMode()">
            <i class="material-icons">edit_mode</i>
        </div>
        <div fxLayoutAlign="center center" class="button-i" *ngIf="normalMode" (click)="goToGame()">
            <i class="material-icons">input</i>
        </div>
    </div>
</div>