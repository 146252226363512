import {HostListener, OnInit} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import * as moment from 'moment';
import {Moment} from 'moment';

export class CommonComponent implements OnInit {

    innerWidth: number;
    public isMobile: boolean;

    xs = 599;
    sm = 959;
    md = 1279;
    lg = 1919;
    xl = 5000;


    public stringToDate(sDate: string): Moment {
        return moment(sDate, 'DD/MM/YYYY').add(4, 'hours');
    }

    public dateToString(date: Moment): string {
        return date.format('DD/MM/YYYY');
    }

    public getErrorMessageControl(fc: AbstractControl): string {
        let message = '';
        if (fc != null && fc != null) {
            if (fc.hasError('required')) {
                message = 'Ce champ est obligatoire';
            }
            if (fc.hasError('email')) {
                message = 'Cet email n\'est pas valide';
            }
        }
        return message;
    }

    public getErrorMessage(form: FormGroup, name: string): string {
        const fc: AbstractControl = form.get(name);
        return this.getErrorMessageControl(fc);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
        this.handleResize();
    }

    @HostListener('backbutton', ['$event'])
    onBackButton(event) {
        event.preventDefault();
        alert('Back Button is Pressed!');
    }

    @HostListener('window:orientationchange', ['$event'])
    onResizeOrientation(event) {
        this.innerWidth = window.innerWidth;
        this.handleResize();
    }

    ngOnInit(): void {
        this.innerWidth = window.innerWidth;
        this.handleResize();
    }

    handleResize() {
        this.isMobile = this.defineIsMobile();
    }

    defineIsMobile(): boolean {
        return this.isXS() || this.isSM();
    }

    protected isXS(): boolean {
        return this.innerWidth < this.xs + 1;
    }

    protected isSM(): boolean {
        return this.innerWidth > this.xs && this.innerWidth < this.sm + 1;
    }

    protected isMD(): boolean {
        return this.innerWidth > this.sm && this.innerWidth < this.md + 1;
    }

    protected isLG(): boolean {
        return this.innerWidth > this.md && this.innerWidth < this.lg + 1;
    }

    protected isXL(): boolean {
        return this.innerWidth > this.lg;
    }

    get getIsMobile() {
        return this.isMobile;
    }
}

