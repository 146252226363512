import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MessageService} from '../../../global/services/message.service';
import {CleanStringPipe} from '@mdw/usual-service';
import {Role, RolesService, SessionService} from '@genos/user-service';

@Component({
    selector: 'app-role-edit',
    templateUrl: './role-edit.component.html',
    styleUrls: ['./role-edit.component.scss'],
    providers: [CleanStringPipe]
})
export class RoleEditComponent implements OnInit {

    @Input() role: Role;
    @Output() onCancel: EventEmitter<boolean> = new EventEmitter<boolean>();

    loadUpdate: boolean;
    loadDelete: boolean;
    errorLabel: boolean;
    errorName: boolean;
    errorDescription: boolean;

    constructor(private session: SessionService,
                private messageService: MessageService,
                private cleanString: CleanStringPipe,
                private rolesService: RolesService) {

    }

    ngOnInit() {
    }

    lockButtons(): boolean {
        return this.loadUpdate || this.loadDelete;
    }

    update(): void {
        this.loadUpdate = true;
        this.role.name = this.cleanString.transform(this.role.name, true);
        this.rolesService.update(this.role).subscribe(role => {
            this.messageService.addSuccess('Modification réussie', 'Le role à bien été modifié');
            this.onCancel.emit(true);
            this.loadUpdate = false;
        }, error => {
            this.messageService.addError('', error.message);
            this.loadUpdate = false;
        });
    }

    delete(): void {
        this.loadDelete = true;
        this.rolesService.deleteRole(this.role).subscribe(role => {
            this.messageService.addSuccess('Suppression réussie', 'Le role à bien été supprimé');
            this.onCancel.emit(true);
            this.loadDelete = false;
        }, error => {
            this.messageService.addError('', error.message);
            this.loadDelete = false;
        });
    }

    cancel(): void {
        this.onCancel.emit(false);
    }
}
