import {CommonModule} from '@angular/common';
import {TimeSince} from './pipes/timesince.pipe';
import {TalkListComponent} from './talk-list/talk-list.component';
import {
    MatBadgeModule,
    MatBottomSheetModule,
    MatIconModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatToolbarModule
} from '@angular/material';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MdwUsualServiceModule} from '@mdw/usual-service';
import {TalkBottomSheetComponent} from './talk-bottom-sheet/talk-bottom-sheet.component';
import {TalkComponent} from './talk/talk.component';
import {NgModule} from '@angular/core';
import {TalkZoneComponent} from './talk-zone/talk-zone.component';
import {TalkCreateComponent} from './talk-create/talk-create.component';
import {MembersModule} from '../members/members.module';
import {FormsModule} from '@angular/forms';
import {TalkService} from './services/talk.service';
import {GenosModule} from '../genos/genos.module';
import {GlobalModule} from '../global/global.module';

@NgModule({
    imports: [
        CommonModule,
        GenosModule,
        FormsModule,
        GlobalModule,
        MembersModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatBadgeModule,
        MatIconModule,
        MatToolbarModule,
        FlexLayoutModule,
        MdwUsualServiceModule,
        MatBottomSheetModule
    ],
    declarations: [TimeSince, TalkListComponent, TalkBottomSheetComponent, TalkComponent, TalkZoneComponent, TalkCreateComponent],
    exports: [TimeSince, TalkListComponent, TalkComponent, TalkZoneComponent],
    providers: [TalkService],
    entryComponents: [TalkBottomSheetComponent]
})
export class TalksModule {
}
