import {isPlatformBrowser} from '@angular/common';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

@Injectable()
export class OnDemandJsLoadService {
    private scripts: string[] = [];

    constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    }

    public load(script: string): Observable<boolean> {
        const s: BehaviorSubject<boolean> = new BehaviorSubject(false);

        const existingScript = this.scripts.find(sc => sc === script);

        if (existingScript) {
            s.next(true);
        } else {
            this.scripts = [...this.scripts, script];

            const scriptElement = document.createElement('script');
            scriptElement.type = 'text/javascript';
            scriptElement.src = script;

            if (isPlatformBrowser(this.platformId)) {
                scriptElement.onload = () => {
                    s.next(true);
                };

                scriptElement.onerror = (error: any) => {
                    s.error('Couldn\'t load script ' + script);
                };

                document.getElementsByTagName('body')[0].appendChild(scriptElement);
            }
        }

        return s.asObservable();
    }

    private doRetryUntilTheTestPasses(toTry: () => void, maxRetry: number, delay: number, s: Subject<boolean>) {
        try {
            s.next(true);
            s.complete();
        } catch (e) {
            if (maxRetry > 0) {
                setTimeout(() => this.doRetryUntilTheTestPasses(toTry, maxRetry - 1, delay, s), delay);
            } else {
                s.error({success: false, errorMsg: 'too.many.retries'});
            }
        }
    }

    public loadUntilTestPasses(script: string, toTry: () => void, maxRetry: number, delay: number): Observable<boolean> {
        const s = new Subject<boolean>();
        this.load(script).subscribe(loaded => {
            if (loaded) {
                this.doRetryUntilTheTestPasses(toTry, maxRetry, delay, s);
            }
        });

        return s.asObservable();
    }
}
