 import {isNullOrUndefined} from 'util';
 import {ToUrl} from '@mdw/usual-service';

export class LicencesMemberFilter extends ToUrl {

    member: number;
    period: string;

    toUrl(): string {
        let url = '';
        if (!isNullOrUndefined((this.member))) {
            url += this.getSeparator() + 'member=' + this.member;
        }
        if (!isNullOrUndefined((this.period))) {
            url += this.getSeparator() + 'period=' + this.period;
        }
        return url;
    }

    withMember(member: number): LicencesMemberFilter {
        this.member = member;
        return this;
    }

    withPeriod(period: string): LicencesMemberFilter {
        this.period = period;
        return this;
    }


}
