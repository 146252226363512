<div class="loader" fxLayout="row" *ngIf="loadData" fxLayoutAlign="center center">
    <p-progressSpinner></p-progressSpinner>
</div>
<div fxFlex="100"  class="my-groups" fxLayout="row" fxLayout.xs="column" *ngIf="!loadData">
    <div fxFlex="20" fxFlex.xs="100">
        <div *ngFor="let group of groups"
             (click)="selectGroup(group)"
             class="card group">
            {{group.name}}
        </div>
    </div>
    <div fxFlex="80" fxFlex.xs="100" class="card group-info" *ngIf="!loadDetailData && haveCurrent()">
        <div class="loader" fxLayout="row" *ngIf="loadDetailData" fxLayoutAlign="center center">
            <p-progressSpinner></p-progressSpinner>
        </div>
        <div fxLayout="column">
            <p-tabView>
                <p-tabPanel class="chat" *ngFor="let talk of talks" header="{{talk.title}}">
                    <!--<app-talk [talk]="talk"></app-talk>-->
                </p-tabPanel>
                <p-tabPanel header="Les membres">
                    <app-group-display-members [showTitle]="false"
                                               [members]="currentGroup.members"></app-group-display-members>
                </p-tabPanel>
                <p-tabPanel header="Les évènements">
                    Coming soon
                </p-tabPanel>
            </p-tabView>

        </div>
    </div>
</div>
