import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {AppComponent} from '../../app.component';
import {Subscription} from 'rxjs';
import {GlobalSessionService} from '@genos/user-service';
import {MenuService} from '../services/menu.service';
import {GlobalMeService} from '../services/global-me.service';


@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    encapsulation: ViewEncapsulation.None


})
export class MenuComponent implements OnInit, OnDestroy {

    @Input() extendedMenu: boolean;
    @Input() isMobile: boolean;
    @Input() reset: boolean;
    @Output() onClick: EventEmitter<void> = new EventEmitter();
    model: any[];

    menuSubscription: Subscription;

    isLoggedIn: boolean;
    private sessionSubscription: Subscription;

    constructor(public app: AppComponent,
                private session: GlobalSessionService,
                private me: GlobalMeService,
                private menuService: MenuService) {
        this.isLoggedIn = session.isLoggedIn();
        this.sessionSubscription = this.session.getSubscriber().subscribe(value => {
            this.isLoggedIn = value;
        });
        this.menuSubscription = this.menuService.getMenuSubscriber().subscribe(v => {
            this.load();
        });
    }

    ngOnDestroy(): void {
        if (this.sessionSubscription) {
            this.sessionSubscription.unsubscribe();
        }
        if (this.menuSubscription) {
            this.menuSubscription.unsubscribe();
        }

    }

    ngOnInit() {
        this.load();
    }

    private load() {
        this.model = this.menuService.getMenusByRights([]);
        this.me.needOrganizations(true, organizations => {
            console.log(organizations);
            this.model = this.menuService.getMenusByOrganizationRelationship(this.me.organizations, this.isMobile);
        });
    }


    changeTheme(theme) {
        const themeLink: HTMLLinkElement = <HTMLLinkElement> document.getElementById('theme-css');
        themeLink.href = 'assets/theme/theme-' + theme + '.css';
    }

    changeLayout(theme) {
        const layoutLink: HTMLLinkElement = <HTMLLinkElement> document.getElementById('layout-css');
        layoutLink.href = 'assets/layout/css/layout-' + theme + '.css';
    }

    updateNanoScroll() {
        setTimeout(() => {
            // jQuery(this.layoutMenuScroller).nanoScroller();
        }, 500);
    }

}

