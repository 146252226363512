import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {ConfigurationRoutes} from './configuration.routes';
import {ConfigurationDashboardComponent} from './configuration-dashboard/configuration-dashboard.component';
import {ConfigurationMailComponent} from './configuration-mail/configuration-mail.component';
import {GenosConfigurationService} from './services/genos-configuration.service';


@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(ConfigurationRoutes)
    ],
    exports: [
        ConfigurationDashboardComponent,
        ConfigurationMailComponent
    ],
    providers: [GenosConfigurationService],
    declarations: [
        ConfigurationDashboardComponent,
        ConfigurationMailComponent
    ]
})
export class ConfigurationModule {
}
