import {Injectable} from '@angular/core';
import {Talk} from '../models/talk';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TalksFilter} from '../models/talks-filter';
import {TalkMessage} from '../models/talk-message';
import {DtoSecurisedService, GlobalSessionService} from '@genos/user-service';
import {AppConfigService} from '@mdw/usual-service';

@Injectable()
export class TalksService extends DtoSecurisedService<Talk> {

    getBaseUrl(): string {
        return this.appConfigService.getSecuredBaseUrl() + '/talks';
    }

    constructor(protected sessionService: GlobalSessionService,
                protected client: HttpClient,
                private appConfigService: AppConfigService) {
        super(client, sessionService);
    }

    update(t: Talk): Observable<Talk> {
        return undefined;
    }

    create(t: Talk): Observable<Talk> {
        return undefined;
    }

    getAll(f: TalksFilter): Observable<Talk[]> {
        return this.getArray(this.getBaseUrl() + f.toUrl(), {}, Talk);
    }

    addMessage(message: TalkMessage, talk: Talk): Observable<TalkMessage> {
        return this.post<TalkMessage>(this.getBaseUrl() + '/' + talk.id + '/messages', message);
    }

}
