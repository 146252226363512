import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {GlobalSessionService} from '@genos/user-service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

    constructor(private session: GlobalSessionService,
                private router: Router) {
    }

    ngOnInit() {
        this.session.destroy();
        this.router.navigate(['/']);
    }

}
