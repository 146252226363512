import {Injectable, NgZone} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {fromEvent} from 'rxjs';

function _window(): any {
    // return the global native browser window object
    return window;
}

@Injectable({
    providedIn: 'root'
})
export class CordovaService {

    private resume: ReplaySubject<void>;
    private backbutton: ReplaySubject<void>;

    constructor(private zone: NgZone) {
        if (environment.isCordova) {
            this.resume = new ReplaySubject<void>();
            this.backbutton = new ReplaySubject<void>();
            fromEvent(document, 'resume').subscribe(event => {
                this.zone.run(() => {
                    this.onResume();
                });
            });
            fromEvent(document, 'backbutton').subscribe(event => {
                console.log(event);
                this.zone.run(() => {
                    console.log('this.onBackbutton');
                    this.onBackbutton();
                });
            });
        }
    }

    getBackButtonSubscriber(): Observable<void> {
        return this.backbutton.asObservable();
    }

    getResumeSubscriber(): Observable<void> {
        return this.resume.asObservable();
    }

    get cordova(): any {
        return _window().cordova;
    }

    get onCordova(): Boolean {
        return !!_window().cordova;
    }

    public onResume(): void {
        this.resume.next();
    }

    public onBackbutton(): void {
        this.backbutton.next();
    }
}
