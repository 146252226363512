import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Contest} from '../../contest/models/contest';
import {Leaderboard} from '../models/leaderboard';
import {LeaderboardType} from '../enums/leaderboard-type.enum';
import {LeaderboardsService} from '../services/leaderboards.service';
import {isNullOrUndefined} from 'util';
import {LeaderboardEditComponent} from '../leaderboard-edit/leaderboard-edit.component';
import {MessageService} from '../../../global/services/message.service';

@Component({
    selector: 'app-leaderboards',
    templateUrl: './leaderboards.component.html',
    styleUrls: ['./leaderboards.component.scss']
})
export class LeaderboardsComponent implements OnInit {

    @Input() contest: Contest;
    @Input() leaderboards: Leaderboard[];
    @ViewChild(LeaderboardEditComponent) leaderboardEditComponent: LeaderboardEditComponent;

    leaderboard: Leaderboard;
    normalMode: boolean;
    editMode: boolean;
    addMode: boolean;
    types: any[];

    constructor(private leaderboardsService: LeaderboardsService,
                private messageService: MessageService) {
    }

    ngOnInit() {
        this.types = [];
        this.types.push({name: 'Par points', value: LeaderboardType.LEADERBOARD_TYPE_POINTS});
        this.types.push({name: 'Par moyenne', value: LeaderboardType.LEADERBOARD_TYPE_AVERAGE});
        this.normalMode = true;
        this.leaderboard = new Leaderboard();
        if (this.leaderboards.length > 0) {
            this.leaderboard = this.leaderboards[0];
        }
    }


    showCancel() {
        return !this.normalMode;
    }

    passToEditMode() {
        this.leaderboard.type = this.types.filter(t => t.value === this.leaderboard.type)[0];
        this.normalMode = false;
        this.editMode = true;
        this.addMode = false;
    }

    cancel() {
        this.normalMode = true;
        this.editMode = false;
        this.addMode = false;
    }

    passAddMode() {
        this.leaderboard = new Leaderboard();
        this.addMode = true;
        this.normalMode = false;
        this.editMode = false;
    }

    haveLeaderboard(): boolean {
        return this.leaderboards.length > 0;
    }

    saveLeaderboard() {
        this.leaderboard.idContest = this.contest.id;
        if (!isNullOrUndefined(this.leaderboard.type.value)) {
            this.leaderboard.type = this.leaderboard.type.value;
        }
        this.leaderboardsService.save(this.leaderboard).subscribe(response => {
            if (isNullOrUndefined(this.leaderboard.id)) {
                this.leaderboards.push(this.leaderboard);
                this.leaderboard = response;
            } else {
                this.leaderboard = response;
            }
            this.cancel();
            this.messageService.addSuccess('Classement sauvegardé', 'Les données du classement ont été sauvegardé');
        });
    }

    deleteLeaderboard() {

    }

    change(event) {
        this.leaderboard = event.value;
    }

    changeType(event) {
        this.leaderboard.type = event.value;
        this.leaderboardEditComponent.reload();
    }

    hasSeveral(): boolean {
        return this.leaderboards.length > 0;
    }
}
