import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {SessionService} from '@genos/user-service';

@Injectable()
export class NoAuthGuard implements CanActivate, CanActivateChild {


    constructor(private session: SessionService,
                private router: Router) {
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return this.canActivate(childRoute, state);
    }

    canActivate(next: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): boolean {
        if (this.session.isLoggedIn()) {
            this.router.navigate(['events']);
            return false;
        } else {
            this.session.logout();
            return true;
        }
    }
}
