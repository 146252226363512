import {Injectable} from '@angular/core';
import {Connection, Session, SubscribeHandler} from 'autobahn';
import {Subject} from 'rxjs';
import {Observable} from 'rxjs';
import {TalkMessage} from '../models/talk-message';
import {environment} from '../../../../environments/environment';

declare var autobahn: any;

@Injectable()
export class TalkWsService {


    connection: Connection;
    session: Session;
    uriTalk: string;

    constructor() {
        this.uriTalk = environment.uriTalk;
    }

    private createConnection(url: string) {
        this.connection = new Connection({
            url: environment.wsEndpoint,
            realm: 'talk'
        });
    }

    public connect(url: string): Observable<Session> {
        const s = new Subject<Session>();
        const self = this;
        this.createConnection(url);
        this.connection.onopen = function (session, details) {
            console.log('onopen');
            console.log(session);
            console.log(details);
            self.session = session;
            s.next(session);
            s.complete();
        };
        this.connection.onclose = function (reason: string, details: any) {
            console.log('onclose');
            console.log(reason);
            console.log(details);
            return false;
        };

        this.connection.open();
        return s.asObservable();
    }

    subscribeOnTalk(idTalk: number, handler: SubscribeHandler) {
        this.session.subscribe(this.uriTalk + idTalk, handler);
    }

    publishOnTalk(idTalk: number, message: TalkMessage) {
        this.session.publish(this.uriTalk + idTalk, [JSON.stringify(message)]);
    }

    public close() {
        try {
            this.connection.close();
        } catch (e) {
            console.log('already close do nothing');
        }
    }
}
