<div fxLayout="column" class="event-attendance" [ngClass]="classStatus">
    <div fxLayout="row" class="item">
        <div fxFlex="20" fxLayoutAlign="start center">
            <img class="roundedImage very-small" [src]="attendance.person.image"/>
        </div>
        <div fxFlex="80" fxLayoutAlign="start center">
            {{attendance.person.getCompleteName()}}
        </div>
    </div>
    <div fxLayout="row">
        <div fxFlex="25" fxLayoutAlign="center center">
            <i class="material-icons available" (click)="passAvailable()"
               [ngClass]="{'selected-available': available}">check_circle</i>
        </div>
        <div fxFlex="25" fxLayoutAlign="center center">
            <i class="material-icons to-be-confirm" (click)="passToBeConfirmed()"
               [ngClass]="{'selected-to-be-confirm': toBeConfirm}">help</i>
        </div>
        <div fxFlex="25" fxLayoutAlign="center center">
            <i class="material-icons not-answered" (click)="passNotAnswered()"
               [ngClass]="{'selected-not-answered': notAnswered}">history</i>
        </div>
        <div fxFlex="25" fxLayoutAlign="center center">
            <i class="material-icons not-available" (click)="passNotAvailable()"
               [ngClass]="{'selected-not-available': notAvailable}">highlight_off</i>
        </div>
    </div>
</div>