<div fxLayout="column">
    <div>
        <app-event-attendance-list
                [attendances]="attendances"></app-event-attendance-list>
    </div>
    <div class="hide-list-button" fxLayoutAlign="center center" fxFlex="100" [fxShow.xs]="true">
        <!--<p-toggleButton [(ngModel)]="showXsList" onLabel="Cacher la liste" offLabel="Voir la liste"></p-toggleButton>-->
    </div>
    <div fxLayout="row" >
        <div fxLayout="column" fxFlex="25">
            <div *ngIf="isCoachMode() || isManagerMode()">
                <app-event-attendance-update-view *ngFor="let attendance of attendanceAvailable"
                                                  (onChangeStatus)="updateParent($event)"
                                                  [event]="event"
                                                  [attendance]="attendance"></app-event-attendance-update-view>
            </div>
            <div *ngIf="isPlayerMode()">
                <app-event-attendance-single-view *ngFor="let attendance of attendanceAvailable"
                                                  [attendance]="attendance"></app-event-attendance-single-view>
            </div>
        </div>
        <div fxLayout="column" fxFlex="25">
            <div *ngIf="isCoachMode() || isManagerMode()">
                <app-event-attendance-update-view *ngFor="let attendance of attendanceToBeConfirm"
                                                  (onChangeStatus)="updateParent($event)"
                                                  [event]="event"
                                                  [attendance]="attendance"></app-event-attendance-update-view>
            </div>
            <div *ngIf="isPlayerMode()">
                <app-event-attendance-single-view *ngFor="let attendance of attendanceToBeConfirm"
                                                  [attendance]="attendance"></app-event-attendance-single-view>
            </div>
        </div>
        <div fxLayout="column" fxFlex="25">
            <div *ngIf="isCoachMode() || isManagerMode()">
                <app-event-attendance-update-view *ngFor="let attendance of attendanceNotAnswered"
                                                  (onChangeStatus)="updateParent($event)"
                                                  [event]="event"
                                                  [attendance]="attendance"></app-event-attendance-update-view>
            </div>
            <div *ngIf="isPlayerMode()">
                <app-event-attendance-single-view *ngFor="let attendance of attendanceNotAnswered"
                                                  [attendance]="attendance"></app-event-attendance-single-view>
            </div>
        </div>
        <div fxLayout="column" fxFlex="25">
            <div *ngIf="isCoachMode() || isManagerMode()">
                <app-event-attendance-update-view *ngFor="let attendance of attendanceNotAvailable"
                                                  (onChangeStatus)="updateParent($event)"
                                                  [event]="event"
                                                  [attendance]="attendance"></app-event-attendance-update-view>
            </div>
            <div *ngIf="isPlayerMode()">
                <app-event-attendance-single-view *ngFor="let attendance of attendanceNotAvailable"
                                                  [attendance]="attendance"></app-event-attendance-single-view>
            </div>
        </div>
    </div>
</div>