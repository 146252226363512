import {
    Component, ElementRef,
    EventEmitter,
    Inject,
    Input, NgZone,
    OnChanges,
    OnInit,
    Output,
    PLATFORM_ID,
    SimpleChanges, ViewChild
} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {GlobalMeService} from '../../global/services/global-me.service';
import {debounceTime} from 'rxjs/operators';
// import {} from '@types/googlemaps';
// import {} from '@types/googlemaps';
import {} from '@types/googlemaps';
import {OnDemandJsLoadService} from '../../mdw/services/on-demand-js-load.service';
import {AddressWithLatLng} from '../models/address-with-lat-lng';
import {CommonComponent} from '../../common-component';


@Component({
    selector: 'app-address-autocomplete',
    templateUrl: './address-autocomplete.component.html',
    styleUrls: ['./address-autocomplete.component.scss']
})
export class AddressAutocompleteComponent extends CommonComponent implements OnInit, OnChanges {

    @Output() onItemSelected: EventEmitter<AddressWithLatLng> = new EventEmitter();
    @Output() onItemRemoved: EventEmitter<void> = new EventEmitter();
    @Input() disabled: boolean;
    @Input() isRequired: boolean;
    @Input() needLockFieldAfterSelection: boolean;
    @ViewChild('textInput') textInput: ElementRef;

    myControl = new FormControl(null, [Validators.required]);
    filteredPlaces: google.maps.places.AutocompletePrediction[];
    isSelected = false;
    selectedValue: AddressWithLatLng;
    search = false;

    autocompleteService: google.maps.places.AutocompleteService;
    geocoder: google.maps.Geocoder;

    constructor(private me: GlobalMeService,
                private onDemandJsLoadService: OnDemandJsLoadService,
                private zone: NgZone,
                @Inject(PLATFORM_ID) private platformId: Object) {
        super();
        this.filteredPlaces = [];
    }


    ngOnInit() {
        super.ngOnInit();
        this.myControl.valueChanges
            .pipe(debounceTime(400))
            .subscribe(value => {
                if (!this.search && !this.isSelected) {
                    this.filteredPlaces = [];
                    this.search = true;
                    this._filter(value).subscribe(values => {
                        this.zone.run(() => {
                            this.filteredPlaces = values;
                            this.search = false;
                            this.textInput.nativeElement.focus();
                        });
                    });
                }
            });
        this.onDemandJsLoadService
            .load('https://maps.googleapis.com/maps/api/js?key=AIzaSyAu5qwMtAAWGXvGVXxPrCNJsc8kQqbHt5o&libraries=places')
            .subscribe(loaded => {
                if (loaded) {
                    setTimeout(() => {
                        this.autocompleteService = new google.maps.places.AutocompleteService();
                        this.geocoder = new google.maps.Geocoder();
                    }, 0);
                }
            });
    }

    clearValue() {
        this.myControl.reset();
        if (!this.needLockFieldAfterSelection) {
            setTimeout(() => {
                this.isSelected = false;
                this.search = false;
            }, 500);
        }
        if (this.onItemRemoved) {
            this.onItemRemoved.emit();
        }
    }

    onPlaceSelected(event) {
        const placeId = event.option.value.place_id;
        const geoRequest: any = {};
        geoRequest.placeId = placeId;
        const that = this;
        this.geocoder.geocode(geoRequest, function (responses: any) {
            const address: AddressWithLatLng = new AddressWithLatLng();
            if (responses[0] !== null && responses[0] !== undefined) {
                const response = responses[0];
                let _address = '';
                let _number = '';
                response.address_components.forEach(c => {
                    if (c.types.some(t => t === 'street_number')) {
                        _number = c.long_name + ' ';
                    }
                    if (c.types.some(t => t === 'route')) {
                        _address = c.long_name;
                    }
                    if (c.types.some(t => t === 'locality')) {
                        address.city = c.long_name;
                    }
                    if (c.types.some(t => t === 'country')) {
                        address.country = c.long_name;
                    }
                    if (c.types.some(t => t === 'postal_code')) {
                        address.zipCode = c.long_name;
                    }
                });
                address.address = _number + _address;
                address.placeId = response.place_id;
                address.latitude = response.geometry.location.lat();
                address.longitude = response.geometry.location.lng();
                that.onItemSelected.emit(address);
                that.selectedValue = address;
                if (that.needLockFieldAfterSelection) {
                    that.myControl.reset();
                } else {
                    that.myControl.setValue(address.address);
                }
                that.isSelected = true;
                that.textInput.nativeElement.blur();
                that.filteredPlaces = [];

            }
        });
    }

    private _filter(value: string): Observable<google.maps.places.AutocompletePrediction[]> {
        const s: Subject<google.maps.places.AutocompletePrediction[]> = new Subject();
        if (value !== undefined && value !== '' && value !== null) {
            const filterValue = value.toLowerCase();
            this.autocompleteService.getPlacePredictions({
                input: filterValue
            }, function (predictions, status) {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    s.next(predictions);
                } else {
                    s.next([]);
                }
            });
        }
        return s.asObservable();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.disabled !== undefined) {
            if (changes.disabled.currentValue) {
                this.myControl.disable();
            } else {
                this.myControl.enable();
            }
        }
    }

    removeSelectedAddress() {
        this.isSelected = false;
        this.onItemSelected.emit(null);
        this.selectedValue = null;
    }

}
