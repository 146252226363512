import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Game} from '../../game/models/game';
import {Router} from '@angular/router';

@Component({
    selector: 'app-contest-game-item',
    templateUrl: './contest-game-item.component.html',
    styleUrls: ['./contest-game-item.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContestGameItemComponent implements OnInit {

    @Input() game: Game;
    @Output() toUpdate: EventEmitter<Game> = new EventEmitter<Game>();
    normalMode: boolean;
    editMode: boolean;


    constructor(private router: Router) {
    }

    ngOnInit() {
        this.passNormalMode();
    }

    passNormalMode() {
        this.normalMode = true;
        this.editMode = false;
    }

    passEditMode() {
        this.toUpdate.emit(this.game);
    }

    goToGame() {
        this.router.navigate(['games/' + this.game.id]);
    }


}
