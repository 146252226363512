import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LicencesService} from './services/licences.service';
import {LicencesMembersService} from './services/licences-members.service';
import {MdwUsualServiceModule} from '@mdw/usual-service';

@NgModule({
    imports: [
        CommonModule,
        MdwUsualServiceModule
    ],
    declarations: [],
    providers: [LicencesService, LicencesMembersService]
})
export class LicenceModule {
}
