import {IdWithName} from './id-with-name';

export class PlaceAutocompleteView extends IdWithName {

    fullAddress: string;

    constructor() {
        super();
    }

    build(): this {
        return this;
    }
}
