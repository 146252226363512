import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AccordionModule} from 'primeng/primeng';
import {ActionListComponent} from './action-list/action-list.component';
import {NotificationsComponent} from './notifications/notifications.component';
import {ActionService} from './services/action.service';
import {NotificationsService} from './services/notifications.service';

@NgModule({
    imports: [
        CommonModule,
        AccordionModule,
        FlexLayoutModule
    ],
    declarations: [ActionListComponent, NotificationsComponent],
    exports: [ActionListComponent],
    providers: [ActionService, NotificationsService]
})
export class MonitoringModule {
}
