import {Injectable} from '@angular/core';
import {SportModeFactory} from '../interfaces/sport-mode-factory';
import {environment} from '../../../../environments/environment';
import {BscModeFactoryService} from '../../../bsc/mode/bsc-mode-factory.service';

@Injectable()
export class ModeFactoryService {

    constructor(private bscModeFactoryService: BscModeFactoryService) {
    }

    get(): SportModeFactory {
        const mode = environment.sportMode;
        let s;
        switch (mode) {
            case 'BSC' :
                s = this.bscModeFactoryService;
                break;
        }
        return s;
    }

}
