import {Buildable, Dto} from '@mdw/usual-service';

export class Licence extends Dto implements Buildable<Licence> {


    name: string;
    sports: string[];
    gender: string;
    category: string;
    prices: any[];
    status: string;
    description: string;

    build(): Licence {
        super.build();
        return this;
    }
}
