import {Component, OnInit} from '@angular/core';
import {AccountService, AskAccount, AskAccountStatus, AsksAccountsFilter} from '@genos/user-service';
import {BreadcrumbService} from '../../../global/services/breadcrumb.service';
import {MessageService} from '../../../global/services/message.service';

@Component({
    selector: 'app-asks-account',
    templateUrl: './asks-account.component.html',
    styleUrls: ['./asks-account.component.scss']
})
export class AsksAccountComponent implements OnInit {

    asks: AskAccount[];
    loadInfo: boolean;
    selectedAsk: AskAccount;
    askIsSelected: boolean;

    constructor(private breadcrumbService: BreadcrumbService,
                private messageService: MessageService,
                private accountService: AccountService) {
    }

    ngOnInit() {
        this.asks = [];
        this.breadcrumbService.setAdminUserChild([{label: 'Demandes de compte'}]);
        this.loadAsks();

    }

    loadAsks() {
        this.loadInfo = true;
        this.askIsSelected = false;
        this.accountService.getAskAccount(new AsksAccountsFilter()).subscribe(asks => {
            this.asks = asks;
            this.loadInfo = false;
        });
    }

    acceptAsk() {
        this.accountService.acceptAskAccount(this.selectedAsk).subscribe(asks => {
            this.messageService.addSuccess('Création de compte effectuée',
                'Un compte associé à la demande à été créer et un mail a été envoyé pour prévenir le proprietaire du compte');
            this.loadAsks();
        });
    }

    rejectAsk() {
        this.accountService.rejectAskAccount(this.selectedAsk).subscribe(asks => {
            this.messageService.addSuccess('Création de compte refusée',
                'La demande à été refusée');
            this.loadAsks();
        });
    }


    canBeRefuse(): boolean {
        return this.selectedAsk.status === AskAccountStatus.NEED_VALIDATION;
    }

    canBeValidate(): boolean {
        return this.selectedAsk.status !== AskAccountStatus.VALIDATED;
    }

    onRowSelect($event) {
        this.selectedAsk = $event.data;
        this.askIsSelected = true;
    }

}
