export class Data {

    label: string;
    old: string;
    new: string;

    constructor(label: string, o: string, n: string) {
        this.old = o;
        this.label = label;
        this.new = n;
    }

    isSame(): boolean {
        return this.old === this.new;
    }
}
