import {Injectable} from '@angular/core';
import {Group} from '../models/group';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {GroupsFilter} from '../models/groups-filter';
import {GroupStatus} from '../enums/group-status.enum';
import {GroupType} from '../enums/group-type.enum';
import {DtoSecurisedService, GlobalSessionService,} from '@genos/user-service';
import {NaturalPerson} from '@genos/person-service';
import {AppConfigService} from '@mdw/usual-service';

@Injectable()
export class GroupsService extends DtoSecurisedService<Group> {


    constructor(protected sessionService: GlobalSessionService,
                protected client: HttpClient,
                protected appConfigService: AppConfigService) {
        super(client, sessionService);
    }

    getBaseUrl(): string {
        return this.appConfigService.getSecuredBaseUrl() + '/groups';
    }

    update(group: Group): Observable<Group> {
        return this.put<Group>(this.getBaseUrl() + '/' + group.id, group);
    }

    create(group: Group): Observable<Group> {
        return this.post<Group>(this.getBaseUrl(), group);
    }

    remove(idGroup: number): Observable<void> {
        return this.delete<void>(this.getBaseUrl() + '/' + idGroup);
    }

    getMainRoster(idTeam: number): Observable<Group> {
        const filter = new GroupsFilter();
        filter.idTo = idTeam;
        filter.status = GroupStatus.MAIN;
        filter.toType = 'TEAM';
        filter.type = GroupType.ROSTER;
        return this.getSingleFiltered(filter);
    }

    getRosters(idTeam: number): Observable<Group[]> {
        const filter = new GroupsFilter();
        filter.idTo = idTeam;
        filter.toType = 'TEAM';
        filter.type = GroupType.ROSTER;
        return this.getAll(filter);
    }

    getById(id: number): Observable<Group> {
        return this.getObject(this.getBaseUrl() + '/' + id, {}, Group);
    }

    getAll(filter: GroupsFilter): Observable<Group[]> {
        return this.getArray(this.getBaseUrl() + filter.toUrl(), {}, Group);
    }

    getSingleFiltered(filter: GroupsFilter): Observable<Group> {
        const s: Subject<Group> = new Subject<Group>();
        this.getArray(this.getBaseUrl() + filter.toUrl(), {}, Group).subscribe(groups => {
            if (groups.length > 1) {
                s.error({message: 'Several results'});
            } else if (groups.length === 0) {
                s.error({message: 'No result'});
            } else {
                s.next(groups[0]);
            }
        });
        return s.asObservable();
    }

    getMembers(group: Group): Observable<NaturalPerson[]> {
        return this.getArray(this.getBaseUrl() + '/' + group.id + '/members', {}, NaturalPerson);
    }

    saveMembers(group: Group, members: NaturalPerson[]): Observable<NaturalPerson[]> {
        return this.post<NaturalPerson[]>(this.getBaseUrl() + '/' + group.id + '/members', members);
    }


    getMain(): Observable<Group> {
        const s: Subject<Group> = new Subject<Group>();
        const cf = new GroupsFilter();
        cf.status = GroupStatus.MAIN;
        this.getArray(this.getBaseUrl() + cf.toUrl(), {}, Group).subscribe(groups => {
            let group;
            if (groups.length === 0) {
                group = new Group();
            } else {
                group = groups[0];
            }
            s.next(group);
        });
        return s.asObservable();
    }

}
